<template>
  <component v-bind:is="value"></component>
</template>

<script>
import Normal from "./Normal.vue";
import Alert from "./Normal.vue";
import Off from "./Normal.vue";
export default {
  name: "livIcons",
  props: {
    value: String,
  },
  components: {
    Normal,
    Alert,
    Off,
  },
};
</script>
import Module from "@/core/module/_module";

export default class Ligar {
    module: Module
    /**
     * Tem que receber um tipo o repository mas modulo que ira escolher como vai chegar no modulo
     */
    constructor(module: Module) {
        this.module = module
    }
    execute(component: any) {
        this.module.send("module", component)
    }
}
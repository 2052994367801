import Repository from "@/core/repository/_repository";
export default class ConectaServidorLocal {
    static action = {
        timeout: 10000,
        text: "Conectando Servidor Local"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        form.model.servidorlocal = fields.servidorlocal
        const [result, err] = await this.repository.update("UpdateProjetos", form.model._id, {
            _id: form.model._id,
            projId: form.model.projId,
            nome: form.model.nome,
            tipo: form.model.tipo,
            revenda: form.model.revenda,
            servidorlocal: fields.servidorlocal
        })
        console.log(result, err);
        return "refresh"
    }
}
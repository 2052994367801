<template>
  <app-layout :header="header" @dropDown="mostraDropDown" @page="setPage">
    <main class="d-grid gap conta" onselectstart="return false">
      <dinamic-form v-if="mostraform" :adapter="adapter"></dinamic-form>
    </main>
  </app-layout>
</template>
  
<script>
import appLayout from "../layouts/appLayout.vue";
import DinamicForm from "@/components/Form/_formdinamic.vue";
import FormUpdateUsuario from "@/Features/Usuario/forms/updateUsuario";
import GetUsername from "@/Features/Usuario/usecases/getUsername"
export default {
  name: "page-conta",
  components: {
    appLayout,
    DinamicForm
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data: () => {
    return {
      header: { title: "", subtitle: "Conta", labels: [] },
      projetos: [],
      dropDown: false,
      mudaConexao: false,
      topDropDown: 0,
      tentativas: 0,
      adapter: {},
      mostraform: false
    };
  },
  async created() {
    console.log("\n\n#### entrou no hall");
    await this.$socket.waitSocket();
    document.title = "Hall";
    this.pegaPage();
    this.header.labels = [
      { name: "Hall", path: "hall", selected: false },
      { name: "Conta", path: "conta", selected: true },
      { name: "Servidor Local", path: "servidorlocal", selected: false },
    ];
  },
  sockets: {
    pronto() {
      this.$store.commit("setSocketReady", true);
      console.log("POSSO MANDAR MENSAGEM HALL");
    },
  },
  methods: {
    async pegaPage() {
      const [result, err] = await this.$repository.findOne("Usuarios", {
        username: await new GetUsername(this.$repository).execute(),
      });
      if (err) console.error(result);
      else {
        console.log(result)
        this.header.title = result.nome;

        this.adapter = new FormUpdateUsuario(this.$repository, result)
        this.mostraform = true
      }
    },
  },
  unmounted() {
    console.log("saiu do monitoramento");
  },
};
</script>

<style>
.conta form.gap-mid {
  gap: 1em
}
</style>
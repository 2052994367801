<template>
  <div class="d-grid">
    <div :class="style" @click="click">
      {{ name }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  name: "add",
  props: {
    field: Object,
  },
  created() {
    this.value = this.$attrs.modelValue;
    if (!this.value || this.value === "") {
      this.style = "botao";
      this.item = this.field.list[0];
    } else {
      this.style = "objects";
      this.item = this.field.list[1];
      this.name = this.item.name;
    }
  },
  data() {
    return {
      value: "",
      type: "text",
      style: "botao",
      name: "Adicionar",
    };
  },
  methods: {
    click() {
      console.log("clicou");
      console.log(this.item);
      this.$emit("object", this.item);
    },
  },
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
}
</style>
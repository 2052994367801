import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { modal } from "@/stores/showModal";
import FormEndpoints from "./Endpoint/endpoints";
import FormSelectProject from "./selectProject";

export default class FormUpdateUsuario {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const add = new FormSelectProject(this.repository, {titulo: "Selecionar", user: this.model})
        return {
            "extras": new Extra("Dados do Usuário", "Atualizar", "updateusuario"),
            "nome": new Field("text", "Nome", "Seu nome completo"),
            "username": new Field("show", "Nome de Usuário", "Nome de usuário único"),
            "amzemail": new Field("text", "E-mail Amazon", "seu email da conta amazon"),
            "projfavorite": Field.make("object", "Projeto Favoríto", [add], (i) => {
                modal.show(i)
                return false
            }),
            "notifications": Field.make("object", "Notificações", [new FormEndpoints(this.repository, this.model)], (i) => {
                modal.show(i)
                return false
            }),
            "error": new Field("error", "Erro", ""),
        }
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import Device from "../entity/device"
import FormConfigWIFIServidorLocal from "./wifi"
import { snackbar } from "@/stores/login"
import FormNome from "@/Features/Componentes/forms/nome"
import { wait } from "@/stores/showModal"

export default class FormConfigServidorLocal {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.model.objname = "Configurar"
        this.repository = repository
    }

    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "none", ""),
            "config": Field.make("show", "Seu navegador nao é compatível com Bluetooth"),
        }
        if (!window.navigator.bluetooth) return fields

        delete fields.config
        wait.show(30000, "Conectando Bluetooth")
        await Device.getDevice()
        wait.show(0, "")
        fields["extras"] = new Extra(this.model.nome, "Enviar", "end/configservidorlocal/true"),
        fields["setuuid"] = Field.make("object", "Registrar UUID no servidor Local", [new FormNome(this.repository, {objname: "Registrar"})], async (): Promise<"none"> => {
            const [characteristic, err] = await Device.getCharacteristic("322e774f-c909-49c4-bd7b-48a4003a967f")
            if(err) {
                snackbar.show("Error ao enviar via Bluetooth", 3000, "error")
                return "none"
            }
            
            characteristic.writeValueWithoutResponse(new TextEncoder().encode(JSON.stringify({uuid: this.model.uuid})))
            snackbar.show("UUID configurado no servidor local", 3000, "success")

            return "none"
        })
        fields["wifi"] = Field.make("object", "WI-FI", [new FormConfigWIFIServidorLocal(this.repository, this.model)])
        return fields
    }
}
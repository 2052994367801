import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { modal } from "@/stores/showModal";
import GetUsername from "../../usecases/getUsername";

export default class FormInscricoes {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.model.objname = "Gerenciar"
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        /**
         * vou ter que fazer no banco de dados uma view com array de inscricoes e de projetos dai que eu relaciono
         */
        console.log(this.model)
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra("Notificando", "Atualizar", "end/updateinscricoes/true"),
        }
        const [result, err] = await this.repository.findOne("EnpointsInscricoesProjetos", {
            _id: this.model._id,
            username: await new GetUsername(this.repository).execute(),
        })
        if (err) return fields
        console.log(result, err)
        this.model.inscricoes = {}
        result.user.projetos.forEach((p: any) => {
            const inscricao = result.inscricoes.find((i: any) => i.projId === p.projId)
            if (inscricao) {
                this.model.inscricoes[p.projId] = { criar: false, _id: inscricao._id }
                this.model[p.projId] = inscricao.notificar
            } else {
                this.model.inscricoes[p.projId] = { criar: true }
                this.model[p.projId] = false
            }
            fields[p.projId] = Field.make("toggle", p.projeto.nome)
        })
        return fields
    }
}
export default class Modos {
    static eventosCompatibilidade: { [key: string]: string[] } = {
        "FUMAÇA-DISPARO-S.FUMAÇA": ["modulo", "r10", "ms8"],
        "FUMAÇA-REST-S.FUMAÇA": ["modulo", "r10", "ms8"],
        "TEMP-DISPARO-TEMPERATURA": ["modulo", "r10", "ms8", "mu5", "gtr"],
        "TEMP-REST-TEMPERATURA": ["modulo", "r10", "ms8", "mu5", "gtr"],
        "MOVI-STATUS-ABRIU PORTA": ["modulo", "r10", "ms8"],
        "MOVI-STATUS-FECHOU PORTA": ["modulo", "r10", "ms8"],
        "MOVI-STATUS-S. MOVI. (E)": ["modulo", "r10", "ms8", "mu5", "gtr"],
        "MOVI-STATUS-S. MOVI. (R)": ["modulo", "r10", "ms8", "mu5", "gtr"],
        "MOVI-DISPARO-ABRIU PORTA": ["modulo", "r10", "ms8"],
        "MOVI-REST-FECHOU PORTA": ["modulo", "r10", "ms8"],
        "MOVI-DISPARO-S. MOVI. (E)": ["modulo", "r10", "ms8", "mu5", "gtr"],
        "MOVI-REST-S. MOVI. (R)": ["modulo", "r10", "ms8", "mu5", "gtr"],
        "MOVI-REARME-TEMPO": ["modulo", "r10", "ms8"],
        "MOVI-REARME-dt3": ["modulo", "r10", "ms8"],
        "MOVI-DESARME-dt3": ["modulo", "r10", "ms8"],
        "subida": ["interruptor", "saida", "dimmer", "entradadigital", "digital-in"],
        "descida": ["interruptor", "saida", "dimmer", "entradadigital", "digital-in"],
        "troca": ["interruptor", "saida", "dimmer"],
        "interruptor": ["entradadigital", "digital-in"],
        "pulsador": ["entradadigital", "digital-in"],
        "clique": ["entradadigital", "digital-in"],
        "retencao": ["entradadigital", "digital-in"]
    }

    static eventosNomes: { [key: string]: string } = {
        "FUMAÇA-DISPARO-S.FUMAÇA": "Disparo Sensor Fumaça",
        "FUMAÇA-REST-S.FUMAÇA": `Restauração Sensor Fumaça`,
        "TEMP-DISPARO-TEMPERATURA": `Disparo Temperatura`,
        "TEMP-REST-TEMPERATURA": `Restauração Temperatura`,
        "MOVI-STATUS-ABRIU PORTA": `Movimento Status Abriu Porta`,
        "MOVI-STATUS-FECHOU PORTA": `Movimento Status Fechou Porta`,
        "MOVI-STATUS-S. MOVI. (E)": `Movimento Status S. MOVI. (E)`,
        "MOVI-STATUS-S. MOVI. (R)": `Movimento Status S. MOVI. (R)`,
        "MOVI-DISPARO-ABRIU PORTA": `Movimento Disparo Abriu Porta`,
        "MOVI-REST-FECHOU PORTA": `Movimento Restauração Fechou Porta`,
        "MOVI-DISPARO-S. MOVI. (E)": `Movimento Disparo S. MOVI. (E)`,
        "MOVI-REST-S. MOVI. (R)": `Movimento Restauração S. MOVI. (R)`,
        "MOVI-REARME-TEMPO": `Movimento Rearme Tempo`,
        "MOVI-REARME-dt3": `Movimento Rearme dt3`,
        "MOVI-DESARME-dt3": `Movimento Desarme dt3`,
        "subida": "Ao Ligar",
        "descida": "Ao Desligar",
        "troca": "Ao Trocar",
        "interruptor": "Interruptor",
        "pulsador": "Pulsador",
        "clique": "Clique",
        "retencao": "Retenção"
    }

    static getEventos(tipo: string): { value: string; name: string }[] {
        const eventos: string[] = []
        for (const key in this.eventosCompatibilidade) {
            if (this.eventosCompatibilidade[key].find(e => e === tipo))
                eventos.push(key)
        }

        return eventos.map(e => { return { value: e, name: this.eventosNomes[e] } })
    }
}
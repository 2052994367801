<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="32"
    height="32"
    viewBox="0 0 8.4666665 8.4666669"
    version="1.1"
    id="svg5029"
    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    sodipodi:docname="cadeado-aberto-24x30.svg"
    class="icon-warning"
  >
    <defs id="defs5023" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="7.9195959"
      inkscape:cx="1.2673632"
      inkscape:cy="10.45875"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1366"
      inkscape:window-height="705"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      units="px"
      inkscape:showpageshadow="true"
    />
    <g
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-288.53332)"
    >
      <g
        id="g3772-6-6-8"
        transform="matrix(0.03225528,0,0,0.03225528,0.45091908,288.72152)"
        inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\cadeado-aberto-24x30.png"
        inkscape:export-xdpi="96"
        inkscape:export-ydpi="96"
      >
        <path
          d="M 160.44,89.902 H 74.524 V 52.345 C 74.524,34.51 89.034,20 106.87,20 c 17.836,0 32.346,10.637656 32.346,28.472656 v 0.459957 c 0,5.523 4.478,10 10,10 5.522,0 10,-4.477 10,-10 V 48.472656 C 159.215,19.609656 135.733,0 106.87,0 78.007,0 54.524,23.482 54.524,52.345 v 37.557 h -1.225 c -15.244,0 -27.646,12.402 -27.646,27.646 v 106.908 c 0,15.923 12.954,28.878 28.878,28.878 h 104.678 c 15.924,0 28.878,-12.955 28.878,-28.878 V 117.548 c 0,-15.244 -12.403,-27.646 -27.647,-27.646 z m 7.647,134.554 c 0,4.896 -3.982,8.878 -8.878,8.878 H 54.531 c -4.896,0 -8.878,-3.982 -8.878,-8.878 V 117.548 c 0,-4.216 3.431,-7.646 7.646,-7.646 H 160.44 c 4.216,0 7.646,3.43 7.646,7.646 v 106.908 z"
          id="path3762-0-2-3"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="scsssssscsscsssssssscssssssscc"
        />
        <path
          d="m 106.87,134.44 c -11.409,0 -20.691,9.282 -20.691,20.691 0,7.783 4.324,14.57 10.691,18.102 v 25.562 c 0,5.523 4.478,10 10,10 5.522,0 10,-4.477 10,-10 v -25.562 c 6.368,-3.532 10.691,-10.319 10.691,-18.102 0,-11.409 -9.282,-20.691 -20.691,-20.691 z"
          id="path3764-2-2-0"
          inkscape:connector-curvature="0"
        />
        <path
          d="m 178.588,54.182 c 1.221,0.49 2.48,0.721 3.72,0.721 3.965,0 7.718,-2.375 9.284,-6.28 l 7.445,-18.563 c 2.056,-5.126 -0.433,-10.948 -5.559,-13.004 -5.125,-2.056 -10.948,0.433 -13.004,5.559 l -7.445,18.563 c -2.055,5.126 0.433,10.947 5.559,13.004 z"
          id="path3766-4-5-1"
          inkscape:connector-curvature="0"
        />
        <path
          d="m 190.093,66.501 c 1.623,3.796 5.317,6.071 9.2,6.071 1.312,0 2.645,-0.259 3.926,-0.808 l 18.39,-7.862 c 5.078,-2.171 7.436,-8.047 5.265,-13.126 -2.172,-5.078 -8.052,-7.436 -13.126,-5.264 l -18.39,7.862 c -5.078,2.172 -7.436,8.048 -5.265,13.127 z"
          id="path3768-5-0-4"
          inkscape:connector-curvature="0"
        />
        <path
          d="m 221.085,85.232 -18.563,-7.445 c -5.126,-2.056 -10.948,0.432 -13.004,5.559 -2.056,5.126 0.433,10.948 5.559,13.004 l 18.563,7.445 c 1.221,0.49 2.48,0.721 3.72,0.721 3.965,0 7.718,-2.375 9.284,-6.28 2.055,-5.126 -0.433,-10.948 -5.559,-13.004 z"
          id="path3770-3-0-9"
          inkscape:connector-curvature="0"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "waiting",
};
</script>
<template>
  <div class="d-grid">
    <form class="d-flex gap-s" style="width: 100%" v-on:submit="uploadFile">
      <input type="file">
      <button style="width: 30%" type="submit">Enviar</button>
    </form>
  </div>
</template>
  
<script>
import SafeJSONParser from "@/core/lib/safejsonparser";
import { getStorage } from "@/scripts/Storage";
import { server } from '@/stores/login';
import { defineComponent } from "vue";
import { wait } from "@/stores/showModal"
export default defineComponent({
  name: "text",
  props: {
    field: Object,
    formu: Object,
    atribkey: String
  },
  data() {
    return {
      value: "url",
      url: "",
      errfilesize: false
    };
  },
  created() {
    this.url = this.$attrs.modelValue;
  },
  methods: {
    setValue() {
      this.$emit("updateAtrib", "url", this.url);
    },
    async uploadFile(e) {
      wait.show(60000, "Enviando")
      e.preventDefault();
      console.log(e.target[0].files[0])
      if (e.target[0].files[0] && e.target[0].files[0].size > 104857600) {
        wait.show(0)
        return this.errfilesize = true
      }
      this.errfilesize = false
      const data = new FormData()
      data.append("projId", this.formu.model.projId)
      data.append("element", this.formu.model._id)
      data.append("file", e.target[0].files[0])
      console.log(data);
      const request = new XMLHttpRequest();
      request.onreadystatechange = () => {
        if (request.readyState === 4 && request.status === 200) {
          wait.show(0)
          console.log(request.responseText)
          const [result, err] = SafeJSONParser(request.responseText)
          if (err) return console.error(result)
          const [name, e] = result
          if (e) return
          console.log(name)
          this.url = `${server.url}/_img/${name}`
          this.value = "url"
          this.$emit("updateAtrib", "url", this.url);
        } else if (request.status > 300) return
      }
      request.open("POST", `${server.url}/uploadfile`)
      request.setRequestHeader("accessToken", (await getStorage("accessToken")).value)
      request.setRequestHeader("refreshToken", (await getStorage("refreshToken")).value)
      request.send(data)
    }
  }
});
</script>

<style>
.selectfile * {
  width: inherit;
}
</style>
<template>
    <ion-page>
        <main class="display" :class="{portrait: portrait}" v-on:keypress="fullscreen" :key="display.key">
            <component v-for="(item, index) in elements" :key="index" :is="item.tipo" :element="item"></component>
        </main>
    </ion-page>
</template>

<script>
import { IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import table from "./table.vue";
import image from "./image.vue";
import iframe from "./iframe.vue";
import square from "./square.vue";
import video from "./video.vue";
import text from "./text.vue";
import carrossel from "./carrossel.vue";
import { display } from "@/stores/components"
import { rmStorage } from "@/scripts/Storage";
export default defineComponent({
    name: "appLayout",
    components: {
        IonPage,
        table,
        image,
        square,
        video,
        text,
        iframe,
        carrossel
    },
    props: {
        header: Object,
    },
    data() {
        return {
            elements: [],
            key: 0,
            display,
            portrait: false,
            removecode: "rd",
            removecount: 0
        };
    },
    async created() {
        console.log(this.$route.params)
        document.onkeydown = this.fullscreen
        if (this.$state.state.name === "start" || !this.$socket.connected)
            await this.$socket.waitSocket();
        this.$state.setState("project")
        const [root, err] = await this.$repository.findOne("RootComponentes", this.$route.params)
        if (err && this.$state.state.name === "start") {
            this.key++
            console.error(root);
            return
        }
        console.log(root)
        root.componentes.forEach(o => this.elements.push(o))
        display.key = 0
        display.elements = this.elements
        if(root.options && root.options.orientation === "portrait") this.portrait = true
        //this.fullscreen({key: "f"})
    },
    methods: {
        fullscreen(e) {
            console.log(e)
            if(e.key === this.removecode[this.removecount]){
                this.removecount++
                if(this.removecount === this.removecode.length)
                    rmStorage("directUrl")
                return
            }
            this.removecount = 0
            if(e.key === "f")
            document.documentElement.requestFullscreen()
                .catch(err => console.error(err))
                .then(e => console.log(e))
        }
    },
});
</script>

<style>
.display {
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-template-rows: repeat(18, 1fr);
    height: 100vh;
}
main.display.portrait {
    transform: rotate(-90deg);
    grid-template-columns: repeat(18, calc(100vh / 18));
    grid-template-rows: repeat(18, calc(100vw / 18));
    height: 100vh;
    width: 100vh;
}
</style>
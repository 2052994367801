import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormConfigEventDigitalIn {
    repository: Repository
    model: any
    fields: any = {}
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.titulo, "Atualizar", "updatecomponente"),
            "notify": Field.make("toggle", "Ativar notificação"),
        }
        fields["false"] = Field.make("text", "Descrição para 0", "Fechou")
        fields["true"] = Field.make("text", "Descrição para 1", "Abriu")
        return fields
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
export default class FormUpdateWallpaper {
    repository: Repository
    model: any
    value: string
    constructor(repository: Repository, model: any) {
        this.model = model
        this.value = model.value
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const [result, err] = await this.repository.findMany("Wallpapers", {})
        console.log(result, err)
        
        return {
            "extras": new Extra(this.model.name, "Atualizar", "end/updatewallpaper/true", {}, true, "deletewallpaper/true"),
            "name": Field.make("text", "Nome descritivo", "Sala de Estar"),
            "url": Field.make("file", "Imagem", "")
        }
    }
}
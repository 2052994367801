import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import FormConfigServidorLocal from "./config"

export default class FormUpdateProjetoLocal {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }

    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra(this.model.nome, "Atualizar", "end/updateprojetolocal/true"),
            "nome": Field.make("show", "Nome"),
            "projId": Field.make("copy", "ID do projeto"),
            "tipo": Field.make("show", "Tipo"),
            "ip": Field.make("text", "IP", "192.168.1.100"),
        }
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import AdicionarProgramacaoEventoModulo from "./servidor/adicionarProgramacao"
import SelectModos from "./servidor/entradaprogramacao"
import Modos from "../entity/modos"
import FormSelectEventos from "./servidor/selectEventos"

export default class FormComponenteProgramacao {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        console.log(Modos.getEventos(this.model.model.tipo))
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "none", ""),
        }

        const [result, err] = await this.repository.findOne("EntradaProgramacoes", {
            projId: this.model.model.projId,
            _id: this.model.model.conexao,
        })
        if (err) console.error(result);
        else console.log(result);

        const add = new FormSelectEventos(this.repository, { titulo: "+ Adicionar", model: this.model.model })
        const operadores: SelectModos[] = []
        if (result) {
            this.model.have = result.programacoes.length
            result.programacoes.forEach((o: any) => {
                o.componentType = this.model.model.tipo
                operadores.push(new SelectModos(this.repository, o, this.model.model))
            });
        } else this.model.have = 0
        operadores.push(add)
        fields.operadores = new Field("objectV", "Eventos", "", operadores, this.model, (o: any) => {
            console.log(o);
            this.model.model.programacaoentrada = o.model
            return true
        })
        return fields
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { setStorage } from "@/scripts/Storage";
import OrderComponent from "../../controllers/order";
import FormComponent from "../../forms/component";
import FormNome from "../../forms/nome";
import Forms from "../../forms/_list";
import Componente from "../../_componente";
import ChooseSelect from "./elementos/_choose";
export default class FormDisplay extends FormComponent {
    constructor(repository: Repository, model: Componente) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields = await super.getFields()
        delete fields.conexao
        delete fields.local
        delete fields.action
        //TODO: aqui tem que ir para a lista de tipos para no futuro poder adiconar componentes no display tbm
        const add = new ChooseSelect(this.repository, { titulo: "+ Adicionar", root: this.model })
        const componentes: any[] = [];
        const [root, err] = await this.repository.findOne("RootComponentes", {
            _id: this.model._id,
            projId: this.model.projId
        })
        if(err) return fields
        if (root)
        root.componentes.forEach((o: any) => {
            componentes.push(new Forms.list[o.tipo](this.repository, o))
        });
        componentes.push(add)
        this.model.elements = componentes
        const order = new OrderComponent(this.repository, this.model)
        fields.orientation = new Field("select", "Orientação", "Selecione", [
            {value: "landscape", name: "Paisagem"},
            {value: "portrait", name: "Retrato"}
        ])
        fields.componentes = Field.make("objectVL", "Elementos", componentes, {order: async i => {
            await order.setOrder(i)
            return false
        }})
        fields.direct = new Field("object", "Deixar", "", [new FormNome(this.repository, {nome: "Aparecendo Direto"})], "", (o) => {
            setStorage("directUrl", `/p/${this.model.projId}/display/${this.model._id}`)
            o.model.nome = "Configurado"
            return "stay"
        })
        return fields
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import RandomToken from "@/core/lib/randomtoken";
import Repository from "@/core/repository/_repository";
import FormNome from "@/Features/Componentes/forms/nome";
import Socket from "@/service/socket";
import { snackbar } from "@/stores/login";
import { modal, wait } from "@/stores/showModal";
import FormInscricoes from "./inscricoes";

export default class FormConfigEndpoint {
    repository: Repository
    model: any
    user: any
    name: string
    constructor(repository: Repository, model: any, user: any) {
        this.model = model
        this.name = model.nome
        this.user = user
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.name, "Atualizar", "end/updateendpoint/true", {}, true, "deleteendpoint/true"),
            "tipo": Field.make("show", "Tipo"),
            "nome": Field.make("text", "Nome", "Celular 1, Telegram, Computador Empresa..."),
            "ativo": Field.make("toggle", "Habilitado"),
            "projetos": Field.make("object", "Projetos", [new FormInscricoes(this.repository, this.model)])
        }
        if (this.model.tipo === "telegram" && this.model.endpoint === "")
            fields["gettelegram"] = Field.make("object", "Endpoint", [new FormNome(this.repository, { objname: "Já adicionei na Conversa" })], async (): Promise<"refresh"> => {
                wait.show(5000, "Pegando dados do endpoint no servidor")
                const [result, err] = await this.repository.findUseCase("EndpointsOld", { tipo: this.model.tipo, username: this.user.username })
                if (err) snackbar.show("Erro ao pegar endpoint. Tente novamente", 3000, "error")
                else {
                    wait.msg = "Salvando dados do endpoint"
                    const [update, err] = await this.repository.update("Endpoints", this.model._id, {
                        endpoint: result.endpoint, username: this.user.username
                    })
                    if (err) snackbar.show(`Erro ao Salvar endpoint.${update}`, 3000, "error")
                    else {
                        snackbar.show("Endpoint configurado com sucesso", 3000, "success")
                        this.model.endpoint = result.endpoint
                    }
                }
                wait.show(0, "")
                return "refresh"
            })
        else fields["test"] = Field.make("object", "Testar Endpoint", [new FormNome(this.repository, {objname: "Testar"})], async (): Promise<"none"> => {
            const event = `endpoint/${RandomToken()}`
            Socket.io.emit(event, this.model)
            const [result, err] = await Socket.io.wait(event)
            if(err) snackbar.show(result, 3000, "error")
            else snackbar.show(result, 3000, "success")
            return "none"
        })
        return fields
    }
}
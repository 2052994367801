import Repository from "@/core/repository/_repository";
import Botao from "../../botao/_botao";
import Componente from "../../_componente";

export default class CreateAcesso {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }

    async execute(root: any, fields: any): Promise<[Componente, boolean]> {
        const component = new Componente();
        component.tipo = "acesso"
        component.projId = root.projId
        component.projeto = root.projeto
        component.main = true
        component.titulo = fields.titulo
        component.func = "manage"

        const [result, err] = await this.repository.create("Componentes", component)
        if (err) console.error(result)
        else Object.assign(component, result)

        const botoes = [
            { tipo: "botao", title: "Cancelar Alarme", action: "commandmqtt", command: "255,1,7,12,0,1,32,10,1,62", type: "noedit", index: 1 },
            { tipo: "botao", title: "Liberar Porta", action: "commandmqtt", command: "255,1,7,12,0,1,32,11,1,63", type: "noedit", index: 2 },
            { tipo: "statussub", title: "Sensor Porta", action: "", type: "noedit", index: 3, icon: { icon: "Fecho", status: "Close" }, state: "fechado"},
            { tipo: "ultimoacesso", title: "Evento", action: "", type: "noedit", index: 4, date: new Date(), causer: "--"}
        ].map(b => {
            const botao = new Botao()
            botao.projId = root.projId
            botao.projeto = root.projeto
            botao.tipo = b.tipo
            botao.titulo = b.title
            botao.options.action = b.action
            botao.options.command = b.command ? b.command : ""
            botao.options.type = b.type ? b.type : "button"
            botao.options.state = b.state
            botao.options.icon = b.icon
            if(b.date) Object.assign(botao.options, {date: b.date, causer: b.causer})
            botao.index = b.index
            botao.painel = component._id
            botao.control = component._id
            return botao
        });

        for (const botao of botoes) {
            const [result, err] = await this.repository.create("Componentes", botao)
            if (err) console.error(result);
        }

        return [component, false]
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import { wait } from "@/stores/showModal"

export default class FormPrograma {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.repository = repository
        this.model = model
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        let action = "Atualizar"
        if (!this.model.duracao) {
            //aqui tem que mostrar o modal do wait 
            wait.show(30000, "Pegando informações do painel")
            this.model.duracao = 0
            const durations: number[] = []
            for (const c of this.model.tela.componentes) {
                if (c.tipo !== "video") continue
                const duration = await new Promise<number>(res => {
                    wait.show(30000, "Pegando tempo do vídeo. Salve a duracão pra deixar permanentes")
                    action = "Salvar"
                    fetch(c.options.url)
                        .then(response => response.blob())
                        .then(blob => {
                            const video = document.createElement("video")
                            video.src = URL.createObjectURL(blob);
                            video.load()
                            video.onloadeddata = function () {
                                console.log({ "video": video.duration })
                                res(Math.floor(video.duration))
                            }
                        })
                        .catch(err => {
                            console.error(err);
                            res(0)
                        });
                })
                durations.push(duration)
            }
            console.log(durations)
            durations.forEach(d => {
                if (this.model.duracao < d) this.model.duracao = d
            })
            if(!durations.length) this.model.duracao = "00:00:00"
            else this.model.duracao = this.secondsToHHMMSS(this.model.duracao, 3600)
            wait.show(0,"")
        }
        return {
            "extras": new Extra(this.model.titulo, action, "end/updateprograma/true", {}, true, "deleteprograma/true"),
            "duracao": Field.make("times", "Duração", "1m30s")
        }
    }

    /**
     * Funcao para transformar tempo em seguns para padrão do input time
     * @param n 
     * @param max 
     * @returns 
     */
    secondsToHHMMSS(n: number, max: number): string{
        if(max === 1) return n < 10 ? `0${n}` : n.toString()
        const now = Math.floor(n/max)
        const ant = this.secondsToHHMMSS(n - now * max, max / 60)
        return `${now < 10 ? `0${now}` : now}:${ant}`
    }

}
<template>
  <ion-page>
    <main class="d-grid login">
      <div class="d-grid gap-mid jc-center ac-center h-100">
        <img class="logo" src="/_img/logo_livtech_v.svg" alt="logo" />
      </div>
      <div class="d-grid gap-mid jc-center ac-center h-100 login">
        <h1>{{ title }}</h1>
        <dinamic-form :adapter="adapter" v-bind:key="path" @forgot="actionExtra"></dinamic-form>
        <loginopt :extra="extra" @extra="actionExtra"></loginopt>
        <p v-if="snackbar.showing" class="snackbar animation-up" :class="snackbar.state">
          {{ snackbar.text }}
        </p>
      </div>
    </main>
  </ion-page>
</template>

<script>
import AdapterCreateUsuario from "@/Features/Usuario/adapters/createUsuario";
import AdapterForgotPassword from "@/Features/Usuario/adapters/forgotPassword";
import AdapterChangePassword from "@/Features/Usuario/adapters/changePassword";
import AdapterLogin from "@/Features/Usuario/adapters/login";
import DinamicForm from "@/components/Form/_form.vue";
import loginopt from "@/components/Form/LoginOpt.vue";
import { IonPage } from "@ionic/vue";
import { login, snackbar } from "@/stores/login";
import Socket from "@/service/socket";
export default {
  name: "page-login",
  components: {
    DinamicForm,
    loginopt,
    IonPage,
  },
  data() {
    return {
      adapter: {},
      adapters: {
        login: AdapterLogin,
        criarconta: AdapterCreateUsuario,
        forgot: AdapterForgotPassword,
        change: AdapterChangePassword,
      },
      path: 0,
      title: "Login",
      extra: {
        text: "Não possui uma conta? ",
        extra: { type: "criarconta", text: "Crie sua conta" },
      },
      snackbar,
    };
  },
  created() {
    if(this.$state.state.name === "p2p") {
      Socket.backToSocket();
      this.$state.setState("start");
    }
    if (this.$route.query && this.$route.query.token) {
      console.log("entrou no query");
      this.actionExtra("change");
    } else
      this.actionExtra("login");
    login.callback = this.actionExtra.bind(this);
  },
  methods: {
    async actionExtra(extra) {
      this.adapter = new this.adapters[extra]();
      const fields = await this.adapter.getFields();
      const extras = fields.extras;
      this.title = extras.title;
      this.extra = extras.extra; //esse extra da para ser usado o padrao state
      this.path++;
    },
    criarUsuario() {
      //this.configurando = true;
    },
  },
};
</script>

<style>
main.login {
  grid-template-columns: 50vw 50vw;
  background-color: var(--color-primary);
  height: 100vh;
}

div.login {
  background-color: var(--main-background-color);
  gap: 2em;
  color: var(--ion-color-dark);
}

@media (max-width: 992px) {
  main.login {
    grid-template-columns: 100vw;
    grid-template-rows: 30vh 70vh;
  }

  main.login img {
    width: 60vw;
  }

  div.login {
    border-radius: 1em 1em 0 0;
    padding: 0 2.5em;
    justify-content: inherit !important;
  }
}
</style>
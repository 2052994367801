<template>
  <card
    :class="['d-grid', 'a-center', 'row-three', 'column-fit-s']"
    @config="config"
  >
    <p>{{ components.list[i].options.state }}</p>
  </card>
</template>

<script>
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
  },
};
</script>
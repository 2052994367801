import type { Socket } from "socket.io-client";

export default class Algo {
    socket: Socket
    constructor(socket: Socket) {
        this.socket = socket
    }
    execute(component: any) {
        console.log(component);
        console.log(component.command);
    }
}
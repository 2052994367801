//import { Socket } from "socket.io-client";
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import type Componente from "../../_componente";
//import CreateUsuario from "../usecase/CreateUsuario";

export default class FormLigar {
    model: any
    component: Componente
    constructor(model: Componente) {
        this.model = model.options
        this.component = model
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const eventTypes = [
            { value: "1", name: "Alteração" },
            { value: "2", name: "Ao Ligar" },
            { value: "3", name: "Ao Desligar" },
        ]
        return {
            "extras": new Extra("Ligar Saídas", "Atualizar", "updatebotao"),
            "titulo": new Field("text", "Titulo", ""),
            "eventType": new Field("select", "Tipo do evento", "Selecione tipo", eventTypes),
            "notify": new Field("toggle", "Gerar Notificação", "255,1,"),
        }
    }
}
<template>
  <alert :title="title" :exclude="exclude" :class="{ wait: true }" @exclude="callExclude" @close="$emit('close')">
    <h3 class="mb-1">{{ msg }}</h3>
    <div v-if="alert.confirm" class="d-flex gap-l">
      <button class="cinza" @click="retorna(false)">Cancelar</button>
      <button @click="retorna(true)">Confirmar</button>
    </div>
    <div v-else class="d-flex gap-m">
      <button @click="retorna">Ok</button>
    </div>
  </alert>
</template>

<script>
//import { setStorage } from "@/scripts/Storage";
import { alert } from "@/stores/showModal";
import { defineComponent } from "vue";
import Alert from "./Alert.vue";

export default defineComponent({
  name: "ModalConfiguracao",
  props: {
    form: Object,
  },
  components: {
    Alert,
  },
  data() {
    return {
      title: "",
      msg: alert.msg,
      count: 0,
      animation: {},
      alert,
    };
  },
  created() {
    this.title = alert.confirm ? "Alerta" : "Aviso"
  },
  methods: {
    retorna(result) {
      console.log(alert);
      alert.callback(alert.confirm ? result : false);
      alert.showing = false;
    },
  },
});
</script>

<style>
.wait {
  background-color: #000000d1 !important;
}

.wait .modal {
  min-height: inherit;
  bottom: inherit;
  border-radius: 0.7em;
}

.wait .alert {
  position: absolute;
  background-color: var(--card-background);
  padding: 2em;
  border-radius: 0.5em;
  color: var(--card-color);
  display: grid;
  gap: 1em;
  width: min(90vw, 600px);
}
</style>
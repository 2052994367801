import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import Formstyle from "./_style"

export default class FormBody extends Formstyle {
    constructor(repository: Repository, model: any) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = await super.getFields("body")
        fields["struct"] = new Field("select", "Estilo", "Selecione", [
            {value: "normal", name: "Normal"},
            {value: "fill", name: "Preencher"},
        ])
        return fields
    }
}
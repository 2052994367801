import Repository from "@/core/repository/_repository";
import Device from "../entity/device";
export default class ConfigServidorLocal {
    static action = {
        timeout: 10000,
        text: "Enviando para Servidor Local"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        
        const [characteristic, err] = await Device.getCharacteristic("4116f8d2-9f66-4f58-a53d-fc7440e7c14e")
        if(err) return "back"

        characteristic.writeValueWithoutResponse(new TextEncoder().encode(JSON.stringify(fields)))
        return "back"
    }
}

import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import Modulo from "../domain/entity/Modulo";
import FormGateway from "./gateway";
import SelectModulos from "./selectModulo";

export default class FormExtra extends FormGateway {
    constructor(repository: Repository, model: Modulo) {
        super(repository, model)
    }

    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = await super.getFields()
        delete fields.ip
        delete fields.modId
        delete fields.state
        const add = new SelectModulos(this.repository, { nome: "Conectar Módulo", projId: this.model.projId })
        fields["modulo"] = new Field("object", "Modulo", "_id", [add])
        if (this.model.modulo && this.model.modulo.length) {
            const [modulo, err] = await this.repository.findOne("Modulos", { _id: this.model.modulo, projId: this.model.projId })
            if (err) console.error(modulo)
            else if(modulo)
                fields["modulo"] = new Field("objectm", "Modulo", "", [new FormGateway(this.repository, modulo), new SelectModulos(this.repository, { nome: "Trocar", projId: this.model.projId })])
        }
        return fields
    }
}
import FormNomeProgramacao from "./nomeProgramacao"

export default class FormsCreate {
    static list: { [key: string]: typeof FormNomeProgramacao } = {
        "macro": FormNomeProgramacao,
        "cena": FormNomeProgramacao,
        "cenalocal": FormNomeProgramacao,
        "managemodulo": FormNomeProgramacao,
    }
    static titulos: { [key: string]: string } = {
        "macro": "Macro",
        "cena": "Cena",
        "cenalocal": "Cenas nos Módulos",
        "managemodulo": "Gateway de Cenas",
    }
    static check(key: string) {
        return Object.prototype.hasOwnProperty.call(this.list, key)
    }
}
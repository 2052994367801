<template>
  <app-layout :header="header" @page="setPage" :homeimg="homeimg">
    <component v-bind:is="page" :key="components.key" v-bind:project="project" @page="setPage" @bgimg="sethomeimg">
    </component>
  </app-layout>
</template>

<script>
import { IonPage } from "@ionic/vue";
import appLayout from "../../layouts/appLayout.vue";
import home from "./Home.vue";
import modulos from "./Gerenciar.vue";
import componentes from "./Componentes.vue";
import programacao from "./Programacao.vue";
import usuarios from "./Usuarios.vue";
import projetos from "./Projetos.vue";
import servidorlocal from "./ServidorLocal.vue";
import agenda from "./Agenda.vue";
import pagina from "@/Features/Componentes/pasta/ui/pagina.vue";
import { components } from "@/stores/components";
import { server } from "@/stores/login";
import configuracao from "./Configuracao.vue"
export default {
  components: {
    appLayout,
    home,
    modulos,
    componentes,
    programacao,
    usuarios,
    agenda,
    pagina,
    IonPage,
    projetos,
    servidorlocal,
    configuracao
  },
  data() {
    return {
      //modal,
      header: { title: "Esperando", subtitle: "Wait", labels: [] },
      name: "Nome do Projeto",
      projId: "",
      page: "wait",
      key: 0,
      sidebar: true,
      project: {},
      components,
      homeimg: false
    };
  }, activated(a) { console.log(a) },
  async created() {
    this.projId = this.$route.params.projId;
    await this.$socket.waitSocket();
    if (this.$state.state.name !== "p2p")
      this.$state.setState("project")
    const [project, err] = await this.$repository.findOne("Projetos", {
      projId: this.projId,
    });

    if (err || !project) {
      console.error(project);
      this.$router.push("/hall");
    } else if (Object.values(project).length) {
      this.$store.commit("setProjeto", {
        projeto: project,
        socket: this.$socket,
      });
      this.project = project;
      this.setPage();
      this.header.title = project.nome;
      this.header.labels = [
        { name: "Home", path: "home", selected: true },
        { name: "Configuração", path: "configuracao", selected: false },
      ];
      if (project.tipo === "revenda") {
        this.header.labels.push({
          name: "Projetos",
          path: "projetos",
          selected: false,
        });
        this.header.labels.push({
          name: "Servidor Local",
          path: "servidorlocal",
          selected: false,
        });
      } else {
        this.header.labels.push({
          name: "Usuários",
          path: "usuarios",
          selected: false,
        });
        this.header.labels.push({
          name: "Módulos",
          path: "modulos",
          selected: false,
        });
        this.header.labels.push({
          name: "Componentes",
          path: "componentes",
          selected: false,
        });
        this.header.labels.push({
          name: "Programação",
          path: "programacao",
          selected: false,
        });
        this.header.labels.push({
          name: "Agenda",
          path: "agenda",
          selected: false,
        });
      }
      this.header.labels.push({ name: "Hall", path: "hall", selected: false });
      if (this.$state.tecnico)
        this.header.labels.push({ name: "Voltar para Revenda", path: "backtecnico", selected: false });
    } else console.error("Project id do not exist");
  },
  methods: {
    setPage(label) {
      if (
        label &&
        (label.name === "Hall" || this.$route.params.page === "modal")
      ) {
        this.page = "Home";
        this.header.labels[0].selected = true;
        return;
      }
      this.page = label ? label.path : this.$route.params.page;
      if (!label && this.page.length > 22) this.page = "pagina"
      if (this.page !== "home" && this.page !== "pagina") this.homeimg = false
      console.log(this.page)
      this.header.subtitle = label ? label.name : `${this.page
        .substring(0, 1)
        .toUpperCase()}${this.page.substring(1)}`;

      components.key++;
    },
    sethomeimg(option) {
      if (option)
        this.homeimg = { url: `${server.url}/_img/${option.url}` }
    }
  },
};
</script>

<style></style>
<template>
  <div class="d-grid gap-mid o-scroll">
    <div class="d-flex a-center gap-m" v-for="(item, i) in list" v-bind:key="item._id">
      <div v-if="!item.model.act && i > 0" @click="up(item)" class="cursor-pointer"><svg style="width: 20px;"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-left-up">
          <polyline points="14 9 9 4 4 9" />
          <path d="M20 20h-7a4 4 0 0 1-4-4V4" />
        </svg></div>
      <div v-if="i === 0" style="width: 20px"></div>
      <div class="item d-grid gap-p p-10 w-100" @click="retorna(item)">
        {{ item.model ? item.model.titulo : item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  name: "objectV",
  props: {
    field: Object,
    formu: Object,
  },
  created() {
    this.list = this.field.list;
    this.list.forEach(operador => {
      if (operador.callchangetitle)
        operador.changetitule(true)
    })
  },
  data() {
    return {
      value: "",
      list: [],
    };
  },
  methods: {
    async retorna(item) {
      if (!this.field.action) return this.$emit("object", item);
      const result = await this.field.action(item)
      this.$emit(result, item);
    },
    up(item) {
      console.log(item);
      const i = this.list.map((i) => i.model._id).indexOf(item.model._id);
      console.log(i);
      if (i === 0) return;
      const aux = this.list[i - 1];
      this.list[i - 1] = this.list[i];
      this.list[i] = aux;
      this.list[i - 1].model.index = i - 1;
      this.list[i].model.index = i;
      [this.list[i - 1].model, this.list[i].model].forEach(this.field.order ?
        this.field.order :
        async (i) => {
          console.log(i)
          const result = await this.$repository.update("Operadores", i._id, i);
          console.log(result);
        });
      console.log(this.list);
    },
  },
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
  cursor: pointer;
}

.item {
  width: 100%;
}
</style>
<template>
  <main class="d-grid gap" onselectstart="return false">
    <nav style="" class="d-flex tabs">
      <div v-for="(tab, index) in tabs" :key="index" :class="{ selected: tab.selected }" @click="changeTab(tab)">
        <h3>{{ tab.name }}</h3>
      </div>
    </nav>
    <component v-bind:is="show" :key="mes" v-bind:agenda="agenda" v-bind:agendamentos="agendamentos"
      v-bind:mes="mes" @change="changeMes" @updateCalendario="updateCalendario"></component>
  </main>
</template>

<script>
import rotinas from "@/Features/Programacao/ui/rotinas.vue";
import eventos from "@/Features/Programacao/ui/eventos.vue";
import cenasmodulo from "@/Features/Programacao/ui/cenasmodulo.vue";
export default {
  name: "page-programacao",
  components: {
    rotinas,
    eventos,
    cenasmodulo
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de modulos" },
      show: "rotinas",
      tabs: [{
        name: "Rotinas",
        value: "rotinas",
        selected: true
      }, {
        name: "Eventos",
        value: "eventos",
        selected: false
      }, {
        name: "Módulos Cenas",
        value: "cenasmodulo",
        selected: false
      }]
    };
  },
  methods: {
    changeTab(tab) {
      this.show = tab.value
      this.tabs.forEach(t => t.selected = t.value === tab.value)
    },
  }
};
</script>
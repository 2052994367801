import Repository from "@/core/repository/_repository";
import Socket from "@/service/socket";
import { login, snackbar } from "@/stores/login";
import { modal } from "@/stores/showModal";
import FormAdicionaUsuario from "../forms/addUser";

export default class CreateUsuario {
    static action = {
        timeout: 3000,
        text: "Criando Usuário"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, options: any): Promise<string> {
        console.log(form)
        console.log(options)
        const obj = form.model && form.model.add ? options : form
        Socket.io.emit("createuser", obj)
        const [result, err] = await Socket.io.wait("createuser")
        if (err) {
            console.error(result)
            obj.error = "Erro ao criar usuário"
            setTimeout(() => {
                obj.error = null
            }, 4000);
        } else {
            if(!form.model)
            login.callback("login")
            snackbar.show("Usuário criado com sucesso!!!")
            console.log(result)
        }
        if (form.model && form.model.add)
        setTimeout(() => {
            modal.show(new FormAdicionaUsuario(this.repository, {
                list: form.model.list,
                username: obj.username,
                nome: obj.nome.split(" ", 1).join("")
            }))
        }, 100);
        return "finish"
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";

export default class AdapterLogin {
    async getFields(): Promise<{ [index: string]: Field | { title: string; action: string; extra?: any } }> {
        return {
            "extras": new Extra("Login", "Entrar", "login", { text: "Não possui uma conta? ", extra: { type: "criarconta", text: "Crie sua conta" } }),
            "username": new Field("text", "Usuário", "Username"),
            "password": new Field("password", "Senha", "Senha", [{ value: "add", name: "Esqueci minha senha" }]),
            "error": new Field("error", "Erro", "Senha"),
        }
    }
}
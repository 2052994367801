<template>
  <select v-model="value">
    <option value="" disabled selected>{{ field.placeholder }}</option>
    <option v-for="item in field.list" v-bind:key="item.id" :value="item.value">
      {{ item.name }}
    </option>
  </select>
</template>

<script>
import { modal } from "@/stores/showModal";
import { defineComponent } from "vue";
export default defineComponent({
  name: "select",
  props: {
    field: Object,
    atribkey: Object,
  },
  created() {
    console.log(modal.form);
    console.log(this.atribkey);
    this.value = this.$attrs.modelValue;
    if (modal.form.fields) modal.form.fields[this.atribkey] = this;
  },
  data() {
    return {
      value: "",
    };
  },
});
</script>
//import { Socket } from "socket.io-client";
//import CreateUsuario from "../usecase/CreateUsuario";

import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";

export default class AdapterCreateUsuario {
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Criar Conta", "Criar", "createusuario", { text: "Já possui uma conta? ", extra: { type: "login", text: "Entre" } }),
            "nome": new Field("text", "Nome", "Seu nome completo"),
            "email": new Field("text", "E-mail", "exemplo@dominio.com"),
            "username": new Field("text", "Nome de Usuário", "Nome de usuário único"),
            "password": new Field("password", "Senha", "Senha para acessa a conta"),
            "error": new Field("error", "Erro", ""),
        }
    }
}
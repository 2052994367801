import Carrossel from "@/core/entity/componentes/layout/carrossel";
import GraficoTemperatura from "@/core/entity/componentes/modulos/graficoTemperatura";
import Componente from "@/core/entity/componentes/_component";
import Factory from "./_Factory";

export default class FactoryComponente extends Factory {
    constructor() {
        super()
        this.classesMap = {
            "graficotemperatura": GraficoTemperatura,
            "carrossel": Carrossel
        }
    }

    make(object: { tipo: string }): [Componente, boolean] {
        const [obj, err] = super.make(object)
        if (err) return [new Componente(""), err]
        const modulo = Object.assign(new this.classesMap[object.tipo](), obj)
        return [modulo, err]
    }
}
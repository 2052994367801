<template>
    <div class="d-flex gap-m">
        <input  v-model="value" type="number" inputmode="numeric" @change="setValue">
        <select v-model="tipo" @change="setValue">
            <option v-for="item in tipos" v-bind:key="item.value" :value="item.value">
                {{ item.name }}
            </option>
        </select>
    </div>
</template>
<script>
export default {
    props: {
        formu: Object
    },
    async created() {
        console.log(this.formu);
        const [key, value] = this.formu.model.intervalo ? this.formu.model.intervalo.split("/"): ["", ""]
        this.tipo = key
        this.value = value
    },
    data() {
        return {
            tipos: [{
                name: "Horas",
                value: "h"
            }, {
                id: 1,
                name: "Dias",
                value: "d"
            }, {
                id: 2,
                name: "Meses",
                value: "m"
            }, {
                id: 3,
                name: "Anos",
                value: "y"
            }],
            tipo: "h",
            value: ""
        }
    },
    methods: {
        setValue(){
            this.$emit("updateAtrib", "intervalo", `${this.tipo}/${this.value}`)
        }
    }
}
</script>

<style>
.week {
    justify-content: space-between;
}

.week .selected {
    background-color: var(--color-primary);
    color: var(--color-primary-contrast);
}

.week input {
    display: none;
}

.week div {
    background-color: var(--ion-color-light-tint);
    border-radius: .5em;
}
</style>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { wait } from "@/stores/showModal";

export default class FormUsuarioMqtt {
    repository: Repository
    model: any
    project: any
    constructor(repository: Repository, model: any, project: any) {
        this.model = model
        this.repository = repository
        this.project = project
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        wait.show(5000, "Pegando Usuário MQTT")
        const [user, err] = await this.repository.findUseCase(this.model.fromProject ? "UsuariosMqttProject" : "UsuariosMqtt", {
            revenda: this.project.revenda,
            projId: this.project.projId
        })
        if(err) console.error(user)
        wait.show(0, "")
        Object.assign(this.model, user)
        return {
            "extras": new Extra("Dados Login MQTT", "none", ""),
            "username": new Field("show", "Usuário", ""),
            "password": new Field("copy", "Senha", ""),
        }
    }
}
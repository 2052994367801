<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="32"
    height="32"
    viewBox="0 0 8.4666665 8.4666669"
    version="1.1"
    id="svg5029"
    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    sodipodi:docname="fumaca_alerta_30x30.svg"
    class="icon-alert"
  >
    <defs id="defs5023" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="7.9195959"
      inkscape:cx="1.2673632"
      inkscape:cy="10.45875"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1366"
      inkscape:window-height="705"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      units="px"
      inkscape:showpageshadow="true"
    />
    <g
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-288.53332)"
    >
      <g
        id="g1140"
        transform="matrix(0.26458333,0,0,0.26458333,-109.76091,442.92565)"
      >
        <g
          inkscape:export-ydpi="96"
          inkscape:export-xdpi="96"
          inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\fumaca_alerta_30x30.png"
          transform="matrix(0.51512678,0,0,0.51512678,-68.89232,-341.02606)"
          id="text1106"
          aria-label="!"
        >
          <path
            sodipodi:nodetypes="cccccccccc"
            inkscape:connector-curvature="0"
            id="path1108"
            d="m 990.65977,-439.31421 -0.70542,15.71738 h -3.32032 l -0.67645,-15.65671 z m -0.39289,23.72519 h -3.98438 v -4.12109 h 3.98438 z"
          />
        </g>
        <path
          inkscape:export-ydpi="96"
          inkscape:export-xdpi="96"
          inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\fumaca_alerta_30x30.png"
          sodipodi:nodetypes="ccccsccccccccsccccccccccccccccccscccccscc"
          inkscape:connector-curvature="0"
          id="path4066-6-1-4-6-0"
          d="m 428.5903,-552.6105 c -1.8405,-1.36216 -2.7047,-3.29684 -2.4134,-5.40298 0.2135,-1.54285 0.7472,-2.71313 2.1769,-4.77204 2.174,-3.13075 2.4194,-4.34025 1.3192,-6.50127 -0.2219,-0.43648 -0.3821,-0.79361 -0.3553,-0.79361 0.1861,0 1.3356,0.84112 1.7363,1.27045 0.9167,0.9824 1.295,2.16465 1.1257,3.51895 -0.1766,1.41203 -0.6138,2.40783 -2.0622,4.69574 -1.4175,2.23881 -1.8151,3.27018 -1.8078,4.68803 0,1.07446 0.2557,2.06059 0.9007,3.55367 0.1,0.23012 -0.039,0.17237 -0.62,-0.2568 z m 3.4202,-0.55587 c -0.9103,-0.8428 -1.3419,-1.77455 -1.3419,-2.89705 0,-1.25578 0.4825,-2.38077 1.7332,-4.0417 0.9392,-1.24726 1.2861,-1.86389 1.4252,-2.53365 0.119,-0.57237 0.017,-1.21033 -0.3341,-1.94383 -0.1383,-0.29414 -0.2365,-0.54899 -0.2188,-0.56616 0.02,-0.0189 0.2605,0.13755 0.54,0.34339 0.5776,0.42523 1.03,0.99626 1.2166,1.5355 0.2016,0.58277 0.1662,1.56587 -0.084,2.30974 -0.2522,0.75078 -0.4024,1.01677 -1.4935,2.64231 -1.0993,1.63794 -1.2483,2.03243 -1.24,3.2835 0,0.81403 0.038,1.04853 0.228,1.57943 0.1207,0.34297 0.2221,0.65151 0.2224,0.68577 0,0.14805 -0.1881,0.034 -0.6526,-0.39725 z m -7.9569,-2.96947 c -0.6534,-0.572 -0.8501,-0.81732 -1.1773,-1.46791 -0.286,-0.56902 -0.3119,-0.68105 -0.3412,-1.47163 -0.05,-1.39139 0.2887,-2.28419 1.5649,-4.10547 1.5285,-2.18177 1.6917,-2.9449 0.9571,-4.47802 -0.1558,-0.32597 -0.2606,-0.59272 -0.2323,-0.59272 0.14,0 0.8834,0.55126 1.1882,0.88203 0.6779,0.73523 0.9286,1.62577 0.7522,2.67132 -0.133,0.78871 -0.5096,1.60762 -1.3476,2.93054 -1.2495,1.97202 -1.4454,2.49775 -1.3772,3.69421 0.034,0.55134 0.1121,0.94621 0.3014,1.47383 0.1417,0.39643 0.2584,0.74239 0.2584,0.76894 0,0.1183 -0.1714,0.021 -0.5467,-0.30522 z"
        />
        <path
          inkscape:export-ydpi="96"
          inkscape:export-xdpi="96"
          inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\fumaca_alerta_30x30.png"
          sodipodi:nodetypes="cccccccccscccsccccscccccccccccccccccsccccccccccccc"
          inkscape:connector-curvature="0"
          id="path4066-6-1-4-6-1"
          d="m 428.3906,-573.17904 c -5.4614,-0.42488 -9.1958,-1.45987 -10.7235,-2.97218 l -0.4988,-0.49368 0.8313,-0.26899 c 0.4571,-0.14805 1.4733,-0.4291 2.2578,-0.62475 l 1.4268,-0.35569 -0.2469,-0.66948 c -0.1365,-0.36799 -0.2542,-0.6752 -0.2632,-0.68225 -0.038,-0.0296 -2.9237,0.7267 -3.6407,0.95716 -0.4389,0.14157 -0.8512,0.25681 -0.916,0.25681 -0.068,0 -0.3209,-0.40791 -0.5899,-0.94873 l -0.4723,-0.94878 0.9404,-0.3336 c 0.5173,-0.18375 1.572,-0.51119 2.3443,-0.72812 0.7721,-0.2167 1.5447,-0.38913 1.5447,-0.38913 l 20.7377,0.14735 c 0.042,0.0338 0.784,0.0263 1.5561,0.24171 1.5551,0.43676 3.3144,1.03847 3.2743,1.11968 -0.5996,1.21368 -0.9574,1.8396 -1.0515,1.8396 -0.064,0 -0.4773,-0.1155 -0.9163,-0.25677 -0.7171,-0.23039 -3.6011,-0.98879 -3.6406,-0.9572 -0.017,0.009 -0.1278,0.31407 -0.263,0.68225 l -0.247,0.66945 1.4266,0.35567 c 0.7847,0.19572 1.8008,0.47679 2.2581,0.6248 l 0.831,0.26899 -0.4986,0.49366 c -1.3317,1.31815 -4.569,2.33952 -8.9363,2.81937 -1.1426,0.12583 -5.5495,0.22868 -6.5253,0.1526 z m -1.4692,-5.48312 c 2.8146,-0.27309 7.0587,-0.1659 9.8655,0.24896 l 0.7196,0.10692 0.086,-0.21623 c 0.2005,-0.50916 0.3986,-1.11841 0.3719,-1.14492 -0.049,-0.0502 -2.2946,-0.34424 -3.4633,-0.45423 -0.631,-0.0595 -2.2676,-0.10535 -3.7407,-0.10535 -1.4729,0 -3.1094,0.0464 -3.7408,0.10535 -1.1686,0.11008 -3.4132,0.40451 -3.4629,0.45423 -0.026,0.0263 0.1715,0.63576 0.3717,1.14492 l 0.086,0.21623 0.7196,-0.10692 c 0.396,-0.0586 1.3811,-0.1708 2.1891,-0.24896 z m -3.1254,-3.44153 c 4.6017,-0.74149 9.3305,-0.74085 13.9401,10e-4 0.8267,0.133 2.7426,0.43134 4.9424,1.1109 l -22.2938,-0.38913 c 0.019,0 2.8502,-0.63352 3.4113,-0.72377 z"
        />
        <path
          inkscape:export-ydpi="96"
          inkscape:export-xdpi="96"
          inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\fumaca_alerta_30x30.png"
          inkscape:connector-curvature="0"
          id="path1143"
          d="m 439.418,-561.00061 c -0.078,-0.85937 -0.1717,-2.60318 -0.2074,-3.87513 l -0.065,-2.31262 1.1004,1.2e-4 1.1005,1.3e-4 -0.163,2.66389 c -0.09,1.46514 -0.1629,3.20889 -0.1629,3.875 0,1.17168 -0.024,1.21111 -0.7301,1.21111 -0.7138,0 -0.7333,-0.035 -0.8725,-1.5625 z"
        />
        <path
          inkscape:export-ydpi="96"
          inkscape:export-xdpi="96"
          inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\fumaca_alerta_30x30.png"
          inkscape:connector-curvature="0"
          id="path1145"
          d="m 439.1686,-556.16886 v -1.0625 h 1 1 v 1.0625 1.0625 h -1 -1 z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "alert",
};
</script>
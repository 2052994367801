<template>
  <div class="d-flex gap-m">
    <select v-model="value" @change="check">
      <option value="" disabled selected>Dimmer</option>
      <option
        v-for="item in field.list"
        v-bind:key="item.id"
        :value="item.value"
      >
        {{ item.name }}
      </option>
    </select>
    <input
      v-if="range"
      type="range"
      min="2"
      max="100"
      v-model="valuerange"
      @change="setValue"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "select",
  props: {
    field: Object,
    atribkey: String,
  },
  created() {
    this.value = this.$attrs.modelValue;
    this.check();
  },
  data() {
    return {
      value: "",
      valuerange: "",
      range: false,
    };
  },
  methods: {
    check() {
      this.range =
        !isNaN(this.value) &&
        Number(this.value) > 1 &&
        Number(this.value) < 100;
      if (this.range) {
        this.valuerange = this.value;
        this.value = 2;
      }

      this.$emit(
        "updateAtrib",
        this.atribkey,
        this.range ? this.valuerange : this.value
      );
    },
    setValue() {
      this.$emit("updateAtrib", this.atribkey, this.valuerange);
    },
  },
});
</script>
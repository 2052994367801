<template>
    <div class="d-grid gap lista">
        <button @click="createAgendamento">Criar Agendamento</button>
        <title-description v-for="(agendamento, index) in agendamentos" :key="index" :object="agendamento"
            :adapter="adapter" @action="mostraAgendamento"></title-description>
    </div>
</template>
<script>
import { modal } from "@/stores/showModal";
import { defineComponent } from 'vue'
import titleDescription from "@/components/cards/titleDescription.vue";
import AdapterPeriodoTitleDescription from "../adapters/periodo";
import FormSelectDay from "../forms/selectDay";
import FormUpdatePeriodo from "../forms/updatePeriodo";
export default defineComponent({
    components: {
        titleDescription
    },
    props: {
        agenda: Object,
    },
    data() {
        return {
            mes: "nenhum",
            dias: [],
            adapter: AdapterPeriodoTitleDescription,
            agendamentos: []
        }
    },
    async created() {
        console.log(this.agenda);
        console.log(this.agendamentos);
        const [periodos, e] = await this.$repository.findMany(
            "PeriodoDelimitadores",
            {
                dono: this.$route.params.projId,
            }
        )
        if (e) console.error(periodos);
        console.log(periodos);
        this.agendamentos = periodos
    },
    methods: {
        mostraAgendamento(agendamento) {
            console.log(agendamento);
            agendamento.agendalista = {
                agenda: this.agenda,
                agendamentos: this.agendamentos
            }
            modal.show(new FormUpdatePeriodo(this.$repository, agendamento));
        },
        createAgendamento() {
            modal.show(new FormSelectDay(this.$repository, {
                agenda: this.agenda,
                agendamentos: this.agendamentos
            }))
        }
    }
})

</script>

<style>
.lista {
    align-items: start;
    align-content: baseline;
    padding: 0 .5em .5em;
}
</style>
<template>
  <div class="fundo">
    <div class="alert">
      <div class="d-flex justify-between">
        <p v-if="voltar" @click="modalcontrole.show()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather stroke feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"/><polyline points="12 19 5 12 12 5"/></svg>
        </p>
        <h2 class="title">{{ title }}</h2>
        <b v-if="exclude" @click="$emit('exclude', exclude)"><svg style="width: 27px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather stroke feather-trash-2"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/></svg></b>
        <b v-if="edit" @click="$emit('edit', edit)"><svg style="width: 27px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather stroke feather-edit-2"><polygon points="16 3 21 8 8 21 3 21 3 16 16 3"/></svg></b>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { modal, modalcontrole } from "@/stores/showModal";
import { book } from "ionicons/icons";
export default defineComponent({
  name: "Modal",
  props: {
    title: { type: String, default: "Title" },
    exclude: { type: Object },
    edit: { type: Boolean },
    voltar: { type: Boolean },
  },
  data() {
    return {
      modal,
      modalcontrole,
    };
  },
  methods: {
    fundo(e) {
      if (e.target.classList.contains("fundo")) {
        modal.showing = false;
        modalcontrole.showing = false;
      }
    },
  },
  setup() {
    return {
      book,
    };
  },
});
</script>

<style>
.center {
    display: grid;
    justify-content: center;
    align-items: center;
}

</style>
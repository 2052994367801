import Checksum from "@/core/AZ1/checksum";
import Repository from "@/core/repository/_repository";
import StaticModule from "@/service/module/_module";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";

export default class DeleteCamera {
    static action = {
        timeout: 10000,
        text: "Salvando Senha"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form);
        console.log(fields);

        const [result, err] = await this.repository.deleteUsecase("Cameras", form.model)
        console.log(result, err)
        if (err)
            snackbar.show(result, 3000, "error")
        else
            snackbar.show("Apagado", 3000, "success")
        return "back"
    }
}
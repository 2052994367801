import Repository from "@/core/repository/_repository";
export default class DeleteEndpoint {
    static action = {
        timeout: 30000,
        text: "Deletando Endpoint"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, options: any): Promise<string> {
        console.log(form)
        console.log(options)
        // TODO: aqui tem que apagar as inscricoes do projeto tbm
        const [result, err] = await this.repository.delete("Endpoints", form.model._id)
        if (err) return `err/${result}`
        return "back"
    }

    sleep(time: number) {
        return new Promise(res => setTimeout(() => res(0), time))
    }
}
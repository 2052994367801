import Repository from "@/core/repository/_repository";
import { store } from "@/store";

export default class AdicionaUsuario {
    static action = {
        timeout: 3000,
        text: "Criando Usuário"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, options: any): Promise<string> {
        const usuariosProjeto = {
            projeto: store.state.projeto._id,
            projId: store.state.projeto.projId,
            username: options.username,
            nome: options.nome,
            acesso: options.acesso
        }
        console.log(usuariosProjeto);
        
        const [result, err] = await this.repository.create("UsuariosProjeto", usuariosProjeto)
        if(err) console.error(result);
        else
        form.model.list.push(result)
        if(form.model.add) return "finish"
        return "close"
    }
}
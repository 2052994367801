import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import DeleteComponente from "../../controllers/deleteComponente";
export default class DeleteTela {
    static action = {
        timeout: 10000,
        text: "Apagando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        await new DeleteComponente(this.repository).execute(form, fields)

        const [result, err] = await this.repository.deleteMany("TelaProgramacao", {
            projId: form.model.projId,
            tela: form.model._id
        })
        console.log(result, err)
        return "back"
    }
}
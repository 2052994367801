<template>
  <card :class="[
    'd-grid',
    'ac-start',
    'gap-m',
    'row-13',
    'column-full',
  ]" @config="config" :style="{ 'background-color': 'var(--screen-background)' }">
    <h2>Amostras Periódicas</h2>
    <div class="o-auto" @scroll="scroll">
      <div v-for="evento in eventos" :key="evento._id" class="d-flex gap-m justify-between a-center digitalinevent">
        <strong>{{ arrumaData(evento.date, "dd/MM hh:mm:ss") }}</strong>
        <strong>{{ `${evento.valor} °C` }}</strong>
      </div>
    </div>
  </card>
</template>

<script>
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
import arrumaData from "@/core/entity/ArrumaData";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      arrumaData,
      eventos: [],
      esperando: false,
      i: 0,
    };
  },
  async created() {
    //aqui tem que pegar com limite e usar o scroll para pegar mais
    const [events, err] = await this.$repository.findManySortLimit("GraficoAmostras", {
      projId: this.component.parent.projId,
      grafico: this.component.parent._id,
      amostrarelatorio: true
    }, 15, -1);
    console.log(events, err)
    if (!err) this.eventos = events
  },
  methods: {
    async scroll(e) {
      const scrollnow = e.target.scrollTop + e.target.offsetHeight + 15
      if (scrollnow >= e.target.scrollHeight && !this.esperando) {
        const ultimo = this.eventos[this.eventos.length - 1]
        this.esperando = true
        e.target.scrollTo(0, scrollnow - 30) 
        const [result, err] = await this.$repository.findManySortLimit("GraficoAmostras", {
          projId: this.component.parent.projId,
          grafico: this.component.parent._id,
          amostrarelatorio: true,
          date: ultimo.date,
          complex: true
        }, 15, -1)
        if (err) console.error(result)
        else this.eventos = this.eventos.concat(result)
        this.esperando = false
      }
    }
  },
};
</script>

<style>
div.digitalinevent {
  padding: 0.5em;
}

div.digitalinevent:nth-child(even) {
  background-color: var(--ion-card-background, #fff);
}
</style>
import Modulo from "./_modulos";

export default class GraficoTemperatura extends Modulo {
    /**
     * Aqui vão as configuracoes de para o grafico
     */
    constructor() {
        super("graficotemperatura")
    }

    getCompatibilidade() {
        return ["temperatura"]
    }
}
<template>
    <section class="widgets ir">
        <div class="thermostat">
            <div class="cap">
                <div class="ring" :style="{ 'box-shadow': `0px 0px 18px 2px ${modecolor}` }"></div>
                <div style="width: 19em; height: min-content; border-radius: 50%; grid-area: 1 / 1 / auto / auto;"
                    :style="{ 'transform': `rotate(${angle}rad)` }">
                    <div @mousemove="move" @touchmove="move" @touchstart="action" @touchend="action" @mousedown="action"
                        @mouseup="action" id="target">
                    </div>
                </div>
            </div>
            <span style="">{{ temp }}</span>
        </div>
        <div v-if="false" class="card head noselect d-grid gap-m ac-center row-three column-fit-l"
            style="grid-template-columns: repeat(4, 1fr);">
            <button type="button" class="botao" style="grid-area: 1 / 1 / auto / auto;"><svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather stroke feather-power">
                    <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                    <line x1="12" y1="2" x2="12" y2="12"></line>
                </svg></button>
            <button type="button" class="botao" style="grid-area: 1 / 2 / auto / auto;"><svg
                    xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" id="Layer_1"
                    data-name="Layer 1" viewBox="0 0 64 64" version="1.1">
                    <path
                        d="m 18.662799,62.400923 a 18.035467,18.035467 0 0 0 18.148897,-17.695176 22.391202,22.391202 0 0 1 0.8167,2.813079 17.286825,17.286825 0 0 1 -0.930131,10.344873 2.2686122,2.2686122 0 0 0 1.088934,2.994568 18.012781,18.012781 0 0 0 7.690595,1.724145 A 18.353072,18.353072 0 0 0 60.72287,54.438094 18.148897,18.148897 0 0 0 35.495901,29.301871 19.555437,19.555437 0 0 1 39.01225,24.447041 17.105336,17.105336 0 0 1 48.086698,19.501466 2.2686122,2.2686122 0 0 0 50.060391,16.937934 18.148897,18.148897 0 1 0 24.198212,35.903532 18.71605,18.71605 0 0 1 18.730857,35.608612 17.218766,17.218766 0 0 1 9.5429769,30.844527 2.2686122,2.2686122 0 0 0 6.3669199,30.70841 18.148897,18.148897 0 0 0 18.662799,62.468981 Z M 29.642881,35.381751 a 2.2686122,2.2686122 0 1 1 2.268613,2.268612 2.2686122,2.2686122 0 0 1 -2.268613,-2.268612 z m 23.434764,-2.404729 a 13.611673,13.611673 0 0 1 3.788585,18.942912 13.725104,13.725104 0 0 1 -15.267762,5.580787 23.207902,23.207902 0 0 0 -3.33486,-19.60081 6.6470336,6.6470336 0 0 0 0.453722,-2.51816 6.8058365,6.8058365 0 0 0 -0.476408,-2.472787 13.611673,13.611673 0 0 1 14.836723,0.06806 z M 18.299821,19.501466 a 13.611673,13.611673 0 0 1 26.792309,-3.970071 22.867611,22.867611 0 0 0 -9.278623,5.67153 23.139844,23.139844 0 0 0 -5.013633,7.463734 6.8058365,6.8058365 0 0 0 -4.650655,3.107999 13.611673,13.611673 0 0 1 -7.849398,-12.273192 z M 7.9776339,35.381751 a 22.686122,22.686122 0 0 0 9.8684641,4.537225 22.958355,22.958355 0 0 0 9.074449,0 6.8058365,6.8058365 0 0 0 4.990947,2.268612 v 0 A 13.611673,13.611673 0 1 1 7.8868899,35.517868 Z"
                        id="path182" style="stroke-width:2.26861; fill: var(--card-color);"></path>
                </svg></button>
            <button type="button" class="botao" style="grid-area: 1 / 3 / auto / auto;">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather stroke feather-up-down" version="1.1">
                    <path d="m 8.2947818,21.184961 c 0,0 -3.2832488,-7.863999 0.9646824,-17.8476685"></path>
                    <path d="M 5.4301914,4.3914615 9.8777006,2.3883625 11.493883,6.4092638" id="path1143"></path>
                    <path d="m 17.744139,2.8009244 c 0,0 -4.48389,7.2468566 -1.865821,17.7760676"></path>
                    <path d="m 12.263559,18.931354 4.075389,2.68029 2.230861,-3.715233" id="path1236"></path>
                </svg></button>
            <button type="button" class="botao"
                style="grid-area: 1 / 4 / auto / auto;background-color: var(--color-primary);">MODO</button>
        </div>
        <component v-for="component in diretionals" v-bind:is="component.tipo" :key="component._id"
            v-bind:component="component"></component>
    </section>
</template>

<script>
//import { setStorage } from "@/scripts/Storage";
import { defineComponent } from "vue";
import { modalcontrole } from "@/stores/showModal";
import select from "@/components/Form/Select.vue";
import text from "@/components/Form/Text.vue";
import textarea from "@/components/Form/Textarea.vue";
import object from "@/components/Form/Object.vue";
import objectV from "@/components/Form/ObjectV.vue";
import objectC from "@/components/Form/ObjectC.vue";
import disabled from "@/components/Form/Disabled.vue";
import toggle from "@/components/Form/Toggle.vue";
import add from "@/components/Form/Add.vue";
import show from "@/components/Form/Show.vue";
import botao from "@/Features/Componentes/botao/ui/botao.vue";
import botaoiconeSQ from "@/Features/Componentes/botao/ui/iconeSQ.vue";
import volume from "@/Features/Componentes/agregador/ui/volume.vue";
import equa from "@/Features/Componentes/agregador/ui/equa.vue";
import entradas from "@/Features/Componentes/agregador/ui/entradas.vue";
import Factory from "@/Features/Componentes/botao/actions/_factory";
import { components } from "@/stores/components";
export default defineComponent({
    name: "ModalConfiguracao",
    props: {
        form: Object,
    },
    components: {
        select,
        text,
        textarea,
        object,
        objectV,
        disabled,
        toggle,
        add,
        objectC,
        show,
        botao,
        botaoiconeSQ,
        volume,
        equa,
        entradas,
    },
    data() {
        return {
            title: "",
            template: false,
            edit: false,
            exclude: false,
            atribs: {},
            fields: {},
            forms: [],
            close: "Cancelar",
            path: 0,
            extras: {},
            components: [],
            modalcontrole,
            projId: "",
            templateType: "",
            head: [],
            volume: [],
            channel: [],
            controlls: [],
            diretionals: [],
            numerical: [],
            holding: false,
            angle: 0,
            x: 0,
            y: 0,
            r: 0,
            temp: "20",
            modecolor: "#2fa4ff",
            button: {},
            temps: [],
            setpoint: {}
        };
    },
    async created() {
        console.log(modalcontrole)
        if (modalcontrole.parent.options.code === "template") {
            this.template = true
            this.edit = true
            this.templateType = modalcontrole.parent.options.type
        }
        this.projId = this.$route.params.projId;
        console.log(modalcontrole.root);
        modalcontrole.root.forEach(b => {
            if (b.options.type === "temp") this.temps.push(b)
            if (b.titulo === "Setpoint") this.setpoint = b
        })
        this.title = modalcontrole.parent.titulo
        this.r = 6.28 / this.temps.length
        console.log(this.values);
        //Aqui vou ter que pegar o que foi o ultimo a mostrar
        const i = this.setpoint && typeof this.setpoint.options.temp !== "undefined" ?
            this.temps.findIndex(b => b._id === this.setpoint.options.temp) : 0
        if (i >= 0) {
            this.button = this.temps[i]
            this.angle = this.r * i
            this.temp = this.button.titulo.substring(0, 2)
            this.modecolor = this.button.options.mode === "HEAT" ? "#ff6d2f" : "#2fa4ff"
        }
        //this.next(modal.form);
        //#2fa4ff
        //#ffc42f
    },
    sockets: {
        configuracao(data) {
            console.log(data);
        },
    },
    methods: {
        async Cancel() {
            const last = this.forms.length - 1;
            if (last > 0) {
                await this.back();
                if (last === 1) this.close = "Cancelar";
            } else this.$emit("close");
        },
        async action(e) {
            this.holding = e.type === "mousedown" || e.type === "touchstart"
            console.log("Action")
            if ((e.type === "mouseup" || e.type === "touchend") && this.setpoint.options.temp !== this.button._id) {
                this.setpoint.options.temp = this.button._id
                const temp = this.button.titulo.substring(0, this.button.titulo.length - 1)
                this.setpoint.options.state = Number(temp)
                this.setpoint.options.statestr = `${temp}°C`
                this.setpoint.options.mode = this.button.options.mode
                this.setpoint.options.icon.icon = this.button.options.mode === "COOL" ? "controller-froze" : "controller-sun"
                await this.$repository.update("Componentes", this.setpoint._id, {
                    projId: this.setpoint.projId,
                    options: this.setpoint.options
                })
                components.update(this.setpoint)
                console.log("Chamando acao do botao");
                const action = Factory.actions[this.button.options.action];
                new action(this.$module).execute(this.button);
            }
        },
        move(e) {
            if (e.target.id !== "target" || !this.holding) return
            const parent = e.target.parentElement.parentElement
            let x = 0
            let y = 0
            if (e.type === "touchmove") {
                x = e.targetTouches[0].clientX - parent.offsetLeft
                y = e.targetTouches[0].clientY - parent.offsetTop
            } else if (e.type === "mousemove") {
                x = e.clientX - parent.offsetLeft
                y = e.clientY - parent.offsetTop
            } else return
            const xa = parent.offsetWidth / 2
            const ya = parent.offsetHeight / 2
            const rad = Math.atan((y - ya) / (x - xa))
            this.angle = x >= xa ? rad + Math.PI : rad
            const i = (this.angle < 0 ? ((Math.PI * 2) + this.angle) : this.angle) / this.r
            this.button = this.temps[Math.floor(i)]
            this.temp = this.button.titulo.substring(0, 2)
            this.modecolor = this.button.titulo[2] === "Q" ? "#ff6d2f" : "#2fa4ff"
        }
    },
});
</script>

<style>
.controle .ir {
    background-color: var(--screen-background);
    border-radius: 0.5em;
}

.thermostat {
    grid-column-end: span 8;
    grid-row-end: span 14;
    display: grid;
    align-content: center;
    place-items: center;
    grid-template-rows: 100%;
}

.thermostat .cap {
    grid-area: 1 / 1 / auto / auto;
    background-color: #dbdbdb;
    width: 20em;
    height: 20em;
    border-radius: 100em;
    display: grid;
    align-content: center;
    place-items: center;
    grid-template-rows: 100%;
}

.thermostat .ring {
    grid-area: 1 / 1 / auto / auto;
    width: 13em;
    height: 13em;
    border-radius: 100em;
    transition: 1s;
    background-color: #ebebeb;
}

.thermostat #target {
    width: 40px;
    height: 40px;
    background-color: #a4a7a7;
    border-radius: 100px;
    float: left;
}

.thermostat span {
    position: relative;
    grid-area: 1 / 1 / auto / auto;
    color: var(--color-primary);
    font-size: 7em;
}

.thermostat span::after {
    content: "°";
    position: absolute;
    left: 95%;
    top: 10px;
    font-size: 60px;
}</style>
import Repository from "@/core/repository/_repository";
import FormComponent from "../../forms/component";
import Componente from "../../_componente";
import Field from "@/core/entity/Field";
import Extra from "@/core/entity/Extra";
import FormNome from "../../forms/nome";
import FormCameraConfig from "./config";
import FormNovaCamera from "./nova";

export default class FormCameras {
    repository: Repository
    model: Componente
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra("Gerenciar Câmeras", "none", ""),
        }
        const [result, err] = await this.repository.findUseCase("Cameras", { projId: this.model.projId })
        console.log(result, err)
        if (err) {
            fields["erro"] = Field.make("show", "Erro: servidor inoperante")
            return fields
        }
        const list: any[] = [new FormNovaCamera(this.repository, { projId: this.model.projId })];
        result.forEach((cam: any) => list.push(new FormCameraConfig(this.repository, cam)));
        fields["cameras"] = Field.make("objectV", "Câmeras", list)

        return fields
    }
}
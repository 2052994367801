<template>
  <div>Lendo Tokens...</div>
</template>

<script>
import { alert, wait } from "@/stores/showModal";
import Socket from "@/service/socket";
import { setStorage } from "@/scripts/Storage";
export default {
  name: "token",
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data: () => {
    return {
      header: { title: "", subtitle: "Hall", labels: [] },
      projetos: [],
      dropDown: false,
      mudaConexao: false,
      topDropDown: 0,
      tentativas: 0,
      favoriteProject: null,
      homeimg: 'repeating-radial-gradient( circle at 0 0, transparent 0, var(--screen-background-shade) 40px ), repeating-linear-gradient( #2f2f2f55, var(--screen-background-tint) )'
    };
  },
  async created() {
    wait.show(30000, "Lendo token")
    const tokens = new URLSearchParams(window.location.search.slice(1));
    const accessToken = tokens.get("accessToken")
    const refreshToken = tokens.get("refreshToken")
    const projId = tokens.get("projId")

    if (accessToken && refreshToken) {
      setStorage("accessToken", accessToken);
      setStorage("refreshToken", refreshToken);
      await Socket.setOns(this.$route.path);
      if(projId) this.$router.replace(`/p/${projId}/home`)
      else this.$router.replace(`/hall`)
    }else {
      wait.show(0)
      await alert.show("Erro ao configurar token", true)
      this.$router.replace("/")
    }
    wait.show(0)
  },
  unmounted() {
    //this.sockets.unsubscribe("pronto");
    //this.sockets.unsubscribe("hall");
    console.log("saiu do monitoramento");
  },
};
</script>

<style>
#projetos {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
</style>
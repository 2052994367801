<template>
  <ion-app>
    <ion-router-outlet :key="modal.count"/>
    <modal-controle
      v-if="modalcontrole.showing"
      :form="form"
      :key="configLayer"
      @close="modalcontrole.show()"
    ></modal-controle>
    <modal-atividade
      v-if="modalslot.showing"
      :form="form"
      :key="configLayer"
      @close="modalslot.show()"
    ></modal-atividade>
    <modal-configuracao
      v-if="modal.showing"
      :form="form"
      :key="configLayer"
      @close="modal.show()"
    ></modal-configuracao>
    <modal-espera v-if="wait.showing"></modal-espera>
    <modal-alert v-if="alert.showing"></modal-alert>
    <p v-if="snackbar.showing" class="snackbar animation-up" :class="snackbar.state">{{ snackbar.text }}</p>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { getStorage, setStorage } from "@/scripts/Storage";
import { modal, modalcontrole, wait, alert, modalslot } from "@/stores/showModal";
import ModalConfiguracao from "@/components/Modal/modalConfiguracao.vue";
import ModalControle from "@/components/Modal/modalControle.vue";
import ModalAtividade from "@/Features/Componentes/atividades/ui/modal.vue";
import ModalEspera from "@/components/Modal/modalEspera.vue";
import ModalAlert from "@/components/Modal/modalAlert.vue";
import Socket from "@/service/socket";
import { snackbar } from "@/stores/login";
//import socket from "./plugins/socket";
import { components, modulos } from "@/stores/components";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    ModalConfiguracao,
    ModalControle,
    ModalEspera,
    ModalAlert,
    ModalAtividade,
  },
  sockets: {
    async redirect(path) {
      console.log(path);
      const refreshToken = (await getStorage("refreshToken")).value;
      if (!refreshToken) {
        console.log("passando para path");
      } else console.log("esperando...");
    },
  },
  async created() {
    console.log(components.list)
    this.$socket.onconnect = this.connect.bind(this);
    window.onfocus = () => { if(!this.$socket.connected && !this.waitingConnection) this.disconnect() }
  },
  data() {
    return {
      connection: null,
      modal,
      modalcontrole,
      modalslot,
      wait,
      alert,
      snackbar,
      waitingConnection: false
    };
  },
  methods: {
    mudaTeste(event) {
      console.log(event);
      this.output = event.data;
    },
    async disconnect() {
      if(window.navigator.onLine){
        console.log("...esperando conectar com o servidor")
        this.waitingConnection = true
        wait.show(10000, "Esperando conectar com o servidor")
      }
    },
    async connect() {
      console.log("Connected🎉")
      if(this.waitingConnection){
        wait.show(0)
        this.waitingConnection = false
      }
      this.temWebSocket = true;
      this.$socket.connected = true;
      await Socket.setOns(this.$route.path);
      const directUrl = (await getStorage("directUrl")).value;
      if (directUrl)
        return this.$router.replace(directUrl)
      if(Socket.auth && this.$route.path === "/")
        this.$router.replace("/hall")
      if(!Socket.auth && this.$route.path !== "/")
        this.$router.replace("/")
      modulos.module = this.$module
    },
    token(token) {
      console.log("chegou no token");
      if (token.result) {
        this.$store.commit("setAccessToken", token.accessToken);
        this.$store.commit("setRefreshToken", token.refreshToken);
        this.$socket.auth.accessToken = token.accessToken;
        this.$socket.auth.refreshToken = token.refreshToken;
        this.$socket.disconnect();
        this.$socket.connect();
        setStorage("accessToken", token.accessToken);
        setStorage("refreshToken", token.refreshToken);
      }
    },
  },
});
</script>

<style>
.ion-page-invisible{
  opacity: 1 !important;
}
</style>
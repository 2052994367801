<template>
  <div class="d-flex space-between gap-mid a-center">
    <div
      class="objects"
      @click="$emit('object', field.list[0])"
    >
      {{ inicio }}
    </div>
    <div class="arrow-right"></div>
    <div
      class="objects"
      @click="$emit('object', field.list[1])"
    >
      {{ fim }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  name: "object",
  props: {
    field: Object,
  },
  async created() {
    this.inicio = `Início: ${this.field.list[0].model.hora}`
    this.fim = `Fim: ${this.field.list[1].model.hora}`
    const [progs, err] = await this.$repository.findMany("Programacoes", {
      projId:  this.$route.params.projId,
    })
    if(err) console.error(progs);
    else {
      this.field.list.forEach(l => {
        const p = progs.find(p => p._id === l.model.action)
        if(p) this.inicio = `${this.inicio} ${p.nome}`
      });
    }
  },
  data() {
    return {
      value: "",
      inicio: "",
      fim: ""
    };
  },
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
}
.arrow-right {
    border: none;
    width: 100%;
    height: 2px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: lightgray;
}
.arrow-right::after {
    content: "";
    margin-left: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent lightgray;
    transform: translate(10px, 0px);
}
</style>
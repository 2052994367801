//import { Socket } from "socket.io-client";
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import type Componente from "../../_componente";
//import CreateUsuario from "../usecase/CreateUsuario";

export default class FormAr {
    model: any
    component: Componente
    constructor(model: Componente) {
        this.model = model.options
        this.component = model
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Botão Som", "Atualizar", "updatebotao"),
            "titulo": new Field("text", "Titulo", ""),
            "command": new Field("text", "Comando", ""),
        }
    }
}
<template>
  <button v-if="component.layout && component.layout.position" type="button"
    :style="{'grid-area': component.layout.position}"
    :class="component.layout.classes"
     class="botao" 
     @mousedown="pressstart" 
    @mouseup="pressend"
    @touchstart="pressstart"
    @touchend="pressend"
    @mouseleave="pressend"
     >
    <Icons :value="component.options.icon" />
  </button>
  <card
    v-else
    :class="['d-flex', 'gap-m', 'a-center', 'row-three', 'column-fit-s']"
    @config="config"
    @mousedown="pressstart" 
    @mouseup="pressend"
    @touchstart="pressstart"
    @touchend="pressend"
    @mouseleave="pressend"
  >
    <Icons :value="component.options.icon" />
  </card>
</template>
<script>
import Icons from "@/components/Icons/icons.vue";
import Factory from "../actions/_factory";
import ListForms from "../forms/_factory";
import { modal } from "@/stores/showModal";
import Card from "@/components/UI/Card.vue";
export default {
  components: {
    Card,
    Icons,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      action: "",
      titulo: "",
      pressed: false,
      holded: false,
      oldrepetir: "",
      componente: this.component
    };
  },
  async created() {
    this.action = this.component.options.action;
  },
  methods: {
    pressstart(e){
      e.target.classList.add("active")
      e.preventDefault();
      this.pressed = true
      this.holded = false
      setTimeout(() => this.executeloop(e), 1500)
      this.oldrepetir = this.componente.options.repetir
    },
    pressend(e){
      e.target.classList.remove("active")
      e.preventDefault();
      if(!this.pressed) return
      this.pressed = false
      if(!this.holded) return
      this.componente.options.repetir = this.oldrepetir
      const action = Factory.actions["cancel"];
      new action(this.$module).execute(this.componente);
    },
    executeloop(e){
      this.execute(e)
      if(!this.pressed) return
      setTimeout(() => {
        if(this.pressed) {
          this.holded = true
          this.componente.options.repetir = "15"
          this.executeloop(e)
        }
      }, 2000);
    },
    execute(e) {
      e.preventDefault();
      const action = Factory.actions[this.action];
      new action(this.$module).execute(this.componente);
    },
    config() {
      console.log("config");
      if (ListForms.check(this.action))
        modal.show(new ListForms.forms[this.action](this.component));
      else console.error(`Form "${this.action}" do not exist`);
    },
  },
};
</script>

<style>
.on path {
  fill: #9aff7c;
}
</style>

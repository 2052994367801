export default class TextTime {
    t: string
    constructor(t: string) {
        this.t = t
    }

    toMilliseconds() {
        const mapMilli: { [key: string]: number } = {
            "ms": 1,
            "s": 1000,
            "m": 60000,
            "h": 3600000,
        }
        let n = 0
        let m = 0
        for (const l of this.t.split("")) {
            if (!isNaN(Number(l)))
                n = (n * 10) + Number(l)
            else {
                if (Object.prototype.hasOwnProperty.call(mapMilli, l))
                    m += mapMilli[l] * n
            }
        }
        return m
    }

    timeToMilliseconds(ta: string[]): number {
        const n = Number(ta[0]) * (1000 * Math.pow(60, ta.length - 1))
        ta.splice(0, 1)
        if (ta.length === 0) return n
        return n + this.timeToMilliseconds(ta)
    }
}
<template>
    <section class="widgets ir p-05em">
        <div class="card bg-card noselect d-flex a-center gap-m justify-between row-three column-fit-l"
            v-on:click="gerenciarsenhas">
            <h2>Gerenciar Senhas</h2>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ccc" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
        </div>
    </section>
</template>

<script>
import { modal, modalcontrole } from "@/stores/showModal";
import ListForms from "../../forms/_list";
import { components } from "@/stores/components";
import FormSenhasAcesso from "../form/senhasacesso"
export default {
    components: {
    },
    props: {
        component: Object,
    },
    data() {
        return {
            value: false,
            components: [],
        };
    },
    async created() {
        console.log(components)
    },
    methods: {
        config() {
            console.log("config");
            if (ListForms.check(this.component.options.action))
                modal.show(
                    new ListForms.forms[this.component.options.action](this.component)
                );
            else
                console.error(`Form "${this.component.options.action}" do not exist`);
        },
        gerenciarsenhas() {
            modal.show(new FormSenhasAcesso(this.$repository, modalcontrole.parent));
        }
    },
};
</script>

<style>
.acesso .gap-m {
    grid-column-end: span 3 !important;
    padding: 0 !important;
}

.acesso div {
    grid-row-end: span 2;
}
</style>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { wait } from "@/stores/showModal";
import CreateOperador from "../../controller/createOperador";
import SelectActions from "./actions";

export default class SelectComponentes {
    repository: Repository
    model: { titulo: string; macro: any }
    constructor(repository: Repository, model: { titulo: string; macro: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const [componentes, err] = await this.repository.findMany("Componentes", {
            projId: this.model.macro.projId,
            main: true
        })
        if (err) return {}
        const list: SelectActions[] = []
        componentes.forEach(element => {
            list.push(new SelectActions(this.repository, { titulo: element.titulo, element, macro: this.model.macro }))
        });
        return {
            "extras": new Extra("Escolha", "none", "conectacomponente"),
            "modulo": new Field("objectV", "Componentes", "", list, "", async (item: any) => {
                if (item.model.element.tipo === "audio") return true
                wait.show(10000, "Adicionando Operador")
                await new CreateOperador(this.repository).execute(this.model.macro, item)
                wait.show(0, "")
                return false
            })
        }

    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormProgramacaoLocal from "./local";
import SelectModulos from "./selectModulos";
import FormOperador from "./operador";
import SelectIluminacao from "./operadores/iluminacao";
import FormTituloComponente from "@/Features/Componentes/forms/nomeComponente";
import FormComponentMacro from "@/Features/Componentes/forms/macro";
import Forms from "./_list";
import FormNomeProgramacao from "./nomeProgramacao";

export default class FormCenasModulo {
    repository: Repository
    model: any
    operadores: any[] = []
    modulos: any[] = []
    
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        const add = new FormNomeProgramacao(this.repository, { tipo: "managemodulo", titulo: "+ Adicionar" }) // TODO: aqui tem fazer forma com que crie e ja adicione no modulo
        const fields = {
            "extras": new Extra(this.model.nome, "Enviar", "updateprogramacaogatewaycena", {}, true, "deleteprogramacao"),
            "modulos": new Field("objectV", "Cenas", "", []),
        }
        const query = {
            modulo: this.model.model._id,
            projId: this.model.model.projId
        }

        console.log(this.model, query)
        const [programacoes, err] = await this.repository.findMany("ModulosProgramacoes", query)
        console.log(programacoes, err)
        if (err) return fields
        const cenas: FormProgramacaoLocal[] = []
        if (programacoes){
            programacoes.forEach((m: any) => {
                m.programacao.titulo = m.programacao.nome
                cenas.push(new Forms.list[m.programacao.tipo](this.repository, m.programacao))
            });
        }

        //cenas.push(add)
        fields.modulos = new Field("objectV", "Cenas", "", cenas)
        return fields
    }
}
export default class Extra {
    title: string
    action: string
    controller: string
    exclude: boolean
    extra?: any
    controllerDelete?: string
    constructor(title: string, action: string, controller: string, extra?: any, exclude = false, controllerDelete?: string) {
        this.title = title
        this.action = action
        this.controller = controller
        this.exclude = exclude
        this.controllerDelete = controllerDelete
        this.extra = extra
    }
}
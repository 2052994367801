<template>
  <ion-page>
    <main class="dash">
      <div v-if="!sidebar" class="fundo" @click="sidebar = true"></div>
      <div class="nav-bar" :class="{ hidden: sidebar }">
        <nav class="d-grid gap-mid w-100">
          <a
            class="pointer"
            v-for="label in header.labels"
            v-bind:key="label.name"
            :class="{ selected: label.selected }"
            @click="navigator(label.path)"
            >{{ label.name }}
          </a>
        </nav>
        <div class="footer">
          <div class="d-grid w-100 gap-m">
            <label @click="refreshpage">Refresh Page</label>
            <label @click="setoffline">Ativar Offline</label>
            <label @click="settheme">Definir Tema</label>
            <label @click="logout">Sair</label>
          </div>
          <img class="logo" src="/_img/logo_livtech_v.svg" alt="logo" />
        </div>
      </div>
      <div class="screen" :style='{
      "background-image": homeimg ? homeimg.url ? `url(${homeimg.url})` : homeimg : "none",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
    }'>
        <div class="header">
          <div
            id="menu"
            name="menu-outline"
            @click="sidebar = false"
          >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather stroke feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
        </div>
          <div class="d-grid">
            <h1>{{ header.title }}</h1>
            <h3>{{ header.subtitle }}</h3>
          </div>
        </div>
        <slot v-bind:class="main"></slot>
      </div>
    </main>
  </ion-page>
</template>

<script>
import { IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { menuOutline } from "ionicons/icons";
import { setStorage } from "@/scripts/Storage";
import Socket from "@/service/socket";
import { modal } from "@/stores/showModal";
import FormSelectTheme from "@/Features/Theme/form/select"
import FormSetOffline from "@/Features/Offline/form/SetOffline"
import RandomToken from "@/core/lib/randomtoken";
export default defineComponent({
  name: "appLayout",
  components: {
    IonPage,
  },
  props: {
    header: Object,
    homeimg: Object,
  },
  data() {
    return {
      sidebar: true,
    };
  },
  setup() {
    return {
      menuOutline,
    };
  },
  methods: {
    dropDown(e) {
      this.$emit("dropDown", e.target);
    },
    async navigator(e) {
      console.log(e)
      if (e === "backtecnico") {
        console.log("clicou no voltar para Revenda")
        const event = `logintecnico/${RandomToken()}`
        this.$socket.emit(event, { reverse: true })
        await this.$socket.wait(event)
        this.$router.replace(`/p/${this.$state.tecnico.revenda.projId}/${this.$state.tecnico.backto}`)
        this.$state.tecnico = undefined
        return
      }
      const path = this.$router.currentRoute.value.path.split("/");
      this.header.labels.forEach((label) => {
        if (label.path === e) {
          path[path.length - 1] = label.path;
          this.$router.replace(e === "hall" ? "/hall" : path.join("/"));
          //window.history.pushState("", "", e === "hall" ? "/hall" : path.join("/"))
          label.selected = e !== "Hall";
          this.$emit("page", label);
        } else label.selected = false;
      });
      this.sidebar = true;
    },
    logout() {
      setStorage("accessToken", "");
      setStorage("refreshToken", "");
      this.$socket.auth.accessToken = "";
      this.$socket.auth.refreshToken = "";
      this.$socket.disconnect();
      this.$socket.connect();
      this.$state.setState("start")
      Socket.auth = false
      this.$router.replace("/");
    },
    settheme(){
      modal.show(new FormSelectTheme(this.$repository, {theme: ""}))
    },
    setoffline(){
      modal.show(new FormSetOffline(this.$socket, this))
    },
    refreshpage() {
      location.reload()
    }
  },
});
</script>

<style>
:root {
  --column-size: 5.712vw;
  --column-count: 12;
  --gap-size: 2.857vw;
  --grid-columns: repeat(var(--column-count), var(--column-size));
  --grid-gap: var(--gap-size);
  --grid-area-full: 1 / 1 / span 1 / span var(--column-count);
  --grid-area-side-bar: 1 / 1 / span 1 / span 3;
  --hidde-side-bar: translate(calc(-5.712vw * 3 + 2 * -2.857vw));
}
main.dash {
  display: grid;
  grid-template-columns: var(--grid-columns);
  height: calc(100vh - calc(100vh - 100%));
  gap: var(--grid-gap);
}

.header {
  display: flex;
  gap: 1em;
}

.header #menu {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.fundo {
  background-color: rgba(0, 0, 0, 0.164);
  grid-area: var(--grid-area-full);
  z-index: 99;
}

.nav-bar {
  background-color: var(--color-primary);
  color: var(--color-primary-contrast);
  display: grid;
  align-content: space-between;
  justify-items: center;
  padding: 1em;
  gap: 1em;
  grid-area: var(--grid-area-side-bar);
  transition: 0.5s;
  z-index: 100;
}

.nav-bar.hidden {
  transform: var(--hidde-side-bar);
}
.nav-bar img.logo {
  width: 150px;
  opacity: 0.2;
}

.nav-bar .footer {
  display: grid;
  gap: 1em;
  width: 100%;
  justify-items: center;
  border-top: solid 1px;
  padding-top: 1em;
}

nav a {
  text-decoration: none;
  padding: 0.5em;
  font-weight: bold;
}
a.selected {
  border-left: solid;
  background-image: linear-gradient(
    90deg,
    rgba(var(--color-primary-contrast-rgb), 0.1),
    rgba(0, 0, 0, 0)
  );
}
.back {
  background-color: rgb(235, 235, 235);
  overflow: hidden;
  overflow-y: auto;
  padding: 1em;
}

.projects {
  grid-template-columns: repeat(2, 1fr);
}
.nav-bar .header #menu {
  display: none;
}
@media (max-width: 992px) {
  :root {
    --column-size: 11.85vw;
    --column-count: 6;
    --gap-size: 5.714vw;
    --grid-area-side-bar: 1 / 1 / span 1 / span 5;
    --hidde-side-bar: translate(calc(-11.85vw * 5 + 4 * -5.714vw));
  }
  main.dash {
    display: grid;
  }

  .nav-bar {
    width: 100%;
    height: inherit;
  }

  .nav-bar .header {
    display: flex;
    justify-content: space-between;
    align-content: space-around;
  }

  .nav-bar .header #menu {
    display: inherit;
    height: 100%;
    width: 10%;
  }
}

.dash {
  background-color: var(--main-background-color);
}
</style>
import Repository from "@/core/repository/_repository";
import CreateDelimitador from "../usecase/createDelimitador";
export default class CreatePeriodo {
    static action = {
        timeout: 3000,
        text: "Conectando Compenente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        const periodo = {
            "nome": "Trabalho",
            "agenda": "",
            "tipo": "unico",
            "inicio": "00:00",
            "fim": "00:00",
            "dias": [],
            "fuso": -3, //TODO: Tenho que implementar para pegar o fuso do cliente dinamicamente
            "ativo": true,
            "dono": "",
            "dinicio": {},
            "dfim": {}
        }

        //TENHO QUE VERIFICAR SE O INICIO FOI CONFIGURADO, SE NAO, RETORNA ERRO
        if(!form.model.dia.inicio)
            return `err/Início não foi configurado`
        if(!form.model.dia.fim)
            return `err/Fim não foi configurado`
        periodo.nome = fields.nome
        periodo.agenda = form.model.dia.agenda._id
        periodo.dono = form.model.dia.agenda.dono
        periodo.tipo = form.model.tipo
        periodo.inicio = form.model.dia.inicio ? form.model.dia.inicio.hora : "00:00"
        periodo.fim = form.model.dia.fim ? form.model.dia.fim.hora : "00:00"
        Object.assign(periodo, fields)
        console.log(periodo);
        
        const [result, err] = await this.repository.create("Periodos", periodo)
        if (err) return `err/${result}`
        
        const usecase = new CreateDelimitador(this.repository)
        const [inicio, ierr] = await usecase.execute(form.model.dia.dia, result, form.model.dia.inicio)
        if (ierr) return `err/${inicio}`
        const [fim, ferr] = await usecase.execute(form.model.dia.dia, result, form.model.dia.fim)
        if (ferr) return `err/${fim}`
        console.log(inicio);
        console.log(fim);

        periodo.dinicio = inicio
        periodo.dfim = fim

        if(form.model.dia.events)
            form.model.dia.events.push(periodo)

        //AQUI TENHO QUE INSERIR NA LISTA DE AGENDAMENTOS
        form.model.dia.agendamentos.push(periodo)
        if (form.model.dia.update)
            form.model.dia.update()
        //tenho que fazer um retoro de erro para quando usuario nao configura a hora do inicio
        //  e por padrao posso deixar o fim como sendo uma hora depois do incio caso no tenha sido configurado
        return "finish"
    }
}
import Repository from "@/core/repository/_repository";

export default class UpdateBotao {
    static action = {
        timeout: 3000,
        text: "Atualizando Botão"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, options: any) {
        console.log(form);
        console.log(options);
        Object.assign(form.component.options, options)

        const [result, err] = await this.repository.update("Componentes", form.component._id, form.component)
        if (err) console.error(result);
        else console.log(result);


        return "close"
    }
}
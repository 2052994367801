import Checksum from "@/core/AZ1/checksum";
import Module from "@/core/module/_module";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";

export default class ASW {
    module: Module
    constructor(module: Module) {
        this.module = module
    }
    async execute(component: any) {
        console.log(">>>  ASW >>>", component);

        const [modId, e485, , err] = checkModulo(component)
        if (err) snackbar.show(modId, 3000, "error");
        else {
            let cmd = Number(component.options.command)
            let query = {query: `CMD/${e485}/13`, tOut: 1000}
            if(cmd === 251 || cmd === 252) {
                query = {query: "none", tOut: 30}
                if(component.options.repetir === "15") cmd-=20
            }
            const pkg = [255, 1, 9, 12, 0, 1, 4, 2, 253, cmd, 3, 2]
            const [result, err] = await this.module.send(`${component.projId}/${modId}/CMD/CMD`, Checksum.calc(pkg).join(","), query);
            if(err) snackbar.show(result, 3000, "error")
        }
    }
}
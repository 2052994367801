import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
export default class FormWallpaper {
    repository: Repository
    model: any
    add = true
    name = "+ Adicionar"
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const [result, err] = await this.repository.findMany("Wallpapers", {})
        console.log(result, err)
        
        return {
            "extras": new Extra("Novo Wallpaper", "Adicionar", "end/createwallpaper/true"),
            "name": Field.make("text", "Nome descritivo", "Sala de Estar"),
            //"img": Field.make("file", "Nome descritivo", "Sala de Estar")
        }
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class SelectActions {
    repository: Repository
    model: { titulo: string; element: any; macro: any }
    constructor(repository: Repository, model: { titulo: string; element: any; macro: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list = [
            { value: "pwr", name: "Energia", type: "interruptor" },
            { value: "input", name: "Entrada", type: "input" },
            { value: "vol", name: "Volume", type: "vol" },
            { value: "loud", name: "Loud", type: "onoff" },
            { value: "bass", name: "Grave", type: "equa" },
            { value: "treble", name: "Agudo", type: "equa" },
            { value: "mute", name: "Mudo", type: "onoff" },
        ]
        return {
            "extras": new Extra("Escolha", "none", ""),
            "modulo": new Field("objectV", "Selecione o que deseja controlar", "", list, "", async (item: any) => {
                console.log(item)
                const operador = {
                    projId: this.model.macro.projId,
                    projeto: "",
                    macro: this.model.macro._id,
                    tipo: "componente",
                    index: this.model.macro.children ? this.model.macro.children.operadores.length : 0
                }
                if (item.value && item.value === "operador") operador.tipo = "operador"
                console.log(operador);
                const [result, err] = await this.repository.create("Operadores", operador)
                if (err) console.error(result);
                else {
                    const options = [{
                        key: "componente",
                        value: this.model.element._id,
                        type: "main",
                        operador: "",
                        projId: ""
                    }, {
                        key: item.value,
                        value: "0",
                        type: item.type,
                        operador: "",
                        projId: ""
                    }]

                    for (const o of options) {
                        o.operador = result._id
                        o.projId = result.projId
                        const [re, err] = await this.repository.create("OpcoesOperadores", o)
                        if (err) console.error(re);
                    }
                }
                return true
            })
        }

    }
}
import router from "@/router";
import { reactive } from "vue";

export const alert = reactive({
    showing: false,
    timeout: 0,
    msg: "Atenção",
    confirm: false,
    callback(res: any) {
        console.log("callback");
        res(true)
    },
    show(msg: string, confirm?: boolean) {
        console.log(msg)
        this.showing = this.showing ? false : true;
        this.msg = msg ? msg : "Atenção"
        this.confirm = confirm ? true : false
        return new Promise(res => {
            console.log("callback");
            this.callback = (confirm: boolean) => res(confirm)
        })
    }
})

export const wait = reactive({
    showing: false,
    timeout: 0,
    msg: "Aguarde",
    component: ({} as any),
    callback: () => { console.log("acabou") },
    show(timeout: number, msg: string) {
        console.log(msg)
        if (!timeout) this.callback = () => { console.log("acabou", msg) }
        this.showing = timeout > 0 ? true : false;
        this.timeout = timeout
        console.log(this)
        this.msg = msg ? msg : "Aguarde"
    },
    timeExtend(timeout: number, msg: string) {
        clearTimeout(this.timeout);
        this.component.msg = msg
        this.timeout = setTimeout(() => {
            this.callback()
            this.show(0, "")
        }, timeout);
    }
})

export const modal = reactive({
    showing: false,
    form: {} as any,
    count: 0,
    show(form: any) {
        this.showing = this.showing ? false : true;
        console.log(this.showing);

        if (this.showing) {
            if (window.location.pathname !== "/modal")
                window.history.pushState({ current: "modal", back: window.location.pathname }, "Modal", 'modal')
        } else {
            console.log("voltou");
            router.back()
        }

        this.form = form
    }
})

export const modalcontrole = reactive({
    showing: false,
    root: [],
    parent: {},
    show(root: [], parent: any) {
        if (modal.showing) return
        this.showing = this.showing ? false : true;
        this.root = root
        this.parent = parent
        if (this.showing)
            window.history.pushState({ current: "modal", back: window.location.pathname }, "Modal", 'modal')
        else {
            console.log("voltou");
            router.back()
        }
    }
})

export const modalslot = reactive({
    showing: false,
    root: [],
    parent: {},
    show(root: [], parent: any) {
        if (modal.showing) return
        this.showing = this.showing ? false : true;
        this.root = root
        this.parent = parent
        if (this.showing)
            window.history.pushState({ current: "modal", back: window.location.pathname }, "Modal", 'modal')
        else {
            console.log("voltou");
            router.back()
        }
    }
})
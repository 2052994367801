import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import Modos from "../../entity/modos"
import FormSelectProgramacao from "./selectProgramacao"

export default class FormSelectEventos {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.repository = repository
        this.model = model
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list = Modos.getEventos(this.model.model.tipo)
        if (!list.length && this.model.model.tipo)
            this.model.eventos = `Nenhum evento disponível para ${this.model.model.tipo.toUpperCase()}`
        else if(!list.length) this.model.eventos = `Tipo inválido`
        return {
            "extras": new Extra("Selecionar Evento", "none", "",),
            "eventos": !list.length ?
                Field.make("show", "Eventos") :
                Field.make("objectV", "Eventos", list.map(e => new FormSelectProgramacao(this.repository, this.model.model, e)))
        }
    }
}
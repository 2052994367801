<template>
    <div class="d-grid gap" onselectstart="return false">
        <h1>aqui tera uma lista de Atividades</h1>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            data: ""
        };
    },
    async created() {
        console.log("criou componente")
    },
    methods: {
        envia() {
            console.log("enviando formulario")
        }
    },
};
</script>
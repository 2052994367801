import { App } from "vue";
import { Socket } from 'socket.io-client';
import WSModule from '@/core/module/websocket';
import WRTCModule from '@/core/module/wrtc';
const modules: { [index: string]: any } = {
    "wrtc": WRTCModule,
    "socket": WSModule
}
export default {
    install: (app: App, options: { tipo: string; socket: Socket }) => {
        app.config.globalProperties.$module = new modules[options.tipo](options.socket)
    }
}
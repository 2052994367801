import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormComandoURL {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.model.objname = "Configurar"
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        delete this.model.nome
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        this.model.stringurlcmd = `https://url.livtech.com.br/cmd?id=${this.model._id}&action=press`
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.titulo, "Salvar", "end/updatecomponente/true"),
            "enableurlcmd": Field.make("toggle", "Ativar Comando via URL"),
            "stringurlcmd": Field.make("copy", "URL para comando")
        }
        return fields
    }
}
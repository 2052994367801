import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import FormBotaoIcon from "./icon"
import FormBotao from "./botao"

export default class SelectTypes {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }

    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Escolha Componente", "none", "createcomponente"),
            "tipo": new Field("objectV", "Componentes", "Selecione o Componente", [
                new FormBotao(this.repository, {titulo: "Botão com Texto", create: true, parent: this.model.parent}),
                new FormBotaoIcon(this.repository, {titulo: "Botão com Icone", create: true, parent: this.model.parent})
            ])
        }
    }
}
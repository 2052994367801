import Repository from "@/core/repository/_repository"

export default class CreateEventoProgramacao {
    static action = {
        timeout: 10000,
        text: "Adicionando Rotina"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        const conexao = typeof form.model.model.conexao === "string" ? form.model.model.conexao : form.model.model._id
        const [entrada, err] = await this.repository.create("ProgramacoesEntradas", {
            programacao: fields.rotina,
            entrada: conexao,
            projId: form.model.model.projId,
            modo: form.evento.value
        })
        console.log(entrada, err)
        return "begin"
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"

export default class SelectToPainel {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }

    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const b = this.model.parent.painel.find((p: any) => p.index === this.model.i)
        console.log(b, this.model.i, this.model.parent.painel)
        this.model.select = b ? b._id : ""
        const blist = this.model.parent.control.map((b: any) => {
            return { value: b._id, name: b.titulo ? b.titulo : b.options.code }
        })
        const remove = { value: "", name: "Remover" } //TODO: esse remover nao parece ser muito claro
        return {
            "extras": new Extra("Escolha Botão", "Atualizar", "end/addtopainel/true"),
            "select": new Field("select", "Botões", "Selecione", b ? [remove].concat(blist) : blist),
        }
    }
}
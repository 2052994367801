<template>
  <card
    :class="[
      'd-flex',
      'a-center',
      'gap-m',
      'justify-between',
      'row-three',
      'column-fit-l',
    ]"
    @click="abrirpasta"
  >
    <h2>{{ component.titulo }}</h2>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ccc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>
  </card>
</template>

<script>
import Card from "@/components/UI/Card.vue";
import { components, mostrando } from "@/stores/components";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
  },
  methods: {
    abrirpasta(){
      console.log(this.component);
      this.$router.push(this.component._id)
      mostrando.componente = this.component
    }
  }
};
</script>
import Repository from "@/core/repository/_repository"

export default class UpdateDelimitadores {
    static action = {
        timeout: 3000,
        text: "Atualizando Delimitador"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form);
        console.log(fields);
        const [hour, min] = fields.hora.split(":").map((s: string) => Number(s))
        form.model.date = new Date(form.model.date).setHours(hour, min)
        form.model.checked = form.model.date < new Date().getTime()
        Object.assign(form.model, fields)
        const [result, err] = await this.repository.update("Delimitadores", form.model._id, form.model)
        if(err) return `err/${result}`
        console.log(result)
        return "back"
    }
}
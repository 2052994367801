import Repository from "@/core/repository/_repository";

export default class OrderComponent {
    repository: Repository
    root: any
    constructor(repository: Repository, root: any) {
        this.repository = repository
        this.root = root
    }
    async setOrder(component: any) {
        const position = {
            index: component.index,
        };
        console.log(position);
        const query = {
            projId: this.root.projId,
            root: this.root._id,
            componente: component._id,
        };
        const result = await this.repository.updateQuery(
            "ComponenteRoot",
            query,
            position
        );
        console.log(result);
    }
}
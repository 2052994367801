import { Constructor } from "./_lib";

export default function Create<Base extends Constructor>(base: Base) {
    return class extends base {
        async create(collection: string, value: any): Promise<[any, boolean]> {
            await this.waitDB("create")
            try {
                if (!this.idb.db.objectStoreNames.contains(collection)){
                    console.log("adicionando =>", collection)
                    await this.idb.addStore(collection)
                }
                const store = this.idb.db.transaction(collection, "readwrite").objectStore(collection);
                return [store.put(value), false]
            } catch (error) {
                return [error, true]
            }
        }
    }
}
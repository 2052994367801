import irconv from "@/Model/IrConvert";
import { checkModulo } from "../../controllers/checkModulo";
import Module from "@/core/module/_module";
import { snackbar } from "@/stores/login";

export default class IR {
    module: Module
    constructor(module: Module) {
        this.module = module
    }
    async execute(component: any) {
        console.log(">>> IR >>>")
        const [modId, , canal, err] = checkModulo(component)
        if (err) snackbar.show(modId, 3000, "error");
        else {
            const cmdGcc = new irconv().hex2gcc(component.options.command, canal, component.options.repetir);
            const [result, err] = await this.module.send(`${component.projId}/${modId}/CMD/sendir`, cmdGcc.slice(7),{query: "completeir"});
            if(err) snackbar.show(result, 3000, "error")
        }
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import Compatibilidade from "./compatibilidade";
import SelectConexoes from "./conexoes";

export default class SelectModulos {
    repository: Repository
    model: { model: any }
    icon = true
    type = "add"
    constructor(repository: Repository, model: { model: any }, type: string) {
        this.model = model
        this.type = type
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = {
            "extras": new Extra(this.type === "change" ? "Mudar" : "Escolha", "none", "conectacomponente"),
            "modulo": new Field("object", "Modulos", "")
        }
        const [modulos, err] = await this.repository.findMany("ModulosPortasConexoes", { projId: this.model.model.projId })
        if (err) {
            console.error(modulos);
            return fields;
        }
        const list: SelectConexoes[] = []
        console.log(modulos)
        modulos.forEach(m => {
            let valido = false
            m.portas.forEach((p: any) => {
                p.conexoes.forEach((c: any) => {
                    if (Compatibilidade.check(this.model.model.tipo, c.tipo)) valido = true
                })
            })
            if (valido)
                list.push(new SelectConexoes(this.repository, { componente: this.model.model, modulo: m }))
        })

        fields.modulo = new Field("object", "Modulos", "", list)
        return fields
    }
}
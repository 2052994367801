<template>
    <div :style="{ 'grid-area': gridarea, 'background-color': color}"></div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
    name: "appLayout",
    components: {
    },
    props: {
        element: Object,
    },
    data() {
        return {
            gridarea: "",
            color: "",
        };
    },
    async created() {
        console.log(this.element)
        this.color = this.element.options.color
        const [style, err] = await this.$repository.findOne("ElementsStyle", {
            projId: this.element.projId,
            element: this.element._id
        })
        if (err) console.error(style);
        console.log(style)
        const p = style.position
        const s = style.size
        this.gridarea = `${p.y}/${p.x}/span ${s.h}/span ${s.w}`
    },
    methods: {
        fullscreen(e) {
            console.log(e)
            document.documentElement.requestFullscreen()
                .catch(err => console.error(err))
                .then(e => console.log(e))
        }
    },
});
</script>

<style>
.display img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
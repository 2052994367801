<template>
  <textarea v-model="value" rows="5" :placeholder="field.placeholder" />
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "textarea",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
    };
  },
  created() {
    this.value = this.$attrs.modelValue;
  },
});
</script>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import Modulo from "../domain/entity/Modulo";
import FormConexao from "./conexao";

export default class FormPorta {
    repository: Repository
    model: Modulo
    constructor(repository: Repository, model: Modulo) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = {
            "extras": new Extra(this.model.nome, "none", "updateporta"),
            "conexoes": new Field("show", "Conexoes", "Conexoes nao encontradas",)
        }
        const [result, err] = await this.repository.findMany("Conexoes", { porta: this.model._id, projId: store.state.projId })
        if (err) {
            console.error(result);
            return fields;
        } else {
            const conexoes: FormConexao[] = []
            result.forEach(c => {
                conexoes.push(new FormConexao(this.repository, c))
            })
            fields.conexoes = new Field("object", "Conexoes", "", conexoes)
            return fields
        }

    }
}
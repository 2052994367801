<template>
  <div class="carrossel" :style="{ 'grid-area': gridarea }">
    <main v-for="tela in telas" :key="tela._id" class="display"
      :class="{ portrait: portrait, active: tela.active, off: tela.off }">
      <component v-for="(item, index) in tela.componentes" :key="index" :is="item.tipo" :element="item">
      </component>
    </main>
  </div>
</template>

<script>
import { IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import table from "./table.vue";
import image from "./image.vue";
import iframe from "./iframe.vue";
import square from "./square.vue";
import video from "./video.vue";
import text from "./text.vue";
import { display } from "@/stores/components"
import TextTime from "@/core/usecase/texttime"
export default defineComponent({
  name: "appLayout",
  components: {
    IonPage,
    table,
    image,
    square,
    video,
    text,
    iframe
  },
  props: {
    element: Object,
  },
  data() {
    return {
      key: 0,
      display,
      portrait: false,
      removecode: "rd",
      removecount: 0,
      telas: [],
      programacao: [],
      mostrando: 0,
      videos: []
    };
  },
  async created() {
    console.log(this.$route.params)
    console.log(this.element)
    const [style, e] = await this.$repository.findOne("ElementsStyle", {
      projId: this.element.projId,
      element: this.element._id
    })
    if (e) console.error(style);
    const p = style.position
    const s = style.size
    this.gridarea = `${p.y}/${p.x}/span ${s.h}/span ${s.w}`
    const [carrossel, err] = await this.$repository.findOne("ProgramacaoTelas", {
      projId: this.$route.params.projId,
      _id: this.element._id
    })
    if (err) console.error(carrossel);
    console.log(carrossel);
    this.programacao = carrossel.programacao
    this.programacao.forEach(t => {
      t.tela = carrossel.telas.find(f => {
        if (f._id === t.tela._id) {
          f.componentes = t.tela.componentes
          return true
        }
      })
      t.tela.componentes.forEach(c => {
        if (c.tipo === "video") this.videos.push(c)
      })
      t.duracao = t.duracao ? new TextTime(t.duracao).timeToMilliseconds(t.duracao.split(":")) : 5000;
    })
    this.telas = carrossel.telas
    setTimeout(() => {
      this.videos.forEach(v => {
        console.log(v)
        //TODO: fazer algo que impessa que o video inicie dinamicamente se nao estiver sendo apresentado
        v.html = document.getElementById(v._id)
      });
    }, 2000);
    const video = this.programacao[0].tela.componentes.map(c => {
      if (c.tipo === "video") return c
    }).find(c => c)
    if(video) this.esperatela(video._id)
    else this.giraCarrossel()
  },
  methods: {
    giraCarrossel() {
      const anterior = this.mostrando === 0 ? this.programacao.length - 1 : this.mostrando - 1
      this.programacao[anterior].tela.off = true
      this.programacao[anterior].tela.active = false
      this.controlVideo(this.programacao[anterior].tela, "pause")
      this.controlVideo(this.programacao[this.mostrando].tela, "load")
      if (this.programacao[this.mostrando]) this.programacao[this.mostrando].tela.active = true
      setTimeout(() => {
        if (this.mostrando - 1 > -1 || this.mostrando === 0)
          this.programacao[anterior].tela.off = false
        if (this.mostrando < this.programacao.length - 1)
          this.mostrando++
        else
          this.mostrando = 0
        this.giraCarrossel()
      }, this.programacao[this.mostrando].duracao);
    },
    controlVideo(tela, action) {
      this.videos.forEach(v => {
        if (v.root === tela._id && v.html) v.html[action]()
      })
    },
    esperatela(id) {
      setTimeout(() => {
        console.log("esperando video")
        const html = document.getElementById(id)
        if(html && html.src) this.giraCarrossel()
        else this.esperatela(id)
      }, 200);
    }
  },
});
</script>

<style>
.carrossel {
  position: relative;
  overflow: hidden;
  transition: 0.5s;
}

.carrossel main {
  transition: 0.2s;
  position: absolute;
  left: 100%;
  opacity: 0;
}

.carrossel main.active {
  position: relative;
  left: 0;
  opacity: 1;
  top: 0;
}

.carrossel main.off {
  left: -100%;
}
</style>
<template>
  <div class="fundo-drop-down">
    <div class="modal-drop-down">
      <ul class="d-grid grid-gap" @click="selecionou">
        <slot></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ModalDropDown",
  props: {
    title: { type: String, default: "Title" },
  },
  methods: {
    selecionou(e) {
      this.$emit("selected", e.target);
    },
  },
});
</script>

<style>
.modal-drop-down {
  background-color: var(--ion-background-color, #fff);
  display: grid;
  gap: 1em;
  padding: 1em;
  border-radius: 0 0 0.7em 0.7em;
  width: calc(100% - 1.4em);
  max-height: 80vh;
  overflow: scroll;
  position: absolute;
  right: 0.7em;
  max-width: 70vw;
}
.fundo-drop-down {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
ul {
  list-style-type: none;
}
</style>
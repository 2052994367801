import { Storage } from '@capacitor/storage';

async function setStorage(key: string, value: string) {
    await Storage.set({ key, value });
}

async function getStorage(key: string) {
    return await Storage.get({ key });
}

async function rmStorage(key: string) {
    await Storage.remove({ key });
}

export { setStorage, getStorage, rmStorage }
export interface PkgQuery {
    query?: string;
    tOut?: number;
    e485?: string;
}
export default abstract class Module {
    msgId() {
        return new Array(25).join("0").replace(/[018]/g, (c: any) =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(24)
        );
    }
    abstract send(topic: string, payload: string, query?: PkgQuery, component?: string): Promise<[any, boolean]>
}
import Repository from "@/core/repository/_repository";
import Socket from "@/service/socket";
import { login, snackbar } from "@/stores/login";

export default class RecuperarSenha {
    static action = {
        timeout: 3000,
        text: "Criando Usuário"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(fields: any): Promise<any> {
        console.log(fields)
        if(fields.email && fields.email.length){
            const msg = `Verifique seus E-mails. Se caso ${fields.email} existir, eviaremos um e-mail com instruções para a troca de sua senha.`
            snackbar.show(msg, 25000)
            Socket.io.emit("forgotpassword", fields)
            login.callback("login")
        }else snackbar.show("Insira um e-mail válido", 3000, "error")
        return "finish"
    }
}
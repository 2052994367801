import Repository from "@/core/repository/_repository";
import FormComponent from "../../forms/component";
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import FormConexao from "@/Features/Modulo/forms/conexao";
import SelectModulos from "../../forms/conecta/modulos";

export default class FormStatusSub extends FormComponent {
    tipo = "statussub"
    name: string
    constructor(repository: Repository, model: any) {
        super(repository, model)
        this.name = model.titulo
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = await super.getFields()
        fields.extras = new Extra(this.model.titulo, "Atualizar", "end/updatecomponente/true")
        delete fields.titulo
        delete fields.action
        if(this.model.options.type === "noedit") {
            delete fields.conexao
            fields.extras = new Extra(this.model.titulo, "none", "end/updatecomponente/true")
            return fields
        }
        if(typeof this.model.conexao === "string"){
            const add = new SelectModulos(this.repository, { model: this.model }, "change")
            const trash = new SelectModulos(this.repository, {model: this.model}, "trash")
            const [result, err] = await this.repository.findOne("ConexoesPorta", {
                _id: this.model.conexao,
                projId: this.model.projId
            })
            console.log(result)
            if(err || !result) return fields
            fields.conexao = Field.make("object", "Conexões", [new FormConexao(this.repository, result), add, trash], i => {
                console.log(i)
                if(i.type === "trash") {
                    this.model.conexao = {}
                    this.repository.update("Componentes", this.model._id, {
                        projId: this.model.projId,
                        conexao: ""
                    })
                    return "refresh"
                }
                return true
            })
        }
        return fields
    }
}

import Algo from "./algo";
import AR from "./ar";
import ASW from "./asw";
import Cancel from "./cancel";
import CommandMqtt from "./commandMqtt";
import Fomulario from "./formulario";
import IR from "./ir";
import Link from "./link";

export default class Factory {
    static actions: { [key: string]: any } = {
        "algo": Algo,
        "commandmqtt": CommandMqtt,
        "ar": AR,
        "ir": IR,
        "asw": ASW,
        "cancel": Cancel,
        "link": Link,
        "formularios": Fomulario
    }
}
<template>
    <section id="acoes">
        <div class="">
            <div class="titulo" style="margin-bottom: 0px;">Ações</div>
        </div>
        <div style="margin-bottom: 1em;" id="table23">
            <table id="acoes" style="width:100%; font-amily:arial; font-size:80%">
                <!-- <tr>
            <th>Ação</th>
            <th>Check</th>
        </tr> -->
                <tr v-if="!acoes.length" id="row_acao1">
                    <td style="width: 90%"><input id="acao1" class="prodName"
                            onchange="action_onChange(this.val,this.id)" /></td>
                    <td style="width: 10%">
                        <label class="checkDiv">
                            <input type="checkbox" id="chk1" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr else v-for="acao in acoes" v-bind:key="acao" :id="acao">
                    <td>
                        <input id="acao2" class="prodName" :value="acao" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td>
                        <label class="checkDiv">
                            <input type="checkbox" id="chk2" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao3" style="display:none;">
                    <td><input id="acao3" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk3" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao4" style="display:none;">
                    <td><input id="acao4" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk4" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao5" style="display:none;">
                    <td><input id="acao5" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk5" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao6" style="display:none;">
                    <td><input id="acao6" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk6" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao7" style="display:none;">
                    <td><input id="acao7" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk7" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao8" style="display:none;">
                    <td><input id="acao8" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk8" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao9" style="display:none;">
                    <td><input id="acao9" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk9" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao10" style="display:none;">
                    <td><input id="acao10" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk10" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao11" style="display:none;">
                    <td><input id="acao11" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk11" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao12" style="display:none;">
                    <td><input id="acao12" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk12" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao13" style="display:none;">
                    <td><input id="acao13" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk13" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao14" style="display:none;">
                    <td><input id="acao14" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk14" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
                <tr id="row_acao15" style="display:none;">
                    <td><input id="acao15" class="prodName" onchange="action_onChange(this.val,this.id)" />
                    </td>
                    <td><label class="checkDiv">
                            <input type="checkbox" id="chk15" class="checkDiv">
                            <span class="checkmark"></span>
                        </label>
                    </td>
                </tr>
            </table>

        </div><!--center-->
    </section><!--head-checkDiv-->
</template>

<script>
export default {
    props: {
        global: Object,
        formulario: Object,
    },
    data() {
        return {
            data: "",
            elProject: document.getElementById("projName"),
            ACOES_LEN: 15,
            acoes: []
        };
    },
    async created() {
        console.log("criou componente")
        this.refreshAcoes(this.formulario.acoes)
    },
    methods: {
        envia() {
            console.log("enviando formulario")
        },
        action_onChange(val, id) {
            const row = Number(id.replace("acao", "")) + 1;
            const row_str = "row_acao" + row;
            if (val != "" && row <= this.ACOES_LEN) {
                document.getElementById(row_str).style.display = "table-row";
            }
        },
        //monitora alteracao do projeto
        refreshAcoes(acoes) {
            if(!acoes) return
            //let i = 1;
            //inclui acoes da ativiade
            if (typeof acoes == "object") {
                this.acoes.push(...Object.keys(acoes))
                /* for (const property in acoes) {
                    if (property == "") continue
                    if (i > this.ACOES_LEN) continue

                    const row_str = "row_acao" + i
                    const acao_str = "acao" + i

                    document.getElementById(row_str).style.display = "table-row";
                    document.getElementById(acao_str).value = property;

                    i++;
                } */
            }

            //limpa espacos restantes
            /* let first = 1;
            for (i; i <= this.ACOES_LEN; i++) {
                const row_str = "row_acao" + i
                const acao_str = "acao" + i

                if (first) document.getElementById(row_str).style.display = "table-row";
                else document.getElementById(row_str).style.display = "none";
                document.getElementById(acao_str).value = "";

                first = 0;
            } */

        }
    }
};
</script>

<style></style>
import ACControl from "../agregador/usecase/createAr"
import CreateAudio from "../agregador/usecase/createSom"
import TVControl from "../agregador/usecase/createTV"
import ReceiverControl from "../agregador/usecase/createReceiver"
import CreateDisplay from "../display/usecase/create"
import CreateDimmer from "../interruptor/usecase/createDimmer"
import CreateInterruptor from "../interruptor/usecase/createInterruptor"
import CreateMacro from "../macro/usecase/createMacro"
import CreatePasta from "../pasta/usecase/create"
import CreateStatus from "../status/usecase/createStatus"
import CreateTitle from "../title/usecase/create"
import CustomControl from "../agregador/usecase/createCustom"
import CreateIRControl from "../agregador/usecase/createIRControl"
import CreateAcesso from "../acesso/usecase/createAcesso"
import CreateAtividade from "../atividades/usecase/create"
import CreateCamera from "../camera/usecase/createCamera"
import CreateColumn from "../column/usecase/create"
import CreateMapList from "../subprojetos/usecase/createMapList"

export default class UseCases {
    static list: { [key: string]: typeof ACControl } = {
        "ac": ACControl,
        "tv": TVControl,
        "receiver": ReceiverControl,
        "custom": CustomControl,
        "template": CreateIRControl,
        "audio": CreateAudio,
        "interruptor": CreateInterruptor,
        "dimmer": CreateDimmer,
        "botaoprog": CreateMacro,
        "status": CreateStatus,
        "grafico": CreateStatus,
        "digital-in": CreateStatus,
        "eventdigitalin": CreateStatus,
        "title": CreateTitle,
        "pasta": CreatePasta,
        "display": CreateDisplay,
        "acesso": CreateAcesso,
        "atividade": CreateAtividade,
        "camera": CreateCamera,
        "column": CreateColumn,
        "maplist": CreateMapList,
    }
    static check(key: string) {
        return Object.prototype.hasOwnProperty.call(this.list, key)
    }
}
import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import { modulos } from "../store/list";
export default class DeleteModulo {
    static action = {
        timeout: 30000,
        text: "Apagando Módulo"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        const deleteList: { collection: string; idList: string[] }[] = [{
            collection: "Modulos",
            idList: [form.model._id]
        }, {
            collection: "Portas",
            idList: []
        }, {
            collection: "Conexoes",
            idList: []
        }]

        const [portas, err] = await this.repository.findMany("PortasConexoes", {
            modulo: form.model._id,
            projId: form.model.projId
        })
        if (err) console.error(portas);
        else {
            portas.forEach(p => {
                deleteList[1].idList.push(p._id)
                p.conexoes.forEach((c: any) => deleteList[2].idList.push(c._id))
            })
        }
        console.log(deleteList)
        for (const list of deleteList) {
            const [result, err] = await this.repository.deleteList(list.collection, list.idList)
            if (err) console.error(result)
            else console.log(result)
        }

        const [result, e] = await this.repository.deleteMany("ModulosRestart", {
            modulo: form.model._id,
            projId: form.model.projId
        })
        if(e) console.error(result);
        else console.log(result)
        
        modulos.remove(form.model)
        return "close"
    }
}
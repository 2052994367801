import FormSelectControle from "./controlesIR/_select"
import FormSelectLayout from "./layout/_select"
import FormTituloComponente from "./nomeComponente"

export default class FormsCreate {
    static list: { [key: string]: typeof FormTituloComponente } = {
        "layout": FormSelectLayout,
        "controle-ir": FormSelectControle,
        "audio": FormTituloComponente,
        "interruptor": FormTituloComponente,
        "dimmer": FormTituloComponente,
        "botaoprog": FormTituloComponente,
        "digital-in": FormTituloComponente,
        "eventdigitalin": FormTituloComponente,
        "status": FormTituloComponente,
        "grafico": FormTituloComponente,
        "acesso": FormTituloComponente,
        "atividade": FormTituloComponente,
        "camera": FormTituloComponente,
        "maplist": FormTituloComponente,
    }
    static titulos: { [key: string]: string } = {
        "layout": "Layout",
        "controle-ir": "Controle IR",
        "audio": "Áudio",
        "interruptor": "Interruptor",
        "dimmer": "Dimmer",
        "botaoprog": "Botão",
        "digital-in": "Entrada Digital",
        "eventdigitalin": "Eventos Entrada Digital",
        "status": "Status",
        "grafico": "Gráfico",
        "acesso": "Controle de Acesso",
        "atividade": "Formulário de Atividades",
        "camera": "Câmera",
        "maplist": "Mapa Subprojetos",
    }
    static check(key: string) {
        return Object.prototype.hasOwnProperty.call(this.list, key)
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import FormWallpaper from "./wallpaper"
import FormUpdateWallpaper from "./updatewallpaper"

export default class FormWallpapers {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const add = new FormWallpaper(this.repository, this.model)
        const list: any[] = [add]
        const [result, err] = await this.repository.findMany("Wallpapers", {})
        if (!err) result.forEach(w => list.push(new FormUpdateWallpaper(this.repository, w)))
        let urls = ""
        result.forEach(r => urls += `'_img/${r.value}',\n`)
        this.model.urls = urls
        return {
            "extras": new Extra("Gerenciar Wallpapers", "none", ""),
            "themes": new Field("objectVIMG", "Imagens", "", list),
            "urls": Field.make("copypre", "URLs para Service Worker")
        }
    }
}
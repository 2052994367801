import Module from "@/core/module/_module";
import Componente from "@/Features/Componentes/_componente";
import GetFeedback from "@/Features/Modulo/usecase/GetFeedback";
import ModulosList from "@/Features/Modulo/_modulo";
import State from "@/service/states/state";
import { reactive } from "vue";
const list: Componente[] = []
export const components = reactive({
    list,
    key: 0,
    update(args: any) {
        for (const i in components.list) {
            if (components.list[i]._id === args._id) {
                if (components.list[i].tipo === "grafico" && Object.prototype.hasOwnProperty.call(components.list[i], "update"))
                    (components.list[i] as any).update(args.options.amostra)
                Object.assign(components.list[i].options, args.options)
            }
        }
    },
    clear() {
        this.list.splice(0, this.list.length)
        modulos.clear()
    },
    push(component: Componente) {
        if (list.find(c => c._id === component._id))
            return
        this.list.push(component)
        if (typeof component.conexao === "object" && (component.conexao as any).porta)
            modulos.push(component)
    },
    remove(component: Componente) {
        console.log(component)
        const i = this.list.map(m => m._id).indexOf(component._id)
        this.list.splice(i, 1);
    }
})

export const display = reactive({
    key: 0,
    receivers: [],
    elements: [],
    update() {
        this.key++
    }
})

const listmain: Componente[] = []
export const componentsMain = reactive({
    listmain,
    clear() {
        this.listmain.splice(0, this.listmain.length)
        modulos.clear()
    },
    push(component: Componente) {
        if (listmain.find(c => c._id === component._id))
            return
        this.listmain.push(component)
    },
    remove(component: Componente) {
        const i = this.listmain.map(m => m._id).indexOf(component._id)
        this.listmain.splice(i, 1);
    }
})

export const mostrando = reactive({
    componente: {}
})



const modulosList: { [key: string]: ModulosList } = {}
/**
 * Responsavel por armazenar a lista de status dos modulos com seus componentes
 * E atuliza status dos componentes quando recebe novo status do servidor
 */
export const modulos = reactive({
    modulosList,
    module: ({} as Module),
    push(component: Componente) {
        const modulo = (component.conexao as any).porta.modulo
        this.insertIn(this.getModulos(modulo), component)
    },
    getModulos(modulo: any): any[] {
        if (modulo.modelo === "gateway") return [modulo]
        return this.getModulos(modulo.modulo).concat(modulo)
    },
    createModulo(modulo: any, list: { [key: string]: ModulosList }) {
        list[modulo._id] = { id: modulo._id, tipo: modulo.tipo, components: [], status: "🟡", e485: Number(modulo.e485), submodulo: {}, checked: false }
    },
    insertIn(modulos: any[], component: Componente, list?: { [key: string]: ModulosList }, modId?: string) {
        if (!list) list = this.modulosList;
        const modulo = modulos.shift()
        if (!modId) modId = modulo.modId
        if (modulos.length === 0) {
            if (!Object.prototype.hasOwnProperty.call(list, modulo._id)) this.createModulo(modulo, list);
            list[modulo._id].components.push(component);
        } else {
            if (!Object.prototype.hasOwnProperty.call(list, modulo._id)) this.createModulo(modulo, list)
            this.insertIn(modulos, component, list[modulo._id].submodulo, modId)
        }
        if (!list[modulo._id].checked)
            new GetFeedback(State.app, `${modulo.projId}/${modId}`).get(list[modulo._id]);
    },
    update(data: any) {
        data.components.forEach((c: Componente) => {
            const i = components.list.map(c => c._id).indexOf(c._id)
            if (i < 0) return
            if (typeof components.list[i].conexao === "object")
                (components.list[i].conexao as any).status = data.status
        });
    },
    clear() {
        for (const key in this.modulosList)
            delete this.modulosList[key]
    },
})

export const conexao = reactive({
    on: (c: any) => c
})
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class ConectaProgramacao {
    repository: Repository
    model: { nome: string; model: any }
    constructor(repository: Repository, model: { nome: string; model: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list: any[] = []
        const fields = {
            "extras": new Extra("Escolha", "none", ""),
            "modulo": new Field("objectV", "Programações", "", list)
        }

        console.log(this.model)
        const [result, err] = await this.repository.findMany("Programacoes", { projId: this.model.model.projId })
        if (err) console.error(result);
        else {
            console.log(result)
            result.forEach(p => list.push({ name: p.nome, value: p._id }))
            fields.modulo = new Field("objectV", "Programações", "", list, this.model.model, async (o: any) => {
                console.log(o);
                this.model.model.options.action = o.value
                delete this.model.model.conexao
                console.log(this.model.model)
                const [result] = await this.repository.update("Componentes", this.model.model._id, this.model.model)
                console.log(result);
                return false
            })
        }

        return fields
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import FormElement from "./_element"
import FormFont from "./styles/font"
import FormData from "./data"
import FormAPI from "./api"
import FormBody from "./styles/body"

export default class FormTable extends FormElement{
    constructor(repository: Repository, model: any) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields = await super.getFields();
        (fields["style"] as any).list.push(new FormFont(this.repository, {objname: "Fonte", element: this.model}));
        (fields["style"] as any).list.push(new FormBody(this.repository, {objname: "Corpo", element: this.model}));
        fields["url"] = new Field("object", "API com valores", "", [new FormAPI(this.repository, this.model)])
        fields["data"] = new Field("object", "Dados", "", [new FormData(this.repository, { objname: "Gerenciar", table: this.model })])
        const [colunas, err] = await this.repository.findMany("TableColumns", {
            projId: this.model.projId,
            table: this.model._id
        })
        if(err) return fields
        const columns: {value: string; name: string}[] = []
        colunas.forEach(c => columns.push({value: c.key, name: c.name}))
        fields["primary"] = new Field("select", "Coluna Primária", "Selecione", columns)
        return fields
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormCopyMove from "./copyMove";

export default class SelectComponentes {
    repository: Repository
    model: { titulo: string; root: any }
    constructor(repository: Repository, model: { titulo: string; root: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        //TODO: tem que pegar tanto main quanto layout sem ser a propria pasta
        const [componentes, err] = await this.repository.findMany("Componentes", {
            projId: this.model.root.projId,
            main: true
        })
        if (err) return {}
        const list: SelectComponentes[] = componentes.map(c => {
            c.root = this.model.root
            return new FormCopyMove(this.repository, c)
        })
        const [layouts, e] = await this.repository.findMany("Componentes", {
            projId: this.model.root.projId,
            func: "layout"
        })
        if (e) return {}
        console.log(layouts)
        return {
            "extras": new Extra("Escolha", "none", "conectacomponente"),
            "modulo": new Field("objectV", "Componentes", "", list.concat(layouts.map(c => {
                c.root = this.model.root
                return new FormCopyMove(this.repository, c)
            })))
        }

    }
}
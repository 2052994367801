import Repository from "@/core/repository/_repository";

export default class DeleteValue {
    static action = {
        timeout: 10000,
        text: "Atualizando Coluna"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        await this.repository.delete("TableRows", form.model._id)
        await this.repository.deleteMany("TableFields", {
            projId: form.model.projId,
            row: form.model._id
        })
        return "back"
    }
}
import { reactive } from "vue";
import Modulo from "../domain/entity/Modulo";
const list: Modulo[] = []
export const modulos = reactive({
    list,
    clear() {
        this.list.splice(0, this.list.length)
    },
    push(modulo: Modulo) {
        if (list.find(m => m._id === modulo._id))
            return
        this.list.push(modulo)
    },
    remove(modulo: Modulo) {
        const i = this.list.map(m => m._id).indexOf(modulo._id)
        this.list.splice(i, 1);
    }
})
//import { Socket } from "socket.io-client";
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
//import CreateUsuario from "../usecase/CreateUsuario";

export default class AdapterForgotPassword {
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Recuperação de Senha", "Enviar", "recuperarsenha", { text: "Lembrou sua senha? ", extra: { type: "login", text: "Entre" } }),
            "email": new Field("text", "E-mail da sua conta", "exemplo@dominio.com"),
            "error": new Field("error", "Erro", "Senha"),
        }
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import FormConfigServidorLocal from "./config"
import FormUpdateProjetoLocal from "./projetoLocal"

export default class FormUpdateOfflineServidorLocal {
    repository: Repository
    model: any
    lista: any[]
    constructor(repository: Repository, model: any, lista: any[]) {
        this.model = model
        this.repository = repository
        this.lista = lista
    }

    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [key: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "none", "updateservidorlocal"),
            "nome": Field.make("text", "Nome", "servidor-local"),
            "uuid": Field.make("copy", "UUID"),
            "config": Field.make("object", "Servidor Local", [new FormConfigServidorLocal(this.repository, this.model)])
        }
        const [projetos, err] = await this.repository.findMany("ProjetosLocais", { servidorlocal: this.model._id })
        console.log(projetos, err)
        this.model.projetoslocais = "Não foram encontrados projetos!!!"
        if(!projetos.length) fields["projetoslocais"] = Field.make("show", "Projetos")
        else fields["projetoslocais"] = Field.make("object", "Projetos", projetos.map(p => new FormUpdateProjetoLocal(this.repository, p)))
        return fields
    }
}
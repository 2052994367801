import Adapter from "../adapter/_adapter"
import { FieldsTypes, FieldsTypesMap } from "./_lib";
type List = Array<{ value: string; name: string } | Adapter>
//Action se retornar true faz acao normal, se false retorna um. Só esta funcionando no ObjectV
type Action = (o?: any) => boolean | Promise<boolean | string> | string
export default class Field {
    type: string
    label?: string
    placeholder?: string
    list?: Array<{ value: string; name: string } | Adapter>
    model?: any
    action?: Action
    order?: Action
    waitValueMsg?: string;
    getAsyncValue?: (o?: any) => Promise<any>;
    constructor(type: string, label?: string, placeholder?: string, list?: List, model?: any, action?: Action) {
        this.type = type
        this.label = label
        this.placeholder = placeholder
        this.list = list
        this.model = model
        this.action = action
    }
    static make<T extends keyof FieldsTypes>(type: T, ...params: FieldsTypes[T]): Field {
        const field: any = new Field(type)
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(field, FieldsTypesMap[type][key]))
                field[FieldsTypesMap[type][key]] = params[key]
            else if (FieldsTypesMap[type][key] === "extra" && typeof params[key] === "object")
                for (const extra in (params[key] as any)) {
                    if (Object.prototype.hasOwnProperty.call(field, extra))
                        field[extra] = (params[key] as any)[extra];
                }
        }
        return field
    }
}

/**
 * "outro": { label: "Outro Parametro", placeholder: "parametro teste", type: "text" },
            "nome": { label: "Nome", placeholder: "Nome", type: "text" },
            "modId": { label: "ID do módulo gateway:", placeholder: "ID do modulo com 2 caracteres", type: "text" },
            "e485": { label: "Endereço barramento 485:", placeholder: "Selecionar", type: "list", list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16] },
            "modIn": { label: "Entrada do módulo:", placeholder: "Selecionar", type: "list", list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
            "mostrarHTML": { label: "Mostrar:", placeholder: "", type: "checkbox" },
            "alarmar": { label: "Enviar Alarme:", placeholder: "", type: "checkbox" },
            "periodo": { label: "Período: ", placeholder: "Escolher", type: "select", list: [{ value: "diario", name: "Diário" }] },
            "contactType": { label: "Inverter leitura (Contato NF):", type: "checkbox" }
 */
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "../../../core/repository/_repository";

export default class FormSelectDay {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Escolha", "Próximo", "selectday"),
            "dia": new Field("date", "Dia que ira iniciar", "")
        }
    }
}
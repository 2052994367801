import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import Device from "../entity/device"

export default class FormConfigWIFIServidorLocal {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.model.objname = "Configurar"
        this.repository = repository
    }

    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "Enviar", "end/configservidorlocal/true"),
            "ssid": Field.make("text", "SSID WI-FI", "meu-wifi"),
            "pass": Field.make("text", "Senha", "meu-wifi"),
        }
        return fields
    }
}
<template>
    <section class="widgets ir">
        <div class="card head noselect d-grid gap-m ac-center row-three column-fit-l" style="grid-template-columns: repeat(4, 1fr);">
            <component v-for="component in head" v-bind:is="component.tipo" :key="component._id"
                v-bind:component="component"></component>
        </div>
        <div class="card controlls noselect d-grid gap-m ac-center row-five column-fit-l">
            <label class="botao" style="grid-area: 1 / 1/ span 3;">
                <component v-for="component in volume" v-bind:is="component.tipo" :key="component._id"
                    v-bind:component="component"></component>
                    <svg style="grid-row-start: 2;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                        stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-volume">
                        <polygon points="16 5 11 9 7 9 7 15 11 15 16 19 16 5"></polygon>
                    </svg>
            </label>
            <label class="botao" style="grid-area: 1 / 3/ span 3;">
                <component v-for="component in channel" v-bind:is="component.tipo" :key="component._id"
                    v-bind:component="component"></component>
                <p style="grid-row-start: 2;">CH</p>
            </label>
            <component v-for="component in controlls" v-bind:is="component.tipo" :key="component._id"
                v-bind:component="component"></component>
        </div>
        <div class="card directionals noselect d-grid gap-m ac-center column-fit-l">
            <label class="" style="
                background: #dbdbdb;
                grid-area: 1 / 1 / span 3 / span 3;
                z-index: 0;
                border-radius: 50%;
            "></label>
            <component v-for="component in diretionals" v-bind:is="component.tipo" :key="component._id"
                v-bind:component="component" :class="dire"></component>
        </div>
        <div class="card colored noselect d-grid gap-m ac-center row-three column-fit-l" style="grid-template-columns: repeat(4, 1fr);grid-template-rows: 2em;">
            <component v-for="component in colored" v-bind:is="component.tipo" :key="component._id"
                v-bind:component="component" v-bind:style="component.style"></component>
        </div>
        <div class="card noselect d-grid gap-m ac-center row-three column-fit-l" style="grid-template-columns: repeat(3, 1fr);grid-row-end: span 8;">
            <component v-for="component in numerical" v-bind:is="component.tipo" :key="component._id"
                v-bind:component="component"></component>
        </div>
        <component v-for="component in others" v-bind:is="component.tipo" :key="component._id"
        v-bind:component="component"></component>
    </section>
</template>

<script>
//import { setStorage } from "@/scripts/Storage";
import { defineComponent } from "vue";
import { modalcontrole } from "@/stores/showModal";
import select from "@/components/Form/Select.vue";
import text from "@/components/Form/Text.vue";
import textarea from "@/components/Form/Textarea.vue";
import object from "@/components/Form/Object.vue";
import objectV from "@/components/Form/ObjectV.vue";
import objectC from "@/components/Form/ObjectC.vue";
import disabled from "@/components/Form/Disabled.vue";
import toggle from "@/components/Form/Toggle.vue";
import add from "@/components/Form/Add.vue";
import show from "@/components/Form/Show.vue";
import botao from "@/Features/Componentes/botao/ui/botao.vue";
import botaoiconeSQ from "@/Features/Componentes/botao/ui/iconeSQ.vue";
import volume from "@/Features/Componentes/agregador/ui/volume.vue";
import equa from "@/Features/Componentes/agregador/ui/equa.vue";
import entradas from "@/Features/Componentes/agregador/ui/entradas.vue";
const layoutMap = {
    "POWER": { group: "head", position: "1 / 1 / auto / auto" },
    "HOME": { group: "head", position: "1 / 2 / auto / auto" },
    "MENU": { group: "head", position: "1 / 3 / auto / auto" },
    "INPUT": { group: "head", position: "1 / 4 / auto / auto" },
    "VOLplus": { group: "volume", position: "1/1" },
    "VOLminus": { group: "volume", position: "3/1" },
    "MUTE": { group: "controlls", position: "2 / 2 / auto / auto" },
    "CHplus": { group: "channel", position: "1/1" },
    "CHminus": { group: "channel", position: "3/1" },
    "UP": { group: "diretionals", position: "1 / 2 / auto / auto", classes: ["dire"] },
    "RIGHT": { group: "diretionals", position: "2 / 3 / auto / auto", classes: ["dire"] },
    "DOWN": { group: "diretionals", position: "3 / 2 / auto / auto", classes: ["dire"] },
    "LEFT": { group: "diretionals", position: "2 / 1 / auto / auto", classes: ["dire"] },
    "OK": { group: "diretionals", position: "2 / 2 / auto / auto", classes: ["ok"] },
    "BACK": { group: "diretionals", position: "3 / 1 / auto / auto", classes: ["extra", "e3"] },
    "CONF": { group: "diretionals", position: "3 / 3 / auto / auto", classes: ["extra", "e4"] },
    "RED": { group: "colored", position: "1 / 1 / auto / auto", classes: ["red"] },
    "GREEN": { group: "colored", position: "1 / 2 / auto / auto", classes: ["green"]},
    "YELLOW": { group: "colored", position: "1 / 3 / auto / auto", classes: ["yellow"]},
    "BLUE": { group: "colored", position: "1 / 4 / auto / auto", classes: ["blue"]},
    "NUM1": { group: "numerical", position: "1 / 1 / auto / auto" },
    "NUM2": { group: "numerical", position: "1 / 2 / auto / auto" },
    "NUM3": { group: "numerical", position: "1 / 3 / auto / auto" },
    "NUM4": { group: "numerical", position: "2 / 1 / auto / auto" },
    "NUM5": { group: "numerical", position: "2 / 2 / auto / auto" },
    "NUM6": { group: "numerical", position: "2 / 3 / auto / auto" },
    "NUM7": { group: "numerical", position: "3 / 1 / auto / auto" },
    "NUM8": { group: "numerical", position: "3 / 2 / auto / auto" },
    "NUM9": { group: "numerical", position: "3 / 3 / auto / auto" },
    "NUME1": { group: "numerical", position: "4 / 1 / auto / auto" },
    "NUM0": { group: "numerical", position: "4 / 2 / auto / auto" },
    "NUME2": { group: "numerical", position: "4 / 3 / auto / auto" },
}
export default defineComponent({
    name: "ModalConfiguracao",
    props: {
        form: Object,
    },
    components: {
        select,
        text,
        textarea,
        object,
        objectV,
        disabled,
        toggle,
        add,
        objectC,
        show,
        botao,
        botaoiconeSQ,
        volume,
        equa,
        entradas,
    },
    data() {
        return {
            title: "",
            template: false,
            edit: false,
            exclude: false,
            atribs: {},
            fields: {},
            forms: [],
            close: "Cancelar",
            path: 0,
            extras: {},
            components: [],
            modalcontrole,
            projId: "",
            templateType: "",
            head: [],
            volume: [],
            channel: [],
            controlls: [],
            diretionals: [],
            numerical: [],
            colored: [],
            others: []
        };
    },
    async created() {
        if (modalcontrole.parent.options.code === "template") {
            this.template = true
            this.edit = true
            this.templateType = modalcontrole.parent.options.type
        }
        this.projId = this.$route.params.projId;
        this.title = modalcontrole.parent.titulo
        modalcontrole.root.forEach((c) => {
            c.parent = modalcontrole.parent;
            if (Object.prototype.hasOwnProperty.call(layoutMap, c.options.code)) {
                c.layout = layoutMap[c.options.code]
                this[layoutMap[c.options.code].group].push(c)
            } else if(c.options.show) this.others.push(c)
        });
        //this.next(modal.form);
    },
    sockets: {
        configuracao(data) {
            console.log(data);
        },
    },
    methods: {
        async Cancel() {
            const last = this.forms.length - 1;
            if (last > 0) {
                await this.back();
                if (last === 1) this.close = "Cancelar";
            } else this.$emit("close");
        },
    },
});
</script>

<style>
.controle .ir {
    background-color: var(--screen-background);
    border-radius: 0.5em;
}
.controle div.head button,
.controle div.controlls button {
    background: none;
    padding: 0;
}

.controle div.controlls {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 2em;
    padding: 0 10px;
}

.controle div.controlls label {
    z-index: 0;
    display: grid;
    align-content: space-between;
    justify-content: center;
    font-weight: normal;
}

.controle div.controlls label *, .controle div.directionals * {
    z-index: 1;
    background: none;
    padding: 0;
}

.controle div.directionals {
    grid-template-columns: repeat(3, 1fr);
    grid-row-end: span 9;
    grid-auto-rows: 4em;
}

.controle div.directionals .extra{
    background: var(--screen-background);
}
.controle .red {
    background-color:#ff4343
}
.controle .green {
    background-color:#4fff43
}
.controle .yellow {
    background-color:#fcff43
}
.controle .blue {
    background-color:#4374ff
}
.controle div.directionals .e3 {
    border-radius: 0 70% 0 0 !important;
}
.controle div.directionals .e4 {
    border-radius: 70% 0 0 0 !important;
}
.controle div.directionals button.ok {
    background: var(--color-primary);
    color: var(--color-primary-contrast);
    border-radius: 50% !important;
}
.controle div.directionals button.ok:active{
    background: gray;
}
</style>
import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import Programacao from "../entity/programacao";

export default class CreateMacro {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(fields: any): Promise<[any, boolean]> {
        const programacao = new Programacao();
        programacao.tipo = fields.tipo
        programacao.projId = store.state.projeto.projId
        programacao.projeto = store.state.projeto._id
        programacao.nome = fields.titulo
        const [result, err] = await this.repository.create("Programacoes", programacao)
        if (err) console.error(result)
        else Object.assign(programacao, result)

        console.log(programacao)
        return [programacao, false]
    }
}
import Repository from "@/core/repository/_repository";
import Componente from "../../_componente";

export default class CreateDimmer {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, fields: any): Promise<[Componente, boolean]> {
        const component = new Componente();
        component.tipo = "dimmer"
        component.projId = root.projId
        component.projeto = root.projeto
        component.main = true
        component.options = {
            titulo: component.titulo = fields.titulo,
            state: "0",
            action: "ligar"
        }
        if(fields.conexao) component.conexao = fields.conexao
        const [result, err] = await this.repository.create("Componentes", component)
        if (err) console.error(result)
        else Object.assign(component, result)

        console.log(component)
        return [component, false]
    }
}
<template>
  <card :class="['d-flex','gap-s', 'a-center', 'row-three', 'column-fit-m']">
    <button class="botao" @click="execute">
      {{ component.titulo }}
    </button>
  </card>
</template>
<script>
import Card from "@/components/UI/Card.vue";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      action: "",
      titulo: "",
    };
  },
  async created() {
    this.action = this.component.options.action;
  },
  methods: {
    async execute(e) {
      e.preventDefault();
      console.log(this.component);
      const [programacao, err] = await this.$repository.findOne(
        "Programacoes",
        {
          projId: this.component.projId,
          _id: this.component.options.action,
        }
      );
      if (err) console.error(programacao);
      this.$socket.emit("programacao", programacao);
    },
  },
};
</script>

import RepositoryIndexedDB from "."
import Repository from "../_repository";
import Aggregation from "./aggregation";
import IndexedDB from "./config";
import { App } from "vue";
import Views from "./views";
import { Socket } from "socket.io-client";
import Stages from "./aggregation/stages";
import { wait } from "@/stores/showModal";

export default async function teste(app: App) {
    console.log()
    const repository: Repository = app.config.globalProperties.$repository
    /* const IDB = new IndexedDB("Livtech")
    const repositoryIDB = new RepositoryIndexedDB(IDB); */
    //await setallprojeto(repository, repositoryIDB)
    //const collection = "AgregadorSubs";
    const collection = "RootComponentes";
    const query = { projId: "BS0fd", tipo: "audio" };

    //console.log(...await repositoryIDB.findOne(collection, query))
    /* TODO: aqui vou ter que gravar as views no banco como object store */
    //await repositoryIDB.waitDB("teste")
    console.log(new Date())

    //IDB.deleteStore(collection)
    //console.log(...await repositoryIDB.findOne(collection, {projId: "BS0fd", tipo: "root"}))

    //await makeViewToCollection2("Hall", repositoryIDB)
    //await makeViewToCollection2("ComponentesConexao", repositoryIDB)
    /* await setallprojeto(repository, repositoryIDB) */
    console.log(new Date())
}

async function insert(repositoryIDB: RepositoryIndexedDB, collection: string, values: any[]) {
    for (const value of values) {
        await repositoryIDB.create(collection, value)
    }
}

/**
 * 
 * @param repository 
 * @param repositoryIDB 
 * 
 * aqui vai ter que criar as views e tbm nas views ja tem que criar os index 
 */
export async function setallprojeto(socket: Socket/* , repositoryIDB: RepositoryIndexedDB */) {
    const event = `getalluser`
    await socket.waitSocket()
    socket.emit(event, { username: "" })
    const [collections, err] = await socket.wait(event)
    console.log(collections)
    const idb = new IndexedDB("Livtech", true)
    const stores: { [key: string]: IDBObjectStore } = {}
    idb.request.onupgradeneeded = async () => {
        console.log("👉 TESTE ONUPGRADENEEDED")
        const db = idb.request.result
        if (err) return
        for (const collection in collections) {
            if (collection === "projetos") {
                for (const projId in collections[collection]) {
                    for (const c in collections[collection][projId]) {
                        console.log(collections[collection][projId][c])
                        if (!Object.prototype.hasOwnProperty.call(stores, c))
                            stores[c] = db.createObjectStore(c, { keyPath: "_id" });
                        for (const value of collections[collection][projId][c][0]) {
                            stores[c].add(value).onsuccess = (e) => console.log(c, e.type)
                            //await repositoryIDB.create(collection, value)
                        }
                        //await insert(repositoryIDB, c, collections[collection][projId][c][0])
                    }
                }
            } else {
                const store = db.createObjectStore(collection, { keyPath: "_id" });
                for (const value of collections[collection]) {
                    store.add(value).onsuccess = (e) => console.log(collection, e)
                    //await repositoryIDB.create(collection, value)
                }
                //await insert(repositoryIDB, collection, collections[collection])
            }
        }

        [
            "Hall",
            "ComponentesConexao",
            "ComponentesRoot",
            "RootComponentes",
            "AgregadorSubs",
            "ProjetosModulos"
        ].forEach(c => db.createObjectStore(c, { keyPath: "_id" }));
    }

    //repositoryIDB.idb.db.close()
    const repositoryIDB = new RepositoryIndexedDB(idb);
    wait.timeExtend(60000, "20% Esperando Banco de Dados local")
    await repositoryIDB.waitDB("setall")
    console.log(repositoryIDB)
    wait.timeExtend(60000 * 5, "25% Criando Hall")
    await makeViewToCollection2("Hall", repositoryIDB)
    wait.timeExtend(60000 * 5, "50% Criando Componentes Conexao")
    await makeViewToCollection2("ComponentesConexao", repositoryIDB)
    wait.timeExtend(60000 * 5, "60% Criando Componentes Root")
    await makeViewToCollection2("ComponentesRoot", repositoryIDB)
    wait.timeExtend(60000 * 5, "70% Criando Root Componentes")
    await makeViewToCollection2("RootComponentes", repositoryIDB)
    wait.timeExtend(60000 * 5, "80% Criando Agregador Subs")
    await makeViewToCollection2("AgregadorSubs", repositoryIDB)
    wait.timeExtend(60000 * 5, "90% Criando Projetos Modulos")
    await makeViewToCollection2("ProjetosModulos", repositoryIDB)
    wait.timeExtend(2000, "100% finalizando")
}
/* TODO: tem que fazer os stages um por um já adicionando no store,
    o primeiro stage in */

async function makeViewToCollection2(collection: string, repositoryIDB: RepositoryIndexedDB) {
    console.log("criando =>", collection);
    const view = Views[collection];
    console.log(view)
    if (!view) return
    const stage = (view.stages as any[]).shift();
    if (!repositoryIDB.idb.db.objectStoreNames.contains(collection)) {
        console.log("adicionando =>", collection);
        await repositoryIDB.idb.addStore(collection);
    }

    const transaction = repositoryIDB.idb.db.transaction(view.storeNames, "readwrite");
    const stages = new Stages(repositoryIDB.idb, transaction)

    console.log(" ====> ", stage.type)
    if (Object.prototype.hasOwnProperty.call(stages.list, stage.type))
        await stages.list[stage.type](view.base, stage.params, async (_, value) => {
            const store = transaction.objectStore(collection);
            store.put(value)
        })

    for (const stage of view.stages) {
        console.log(" ====> ", stage.type)
        if (Object.prototype.hasOwnProperty.call(stages.list, stage.type))
            await stages.list[stage.type](view.name, stage.params, async (cursor, value) => {
                cursor.update(value)
            })
    }

    transaction.objectStore(collection).getAll().onsuccess = (event) => console.log(collection, (event.target as any).result)
}

async function findNext(idb: IndexedDB, collection: string, count: number): Promise<[any, number, boolean]> {
    if (idb.db.objectStoreNames.contains(collection)) {
        return new Promise(res => {
            const store = idb.db.transaction(collection).objectStore(collection);
            let advanced = false
            store.openCursor().onsuccess = async (event) => {
                const cursor: IDBCursor = (event as any).target.result;
                if (!cursor) return res([null, count, true]);
                if (advanced || count === 0) return res([cursor.request.result.value, count, false])
                cursor.advance(count)
                advanced = true
            };
        });
    }
    return [{}, count, true]
}

async function makeViewToCollection(collection: string, repositoryIDB: RepositoryIndexedDB) {
    console.log("criando =>", collection)
    const view = Views[collection]
    const transaction = repositoryIDB.idb.db.transaction(view.storeNames, "readwrite");
    let count = 0
    let value = await new Aggregation(repositoryIDB.idb, transaction).makePipeline(view.base, view.stages, 0)
    while (value) {
        await repositoryIDB.create(collection, value)
        count++
        value = await new Aggregation(repositoryIDB.idb, transaction).makePipeline(view.base, view.stages, count)
    }
    return [{}, true]
}
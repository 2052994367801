import Repository from "@/core/repository/_repository";
import { alert } from "@/stores/showModal";
export default class DeleteProjeto {
    static action = {
        timeout: 10000,
        text: "Apagando Projeto"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        const confirm = await alert.show("Deseja realmente apagar esse projeto?", true)
        if(!confirm) return "none"
        
        fields._id = form.model._id
        fields.revenda = form.model.revenda
        const [result, err] = await this.repository.deleteUsecase("DeleteProjetos", fields)
        if(err) return "close";
        const i = form.lista.map((p: any) => p._id).indexOf(form.model._id)
        if(i > -1)
        (form.lista as any[]).splice(i, 1)
        
        console.log(result);
        return "close"
    }
}
import { reactive } from "vue";
const list: any[] = []
export const programacoes = reactive({
    list,
    clear() {
        this.list.splice(0, this.list.length)
    },
    push(programacao: any) {
        if (list.find(c => c._id === programacao._id))
            return
        this.list.push(programacao)
    },
    remove(programacao: any) {
        const i = this.list.map(m => m._id).indexOf(programacao._id)
        this.list.splice(i, 1);
    }
})
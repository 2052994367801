import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class SelectModos {
    repository: Repository
    model: any
    last?: any
    constructor(repository: Repository, model: any, last: any) {
        this.model = model
        this.model.titulo = model.programacao ? model.programacao.nome : model.nome
        this.repository = repository
        this.last = last
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        console.log(this.last)
        const list: { value: string; name: string }[] = [
            { value: "subida", name: "Ao Ligar" },
            { value: "descida", name: "Ao Desligar" }
        ]
        if (this.model.componentType === "interruptor")
            list.push(
                { value: "troca", name: "Ao Trocar" }
            )
        else
            list.push(
                { value: "interruptor", name: "Interruptor" },
                { value: "pulsador", name: "Pulsador" },
                { value: "clique", name: "Clique" },
                { value: "retencao", name: "Retenção" }
            )

        const fields = {
            "extras": new Extra(this.model.nome, "Atualizar", this.last ? "end/updateprogramacaoentrada/true" : "end/updateprogramacaoentrada", {}, true, this.last ? "deleteprogramacaoentrada/true" : "deleteprogramacaoentrada"),
            "modo": new Field("select", "Modos", "Selecione um modo", list)
        }
        return fields
    }
}
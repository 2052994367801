import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormTemporario {
    repository: Repository
    model: any
    projeto: any
    constructor(repository: Repository, model: any, projeto: any) {
        this.model = model
        this.repository = repository
        this.projeto = projeto
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        let fields: { [key: string]: Field | Extra } = {}
        if (this.model.username === this.projeto.projId){
            this.model.projeto = this.projeto.projId
            fields = {
                "extras": new Extra("Dados Login Temporário", "Atualizar", "end/updateusuariotemporario/true"),
                "username": new Field("text", "Usuário", ""),
            }
            
        }else fields = {
            "extras": new Extra("Dados Login Temporário", "none", ""),
            "username": new Field("copy", "Usuário", ""),
        }
        fields["password"] = new Field("copy", "Senha", "");
        return fields
    }
}
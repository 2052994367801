import Repository from "@/core/repository/_repository";
import { snackbar } from "@/stores/login";

export default class UpdateUsuario {
    static action = {
        timeout: 3000,
        text: "Atualizando Relacionamento Usuário"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any): Promise<string> {
        Object.assign(form.model, fields)
        console.log(form.model)
        console.log(fields)
        const [result, err] = await this.repository.update("Usuarios", form.model._id, form.model)
        if(err) console.error(result);
        console.log(result);
        snackbar.show("Atualizado!!!", 3000, "success")
        return "close"
    }
}
import { snackbar } from "@/stores/login"

export default class Device {
    static device: BluetoothDevice
    static server: BluetoothRemoteGATTServer
    static service: BluetoothRemoteGATTService
    static characteristic: BluetoothRemoteGATTCharacteristic
    static async getDevice() {
        if (this.device) return
        this.device = await navigator.bluetooth.requestDevice({
            acceptAllDevices: true,
            optionalServices: ['12634d89-d598-4874-8e86-7d042ee07ba7', '4116f8d2-9f66-4f58-a53d-fc7440e7c14e']
        })

        await this.connect()
       
        this.device.ongattserverdisconnected = (e) => {
            snackbar.show(`Desconectou: ${e.type}`, 4000, "error")
            this.connect()
        }
    }

    static async connect() {
        const server = await this.device.gatt?.connect()
        if (!server) {
            snackbar.show("Erro ao pegar server")
            return [{ msg: `Erro ao pegar server: ${server}` }, true]
        }
        this.server = server
        this.service = await this.server.getPrimaryService('12634d89-d598-4874-8e86-7d042ee07ba7');
        if (!this.service) return [{ msg: `Erro ao pegar service: ${this.service}` }, true]
        snackbar.show("Conectou", 3000, "success")
    }

    static async getCharacteristic(uuid: string): Promise<[BluetoothRemoteGATTCharacteristic, false] | [any, true]> {
        if (!this.device) return [{}, true]
        const characteristic = await this.service.getCharacteristic(uuid)
        return [characteristic, false];
    }
}
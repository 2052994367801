import Conexao from "../Conexoes/_Conexao"


export default class Porta {
    _id = ""
    projId = ""
    projeto = ""
    modulo: string
    nome: string
    tipo: string
    conexoes: Conexao[] = []
    constructor(nome: string, tipo: string, modulo: string) {
        this.nome = nome
        this.tipo = tipo
        this.modulo = modulo
    }

    getObjectIds() {
        return ["modulo"]
    }

    getConexoes(): string[] {
        return []
    }
}
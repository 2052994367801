<template>
  <card :class="[
    'd-flex',
    'a-center',
    'gap-m',
    'justify-between',
    'row-20',
    'column-fit-l',
  ]" @click="abrirpasta">
    <section class="widgets column">
      <div></div>
      <component v-for="botao in component.painel" v-bind:is="botao.tipo" :key="botao._id" v-bind:component="botao">

      </component>
    </section>
  </card>
</template>

<script>
import Card from "@/components/UI/Card.vue";
import { components, mostrando } from "@/stores/components";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
  },
  methods: {
    abrirpasta() {
      console.log(this.component);
      this.$router.push(this.component._id)
      mostrando.componente = this.component
    }
  }
};
</script>
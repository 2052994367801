import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"

export default class FormBotao {
    repository: Repository
    model: any
    tipo = "botao"
    name: string | null
    constructor(repository: Repository, model: any) {
        this.model = model
        if (!this.model.titulo)
            this.name = model.options.code
        else this.name = null
        this.repository = repository
    }

    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        if (this.model.create) this.model.titulo = ""
        return {
            "extras": new Extra(this.model.create ? "Criando Componente" : "Atualizando Componente", this.model.create ? "Criar" : "Atualizar", this.model.create ? "end/createbotaoir/true" : "end/updatecomponente/true", '', !this.model.create, "deletecomponente/true"),
            "titulo": new Field("text", "Titulo", "titulo"),
            "command": new Field("text", "Comando IR", "comando"),
            "repetir": new Field("text", "Repetir", "numero"),
            "code": new Field("text", "Nome universal", "texto"),
            "show": Field.make("toggle", "Mostrar")
        }
    }
}
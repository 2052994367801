


export default class IrConv {
    // irHex: string
    // constructor(irHex: string) {
    //     this.irHex = irHex
    // }

    //Converte codigo HEX para Sendir
    //--------------------------------------------------------------------------------------------------------------        
    hex2gc(code: string, ch: number, repeat: number) {
        let k = 0;
        let dtCount = 0;
        let dtStart = 0;
        let dtEnd = 0;
        let errIndex = 0;
        let iChar = 0;
        let dadok = 0;
        let dtAdd = 0;
        let sendirCode = "sendir,1:"+ch+",1,";
        const codeLength = code.length - 1;
        for(let i=0;i<=codeLength;i++){
            iChar = code.charCodeAt(i);
            //dado (deve ter 4 digitos e somente caracteres hex)
            if(k<4){
                //de 0 a 9
                if(iChar>=48 && iChar<=57){
                    dadok += (iChar-48)<<((3-k)*4);
                //de "a" a "e"
                }else if(iChar>=97 && iChar<=102){
                    dadok += (iChar-87)<<((3-k)*4);

                //de "A" a "B"
                }else if(iChar>=65 && iChar<=70){
                    dadok += (iChar-55)<<((3-k)*4);

                //caracter inesperado
                }else{
                    console.log("Conv_HexToSendir->err:caracter inesperado");
                    errIndex += 1;
                    break;
                }

                //se eh o ultimo byte do array
                if(i==codeLength){
                    //e completou a leitura do dado
                    if(k==3){
                        dtAdd = 1;
                    }
                }

            //separador (space)
            }else if(k==4 && iChar==32){
                dtAdd = 1;

            //sequencia inesperada
            }else{
                errIndex += 2;
                console.log("Conv_HexToSendir->err:squencia inesperada");
                break;

            }
            k++;


            //adiciona dado
            if(dtAdd==1){
                dtAdd = 0;
                dtCount++;
                if(dtCount==1){
                //freq
                }else if(dtCount==2){
                    const frequency = 1000000/(dadok * .241246);
                    sendirCode = sendirCode + Math.round(frequency) + ",";
                    sendirCode = sendirCode + repeat + ",1";
                    dadok = 0;
                //sequencia burstpairs 1
                }else if(dtCount==3){
                    if(dadok>2){
                        dtStart = 5;
                        dtEnd = 5+(dadok*2);
                    }else{
                        dtStart = 5+(dadok*2);
                    }
                //sequencia burstpairs 2
                }else if(dtCount==4){
                    if(dtEnd==0){
                        dtEnd = dtStart+(dadok*2);
                    }
                //dados do comando (dentro do intervalo da sequencia escolhida)
                }else if(dtCount>=dtStart && dtCount<dtEnd){
                    sendirCode = sendirCode + "," + dadok;
                }
                dadok = 0;
                k = 0;
            }
        }

        //se array terminou antes de adicionar o ultimo dado
        if(k>0 && k<4){
            errIndex += 4;
            console.log("Conv_HexToSendir->err:terminou antes de ler o ultimo dado");
        }

        //se nao registrou erros
        if(errIndex==0){
            //verifica se tem mais que 8 dados
            //4 header + 2 start par + 2 end par
            if(dtCount>=8){
                dtCount = dtCount%2;
                //verifica se o numero de dados eh par
                if(dtCount==0){
                    return sendirCode;
                }else{
                    errIndex += 16;
                    console.log("Conv_HexToSendir->err:quantidade de dados eh impar");
                }
            }else{
                errIndex += 8;
                console.log("Conv_HexToSendir->err:quantidade de pares insuficiente");
            }
        }

        return "";
    }



    //Converte codigo HEX para Sendir Compressed
    //--------------------------------------------------------------------------------------------------------------
    hex2gcc(code: string, ch: number, repeat: number) {

        code =  this.hex2gc(code, ch, repeat);

        let commaCount = 0;
        let dtValue = 0;
        //let dtValueTon = 0;
        //let dtValueToff = 0;
        let dtValueCount = 0;

        let currentChar;
        let errIndex2 = 0;

        let pairStr = "";
        let cmdHead = "";
        let cmdIrCode = "";

        //se entrada eh nula retorna
        if(code=="") return "";

        //verificar se ultimo caracter eh numero
        code += ",";

        this.hex2gccPairsAdd("reset",1);

        for(let i=0;i<code.length;i++){
            currentChar = code.charCodeAt(i);

            //comprime codigo ir
            if (commaCount>5){

                //encerra leitura do dado
                if(currentChar==44){ //','
                    dtValueCount++;


                    //se eh o primeiro dado do ciclo
                    if(dtValueCount%2==1){
                        //dtValueTon = dtValue;

                    //se ja tem os dois tempos do ciclo
                    }else{
                        //dtValueToff = dtValue;
                        cmdIrCode += this.hex2gccPairsAdd(pairStr,0);
                        pairStr = "";
                    }

                    pairStr += ',';

                    dtValue = 0;

                //copia dados do par
                //verifica integridade
                //converte dado para inteiro
                }else{
                    //de 0 a 9
                    if(currentChar>=48 && currentChar<=57){
                        pairStr += String.fromCharCode(currentChar);
                        dtValue = 10*dtValue + (currentChar-48);
                    }else{
                        errIndex2 = 1;
                    }
                }


            //ignora primeiros parametros
            }else{
                //conta parametros verificados ','
                if(currentChar==44) commaCount++;

                //copia dados do cabecalho
                cmdHead += String.fromCharCode(currentChar);
            }
        }


        //retira virgula do final
        const len = cmdIrCode.length;
        if(len>0){
            if(cmdIrCode.charAt(len-1)==','){
                cmdIrCode = cmdIrCode.slice(0,len-1);
            }
        }


        //retorna dados
        if(errIndex2==0){
            return cmdHead + cmdIrCode;
        }else{
            return "";
        }
    }

    pairsModels = ["","","","","","","","","","","","","","",""]; //len 15
    pairsModelsActive = 0;

    hex2gccPairsAdd(pairStr: string, reset: number){

        if(reset==1){
            this.pairsModelsActive = 0;
            return "";
        }else{
            for(let i=0;i<this.pairsModelsActive;i++){
                if(pairStr==this.pairsModels[i]){

                    //encontrou modelo compativel
                    let pairDt = "";
                    pairDt += String.fromCharCode(i+65);
                    return pairDt;
                }
            }

            //nao encontrou modelo
            //verifica se tem espaco para adionar novo modelo
            if(this.pairsModelsActive<15){
                this.pairsModels[this.pairsModelsActive] = pairStr;
                this.pairsModelsActive++;
            }
            //retorna para sem alteracao
            return pairStr;
        }

    }
}
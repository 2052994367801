<template>
    <div>
        <div class="form-input d-grid gap-s">
            <label for="data21" class="">Data</label>
            <input type="date" name="Data" id="data21" onchange="dataChange(this)" />
        </div>
        <div>
            <input type="range" min="1" max="100" value="0" class="m20" id="data22" oninput="dataChange(this)">
        </div>
        <div class="d-grid gap-m grid-column">
            <div class="form-input d-grid gap-s grid26-i">
                <label for="inicio21" class="">Início</label>
                <input type="time" name="Inicio" id="inicio21" onchange="dataChange(this)" />
                <div>
                    <input type="range" min="1" max="100" value="0" class="m20" id="inicio22" oninput="dataChange(this)">
                </div>
            </div>
            <div class="form-input d-grid gap-s grid26-i">
                <label for="fim21" class="">Fim</label>
                <input type="time" name="Fim" id="fim21" onchange="dataChange(this)" />
                <div>
                    <input type="range" min="1" max="100" value="0" class="m20" id="fim22" oninput="dataChange(this)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        projetos: Object,
    },
    data() {
        return {
            data: "",
            elProject: document.getElementById("projName")
        };
    },
    async created() {
        console.log("criou componente")
    },
    methods: {
        envia() {
            console.log("enviando formulario")
        },
        refreshProjetos() {
            /* console.log("atualiza lista dos projetos")
            //console.log(document.getElementById("projetos").innerHTML)
            //atualiza lista de projetos
            let plist = ""
            for (const property in global_data) {
                if (property == "") continue
                if (property == "Legenda") continue
                if (property == "#fim#") continue

                plist += '<option value="'
                plist += property
                plist += '">'
            }

            document.getElementById("projetos").innerHTML = plist
            //console.log(">>>>>",global_projectsList)  */       
        },
        //monitora alteracao do projeto
        onProjectNameChange() {
            //EXE
            //executa todas as funcoes associadas a troca do projeto
            /* if (global_projChange_callbacks) {
                let max = global_projChange_callbacks.length
                for (let i = 0; i < max; i++) {
                    let func = global_projChange_callbacks[i]
                    if (typeof func == "function") {
                        func(elProject.value)
                    }
                }
            } */
        }
        /* elProject.onchange = onProjectNameChange */
    }
};
</script>

<style></style>
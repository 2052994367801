import Checksum from "@/core/AZ1/checksum";
import Repository from "@/core/repository/_repository";
import StaticModule from "@/service/module/_module";
import { store } from "@/store";
import { components } from "@/stores/components";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";
import Componente from "../../_componente";
export default class UpdateComponenteStatus {
    static action = {
        timeout: 3000,
        text: "Atualizando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        delete fields.conexao
        if (form.model.inoptions) {
            for (const key in fields) {
                if (Object.prototype.hasOwnProperty.call(new Componente, key))
                    form.model[key] = fields[key]
                else if (key === "icon")
                    form.model.options[key].icon = fields[key]
                else form.model.options[key] = fields[key]
            }
        }
        /* const [modId, e485, canal, errm] = checkModulo(form.model)
        if (errm) snackbar.show(modId, 3000, "error");
        else if (fields.tecla)
            StaticModule.io.send(
                `${form.model.projId}/${modId}/CMD/CMD`,
                //255,1,9,12,0,2,16,6,13,255,2,57;
                Checksum.calc(`255,1,9,12,0,${e485},16,${canal},${Number(fields.tecla)},255,2,57;`.split(",").map(s => Number(s))).join(","),
            ) */

        delete fields.tecla
        Object.assign(form.model, fields)
        const aux = form.model.conexao
        delete form.model.conexao
        const [result, err] = await this.repository.update("Componentes", form.model._id, form.model)
        if (err) console.error(result);
        else console.log(result);
        form.model.conexao = aux
        return "close"
    }
}
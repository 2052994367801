<template>
  <button v-if="component.layout && component.layout.position" type="button"
    :style="{'grid-area': component.layout.position}"
    :class="component.layout.classes"
     class="botao"
    @mousedown="pressstart" 
    @mouseup="pressend"
    @touchstart="pressstart"
    @touchend="pressend"
    @mouseleave="pressend"
     >
    {{ component.titulo }}
  </button>
  <card
  v-else
    :class="['d-flex', 'gap-s', 'a-center', 'row-three', 'column-fit-m']"
    @config="config"
  >
    <p v-if="component.options.action !== 'link' && components.list[i].conexao && components.list[i].conexao.status !== '🟢'">{{ components.list[i].conexao.status }}</p>
    <button class="botao" @click="execute">
      {{ component.titulo }}
    </button>
  </card>
</template>
<script>
import Factory from "../actions/_factory";
import ListForms from "../forms/_factory";
import { modal } from "@/stores/showModal";
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      action: "",
      titulo: "",
      pressed: false,
      oldrepetir: "",
      componente: this.component,
      components,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
    this.action = this.component.options.action;
  },
  methods: {
    pressstart(e){
      e.preventDefault();
      this.pressed = true
      this.holded = false
      setTimeout(() => this.executeloop(e), 1500)
      this.oldrepetir = this.componente.options.repetir
    },
    pressend(e){
      e.preventDefault();
      if(!this.pressed) return
      this.pressed = false
      if(!this.holded) return
      this.componente.options.repetir = this.oldrepetir
      const action = Factory.actions["cancel"];
      new action(this.$module).execute(this.componente);
    },
    executeloop(e){
      this.execute(e)
      if(!this.pressed) return
      setTimeout(() => {
        if(this.pressed) {
          this.holded = true
          this.componente.options.repetir = "15"
          this.executeloop(e)
        }
      }, 2000);
    },
    execute(e) {
      e.preventDefault();
      const action = Factory.actions[this.action];
      new action(this.$module, this.$repository).execute(this.componente);
    },
    config() {
      console.log("config");
      if (ListForms.check(this.action))
        modal.show(new ListForms.forms[this.action](this.component));
      else console.error(`Form "${this.action}" do not exist`);
    },
  },
};
</script>

import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import CreateIRCommand from "./createIrCommand";
export default class CreateControl {
    static action = {
        timeout: 10000,
        text: "Criando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        const botoes: { [key: string]: any } = {}
        for (const b of form.model.parent.control) {
            const code = b.options.code ? b.options.code : b._id.substring(-5)
            botoes[code] = {
                "tipo": b.tipo,
                "title": b.titulo ? b.titulo : "",
                "code": code,
                "action": "ir",
                "repetir": b.options.repetir,
                "options": b.options
            }
            if(b.tipo === "botaoiconeSQ")
            botoes[code].icon = b.options.icon
            if(b.painel && b.painel.length > 0){
                botoes[code].index = b.index
                botoes[code].painel = true
            }
        }
        const [result, err] = await this.repository.create("ControlesIR", {
            titulo: fields.titulo,
            tipo: fields.tipo,
            botoes
        })
        if (err) {
            console.log(result);
            return "finish"
        }
        return await new CreateIRCommand(this.repository).execute(form, fields)
    }
}
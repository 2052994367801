import Extra from "../entity/Extra";
import Field from "../entity/Field";
import Adapter from "./_adapter";

export default class AdapterGenerico implements Adapter{
    model: any
    constructor(model: any) {
        this.model = model
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {}
    }
}
<template>
  <card
    :class="[
      'd-grid',
      'gap-m',
      'row-four',
      'column-fit-l',
    ]"
  >
    <div class="d-flex justify-between" @click="abrirpasta">
      <h2>{{ component.titulo }}</h2>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ccc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"/></svg>
    </div>
    <div class="d-flex gap-m">
      <button @click="abrirconfiguracao" type="button">Configurar</button>
    </div>
  </card>
</template>

<script>
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
import { modal } from "@/stores/showModal";
import FormDisplay from "../form/display";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
  },
  methods: {
    abrirpasta(){
      console.log(this.component);
      /* this.$emit("page", {path: "pagina", name: this.component.titulo})
      mostrando.componente = this.component */
      this.$router.push(`display/${this.component._id}`)
    },
    abrirconfiguracao() {
      console.log("Aqui vai abrir o modal da configuracao por em quanto")
      modal.show(new FormDisplay(this.$repository, this.component))
    }
  }
};
</script>
import Repository from "@/core/repository/_repository";

export default class GetUsername{
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }

    async execute(){
        if(this.repository.type === "indexeddb") {
            const [result, err] = await this.repository.findOne("Usuarios", {})
            if(err) return ""
            console.log(result)
            return result.username
        }
        return ""
    }
}
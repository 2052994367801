/* eslint-disable */
import State from "@/service/states/state";
import Peer from "@/service/wrtc/peer";
import { wait } from "@/stores/showModal";

export type CommandReturn = Promise<[any, boolean]>
export type Command = (event: string, args: any, session: any) => CommandReturn;
let answercount = 0
async function Answer(event: string, args: any, session: any): CommandReturn {
    console.log(Peer.connection);
    console.log(Peer.connection.signalingState);
    console.log(args.answer.sdp.split("\n"));
    console.log(Peer.connection.remoteDescription);
    //args.answer.sdp.search("c=IN IP4 177.82.169.39") > 0 && 
    const IN = args.answer.sdp.split("\n")[8].replace("\r", "")
    if (answercount > 6){// o melhor é pegar o IN do primeiro e se for diferente do anterior entra
        console.log("$$$$$$$$$$$$$$$$");
        Peer.connection.setRemoteDescription(args.answer)
    }
    answercount++
    return ["", false]
}
async function EnterProject(event: string, args: any, session: any): CommandReturn {
    console.log(State.name, State.state.name)
    if (args[1] && args[0] === "offer" && State.state.name !== "p2p"){
        wait.show(30000, "Conectando com o Servidor Local")
        State.setState("p2p")
    }
    return ["", false]
}
export const Auth: { [key: string]: Command } = {
    "answer": Answer,
    "enterproject": EnterProject,
    "offer": async (event: string, args: string, session: any) => [console.log(args), false]
};

<template>
  <input v-model="value" type="time" :placeholder="field.placeholder" />
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "date",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
    };
  },
  created() {
    if(typeof this.$attrs.modelValue === "string")
    this.value = this.$attrs.modelValue.split("T")[0];
  },
});
</script>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import FormComponent from "../../forms/component";
import FormIdSenha from "./idSenha";

export default class FormSenhasAcesso extends FormComponent {
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = {
            "extras": new Extra("Gerenciamento de Senhas", "none", "none"),
            "name": Field.make("objectV", "Senhas", []),
        }
        const [codigos, err] = await this.repository.findMany("CodigosAcesso", {
            projId: this.model.projId,
            parent: this.model._id,
        })
        if (err) return fields

        const senhas = new Array(53).fill(0).map((v, i) => new FormIdSenha(this.repository, i + 1, this.model, codigos.find(c => c.id - 1 === i)))
        return {
            "extras": new Extra("Gerenciamento de Senhas", "none", "none"),
            "name": Field.make("objectV", "Senhas", senhas),
        }
    }
}
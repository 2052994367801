<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="32"
    height="32"
    viewBox="0 0 8.4666665 8.4666669"
    version="1.1"
    id="svg5029"
    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    sodipodi:docname="temperatura_off_17x32.svg"
    class="icon-off"
  >
    <defs id="defs5023" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="11.2"
      inkscape:cx="33.658538"
      inkscape:cy="24.968328"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1920"
      inkscape:window-height="1017"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      units="px"
      inkscape:showpageshadow="true"
    />
    <metadata id="metadata5026">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-288.53332)"
    >
      <path
        d="m 1.5143773,295.84313 c -0.57633843,-0.17061 -1.05507101,-0.62738 -1.2599117,-1.20207 -0.0859258,-0.24101 -0.11560459,-0.71323 -0.0622682,-0.99079 0.0643192,-0.33501 0.26417564,-0.70719 0.51908065,-0.96669 l 0.22215758,-0.22614 0.010365,-1.44404 0.0101452,-1.44407 0.0930739,-0.20148 c 0.1080426,-0.23397 0.3352101,-0.46347 0.5624002,-0.56819 0.2340898,-0.10793 0.669816,-0.10072 0.9167135,0.0149 0.2244833,0.10524 0.4177762,0.29387 0.5318429,0.51903 0.067158,0.1325 0.089959,0.23256 0.1022729,0.4489 l 0.015795,0.27839 0.3432176,0.0203 c 0.3154803,0.0187 0.3491062,0.0271 0.415906,0.10341 0.088942,0.10157 0.093455,0.24053 0.011049,0.34247 -0.056198,0.0695 -0.092825,0.0778 -0.4120265,0.0924 l -0.3504113,0.0162 -0.00946,0.27804 -0.00946,0.27806 0.1998566,0.021 c 0.1098959,0.011 0.2693374,0.021 0.3543358,0.021 0.1284119,0 0.1658269,0.0144 0.2214857,0.0851 0.090636,0.11524 0.085787,0.25449 -0.012172,0.35261 -0.074194,0.0742 -0.1014147,0.0794 -0.4201407,0.0794 H 3.1673803 v 0.35282 0.3528 l 0.2033291,0.19525 c 0.7753358,0.74445 0.7946877,1.9525 0.043391,2.70036 -0.5114801,0.50916 -1.2201065,0.69232 -1.8998004,0.49111 z M 1.154786,294.11687 c 0.015795,-0.0401 0.053582,-0.14348 0.08423,-0.22996 0.078031,-0.22043 0.3354393,-0.56628 0.6671124,-0.76898 0.1495629,-0.0913 0.2800906,-0.19612 0.3006602,-0.24126 0.025259,-0.0551 0.042938,-0.51525 0.03809,-1.45057 v -1.35582 h 0.1115856 0.2877881 l -0.014891,-0.202 c -0.00834,-0.1111 -0.02119,-0.2336 -0.0292,-0.2722 -0.03631,-0.17983 -0.3328682,-0.39611 -0.5453063,-0.39768 -0.1638661,-0.002 -0.3723117,0.12396 -0.47443,0.28496 l -0.093294,0.14711 v 1.51179 1.51177 l -0.1050028,0.0941 c -0.42837439,0.38384 -0.48369409,0.44598 -0.59151699,0.66461 -0.12807264,0.25971 -0.18537573,0.62427 -0.11206048,0.71262 0.0625136,0.0753 0.44294247,0.0769 0.47643277,-0.009 z"
        id="path4168-7-9-7-01"
        inkscape:connector-curvature="0"
        sodipodi:nodetypes="cccccccccccccccccccccsccscccccccccccsccccccccccccccc"
        inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\temperatura_17x32.png"
        inkscape:export-xdpi="96"
        inkscape:export-ydpi="96"
      />
      <g
        aria-label="OFF"
        id="text1678-8-6-0-3-1-6-5-0"
        transform="matrix(0.11979662,0,0,0.11979662,-21.431915,237.73551)"
      >
        <path
          d="m 209.99166,482.90159 q 0,4.73245 -2.71185,7.52406 -2.71185,2.77831 -7.49746,2.77831 -4.77233,0 -7.48418,-2.77831 -2.71185,-2.79161 -2.71185,-7.52406 0,-4.77232 2.71185,-7.53734 2.71185,-2.77832 7.48418,-2.77832 4.75903,0 7.48417,2.77832 2.72514,2.76502 2.72514,7.53734 z m -6.76633,5.01161 q 0.74443,-0.90395 1.10335,-2.12694 0.35892,-1.23629 0.35892,-2.89796 0,-1.78131 -0.41209,-3.03089 -0.4121,-1.24958 -1.07677,-2.0206 -0.67796,-0.7976 -1.56861,-1.15652 -0.87737,-0.35892 -1.83449,-0.35892 -0.97042,0 -1.83449,0.34563 -0.85077,0.34562 -1.56862,1.14323 -0.66467,0.74443 -1.09005,2.06047 -0.4121,1.30275 -0.4121,3.03089 0,1.76802 0.3988,3.0176 0.4121,1.23629 1.07677,2.0206 0.66466,0.78431 1.55532,1.15652 0.89066,0.37221 1.87437,0.37221 0.98371,0 1.87437,-0.37221 0.89065,-0.38551 1.55532,-1.18311 z"
          id="path1209-0-2"
          inkscape:connector-curvature="0"
        />
        <path
          d="m 228.05737,476.82652 h -9.10597 v 3.68226 h 8.4413 v 3.8285 h -8.4413 v 8.45459 h -5.07807 v -19.79385 h 14.18404 z"
          id="path1211-5-0"
          inkscape:connector-curvature="0"
        />
        <path
          d="m 245.76416,476.82652 h -9.10597 v 3.68226 h 8.4413 v 3.8285 h -8.4413 v 8.45459 h -5.07807 v -19.79385 h 14.18404 z"
          id="path1213-1-3"
          inkscape:connector-curvature="0"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "alert",
};
</script>
import Repository from "@/core/repository/_repository";
export default class UpdatePeriodo {
    static action = {
        timeout: 3000,
        text: "Atualizando Período"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        delete form.model.agendalista
        Object.assign(form.model, fields)
        const [result, err] = await this.repository.update("Periodos", form.model._id, form.model)
        if(err) return `erro/${result}`
        
        form.model.dinicio.date = new Date(`${fields.date}T${form.model.dinicio.hora}`).getTime()
        const [dresult, derr] = await this.repository.update("Delimitadores", form.model.dinicio._id, form.model.dinicio)
        if(derr) return `erro/${dresult}`
        if (form.sub){
            if (form.update)
                form.update()
            return "back"
        }
        return "close"
    }
}
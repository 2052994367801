export default class DateToMask {
    masks: { [index: string]: string }
    constructor() {
        this.masks = {
            "default": "dd/mm/yyyy hh:ii:ss",
            "basic": "dd/mm hh:ii",
            "time": "hh:ii:ss"
        }
    }
    state = "start"
    convert(date: Date, type: string) {
        let formated = ""
        if (Object.prototype.hasOwnProperty.call(this.masks, type))
            this.masks[type].split("").forEach(c => {
                switch (c) {
                    case "d":
                        if (this.state === c)
                            formated += `${date.getDate() < 10 ? "0" : ""}${date.getDate()}`
                        else
                            this.state = c
                        break;
                    case "m":
                        if (this.state === c)
                            formated += `${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}`
                        else
                            this.state = c
                        break;
                    case "y":
                        if (this.state === c) this.state = "half"
                        else if (this.state === "half") this.state = "full"
                        else if (this.state === "full")
                            formated += `${date.getFullYear()}`
                        else
                            this.state = c
                        break;
                    case "h":
                        if (this.state === c)
                            formated += `${date.getHours() < 10 ? "0" : ""}${date.getHours()}`
                        else
                            this.state = c
                        break;
                    case "i":
                        if (this.state === c)
                            formated += `${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`
                        else
                            this.state = c
                        break;
                    case "s":
                        if (this.state === c)
                            formated += `${date.getSeconds() < 10 ? "0" : ""}${date.getSeconds()}`
                        else
                            this.state = c
                        break;
                    default:
                        if (this.state === "half") formated += `${date.getFullYear()}`.substring(2)
                        else formated += c
                        this.state = c
                        break;
                }
            })
        return formated
    }
}
<template>
  <card :class="[
    'd-flex',
    'gap-m',
    'justify-between',
    'a-center',
    'row-three',
    'column-fit-l',
  ]" @config="config" @click="config" :style="{ 'background-color': 'var(--screen-background)' }">
    <h2>Configurar Setpoint</h2>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ccc" stroke-width="2"
      stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  </card>
</template>

<script>
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
import arrumaData from "@/core/entity/ArrumaData";
import { modal } from "@/stores/showModal";
import FormSetpoint from "../form/setpoint";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      arrumaData,
      eventos: [],
      i: 0,
    };
  },
  async created() {
    console.log("configurando")
  },
  methods: {
    config(){
      modal.show(new FormSetpoint(this.$repository, this.component.parent));
    }
  },
};
</script>

<style>
div.digitalinevent {
  padding: 0.5em;
}

div.digitalinevent:nth-child(even) {
  background-color: var(--ion-card-background, #fff);
}
</style>
import Repository from "@/core/repository/_repository";
export default class UpdateUsuarioTemporario {
    static action = {
        timeout: 10000,
        text: "Criando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        const update = {
            revenda: form.projeto.revenda,
            projeto: form.projeto,
            usuario: form.model
        }
        const [result, err] = await this.repository.update("UpdateUsuarioTemporario", form.model._id, update)
        console.log(result, err);
        Object.assign(form.model, result)
        return "back"
    }
}
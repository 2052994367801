import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import FormStatus from "./status";

export default class FormEventDigitalIn extends FormStatus {
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = await super.getFields()
        delete fields["nshow"]
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        fields["false"] = Field.make("text", "Descrição para 0", "Fechou")
        fields["true"] = Field.make("text", "Descrição para 1", "Abriu")
        return fields
    }
}
function putZero(n: number) {
    return `${n < 10 ? "0" : ""}${n}`
}
export default function arrumaData(date: string | number, mask?: string) {
    if (!mask) mask = "hh:mm dd/MM"
    const data = new Date(date);
    mask = mask.replace("dd", putZero(data.getDate()))
    mask = mask.replace("MM", putZero(data.getMonth() + 1))
    mask = mask.replace("yyyy", putZero(data.getFullYear()))
    mask = mask.replace("hh", putZero(data.getHours()))
    mask = mask.replace("mm", putZero(data.getMinutes()))
    mask = mask.replace("ss", putZero(data.getSeconds()))
    return mask
}
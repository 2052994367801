import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import { componentsMain } from "@/stores/components";
import DeleteElement from "@/Features/Componentes/display/controller/deleteElement";
export default class DeleteComponente {
    static action = {
        timeout: 10000,
        text: "Apagando Componente"
    }
    list: { [key: string]: typeof DeleteElement } = {
        "element": DeleteElement
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        if (form.model.parent && form.model.parent.control)
            form.model.parent.control.forEach((b: any, i: number) => {
                console.log(b._id, form.model._id, b._id === form.model._id);
                
                if (b._id === form.model._id)
                    form.model.parent.control.splice(i, 1)
            })
        
        if (form.model.sub) {
            console.log(form.model)
            const [d1, e1] = await this.repository.deleteMany("ComponenteRoot", {
                projId: form.model.projId,
                componente: form.model._id,
                root: form.model.root._id
            })
            console.log(e1, d1)
            componentsMain.remove(form.model)
            return "close"
        }
        const deleteList: { collection: string; idList: string[] } = {
            collection: "Componentes",
            idList: [form.model._id]
        }

        if (Object.prototype.hasOwnProperty.call(this.list, form.model.func))
            new this.list[form.model.func](this.repository).execute(form, fields)
        //é melhor usar uma um campo root para diser quem o componente pertence do que usar um array
        //pois esse processo nao seria necessario e tem o risco de excluir dois ou mais ao mesmo tempo e fazer o update do array com o antigo
        //e resolve o problema de pegar a lita de componentes na home ja pega com um findMany
        const [result, err] = await this.repository.deleteList(deleteList.collection, deleteList.idList)
        if (err) console.error(result)
        else console.log(result)

        if (form.model.main)
            componentsMain.remove(form.model)
        const [d1, e1] = await this.repository.deleteMany("ComponenteRoot", {
            projId: form.model.projId,
            componente: form.model._id
        })
        console.log(e1, d1)

        const [d2, e2] = await this.repository.deleteMany("Componentes", {
            projId: form.model.projId,
            painel: form.model._id
        })
        console.log(e2, d2)

        const [d3, e3] = await this.repository.deleteMany("Componentes", {
            projId: form.model.projId,
            control: form.model._id
        })
        console.log(e3, d3);
        if(form.model.parent) return "back"
        return "close"
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormNomeProgramacao from "@/Features/Programacao/forms/nomeProgramacao";
import FormNome from "../../forms/nome";
import FormAmostrasDiaria from "./amostrasdiaria";

export default class FormSetpoint {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        return {
            "extras": new Extra(this.model.titulo, "Atualizar", "updatesetpoint"),
            "enable": Field.make("toggle", "Ativar Alarme"),
            "setpoint": Field.make("number", "Setpoint (°C)", "25"),
            "t1": Field.make("number", "Tempo de retardo do alarme (minutos)", "2"),
            "histerese": Field.make("number", "Histerese", "6"),
            "offset": Field.make("number", "Deslocamento na leitura da temperatura", "-10.0 ate 10.0", { min: -10, max: 10 }),
            "amostra": Field.make("object", "Amostras Diária", [new FormAmostrasDiaria(this.repository, { objname: "Configurar", model: this.model })])
        }
    }
}
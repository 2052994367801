import Repository from "@/core/repository/_repository";
import { snackbar } from "@/stores/login";

export default class DeleteUsuarioProjeto {
    static action = {
        timeout: 3000,
        text: "Atualizando Relacionamento Usuário"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any): Promise<string> {
        console.log(form)
        let numeroAdmins = 0
        form.list.forEach((u: any) => {
            if (u.acesso === "admin") numeroAdmins++
        });
        const adminsNecessarios = form.model.acesso === "admin" ? 2 : 1
        if (numeroAdmins < adminsNecessarios) {
            snackbar.show("É necessário ter ao menos 1 Administrador", 4000, "error")
            return "close"
        }

        let remover = false
        if (form.model.projId === form.model.username) {
            console.log("é usuario temporario");
            const promise = this.repository.deleteUsecase("UsuariosProjetoTemporario", form.model);
            const [result, err] = await promise;
            if(!err) remover = true;
            else console.error(result);
        } else {
            const [result, err] = await this.repository.delete("UsuariosProjeto", form.model._id)
            if(!err) remover = true
            else console.error(result);
        }

        if (remover) {
            const i = form.list.map((m: any) => m._id).indexOf(form.model._id)
            form.list.splice(i, 1);
        }
        return "close"
    }
}
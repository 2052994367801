import Checksum from "@/core/AZ1/checksum";
import Repository from "@/core/repository/_repository"
import { checkModulo } from "@/Features/Componentes/controllers/checkModulo";
import StaticModule from "@/service/module/_module";
interface Retorno {
    ipid: string
    e485: number
    canal: number
    valor: string
}
export default class UpdateProgramacaoGatewayCena {
    static action = {
        timeout: 10000,
        text: "Atualizando Programação"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        fields.projId = form.model.projId

        const acoes: Retorno[] = []
        for (const o of form.operadores) {
            const option = o.opcoes.find((k: any) => k.type === "main");
            const action = o.opcoes.find((k: any) => k.type !== "main");
            if (option) {
                const [componente, e] = await this.repository.findOne(
                    "ComponentesConexao",
                    {
                        _id: option.value,
                        projId: option.projId,
                    }
                );
                if (e) {
                    console.error(componente);
                    continue
                }
                if (typeof componente === "undefined") continue
                console.log(componente)
                const [, e485, canal, error, ip] = checkModulo(componente)
                if (!error)
                    acoes.push({
                        ipid: ip,
                        e485,
                        canal,
                        valor: action.value
                    })
            }
        }

        const cena = {
            "_id": form.model._id,
            "projId": form.model.projId,
            "projeto": form.model.projeto,
            "tipo": form.model.tipo,
            "nome": form.model.nome,
            acoes
        };

        form.modulos.forEach((m: any) => StaticModule.io.send(`${m.projId}/${m.modId}/CMD/CMD/CENA`, JSON.stringify(cena)))
        return "close"
    }
}
import Repository from "@/core/repository/_repository";
import { components } from "@/stores/components";
export default class ConectaComponente {
    static action = {
        timeout: 3000,
        text: "Conectando Compenente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        if(form.model.componente) form.model = form.model.componente
        fields.projId = form.model.projId
        const [result, err] = await this.repository.update("Componentes", form.model._id, fields)
        if (err) console.error(result);
        else console.log(result);
        const [c, cerr] = await this.repository.findOne("ComponentesConexao", {
            _id: form.model._id,
            projId: form.model.projId
        })
        if (cerr) console.error(result);
        else Object.assign(form.model, c)
        console.log(components)
        return "close"
    }
}
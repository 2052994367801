import Repository from "@/core/repository/_repository";

export default class UpdateValue {
    static action = {
        timeout: 10000,
        text: "Atualizando Valor"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        if(form.model.new){
            const field = {
                projId: form.model.column.projId,
                key: form.model.column.key,
                value: fields.value,
                row: form.model.row._id,
                column: form.model.column._id
            }
            console.log(field)
            const [f, e] = await this.repository.create("TableFields", field)
            if (e) console.error(f)
            return "back"
        }
        const [f, e] = await this.repository.update("TableFields", form.model._id, { 
            projId: form.model.projId,
            value: fields.value
        })
        if(e) console.error(f);
        return "back"
    }
}
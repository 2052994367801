import arrumaData from "@/core/entity/ArrumaData";
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormUpdateDelimitadores {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model ? model : {}
        this.repository = repository
        this.model.hora = this.model.date ? arrumaData(this.model.date, "hh:mm") : "00:00"
        this.model.nome = `${this.model.tipo === "inicio" ? "Início" : "Fim"}`
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        console.log(this.model);
        const actions = [
            { name: "Nenhuma", value: "none" },
        ]
        const fields = {
            "extras": new Extra(this.model.nome, "Atualizar", "end/updatedelimitador/true", {}),
            "hora": new Field("time", "Hora", ""),
            "action": new Field("select", "Programação", "Selecione uma opção", actions),
        }
        const [progs, err] = await this.repository.findMany("Programacoes", { projId: this.model.dono })
        if (err) {
            console.error(progs);
            return fields
        }
        progs.forEach(p => actions.push({name: p.nome, value: p._id}))
        fields.action = new Field("select", "Programação", "Selecione uma opção", actions)
        return fields
    }
}
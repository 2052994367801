import Repository from "@/core/repository/_repository";
import Componente from "../../_componente";

export default class CreateMapList {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, fields: any): Promise<[Componente, boolean]> {
        const component = new Componente();
        component.titulo = fields.titulo
        component.tipo = fields.tipo
        component.projId = root.projId
        component.projeto = root.projeto
        component.main = true
        component.options = {}
        component.func = "gerenciamento"
        
        const [result, err] = await this.repository.create("Componentes", component)
        if (err) console.error(result)
        else Object.assign(component, result)

        return [component, false]
    }
}
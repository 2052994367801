import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import AdicionaElement from "../../usecase/elements/adicionaElement";
import { wait } from "@/stores/showModal";

export default class SelectExist {
    repository: Repository
    model: any
    constructor(repository: Repository, model: { titulo: string; root: any }) {
        this.model = model
        this.model.act = true
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const exist: {name: string; value: string}[] = []
        const [result, err] = await this.repository.findMany("Componentes", {
            projId: this.model.root.projId,
            func: "element"
        })
        if(err) console.error(err)
        else result.forEach(c => exist.push({name: c.titulo, value: c._id}))
        return {
            "extras": new Extra("Escolha", "none", ""),
            "existente": new Field("objectV", "Elementos", "", exist, "", async (item: any) => {
                console.log(item)
                wait.show(5000, "Adicionando Elemento")
                item._id = item.value
                await new AdicionaElement(this.repository).execute(this.model.root, item)
                wait.show(0, "")
                return "back/2"
            })
        }

    }
}
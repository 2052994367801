import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormComponent from "../../forms/component";

export default class FormIdSenha {
    repository: Repository
    model: any
    name: string
    parent: any
    constructor(repository: Repository, id: number, parent: any, model?: any) {
        this.parent = parent
        this.repository = repository
        if(model) {
            this.model = model
            this.name = `${id} - ${this.model.name}`
        } else {
            this.model = {id}
            this.name = `${id}`
        }
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const action = Object.keys(this.model).length === 1 ? "Salvar" : "Atualizar"
        return {
            "extras": new Extra(`${action} Senha`, action, "end/salvaridsenha/true"),
            "id": Field.make("show", "Id"),
            "name": new Field("text", "Nome", "nome"),
            "code": new Field("number", "Senha", "1234"),
        }
    }
}
<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="32"
    height="32"
    viewBox="0 0 8.4666665 8.4666669"
    version="1.1"
    id="svg5029"
    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    sodipodi:docname="cadeado-fechado-20x32.svg"
    class="icon-normal"
  >
    <defs id="defs5023" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="7.9195959"
      inkscape:cx="13.930034"
      inkscape:cy="11.390405"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1366"
      inkscape:window-height="705"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      units="px"
      inkscape:showpageshadow="true"
    />
    <g
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-288.53332)"
    >
      <g
        id="g3772-6-8-4-1-4"
        transform="matrix(0.03337625,0,0,0.03337625,0.73255934,288.53678)"
        inkscape:export-xdpi="96"
        inkscape:export-ydpi="96"
        inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\cadeado-fechado-20x32.png"
      >
        <path
          d="M 160.44,89.902 H 74.524 V 52.345 C 74.524,34.51 89.034,20 106.87,20 c 17.836,0 32.42947,14.510195 32.346,32.345 l -0.1773,37.883254 c -0.0258,5.522939 7.49204,1.844361 13.01404,1.844361 5.522,0 8.17895,3.677127 8.04974,-1.844361 L 159.216,52.345 C 159.215,23.482 135.733,0 106.87,0 78.007,0 54.524,23.482 54.524,52.345 v 37.557 h -1.225 c -15.244,0 -27.646,12.402 -27.646,27.646 v 106.908 c 0,15.923 12.954,28.878 28.878,28.878 h 104.678 c 15.924,0 28.878,-12.955 28.878,-28.878 V 117.548 c 0,-15.244 -12.403,-27.646 -27.647,-27.646 z m 7.647,134.554 c 0,4.896 -3.982,8.878 -8.878,8.878 H 54.531 c -4.896,0 -8.878,-3.982 -8.878,-8.878 V 117.548 c 0,-4.216 3.431,-7.646 7.646,-7.646 H 160.44 c 4.216,0 7.646,3.43 7.646,7.646 v 106.908 z"
          id="path3762-0-0-4-1-6"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="scsssssscsscsssssssscssssssscc"
        />
        <path
          d="m 106.87,134.44 c -11.409,0 -20.691,9.282 -20.691,20.691 0,7.783 4.324,14.57 10.691,18.102 v 25.562 c 0,5.523 4.478,10 10,10 5.522,0 10,-4.477 10,-10 v -25.562 c 6.368,-3.532 10.691,-10.319 10.691,-18.102 0,-11.409 -9.282,-20.691 -20.691,-20.691 z"
          id="path3764-2-1-3-9-7"
          inkscape:connector-curvature="0"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Close",
};
</script>
<template>
  <modal :title="title" :voltar="true" @exclude="callExclude" @close="$emit('close')"
    :class="{ controle: true }">
    <div class="registroatividade">
      <nav style="" class="d-flex tabs">
        <div v-for="(tab, index) in tabs" :key="index" :class="{ selected: tab.selected }" @click="changeTab(tab)">
          <h3>{{ tab.name }}</h3>
        </div>
      </nav>
      <section class="abas">
        <component v-bind:is="show"></component>
      </section>
    </div>
  </modal>
</template>

<script>
//import { setStorage } from "@/scripts/Storage";
import { defineComponent } from "vue";
import { modal } from "@/stores/showModal";
import Modal from "@/components/Modal/Modal.vue"
import Formulario from "./abas/formulario.vue"
import Atividades from "./abas/atividades.vue"
export default defineComponent({
  name: "ModalConfiguracao",
  props: {
    form: Object,
  },
  components: {
    Modal,
    "form": Formulario,
    "atividades": Atividades
  },
  data() {
    return {
      title: "Registro Atividades",
      show: "form",
      tabs: [{
        name: "Formulario",
        value: "form",
        selected: true
      }, {
        name: "Atividades",
        value: "atividades",
        selected: false
      }]
    };
  },
  async created() {
    console.log(this.form)

    //this.next(modal.form);
  },
  sockets: {
    configuracao(data) {
      console.log(data);
    },
  },
  methods: {
    changeTab(tab) {
      this.show = tab.value
      this.tabs.forEach(t => t.selected = t.value === tab.value)
    },
  },

  unmounted() {
    ////this.sockets.unsubscribe("configuracao");
  },
});
</script>

<style>
@media (min-width: 992px){
  .controle .registroatividade {
      width: 500px;
  }
}

.registroatividade input {
  width: 100% !important;
}

.abas {
    background-color: var(--card-background-tint);
    border-radius: 0.5em;
    padding: 0.5em;
}
</style>
import Field from "../../../core/entity/Field";
import Repository from "../../../core/repository/_repository";

export default class FormCreateChamado {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }

    async getFields(): Promise<{ [index: string]: Field }> {
        const categoria: { value: string; name: string }[] = [
            { value: "1", name: "Alarme" },
            { value: "2", name: "Camera" },
        ]
        return {
            "tipo": new Field("select", "Categorias", "Selecione o Categoria", categoria),
            "descricao": new Field("textarea", "Descrição", "Descreva seu Problema"),
        }
    }

}
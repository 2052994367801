<template>
  <section class="widgets">
    <component
      v-bind:is="component.tipo"
      :key="component._id"
      v-bind:component="component"
    ></component>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import botao from "@/Features/Componentes/botao/ui/botao.vue";
import botaoicone from "@/Features/Componentes/botao/ui/icone.vue";
import interruptor from "@/Features/Componentes/interruptor/ui/switch.vue";
import dimmer from "@/Features/Componentes/interruptor/ui/dimmer.vue";
import agregador from "@/Features/Componentes/agregador/ui/agregador.vue";
import audio from "@/Features/Componentes/agregador/ui/agregador.vue";
import status from "@/Features/Componentes/status/ui/digitalin.vue";
export default defineComponent({
  components: {
    botao,
    botaoicone,
    interruptor,
    agregador,
    audio,
    dimmer,
    status
  },
  name: "action",
  props: {
    field: Object,
  },
  created() {
    console.log("###### action");
    this.component = this.field.model;
  },
  data() {
    return {
      value: "",
      component: {},
    };
  },
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
}
</style>
<template>
  <main class="d-grid gap" onselectstart="return false">
    <div class="d-flex a-center gap-m" v-for="programacao in programacoes" :key="programacao._id">
      <div v-if="programacao.have" class="haveevent"></div>
      <card-title-description :value="programacao" @mostraModulo="mostraModulo" />
    </div>
  </main>
</template>

<script>
/* TODO: aquie vai exibir componentes capazes de acionar uma rotina */
/**
 * Modulos -> Eventos/Alarme, Entradas e Saidas
 * Agenda -> action do delimitadores
 * 
 * eu posso conectar direto a programacao na conexao, pois ProgramacoesEntradas na verdade é ProgramacoesConexoes 
 */
import { modal } from "@/stores/showModal";
import Forms from "@/Features/Programacao/forms/_list";
import cardTitleDescription from "@/components/cards/_card.vue";
export default {
  name: "page-programacao",
  components: {
    cardTitleDescription,
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de Programações" },
      dropDown: false,
      configurar: false,
      configurando: false,
      adapter: {},
      configLayer: 0,
      programacoes: [],
      show: Forms,
    };
  },
  async created() {
    const [componentes, cerr] = await this.$repository.findMany("Componentes", {
      projId: this.$route.params.projId,
      tipo: ["digital-in", "interruptor", "dimmer"],
      complex: true
    });
    console.log(componentes, cerr)
    if (cerr) console.error("Erro ao pegar modulos:", modulos);
    else componentes.forEach(async m => {
      const c = {
        nome: m.titulo, /* TODO: Aqui teria que adicionar a quantidade de eventos ativos */
        tipo: "componente",
        model: m,
        have: 0
      }
      this.programacoes.push(c)
      const [result, err] = await this.$repository.findOne("EntradaProgramacoes", {
        projId: m.projId,
        _id: m.conexao,
      })
      if (err || !result) return
      c.have = result.programacoes.length
    })

    const [modulos, err] = await this.$repository.findMany("ModulosPortasConexoes", {
      projId: this.$route.params.projId,
    });
    if (err) console.error("Erro ao pegar modulos:", modulos);
    else modulos.forEach(async m => {
      const mo = {
        nome: m.nome,
        tipo: "modulo",
        model: m,
        have: 0
      }
      this.programacoes.push(mo)
      const [result, err] = await this.$repository.findOne("EntradaProgramacoes", {
        projId: m.projId,
        _id: m._id,
      })
      if (err || !result) return
      console.log(result)
      mo.have = result.programacoes.length
    })

    const [periodos, e] = await this.$repository.findMany("PeriodoDelimitadores", {
      dono: this.$route.params.projId,
    });
    if (e) console.error("Erro ao pegar períodos:", periodos);
    else periodos.forEach(p => {
      console.log(p)
      this.programacoes.push({
        nome: p.nome,
        tipo: "agenda",
        model: p,
        have: 0
      })
    })


  },
  methods: {
    mostraModulo(modulo) {
      modal.show(new this.show.list[modulo.tipo](this.$repository, modulo));
    },
  },
};
</script>

<style>
.haveevent {
  background-color: var(--card-color);
  padding: .4em;
  border-radius: 50%;
  height: min-content;
}
</style>
import { Projeto } from "@/Model/Projeto"
import { getStorage } from "@/scripts/Storage"
import { Socket } from "socket.io-client"
import { createStore } from "vuex"

export const store = createStore({
    async state() {
        return {
            socketReady: false,
            accessToken: await getStorage("accessToken"),
            refreshToken: await getStorage("refreshToken"),
            projId: "",
            sensor0: "",
            projeto: {}
        }
    },
    mutations: {
        setAccessToken(state: any, accessToken) {
            state.accessToken = accessToken
        },
        setRefreshToken(state: any, refreshToken) {
            state.refreshToken = refreshToken
        },
        setSocketReady(state: any, socketReady) {
            state.socketReady = socketReady
        },
        setProjId(state: any, projId: string) {
            state.projId = projId
        },
        setProjeto(state: any, obj: { projeto: Projeto; socket: Socket }) {
            state.projeto = obj.projeto
            //obj.socket.emit("setProjeto", obj.projeto.projId)
        },
        setSensor0(state: any, sensor0) {
            state.sensor0 = sensor0
        }
    }
})
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormProgramacaoLocal {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        const saidas = [
            { value: "255", name: "Não Mudar" },
            { value: "254", name: "Trocar" },
            { value: "1", name: "Ligar" },
            { value: "0", name: "Desligar" },
        ]
        const dimmer = saidas.concat([{ value: "2", name: "Dimmer" }])
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.titulo, "Atualizar", "end/updateprogramacaolocal/true", {}),
            "titulo": new Field("text", "Título", "Título da cena"),
            "out0": new Field("selectrange", "Saída 1", "Selecione um estado", dimmer),
            "out1": new Field("selectrange", "Saída 2", "Selecione um estado", dimmer),
            "out2": new Field("select", "Saída 3", "Selecione um estado", saidas),
            "out3": new Field("select", "Saída 4", "Selecione um estado", saidas),
        }
        console.log(this.model);

        return fields
    }
}
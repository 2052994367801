import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import SelectComponentes from "./componentes";
import SelectOperadores from "./operadores";

export default class SelectTipoOperadores {
    repository: Repository
    model: { titulo: string; macro: any }
    constructor(repository: Repository, model: { titulo: string; macro: any; act: boolean }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list: any[] = [
            new SelectOperadores(this.repository, { titulo: "Operador", macro: this.model.macro }),
            new SelectComponentes(this.repository, { titulo: "Ação Componente", macro: this.model.macro })
        ]
        return {
            "extras": new Extra("Escolha", "none", ""),
            "modulo": new Field("objectV", "Tipos", "", list)
        }

    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { modal } from "@/stores/showModal";
import FormOpcao from "./opcoes";
const interruptor: {[key: string]: string} = {
    "0": "Desliga",
    "1": "Liga",
    "254": "Troca",
}
const tipos: {[key: string]: string} = {
    "interruptor": "O Interruptor",
    "dimmer": "O Dimmer",
    "audio": "O Sistema",
    "ac": "O Ar Condicionado",
}
const times: {[key: string]: string} = {
    "wait": "Espera",
    "dimmer": "O Dimmer",
    "1": "Ligar",
    "254": "Troca",
}
const audio: {[key: string]: string} = {
    "vol": "com Volume em",
    "loud": "com Loud",
    "bass": "com Grave em",
    "treble": "com Agudo em",
    "mute": "com Mudo",
}
export default class FormOperador {
    repository: Repository
    model: any
    main: any
    opcao: any
    callchangetitle = false
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
        const main = model.opcoes.find((o: any) => o.type === "main")
        const opcao = model.opcoes[1] ? model.opcoes[1] : {}
        this.main = main
        this.opcao = opcao
        console.log(opcao, main)
        if (main && main.key === "componente" && main.componente) {
            if(opcao.type === "interruptor")
                model.titulo = `${tipos[main.componente.tipo]} ${main.componente.titulo} ${interruptor[opcao.value]}`;
            else if(opcao.type === "agregador"){
                this.callchangetitle = true
                this.changetitule()
            } else model.titulo = `${tipos[main.componente.tipo]} ${main.componente.titulo} ${audio[opcao.key] ? audio[opcao.key] : opcao.type} ${opcao.type === "onoff" ? opcao.value === "1" ? "Ativado" : "Desativado" :opcao.value}`
        } else if (main && main.value){
            if(opcao.key === "time")
                model.titulo = `${times[main.value]} ${opcao.value}ms`
            else model.titulo = `${main.value.toUpperCase()} - ${opcao.value}`
        } else model.titulo = `${model.tipo.toUpperCase()} - ${opcao.value}`
    }
    async changetitule(getfromdb?: boolean) {
        const arrtitle = [tipos[this.main.componente.tipo] ? tipos[this.main.componente.tipo] : tipos[this.main.componente.options.type], this.main.componente.titulo, this.opcao.key]
        this.model.titulo = arrtitle.join(" ")
        if(this.opcao.key === "botao" && getfromdb) {
            const [result, err] = await this.repository.findOne("Componentes", {_id: this.opcao.value, projId: this.model.projId}) 
            console.log(result,err)
            if(err) return
            arrtitle[2] = `aperta ${result.titulo}`
            console.log(result.titulo, arrtitle)
            this.model.titulo = arrtitle.join(" ")
            console.log(this.model)
            /* TODO: forma de trocar titulo depois do await */

        }
        return arrtitle.join(" ")
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        
        console.log("\n\n###########\n\n");
        console.log(this.model)
        const opcoes: FormOpcao[] = []
        if (this.model.tipo === "componente") {
            const option = this.model.opcoes.find((o: any) => o.key === "componente");
            const options = this.model.opcoes.filter((o: any) => o.key != "componente");
            const [result, err] = await this.repository.findOne("Componentes", {
                _id: option.value,
                projId: this.model.projId
            })
            if (err) console.error(result)
            else if (result) {
                console.log(result)
                this.model.titulo = result.titulo
                options.forEach(async (o: any) => {
                    o.componente = option.value
                    opcoes.push(new FormOpcao(this.repository, o))
                });
            }
        } else {
            const option = this.model.opcoes.find((o: any) => o.key === "tipo");
            const options = this.model.opcoes.filter((o: any) => o.key != "tipo");
            this.model.titulo = option.value.toUpperCase()
            options.forEach(async (o: any) => {
                opcoes.push(new FormOpcao(this.repository, o))
            });
        }
        modal.form = this
        const fields = {
            "extras": new Extra(this.model.titulo, "Salvar", "end/updateoptions", {}, true, "deleteoperador"),
            "opt": new Field("opcoes", "Operadores", "", opcoes)
        }
        return fields
    }
}
import Field from "../entity/Field";
import FactoryComponente from "@/Model/Factory/Componente";
import CreateComponente from "../usecase/CreateComponente";
import Layout from "../entity/componentes/layout/_layout";
import Repository from "../repository/_repository";

export default class AdapterCreateComponente {
    tipo = ""
    raiz = ""
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field }> {
        const classes = new FactoryComponente().classesMap
        const componentes = []
        for (const key in classes) {
            componentes.push({ value: key, name: key.toUpperCase() })
        }
        const [raizes, err] = await this.repository.findMany("Componentes", { modelo: "many" })
        if (err) console.error(err)
        const many: { value: string; name: string }[] = []
        raizes.forEach((e: any) => {
            many.push({ value: e._id, name: e.tipo })
        });
        return {
            "tipo": new Field("select", "Componentes", "Selecione o Componente", componentes),
            "raiz": new Field("select", "Componentes Raiz", "Selecione o Componente", many)
        }
    }
    async getReturn() {
        console.log(this.raiz)
        const [raiz, err] = await this.repository.read("Componentes", this.raiz)
        if (err) console.log(err);
        //NAO ESTA INSERINDO O COMPONENTE
        console.log(raiz);

        const result = Object.assign(new Layout(""), raiz)
        console.log(result);

        const component = await new CreateComponente(this.repository).execute({ tipo: this.tipo, raiz: result })
        console.log(component);

        return "close"
    }
}
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { store } from "@/store";
export default class UpdateOptions {
    static action = {
        timeout: 3000,
        text: "Atualizando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: { opt: Field }) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        if (fields.opt.list)
            fields.opt.list.forEach(async (e: any) => {
                const result = await this.repository.update("OpcoesOperadores", e.model._id, e.model)
                console.log(result)
            })

        return "close"
    }
}
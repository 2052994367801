import Repository from "@/core/repository/_repository";
import Botao from "../../botao/_botao";
import Componente from "../../_componente";

export default class CreateAudio {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, fields: any): Promise<[Componente, boolean]> {
        const component = new Componente();
        component.tipo = "audio"
        component.titulo = fields.titulo
        component.projId = root.projId
        component.projeto = root.projeto
        component.main = true
        if(fields.conexao) component.conexao = fields.conexao
        component.options.action = "start"
        const botoes = [
            { tipo: "botaoiconeSQ", title: "", icon: { icon: "Power", status: "normal" }, action: "asw", cmd: "233", state: "0", type: "pwr" },
            { tipo: "botaoiconeSQ", title: "", icon: { icon: "VolDown", status: "normal" }, action: "asw", cmd: "251", state: "0", type: "voldn" },
            { tipo: "botaoiconeSQ", title: "", icon: { icon: "VolUp", status: "normal" }, action: "asw", cmd: "252", state: "0", type: "voldup" },
            { tipo: "statusvol", title: "", action: "", cmd: "252", state: "OFF", type: "status" },
            { tipo: "botaoiconeSQ", title: "", icon: { icon: "Power", status: "normal" }, action: "asw", cmd: "233", state: "0", type: "pwr" },
            { tipo: "botaoiconeSQ", title: "", icon: { icon: "Mute", status: "normal" }, action: "asw", cmd: "233", state: "0", type: "mute" },
            { tipo: "entradas", title: "Input 1", action: "som", cmd: "237", state: "0", type: "input" },
            { tipo: "volume", title: "Volume", action: "som", cmd: "242", state: "0", type: "vol" },
            { tipo: "equa", title: "Grave", action: "som", cmd: "241", state: "0", type: "bass" },
            { tipo: "equa", title: "Agudo", action: "som", cmd: "243", state: "0", type: "treble" },
            { tipo: "loud", title: "Loud", action: "som", cmd: "245", state: "0", type: "loud" },
        ].map(b => {
            const botao = new Botao()
            botao.projId = root.projId
            botao.projeto = root.projeto
            botao.tipo = b.tipo
            botao.titulo = b.title
            botao.options.action = b.action
            botao.options.command = b.cmd
            botao.options.state = b.state
            botao.options.type = b.type
            if (b.icon) botao.options.icon = b.icon
            return botao
        });

        const [result, err] = await this.repository.create("Componentes", component)
        if (err) console.error(result)
        else Object.assign(component, result)

        //criando botoes que ficarao no componente
        component.options.botoes = []
        for (const botao of botoes.splice(0, 4)) {
            botao.painel = component._id
            const [result, err] = await this.repository.create("Componentes", botao)
            if (err) console.error(result);
        }

        //inserindo os outros botoes na tela do controle
        for (const botao of botoes.splice(0)) {
            botao.control = component._id
            const [result, err] = await this.repository.create("Componentes", botao)
            if (err) console.error(result);
        }

        console.log(component)
        return [component, false]
    }
}
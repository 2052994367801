import Repository from "@/core/repository/_repository";
import { modal } from "@/stores/showModal";
import FormChoose from "../forms/choose";
export default class SelectDay {
    static action = {
        timeout: 3000,
        text: "Conectando Compenente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        const dia = {
            dia: new Date(fields.dia).toISOString(),
            agenda: form.model.agenda,
            agendamentos: form.model.agendamentos
        }
        console.log(dia);
        
        setTimeout(() => {
            console.log(modal.showing);
            
            modal.show(new FormChoose(this.repository, dia))
        }, 100);
        return "close"
    }
}
<template>
  <main class="d-grid gap" onselectstart="return false">
    <nav style="" class="d-flex tabs">
      <div v-for="(tab, index) in tabs" :key="index" :class="{ selected: tab.selected }" @click="changeTab(tab)">
        <h3>{{ tab.name }}</h3>
      </div>
    </nav>
    <component v-bind:is="show" :key="mes" v-bind:agenda="agenda" v-bind:agendamentos="agendamentos"
      v-bind:mes="mes" @change="changeMes" @updateCalendario="updateCalendario"></component>
  </main>
</template>

<script>
import layout from "@/Features/Componentes/ui/layout.vue"
import list from "@/Features/Componentes/ui/list.vue"
export default {
  name: "page-componentes",
  components: {
    layout,
    list
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de modulos" },
      dropDown: false,
      configurar: false,
      configurando: false,
      show: "list",
      tabs: [{
        name: "Lista",
        value: "list",
        selected: true
      }, {
        name: "Layout",
        value: "layout",
        selected: false
      }]
    };
  },
  methods: {
    changeTab(tab) {
      this.show = tab.value
      this.tabs.forEach(t => t.selected = t.value === tab.value)
    },
  },
};
</script>
<template>
  <div class="d-grid gap-mid o-scroll">
    <div v-for="item in list" v-bind:key="item._id" class="d-grid gap-p p-10 w-100" @click="retorna(item)"
      :class="[item.icon ? 'icon center' : 'item']">
      <component v-if="item.icon" v-bind:is="item.type"></component>
      <p v-else>{{ item.name ? item.name : item.model ? item.model.titulo : item.name }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import trash from "./icons/trash.vue";
import add from "./icons/add.vue";
import change from "./icons/change.vue";
export default defineComponent({
  components: {
    trash,
    add,
    change
  },
  name: "objectV",
  props: {
    field: Object,
  },
  created() {
    this.list = this.field.list;
  },
  data() {
    return {
      value: "",
      list: [],
    };
  },
  methods: {
    async retorna(item) {
      if (!this.field.action) return this.$emit("object", item);
      const result = await this.field.action(item)
      console.log("\n\n\n", result, "\n\n\n", typeof result)
      if (typeof result === "string") {
        const events = result.split("/")
        if (events[0] === "back" && events.length)
          return this.$emit(events[0], {backnumber: Number(events[1])});
        
        return this.$emit(result, item);
      }

      if (result) return this.$emit("object", item);

      this.$emit("back", item);
    },
  },
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
  cursor: pointer;
}
</style>
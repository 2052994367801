type strmap = { [key: string]: string }
export const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
export const tipos: strmap = {
    "unico": "Único",
    "repete": "Repete",
}
export const repete: strmap = {
    "diario": "Diáriamente",
    "semanal": "Semanalmente",
    "intervalo": "Em um Intervalo",
}

export const repeteList = [{
    name: "Diáriamente",
    value: "diario"
},{
    name: "Semanalmente",
    value: "semanal"
},{
    name: "Em um Intervalo",
    value: "intervalo"
}]

export const intervalo: strmap = {
    "h": "Horas",
    "d": "Dias",
    "m": "Meses",
    "y": "Anos",
}
import Repository from "@/core/repository/_repository";
import Botao from "../../botao/_botao";
import Componente from "../../_componente";
import Agregador from "../_agregador";

export default class ACControl {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, fields: any): Promise<[Componente, boolean]> {
        const [comandos, errC] = await this.repository.findOne("ComandosIR", {
            tipo: fields.tipo,
            marca: fields.comandos
        })
        if (errC) return [comandos, errC]
        console.log(comandos)
        const agregador = new Agregador();
        agregador.titulo = fields.titulo
        agregador.projId = root.projId
        agregador.projeto = root.projeto
        agregador.main = true
        agregador.options.type = "ac"
        agregador.options.code = "template"
        /**
         * tem que criar os controle IR no banco 
         * e depois fazer a implementacao que criar o controle com base no banco
         * em seguida fazer para criar o controle com base em uma ja existente
         * 
         * criar comando ir separedo do controle dai permite criar marcas para aquele controle
         *  
         */
        const [result, err] = await this.repository.create("Componentes", agregador)
        if (err) console.error(result)
        else Object.assign(agregador, result)

        const botoes = [
            { tipo: "botao", code: "ON" , title: "ON", action: "ar", repetir: "1", index: 1 },
            { tipo: "botao", code: "OFF" , title: "OFF", action: "ar", repetir: "5", index: 2 },
            { tipo: "statussub", code: "Setpoint" , title: "Setpoint", action: "ar", repetir: "5", type: "noedit", index: 3, "icon": { "icon": "controller-froze", "status": "normal" }},
            { tipo: "statussub", code: "Sensor" , title: "Sensor Temp", action: "ar", repetir: "5", type: "status", index: 4, "icon": { "icon": "controller-home", "status": "normal" } },
            { tipo: "botao", code: "20F" , title: "20F", action: "ar", repetir: "5", type: "temp", mode: "COOL"},
            { tipo: "botao", code: "21F" , title: "21F", action: "ar", repetir: "5", type: "temp", mode: "COOL"},
            { tipo: "botao", code: "22F" , title: "22F", action: "ar", repetir: "5", type: "temp", mode: "COOL"},
            { tipo: "botao", code: "23F" , title: "23F", action: "ar", repetir: "5", type: "temp", mode: "COOL"},
            { tipo: "botao", code: "24F" , title: "24F", action: "ar", repetir: "5", type: "temp", mode: "COOL"},
            { tipo: "botao", code: "25F" , title: "25F", action: "ar", repetir: "5", type: "temp", mode: "COOL"},
            { tipo: "botao", code: "23Q" , title: "23Q", action: "ar", repetir: "5", type: "temp", mode: "HEAT"},
            { tipo: "botao", code: "24Q" , title: "24Q", action: "ar", repetir: "5", type: "temp", mode: "HEAT"},
            { tipo: "botao", code: "25Q" , title: "25Q", action: "ar", repetir: "5", type: "temp", mode: "HEAT"},
            { tipo: "botao", code: "26Q" , title: "26Q", action: "ar", repetir: "5", type: "temp", mode: "HEAT"},
            { tipo: "botao", code: "27Q" , title: "27Q", action: "ar", repetir: "5", type: "temp", mode: "HEAT"},
            { tipo: "botao", code: "30Q" , title: "30Q", action: "ar", repetir: "5", type: "temp", mode: "HEAT"},
            { tipo: "botao", code: "Power" , title: "Power", action: "ar", repetir: "5" },
            { tipo: "botao", code: "Velocidade" , title: "Velocidade", action: "ar", repetir: "5" },
            { tipo: "botao", code: "Oscilar" , title: "Oscilar", action: "ar", repetir: "5" },
            { tipo: "botao", code: "Modo" , title: "Modo", action: "ar", repetir: "5" },
            { tipo: "botao", code: "Mais" , title: "Mais", action: "ar", repetir: "5" },
            { tipo: "botao", code: "Menos" , title: "Menos", action: "ar", repetir: "5" },
        ].map(b => {
            const botao = new Botao()
            botao.projId = root.projId
            botao.projeto = root.projeto
            botao.tipo = b.tipo
            botao.titulo = b.title
            botao.options.action = b.action
            botao.options.repetir = b.repetir
            botao.options.type = b.type ? b.type : "button"
            botao.options.code = b.code
            if(comandos && comandos.list) 
                botao.options.command = comandos.list[b.code]
            if(b.mode)
                (botao.options as any).mode = b.mode
            if(b.index) {
                botao.index = b.index
                botao.painel = agregador._id
            }
            if(b.icon) botao.options.icon = b.icon
            botao.control = agregador._id
            return botao
        });

        for (const botao of botoes) {
            const [result, err] = await this.repository.create("Componentes", botao)
            if (err) console.error(result);
        }

        console.log(agregador)
        return [agregador, false]
    }
}
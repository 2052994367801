import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class TemplateControle {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.repository = repository
        this.model = model
        this.model.nome = "Template do Controle"
    }

    async getFields(): Promise<{[key: string]: Field | Extra}> {
        this.model.tipo = this.model.parent.options.type
        console.log(this.model)
        const tipos: {value: string, name: string}[] = [
            {value: "ac", name: "Ar Condicionado"},
            {value: "tv", name: "Controle TV"},
            //{value: "outro", name: ""},
        ]
        return {
            "extras": new Extra("Template do Controle", "Gerar", "end/createcontrol/true"),
            "titulo": new Field("text", "Título", "Controle"),
            "tipo": Field.make("select", "Tipo", "Selecione", tipos),
            "marca": new Field("text", "Nome da Coleção", "marca-123"),
        }
    }
}
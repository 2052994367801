<template>
  <section class="card d-grid gap-p p-10 w-100 pointer" @click="abreProjeto">
    <h2>{{ value.nome ? value.nome : value.titulo }}</h2>
    <p>{{ value.tipo }}</p>
  </section>
</template>

<script>
export default {
  name: "cardTitleDescription",
  props: {
    value: Object,
  },
  methods: {
    abreProjeto() {
      this.$emit("mostraModulo", this.value);
    },
  },
};
</script>

<style>
section#projeto,
section#balanca {
  margin: 0 0 25px 0;
  padding: 10px;
  border-radius: 10px;
  display: grid;
  cursor: pointer;
  background-color: var(--ion-card-background);
}
</style>
import { App } from "vue";
import { Socket } from 'socket.io-client';
declare module 'socket.io-client' {
    interface Socket {
        wait(event: string, time?: number): Promise<[any, boolean]>;
        waitSocket(): Promise<boolean>;
    }
}
export default {
    install: async (app: App, options: { socket: Socket }) => {
        Socket.prototype.wait = (event: string, time?: number): Promise<[any, boolean]> => {
            return new Promise(res => {
                let timeout = 0
                function response(result: any) {
                    res(result)
                    options.socket.off(event, response)
                    clearTimeout(timeout)
                }
                if (time)
                    timeout = setTimeout(() => {
                        res(["time out foi acionando", true])
                        options.socket.off(event, response)
                    }, time);
                options.socket.on(event, response)
            })
        }
        Socket.prototype.waitSocket = (): Promise<boolean> => {
            return new Promise((res) => {
                if (options.socket.connected) res(true)
                else
                    setTimeout(async () => {
                        console.log("esperando socket...");
                        if (!options.socket.connected) res(await options.socket.waitSocket());
                        else res(true)
                    }, 50);
            })
        }
        app.config.globalProperties.$socket = options.socket
    }
}
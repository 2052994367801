<template>
  <card
    :class="['d-grid', 'a-center', 'row-three', 'column-fit-m']"
    @config="config"
  >
    <select v-model="components.list[i].options.state" @change="execute">
      <option value="1">Entrada 1</option>
      <option value="2">Entrada 2</option>
      <option value="3">Entrada 3</option>
    </select>
  </card>
</template>

<script>
import { modal } from "@/stores/showModal";
import Factory from "../actions/_factory";
import ListForms from "../forms/_factory";
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
  },
  methods: {
    execute() {
      console.log(components.list[this.i].options.state);
      console.log(this.component.options.action);
      const action = Factory.actions[this.component.options.action];
      Object.assign(this.component.options, {
        state: components.list[this.i].options.state,
      });
      new action(this.$module).execute(this.component);
    },
    config() {
      console.log("config");
      if (ListForms.check(this.component.options.action))
        modal.show(
          new ListForms.forms[this.component.options.action](this.component)
        );
      else
        console.error(`Form "${this.component.options.action}" do not exist`);
    },
  },
};
</script>
<template>
  <main class="d-grid gap" onselectstart="return false">
    <button @click="mostrarUsuarioMqtt">Mostrar Usuário MQTT</button>
  </main>
</template>

<script>
import { modal } from "@/stores/showModal";
import ModuloForms from "@/Features/Modulo/forms/_list";
import AdapterCreateModulo from "@/Features/Modulo/forms/CreateModulo";
import { modulos } from "@/Features/Modulo/store/list";
import FormUsuarioMqtt from "@/Features/Projeto/form/usuariomqtt"
export default {
  name: "page-gerenciar",
  components: {
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de modulos" },
      dropDown: false,
      configurar: false,
      configurando: false,
      adapter: {},
      configLayer: 0,
      modulos: modulos.list,
      create: AdapterCreateModulo,
      show: ModuloForms,
      collection: "Modulos",
      query: {
        projId: this.$route.params.projId,
      },
    };
  },
  async created() {
    modulos.clear();
    const [result, err] = await this.$repository.findMany(
      this.collection,
      this.query
    );
    if (err) console.error("Erro ao pegar modulos");
    else result.forEach((m) => modulos.push(m));
  },
  methods: {
    async mostrarUsuarioMqtt(button) {
      const [result, err] = await this.$repository.findOne("Projetos", {
        projId: this.$route.params.projId
      })
      console.log(err, result);
      modal.show(new FormUsuarioMqtt(this.$repository, {fromProject: true}, result))
    },
  },
};
</script>
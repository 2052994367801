<template>
    <div class="d-flex week">
        <div v-for="(item, index) in week" :key="index" :class="{ selected: item.value }">
            <label :for="item.id">{{ item.name }}</label>
            <input :id="item.id" v-model="item.value" type="checkbox" @change="setValue">
        </div>
    </div>
</template>
<script>
export default {
    props: {
        formu: Object
    },
    async created() {
        console.log(this.formu);
        if (this.formu && this.formu.model.dias)
            this.week.forEach(d => d.value = typeof this.formu.model.dias.find(n => n === d.id) === "number")

    },
    data() {
        return {
            week: [{
                id: 0,
                name: "Dom",
                value: false
            }, {
                id: 1,
                name: "Seg",
                value: false
            }, {
                id: 2,
                name: "Ter",
                value: false
            }, {
                id: 3,
                name: "Qua",
                value: false
            }, {
                id: 4,
                name: "Qui",
                value: false
            }, {
                id: 5,
                name: "Sex",
                value: false
            }, {
                id: 6,
                name: "Sab",
                value: false
            }]
        }
    },
    methods: {
        setValue() {
            const dias = []
            this.week.forEach(d => {
                if (d.value) dias.push(d.id)
            })
            this.$emit("updateAtrib", "dias", dias)
        }
    }
}
</script>

<style>
.week {
    justify-content: space-between;
}

.week .selected {
    background-color: var(--color-primary);
    color: var(--color-primary-contrast);
}

.week input {
    display: none;
}

.week div {
    background-color: var(--ion-color-light-tint);
    border-radius: .5em;
}
</style>
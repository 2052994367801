import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormOperador from "./operador";
import SelectIluminacao from "./operadores/iluminacao";
import FormTituloComponente from "@/Features/Componentes/forms/nomeComponente";
import FormComponentMacro from "@/Features/Componentes/forms/macro";

export default class FormCena {
    repository: Repository
    model: any
    back = true
    fromothersrc = true
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        const add = new SelectIluminacao(this.repository, { titulo: "+ Adicionar", macro: this.model })
        const cadd = new FormTituloComponente(this.repository, { nome: "+ Adicionar", tipo: "botaoprog", action: this.model._id })
        const fields = {
            "extras": new Extra(this.model.nome, "Atualizar", "updateprogramacao", {}, true, "deleteprogramacao"),
            "nome": new Field("text", "Titulo", "nome"),
            "operadores": new Field("object", "Ações", "", [add]),
            "componentes": new Field("object", "Componentes", "", [cadd]),
        }
        const [result, err] = await this.repository.findOne("Macros", { _id: this.model._id, projId: this.model.projId })
        if (err) console.error(result);
        else {
            this.model.children = result
            const operadores: any[] = []
            const componentes: FormComponentMacro[] = []
            if (result){
                result.operadores.forEach((o: any) => {
                    operadores.push(new FormOperador(this.repository, o))
                });
                result.componentes.forEach((o: any) => {
                    componentes.push(new FormComponentMacro(this.repository, o))
                });
            }
            operadores.push(add)
            componentes.push(cadd)
            fields.operadores = new Field("objectVL", "Ações", "", operadores)
            fields.componentes = new Field("object", "Componentes", "", componentes)
        }
        return fields
    }
}
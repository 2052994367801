import Repository from "@/core/repository/_repository"
import { wait } from "@/stores/showModal"

export default class UpdateAmostra {
    static action = {
        timeout: 30000,
        text: "Atualizando"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        if (fields.amostradiaria) {
            const query = {
                tipo: "amostratemperatura",
                pertence: "projeto",
                dono: form.model.model.projId
            }
            const [result, err] = await this.repository.findMany("Agendas", query)
            console.log(result, err)
            if (!result.length) {
                wait.msg = "Criando Agendamento de Amostras"
                const [agenda, aerr] = await this.repository.create("AgendaAmostra", query)
                if(aerr) return "back"
                console.log(agenda, aerr)
            }
            wait.msg = "Atualizando"
        }
        form.model.model.options.amostradiaria = fields.amostradiaria
        const [result, err] = await this.repository.update("Componentes", form.model.model._id, {
            projId: form.model.model.projId,
            options: form.model.model.options
        })
        console.log(result, err)

        return "refresh"
    }
}
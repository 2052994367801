<template>
  <component v-bind:is="value.icon" v-bind:value="value.status"></component>
</template>

<script>
import Temperatura from "./Temperatura/_Temperatura.vue";
import Conexao from "./Conexao/_Conexao.vue";
import Fumaca from "./Fumaca/_Fumaca.vue";
import Presenca from "./Presenca/_Presenca.vue";
import Fecho from "./Fecho/_Fecho.vue";
import RJ45 from "./RJ45.vue";
import VolUp from "./AV/VolUp/_index.vue";
import VolDown from "./AV/VolDown/_index.vue";
import Power from "./AV/Power/_index.vue";
import Mute from "./AV/Mute/_index.vue";
import ArrowDown from "./Controle/ArrowDown/_index.vue";
import ArrowLeft from "./Controle/ArrowLeft/_index.vue";
import ArrowRight from "./Controle/ArrowRight/_index.vue";
import ArrowUp from "./Controle/ArrowUp/_index.vue";
import Back from "./Controle/Back/_index.vue";
import Home from "./Controle/Home/_index.vue";
import Input from "./Controle/Input/_index.vue";
import Menu from "./Controle/Menu/_index.vue";
import Minus from "./Controle/Minus/_index.vue";
import CMute from "./Controle/Mute/_index.vue";
import Plus from "./Controle/Plus/_index.vue";
import CVolUp from "./Controle/VolUP/_index.vue";
import CVolDown from "./Controle/VolDown/_index.vue";
import CPower from "./Controle/Power/_index.vue";
import Settings from "./Controle/Settings/_index.vue";
import Froze from "./Controle/Froze/_index.vue";
import Sun from "./Controle/Sun/_index.vue";
export default {
  name: "livIcons",
  props: {
    value: Object,
  },
  components: {
    Temperatura,
    Conexao,
    RJ45,
    Fumaca,
    Presenca,
    Fecho,
    VolUp,
    VolDown,
    Power,
    Mute,
    "controller-arrow-down": ArrowDown,
    "controller-arrow-left": ArrowLeft,
    "controller-arrow-right": ArrowRight,
    "controller-arrow-up": ArrowUp,
    "controller-back": Back,
    "controller-home": Home,
    "controller-input": Input,
    "controller-menu": Menu,
    "controller-minus": Minus,
    "controller-mute": CMute,
    "controller-plus": Plus,
    "controller-power": CPower,
    "controller-settings": Settings,
    "controller-vol-down": CVolDown,
    "controller-vol-up": CVolUp,
    "controller-froze": Froze,
    "controller-sun": Sun,
  },
};
</script>

<style>
svg:active {
  background-color: var(--card-color) !important;
  border-radius: 50%;
}
path.fill {
  fill: var(--card-color);
}
</style>
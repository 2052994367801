<template>
  <component v-bind:is="value"></component>
</template>

<script>
import Close from "./Close.vue";
import Open from "./Open.vue";
export default {
  name: "fumaca",
  props: {
    value: String,
  },
  components: {
    Close,
    Open,
  },
};
</script>
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
const imgList: { [key: string]: string } = {
    "livingroom": "Sala de Estar",
    "kitchen": "Cozinha",
    "bedroom": "Quarto",
    "restroom": "Banheiro",
    "pool": "Piscina",
    "send": "Enviar Imagem"
}
export default class FormSelectImage {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        if(this.model.options)
            this.model.objname = this.model.options.url ? imgList[this.model.options.url] : "Selecione"
        else {
            this.model.options = {url: ""}
            this.model.objname = "Selecione"
        }
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list: {value: string; name: string}[] = []
        const [result, err] = await this.repository.findMany("Wallpapers", {})
        if(!err) result.forEach(r => list.push({value: r.value, name: r.name}))
        return {
            "extras": new Extra("Escolha uma Imagem", "none", ""),
            "themes": new Field("objectVIMG", "Imagens", "", list, this.model, async (i) => {
                console.log(this.model)
                console.log(i)
                this.model.options.url = i.value
                const result = await this.repository.update("Componentes", this.model._id, {
                    projId: this.model.projId,
                    options: { url: i.value }
                })
                console.log(result)
                if(this.model.tipo === "root")
                    return "close"
                return "back"
            })
        }
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormOpcao {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        model.titulo = model.key.toUpperCase()
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        console.log(this.model)
        const fields = {
            "extras": new Extra(this.model.titulo, "Atualizar", "updatecomponente", {}, true, "deletecomponente"),
            "key": new Field("text", "Identificador", ""),
            "value": new Field("text", "Valor", "")
        }
        return fields
    }
}
import P2P from "./project/p2p"
import Project from "./project/_state"
import Start from "./start/_state"

export interface States {
    "start": typeof Start;
    "project": typeof Project;
    "p2p": typeof P2P;
}
export class List {
    static states: States = {
        "start": Start,
        "project": Project,
        "p2p": P2P
    }
}
<template>
    <div class="d-grid gap-m row-mc calendar">
        <nav class="d-flex gap-m row-mc justify-between a-center">
            <p class="w-inherit p-1em" @click="$emit('change', 'back')"> 
                <svg style="width: 30px;" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path style="fill:none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="48" d="M328 112L184 256l144 144" />
                </svg></p>
            <h2>{{ mesStr }}</h2>
            <p class="w-inherit p-1em" @click="$emit('change', 'next')"> 
                <svg style="width: 30px;" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path style="fill:none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="48" d="M184 112l144 144-144 144" />
                </svg> </p>
        </nav>
        <div class="d-grid row-mc">
            <div class="d-grid week">
                <p class="day" v-for='dia in ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."]' v-bind:key="dia">
                    {{ dia }}
                </p>
            </div>
            <div class="d-grid week">
                <div v-for="dia in dias" v-bind:key="dia.nome" :class="{ nmes: !dia.now, hoje: dia.hoje }"
                    @click="abreDia(dia)">
                    <p :class="{ hoje: dia.hoje }">{{ dia.nome }}</p>
                    <p v-if="dia.events.length" class="event">{{ dia.events.length }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { modal } from "@/stores/showModal";
import FormDia from "@/Features/Agenda/forms/mostraDia";
import { defineComponent } from 'vue'
const meses = ["JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO", "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"]
export default defineComponent({
    props: {
        agenda: Object,
        mes: Number
    },
    data() {
        return {
            mesStr: "nenhum",
            dias: [],
            agendamentos: [],
        }
    },
    async created() {
        const timeshow = new Date().setMonth(this.mes)
        const start = new Date(timeshow)
        const now = new Date(timeshow)
        const end = new Date(timeshow)
        start.setMonth(now.getMonth(), 1)
        now.setMonth(now.getMonth(), 15)
        end.setMonth(now.getMonth() + 1, 1)
        start.setDate(-start.getDay() + 1);
        start.setHours(0, 0, 0, 0)
        end.setDate(end.getDay() ? 7 - end.getDay() : end.getDay());
        end.setHours(24, 0, 0, 0)
        this.mesStr = `${meses[now.getMonth()]}, ${now.getFullYear()}`
        const dias = []
        const days = new Date(start)
        while (days.valueOf() < end.valueOf()) {
            dias.push(new Date(days))
            days.setDate(days.getDate() + 1)
        }
        this.dias = dias.map(d => {
            const hoje = new Date()
            return {
                mes: now.toISOString(),
                now: now.getMonth() === d.getMonth(),
                dia: d.toISOString(),
                hoje: (d.getDate() === hoje.getDate() && d.getDay() === hoje.getDay()),
                nome: d.getDate(),
                events: []
            }
        })
        console.log("Tenho que pegar os periodos entre: ", start, end);
        const query = {
                dono: this.$route.params.projId,
                inicio: start,
                fim: end,
                complex: true,
            }
        console.log(query)
        const [agendamentos, err] = await this.$repository.findMany(
            "PeriodoDelimitadores",
            query
        )
        if (err) console.error(agendamentos);
        console.log(agendamentos);
        this.agendamentos = agendamentos
        const diasiso = this.dias.map(d => d.dia)
        agendamentos.forEach(a => {
            if (a.tipo === "unico") {
                const iso = new Date(new Date(a.dinicio.date).setHours(0, 0, 0, 0)).toISOString()
                const i = diasiso.indexOf(iso)
                this.dias[i].events.push(a)
                return
            }
            if(a.repete === "semanal"){
                this.dias.forEach(d => {
                    if(typeof a.dias.find(s => s === new Date(d.dia).getDay()) === "number")
                        d.events.push(a)
                })
                return
            }
            if(a.repete === "intervalo") {
                const [tipo, valor] = a.intervalo.split("/")
                if(tipo === "h")
                    this.dias.forEach(d => d.events.push(a))
                // TODO: tem que melhorar o calculo do intervalo. Deixar mais dinamico ao inves de ir um por um
                const date = new Date(new Date(a.dinicio.date).setHours(0, 0, 0, 0))
                const arr = [date.getFullYear(), date.getMonth(), date.getDate()]
                const tipos = {y: 0, m:1, d:2}
                const lastiso = diasiso[diasiso.length - 1]
                while(date <= new Date(lastiso) ){
                    const iso = date.toISOString()
                    const i = diasiso.indexOf(iso)
                    if(i > -1) this.dias[i].events.push(a)
                    arr[tipos[tipo]] += Number(valor)
                    date.setFullYear(...arr)
                }
                return
            }
            if(a.repete === "diario") {
                this.dias.forEach(d => d.events.push(a))
                return
            }
        });
        console.log(this.dias);
    },
    methods: {
        abreDia(dia) {
            console.log(dia);
            dia.agenda = this.agenda
            dia.agendamentos = this.agendamentos
            dia.update = () => {this.$emit("updateCalendario")}
            modal.show(
                new FormDia(this.$repository, dia)
            );
        }
    }
})

</script>

<style>
.calendar {
    background-color: var(--card-background-tint);
    border-radius: .5em;
}

.day {
    padding: 0.5em 0;
    text-align: center;
}

.week {
    grid-template-columns: repeat(7, 1fr);
}

.week div {
    background-color: var(--card-background-tint);
    padding: 0.5em;
}

.nmes {
    opacity: .5;
}

.hoje {
    background-color: var(--color-primary) !important;
    color: var(--color-primary-contrast);
}

.event {
    background-color: lightgrey;
    color:black;
    padding: 0.1em 0;
    text-align: center;
    border-radius: 0.3em;
}
</style>
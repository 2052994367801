import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "../../../core/repository/_repository";
import FormsCreate from "./_listCreate";

export default class FormCreateComponente {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const forms = []
        for (const key in FormsCreate.list) {
            forms.push(new FormsCreate.list[key](this.repository, { tipo: key, titulo: FormsCreate.titulos[key] }))
        }
        return {
            "extras": new Extra("Escolha Componente", "none", "createcomponente"),
            "tipo": new Field("objectV", "Componentes", "Selecione o Componente", forms)
        }
    }
}
import Repository from "@/core/repository/_repository";
import router from "@/router";
import { setStorage } from "@/scripts/Storage";
import Socket from "@/service/socket";

export default class Login {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }

    async execute(obj: any): Promise<any> {
        Socket.io.emit("login", { username: obj.username, password: obj.password })
        const [result, err] = await Socket.io.wait("login")
        if (err) {
            console.error(result)
            obj.error = "Usuário e Senha não conferem"
            if(result === "Comando invalido para auth")obj.error ="VA PARA O HALL"
            setTimeout(() => {
                obj.error = null
            }, 4000);
        } else {
            console.log(result)
            setStorage("accessToken", result.accessToken);
            setStorage("refreshToken", result.refreshToken);
            Socket.setTokens(result.accessToken, result.refreshToken)
            router.replace("/hall");
        }
        return [result, err]
    }
}
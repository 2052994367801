import Repository from "@/core/repository/_repository"
import { store } from "@/store"
import CreateOperadorCheckbox from "./createOperadorCheckbox"
import { snackbar } from "@/stores/login"

export default class CreateOperadores {
    static action = {
        timeout: 10000,
        text: "Criando Operadores"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        for (const item of fields.componentes) {
            await new CreateOperadorCheckbox(this.repository).execute(form.model.macro, item);
        }

        snackbar.show("Operador criadores!!!", 3000, "success")
        return "back"
    }
}
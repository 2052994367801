<template>
    <div class="form-input d-grid gap-s">
        <div class="d-flex jc-between">
            <label for="projName" class="required">Projeto</label>
            <div class="clear1" @click="clear('projName')">Limpar</div>
        </div>
        <input list="projetos" type="text" name="Projeto" v-model="value" @change="changeInput" />
        <datalist id="projetos">
            <option v-for="projeto in projetosl" v-bind:key="projeto" :value="projeto"></option>
        </datalist>
    </div>
</template>

<script>
export default {
    props: {
        projetos: Object,
        global: Object,
    },
    data() {
        return {
            data: "",
            elProject: document.getElementById("projName"),
            value: "",
            projetosl: []
        };
    },
    async created() {
        console.log("criou componente")
        this.$emit("setCallback", "dataRefresh_callbacks", this.refreshProjetos)
        this.refreshProjetos()
    },
    methods: {
        envia() {
            console.log("enviando formulario")
        },
        clear(tipo) {
            console.log(this.value, tipo)
            this.value = ""
        },
        changeInput() {
            console.log("dentro do elemento", this.value)
            this.$emit("changeInput", "projeto", this.value)
            this.onProjectNameChange()
        },
        refreshProjetos() {
            console.log("atualiza lista dos projetos")
            //console.log(document.getElementById("projetos").innerHTML)
            //atualiza lista de projetos
            for (const property in this.global.data) {
                if (property == "") continue
                if (property == "Legenda") continue
                if (property == "#fim#") continue
                this.projetosl.push(property)
            }
        },
        //monitora alteracao do projeto
        onProjectNameChange() {
            //EXE
            //executa todas as funcoes associadas a troca do projeto
            if (this.global.projChange_callbacks) {
                this.global.projChange_callbacks.forEach((func) => {
                    if (typeof func == "function")
                        func(this.value)
                });
            }
        }
    }
};
</script>

<style></style>
import Repository from "@/core/repository/_repository";
import Componente from "../../_componente";
import CopiarComponente from "./copiarComponente";
export default class AdicionaComponente {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, componente: any): Promise<[Componente, boolean]> {
        console.log(componente)
        const [rela, err] = await this.repository.findOne("ComponenteRoot", {
            projId: root.projId,
            componente: componente._id
        })
        if(err) return [componente, true]
        if(!rela) return new CopiarComponente(this.repository).execute(root, componente)
        console.log(rela)
        rela.root = root._id
        const update = await this.repository.update("ComponenteRoot", rela._id, rela)
        console.log(update)
        return [componente, false]
    }
}
<template>
  <input v-model="value" step=".1" :min="atribs.min" :max="atribs.max" type="number" inputmode="numeric" :placeholder="field.placeholder" />
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "text",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
      atribs: {}
    };
  },
  created() {
    this.value = this.$attrs.modelValue;
    if(this.field.list)
    this.field.list.forEach(a => {
      this.atribs[a.name] = a.value
    });
    if(this.field.extra) {
      this.atribs["max"] = this.field.extra.max
      this.atribs["min"] = this.field.extra.min
    }
  },
});
</script>
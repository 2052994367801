import Repository from "@/core/repository/_repository";
import Componente from "../../../_componente";
export default class AdicionaElement {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, componente: any): Promise<[Componente, boolean]> {
        console.log(componente)
        const [rela, err] = await this.repository.create("ComponenteRoot", {
            projId: root.projId,
            root: root._id,
            index: root.elements.length - 1,
            componente: componente._id
        })
        if(err) return [rela, true]
        return [rela, false]
    }
}
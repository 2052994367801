import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormConfigEndpoint from "./config";
import FormSelectEndpoint from "./select";
import GetUsername from "../../usecases/getUsername";

export default class FormEndpoints {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.model.objname = "Gerenciar"
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list: Array<FormConfigEndpoint | FormSelectEndpoint> = [new FormSelectEndpoint(this.repository, this.model)]
        const [result, err] = await this.repository.findMany("Endpoints", {
            username: await new GetUsername(this.repository).execute()
        })
        if(err) console.log(result)
        else result.forEach((p: any) => list.push(new FormConfigEndpoint(this.repository, p, this.model)))
        console.log(result)
        return {
            "extras": new Extra("Notificações", "none", ""),
            "endpoints": Field.make("objectV", "Endpoints", list),
        }
    }
}
export function checkModulo(component: any): [modId: string, e485: number, canal: number, error: boolean, ip: string] {
    let modulo, conexao
    if (component.modulo) {
        modulo = component.modulo
        conexao = { canal: 0 }
    }
    //nao eh agredor (interrupor, dimmer)
    else if (component.conexao && component.conexao.porta && component.conexao.porta.modulo) {
        modulo = component.conexao.porta.modulo
        conexao = component.conexao
        //faz parte de um grupo de comandos (agregador)
    } else if (component.parent && component.parent.conexao && component.parent.conexao.porta && component.parent.conexao.porta.modulo) {
        modulo = component.parent.conexao.porta.modulo
        conexao = component.parent.conexao
    } else return ["Erro ao pegar dados do modulo", 0, 0, true, ""]

    let modId = modulo.modId
    let ip = modulo.ip
    //esta vinculado a um gateway (e485, ...)
    if (modulo.modelo !== "gateway") {
        modId = modulo.modulo.modId
        ip = modulo.modulo.ip
    }

    const e485 = Number(modulo.e485)
    const canal = isNaN(Number(conexao.canal)) ? 0 : Number(conexao.canal)
    return [modId, e485, canal, false, ip]
}
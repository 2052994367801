import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import AddValue from "./addValue";

const types: {[key: string]: string} = {
    "imageselect": "toggle",
    "textpoints": "text",
    "text": "text",
    "number": "number"
}
export default class FormValue extends AddValue {
    model: {projId: string; table: string; titulo: string; fields: any[]; column: any}
    constructor(repository: Repository, model: any) {
        super(repository, model)
        this.model = model
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.column.name, "Atualizar", "end/updatevalue/last",{}, true, "deletevalue/true"),
            "value": new Field(types[this.model.column.type], "Valor", this.model.column.name)
        }
        
        return fields

    }
}
import Repository from "@/core/repository/_repository";
import { store } from "@/store";
export default class DeleteOperador {
    static action = {
        timeout: 10000,
        text: "Apagando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)

        const [result, err] = await this.repository.delete("Operadores", form.model._id)
        if (err) console.error(result)
        else console.log(result)

        const [deleted, derr] = await this.repository.deleteMany("OpcoesOperadores", {
            projId: form.model.projId,
            operador: form.model._id
        })
        if (derr) console.error(deleted)
        else console.log(deleted)

        const [operadores, e] = await this.repository.findMany("Operadores", {
            projId: form.model.projId,
            index: { $gt: form.model.index }
        })
        console.log(operadores);
        if (!e)
            operadores.forEach(async o => {
                o.index--
                const result = await this.repository.update("Operadores", o._id, o)
                console.log(result);
            })
        console.log("APAGOU")
        return "back"
    }
}
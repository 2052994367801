import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormTituloComponente from "../nomeComponente";

export default class FormSelectMarcas {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const forms = [new FormTituloComponente(this.repository, {
            tipo: this.model.tipo,
            comandos: false,
            nome: "Novo",
            titulo: "Adicionar nova Coleção"
        })]
        const fields = {
            "extras": new Extra("Selecione uma coleção", "none", "none"),
            "tipo": new Field("objectV", "Coleções de Códigos IR", "", forms)
        }
        const [brands, err] = await this.repository.findMany("ComandosIR", {tipo: this.model.tipo})
        if(err) return fields
        brands.forEach(b => forms.push(new FormTituloComponente(this.repository, {
            tipo: this.model.code,
            comandos: b.list,
            nome: b.marca,
            titulo: b.marca,
            control: this.model
        })))
        fields.tipo = new Field("objectV", "Coleções de Códigos IR", "", forms)
        return fields
    }
}
import Repository from "@/core/repository/_repository";
import Botao from "../../botao/_botao";
import Componente from "../../_componente";
import Agregador from "../_agregador";

export default class CreateIRControl {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, fields: any): Promise<[Componente, boolean]> {
        const agregador = new Agregador();
        agregador.titulo = fields.titulo
        agregador.projId = root.projId
        agregador.projeto = root.projeto
        agregador.main = true
        agregador.options.code = fields.control.code
        agregador.options.type = fields.control.tipo

        const [result, err] = await this.repository.create("Componentes", agregador)
        if (err) console.error(result)
        else Object.assign(agregador, result)

        for (const key in fields.control.botoes) {
            const botao = new Botao()
            botao.projId = root.projId
            botao.projeto = root.projeto
            botao.tipo = fields.control.botoes[key].tipo
            botao.titulo = fields.control.botoes[key].title
            if(fields.control.botoes[key].options)
            botao.options = fields.control.botoes[key].options
            botao.options.action = fields.control.botoes[key].action
            botao.options.repetir = fields.comandos && fields.comandos[fields.control.botoes[key].code] ? fields.comandos[fields.control.botoes[key].code].repetir : ""
            botao.options.command = fields.comandos && fields.comandos[fields.control.botoes[key].code] ? fields.comandos[fields.control.botoes[key].code].command : ""
            botao.options.code = fields.control.botoes[key].code
            botao.control = agregador._id
            if (fields.control.botoes[key].icon) botao.options.icon = fields.control.botoes[key].icon
            if (fields.control.botoes[key].painel) {
                botao.painel = agregador._id
                botao.index = fields.control.botoes[key].painel
            }
            const [result, err] = await this.repository.create("Componentes", botao)
            console.log(result);
            
            if(err) continue 
        }

        console.log(agregador)
        return [agregador, false]
    }
}

import Repository from "@/core/repository/_repository";
export default class UpdateModulo {
    static action = {
        timeout: 3000,
        text: "Atualizando Módulo"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        Object.assign(form.model, fields)
        form.model.e485 = Number(form.model.e485).toString()
        const [result, err] = await this.repository.update("Modulos", form.model._id, form.model)
        if (err) console.error(result);
        else console.log(result);

        return "close"
    }
}
import Field from "../../../core/entity/Field";
import AdapterPortaConfiguracao from "../../../core/adapter/PortaConfiguracao";
import Repository from "../../../core/repository/_repository";
import { store } from "@/store";
import DateToMask from "../../../core/adapter/DateToMask";
import Extra from "@/core/entity/Extra";
import Chamado from "../domain/entity/chamado";

export default class FormUpdateChamado {
    model: Chamado
    repository: Repository
    constructor(model: Chamado, repository: Repository) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const tipos: { categorias: Array<{ value: string; name: string }>; status: Array<{ value: string; name: string }> } = {
            categorias: [],
            status: []
        }
        const [categorias, ec] = await this.repository.findMany("ChamadosCategorias", {});
        if (ec) console.error(categorias);
        else tipos.categorias = categorias;
        const [rstatus, es] = await this.repository.findMany("ChamadosStatus", {});
        if (es) console.error(rstatus);
        else tipos.status = rstatus;
        const categoria = tipos.categorias.find((c) => c.value === this.model.categoria)
        const status = tipos.status.find((s) => s.value === this.model.status)
        this.model.categoria = categoria ? categoria.name : this.model.categoria;
        this.model.status = status ? status.name : this.model.status;

        this.model.data = new DateToMask().convert(new Date(this.model.data), "default")
        const [result, err] = await this.repository.findMany("Portas", { modulo: this.model._id, projId: store.state.projId })
        const fields = {
            "extras": new Extra("Botão", "Atualizar", "updatebotao"),
            "data": new Field("show", "Data de Abertura", "Selecione"),
            "status": new Field("show", "Status", ""),
            "categoria": new Field("show", "Categoria", ""),
            "descricao": new Field("textarea", "Descrição", "Descreva seu Problema"),
        }
        if (err) {
            console.error(result);
            return fields;
        } else {
            const portas: AdapterPortaConfiguracao[] = []
            result.forEach((p: any) => {
                portas.push(Object.assign(new AdapterPortaConfiguracao(this.repository), p))
            })
            return fields
        }

    }
}
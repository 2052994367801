import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormAmostrasDiaria {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        this.model.inoptions = true
        Object.assign(this.model, this.model.model.options)
        return {
            "extras": new Extra(this.model.model.titulo, "Atualizar", "end/updateamostra/true"),
            "amostradiaria": Field.make("toggle", "Ativar Amostra")
        }
    }
}
<template>
    <video :id="element._id" :style="{ 'grid-area': gridarea, 'object-fit': objectfit }" autoplay loop>
    </video>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
    name: "appLayout",
    components: {
    },
    props: {
        element: Object,
    },
    data() {
        return {
            gridarea: "",
            url: "",
            objectfit: "",
        };
    },
    async created() {
        console.log(this.element)
        this.url = this.element.options.url
        this.objectfit = this.element.options.objectfit
        const [style, err] = await this.$repository.findOne("ElementsStyle", {
            projId: this.element.projId,
            element: this.element._id
        })
        if (err) console.error(style);
        console.log(style)
        const p = style.position
        const s = style.size
        this.gridarea = `${p.y}/${p.x}/span ${s.h}/span ${s.w}`
        setTimeout(() => {
            document.querySelectorAll(".display video").forEach(e => e.play())
        }, 2000)
        const video = document.getElementById(this.element._id)
        fetch(this.url)
            .then(response => response.blob())
            .then(blob => {
                video.src = URL.createObjectURL(blob);
                video.load();
                video.onloadeddata = function () {
                    video.play();
                }
            })
            .catch(console.error);
    },
    methods: {
        fullscreen(e) {
            console.log(e)
            document.documentElement.requestFullscreen()
                .catch(err => console.error(err))
                .then(e => console.log(e))
            const video = document.querySelector(".display video")
            if (video.paused) video.play()
        }
    },
});
</script>

<style>
.display video {
    width: 100%;
    height: 100%;
}
</style>
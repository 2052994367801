<template>
  <card :class="[
    'd-grid',
    'gap-m',
    'row-four',
    'column-fit-l',
  ]" @config="config" @click="screen">
    <div class="d-flex gap-m justify-between a-center">
      <p v-if="components.list[i].conexao && components.list[i].conexao.status !== '🟢'">{{
        components.list[i].conexao.status }}</p>
      <h2>{{ component.titulo }}</h2>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ccc" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize">
        <polyline points="9 18 15 12 9 6" />
      </svg>
    </div>
    <div class="d-flex gap-m justify-between a-center">
      <p>Último evento:</p>
      <strong>{{ components.list[i].options.status }}</strong>
      <strong>{{ arrumaData(components.list[i].options.date, "dd/MM hh:mm:ss") }}</strong>
    </div>
  </card>
</template>

<script>
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
import arrumaData from "@/core/entity/ArrumaData";
import { modalcontrole } from "@/stores/showModal";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      arrumaData,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
  },
  methods: {
    screen() {
      console.log("#######");
      console.log(this.component);
      const component = {}
      Object.assign(component, this.component)
      component.control = [
        {tipo: "configdigitalin", parent: component},
        {tipo: "eventstable", parent: component}
      ]
      modalcontrole.show(component.control, component);
    },
  },
};
</script>

<style></style>
<template>
  <p>
    {{ extra.text }}
    <b style="cursor: pointer" @click="$emit('extra', extra.extra.type)">{{
      extra.extra.text
    }}</b>
  </p>
</template>

<script>
export default {
  name: "loginopt",
  props: {
    extra: Object,
  },
};
</script>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import GetUsername from "../usecases/getUsername";

export default class FormSelectProject {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.model.favorite = model.user.favorite
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list = [{name: "Nenhum", value: ""}]
        const [result, err] = await this.repository.findOne("Hall", {
            username: await new GetUsername(this.repository).execute()
        })
        if(err) console.log(result)
        else result.projetos.forEach((p: any) => list.push({name: p.projeto.nome, value: p.projeto._id}))
        return {
            "extras": new Extra("Mudar favoríto", "Mudar", "setfavoriteproject"),
            "favorite": Field.make("select", "Projeto Favoríto", "Selecionar", list),
        }
    }
}
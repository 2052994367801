<template>
  <main class="d-grid gap" onselectstart="return false">
    <div class="d-flex a-center gap-m" v-for="programacao in programacoes" :key="programacao._id">
      <card-title-description :value="programacao" @mostraModulo="mostraModulo" />
    </div>
  </main>
</template>

<script>
/* TODO: aquie vai exibir componentes capazes de acionar uma rotina */
/**
 * Modulos -> Eventos/Alarme, Entradas e Saidas
 * Agenda -> action do delimitadores
 * 
 * eu posso conectar direto a programacao na conexao, pois ProgramacoesEntradas na verdade é ProgramacoesConexoes 
 */
import { modal } from "@/stores/showModal";
import Forms from "@/Features/Programacao/forms/_list";
import cardTitleDescription from "@/components/cards/_card.vue";
export default {
  name: "page-programacao",
  components: {
    cardTitleDescription,
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de Programações" },
      dropDown: false,
      configurar: false,
      configurando: false,
      adapter: {},
      configLayer: 0,
      programacoes: [],
      show: Forms,
    };
  },
  async created() {
    const [modulos, err] = await this.$repository.findMany("ModulosPortasConexoes", {
      projId: this.$route.params.projId,
    });
    if(err) console.error("Erro ao pegar modulos:", modulos);
    else modulos.forEach(m => this.programacoes.push({
      nome: m.nome,
      tipo: "cenasmodulo",
      model: m
    }))

  },
  methods: {
    mostraModulo(modulo) {
      modal.show(new this.show.list[modulo.tipo](this.$repository, modulo));
    },
  },
};
</script>
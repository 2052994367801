import Repository from "@/core/repository/_repository";

export default class UpdatePrograma {
    static action = {
        timeout: 10000,
        text: "Adicionando a Programação"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form);
        console.log(fields);
        const [result, err] = await this.repository.update("TelaProgramacao", form.model._id, {
            projId: form.model.projId,
            duracao: fields.duracao
        })
        if(err) console.error(result);
        
        return "back"
    }
}
import Socket from "@/service/socket";
import { store } from "@/store";
import { Default } from "../command"

export default class Project extends Default {
    constructor(){
        super("project")
    }
    setup() {
        /* aqui teria aque chamar um usecase que pega todas as informaçoes do projeto no banco de dados */
        Socket.io.emit("enterproject", { projId: store.state.projId });
    }
}
<template>
    <card :class="[
        'd-grid',
        'gap-m',
        'row-mc',
        'row-13',
        'all-absolute',
    ]" @config="config">
        <div class="d-flex justify-between">
            <h2>{{ component.titulo }}</h2>
            <change class="cursor-pointer" @click="refresh"></change>
        </div>
        <!-- <div class="d-grid gap-m ac-start o-auto p-05em br-05em bg-screen projetosmodulos auto-fit">
            <div class="d-grid gap-s br-05em p-05em bg-card cursor-pointer" v-for="projeto in projetos" :key="projeto._id"
                @click="form(projeto)">
                <h2>{{ projeto.nome }}</h2>
                <div class="d-flex gap-m">
                    <p v-for="modulo in projeto.modulos" :key="modulo._id">{{ modulo.conexao ? modulo.conexao.status : "⚫" }}</p>
                </div>
            </div>
        </div> -->
        <div id="map"></div>
    </card>
</template>
  
<script>
import L from "leaflet"
import Card from "@/components/UI/Card.vue";
import { modal } from "@/stores/showModal";
import change from "@/components/Form/icons/change.vue";
import FormStatusModulos from "../../status/forms/statusmodulos";
export default {
    components: {
        Card,
        change
    },
    props: {
        component: Object,
        project: Object,
    },
    data() {
        return {
            value: false,
            projetos: [],
            store: { root: {} },
            i: 0,
            map: {}
        };
    },
    async created() {
        await this.getProjects()
        console.log(this.projetos)
        this.map = L.map('map').setView([-28.6351355,-53.0968794], 15);
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '<a href="https://livtech.com.br">Livtech</a>'
        }).addTo(this.map);

        const greenIcon = L.icon({
            iconUrl: 'https://app.livtech.com.br/_img/icons/icon-192x192.png',
            shadowUrl: '',

            iconSize: [38, 38], // size of the icon
            shadowSize: [50, 64], // size of the shadow
            iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62],  // the same for the shadow
            popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
        });
        this.projetos.forEach(p => {
            const marker = L.marker(p.coordenadas, {icon: greenIcon}).addTo(this.map);
            console.log(p)
            marker.on("click", () => {
                console.log("clicou em", p)
                this.form(p)
            })
        })
    },
    methods: {
        async getProjects() {
            this.projetos = []
            this.store = { root: {} }
            const [result, err] = await this.$repository.findOne("ProjetosGerenciandos", {
                _id: this.project._id,
                projId: this.project.projId,
            })
            if (err || !result) return console.error(result)
            console.log(result)
            result.gerenciandos.forEach(p => {
                p.rank = p.modulos.length ? 0 : -1
                p.modulos.forEach(m => {
                    if (!m || !m.conexao) return
                    if (m.conexao.status === "🔴") p.rank++
                    if (m.conexao.status === "🟡") p.rank += 0.5
                })

                if (!Object.keys(this.store.root).length) return Object.assign(this.store.root, p)

                this.insere(this.store.root, p)
            })
            console.log(this.store.root)
            this.pushProjeto(this.store.root)
        },
        insere(raiz, p) {
            if (p.rank <= raiz.rank) {
                if (raiz.proximo) this.insere(raiz.proximo, p)
                else {
                    raiz.proximo = p
                    p.anterior = raiz
                }
            } else {
                if (!raiz.anterior)
                    this.store.root = p
                else {
                    raiz.anterior.proximo = p
                    p.anterior = raiz.anterior
                }
                p.proximo = raiz
                raiz.anterior = p
            }
        },
        pushProjeto(raiz) {
            this.projetos.push(raiz)
            if (raiz.proximo) this.pushProjeto(raiz.proximo)
        },
        form(projeto) {
            projeto.parent = this.project
            modal.show(new FormStatusModulos(this.$repository, projeto))
        },
        refresh() {
            this.getProjects()
        }
    },
};
</script>
  
<style>
.all-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
}
</style>
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import OrderComponent from "@/Features/Componentes/controllers/order"
import Forms from "@/Features/Componentes/forms/_list"
import ChooseSelect from "../_choose"
import FormElement from "../_element"

export default class FormTela extends FormElement {
    constructor(repository: Repository, model: any) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields = await super.getFields();
        fields.extras = new Extra(this.model.titulo,"Atualizar", "end/updatecomponente/last/back", {}, true, "deletetela/true")
        delete fields.style
        const componentes: any[] = [];
        const add = new ChooseSelect(this.repository, { titulo: "+ Adicionar", root: this.model })
        const [root, err] = await this.repository.findOne("RootComponentes", {
            _id: this.model._id,
            projId: this.model.projId
        })
        if(err) return fields
        if (root)
        root.componentes.forEach((o: any) => {
            componentes.push(new Forms.list[o.tipo](this.repository, o))
        });
        componentes.push(add)
        this.model.elements = componentes
        const order = new OrderComponent(this.repository, this.model)
        fields.componentes = Field.make("objectVL", "Elementos", componentes, {order: async i => {
            await order.setOrder(i)
            return false
        }})
        return fields
    }
}
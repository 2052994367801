import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import SelectComponentes from "./componentes";

export default class SelectTipoComponentes {
    repository: Repository
    model: { titulo: string; root: any }
    constructor(repository: Repository, model: { titulo: string; root: any; act: boolean }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list: any[] = [
            new SelectComponentes(this.repository, { titulo: "Existente", root: this.model.root }) 
            /* new SelectOperadores(this.repository, { titulo: "Operador", macro: this.model.macro }), */
        ]
        return {
            "extras": new Extra("Escolha", "none", ""),
            "modulo": new Field("objectV", "Componente", "", list)
        }

    }
}
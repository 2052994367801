import arrumaData from "@/core/entity/ArrumaData";
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { repeteList, tipos } from "../lib";
import FormCreateDelimitador from "./createDelimitador";
import FormUpdateDelimitadores from "./updateDelimitadores";

export default class FormUpdatePeriodo {
    repository: Repository
    model: any
    sub?: boolean
    update?: () => void
    dia: any
    constructor(repository: Repository, model: any, dia: any, sub?: boolean, update?: () => void) {
        this.model = model
        this.model.titulo = `${model.nome} - ${tipos[model.tipo]}`
        this.repository = repository
        this.sub = sub
        this.update = update
        this.dia = dia
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        this.model.date = arrumaData(this.model.dinicio.date, "yyyy-MM-dd")
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "Atualizar", this.sub ? "end/updateperiodo/true" : "updateperiodo", {}, true, "deleteperiodo/true"),
            "nome": new Field("text", "Nome do evento", "nome"),
            "tipo": new Field("show", "Tipo", "nome"),
            "delimitadores": new Field("duracao", "Duração", "", [
                this.model.dinicio ?
                    new FormUpdateDelimitadores(this.repository, this.model.dinicio) :
                    new FormCreateDelimitador(this.repository, { titulo: "Início", periodo: this.model, tipo: "inicio" })
                ,
                this.model.dfim ?
                    new FormUpdateDelimitadores(this.repository, this.model.dfim) :
                    new FormCreateDelimitador(this.repository, { titulo: "Fim", periodo: this.model, tipo: "fim" })
            ]),
            "date": new Field("date", "Próximo", "nome"),
        }
        if (this.model.tipo === "repete")
            fields["repete"] = new Field("selectrepete", "Repete", "Selecione um tipo", repeteList)
        return fields

    }
}
import FormDisplay from "../display/form/display"
import FormImage from "../display/form/elementos/image"
import FormSquare from "../display/form/elementos/square"
import FormTable from "../display/form/elementos/table"
import FormVideo from "../display/form/elementos/video"
import FormText from "../display/form/elementos/text"
import FormInterruptor from "../interruptor/forms/interruptor"
import SelectTipoComponentes from "../pasta/form/componentes/tipos"
import FormPasta from "../pasta/form/pasta"
import FormStatus from "../status/forms/status"
import FormTitle from "../title/form/title"
import FormComponent from "./component"
import FormCreateComponente from "./createComponente"
import FormMacro from "./macro"
import FormRoot from "./root"
import FormIFrame from "../display/form/elementos/iframe"
import FormCarrossel from "../display/form/elementos/carrossel/carrossel"
import FormTela from "../display/form/elementos/carrossel/tela"
import FormComponentStatus from "../status/forms/componentStatus"
import FormAcesso from "../acesso/form/acesso"
import FormSenhasAcesso from "../acesso/form/senhasacesso"
import FormEventDigitalIn from "../status/forms/eventdigitalin"
import FormAgregador from "../agregador/forms/agregador"
import FormAtividade from "../atividades/forms/update"
import FormCamera from "../camera/form/camera"

export default class Forms {
    static list: { [key: string]: any } = {
        "component": FormComponent,
        "title": FormTitle,
        "pasta": FormPasta,
        "interruptor": FormInterruptor,
        "dimmer": FormComponent,
        "botao": FormComponent,
        "agregador": FormAgregador,
        "audio": FormComponent,
        "root": FormRoot,
        "createcomponente": FormCreateComponente,
        "selecttipo": SelectTipoComponentes,
        "botaoprog": FormMacro,
        "digital-in": FormStatus,
        "eventdigitalin": FormEventDigitalIn,
        "display": FormDisplay,
        "table": FormTable,
        "image": FormImage,
        "square": FormSquare,
        "video": FormVideo,
        "text": FormText,
        "iframe": FormIFrame,
        "carrossel": FormCarrossel,
        "tela": FormTela,
        "status": FormComponentStatus,
        "grafico": FormComponent,
        "acesso": FormAcesso,
        "senhasacesso": FormSenhasAcesso,
        "atividade": FormAtividade,
        "camera": FormCamera
    }
    static check(key: string) {
        return Object.prototype.hasOwnProperty.call(this.list, key)
    }
}
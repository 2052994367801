import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import AddColumn from "./addColumn";

export default class FormColumn extends AddColumn {
    model: any
    constructor(repository: Repository, model: any) {
        super(repository, model)
        this.model = model
        this.model.nome = model.name
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields: {[key: string]: Field | Extra} = {
            "extras": new Extra(this.model.nome, "Atualizar", "end/updatecolumn/last",{}, true, "deletecolumn/true"),
            "key": new Field("show", "Chave", ""),
            "type": new Field("show", "Tipo", ""),
            "name": new Field("text", "Título", "Título da coluna"),
            "size": new Field("text", "Largura da Coluna (%)", "80"),
            "hshow": new Field("toggle", "Mostrar Cabeçalho", ""),
            "nshow": new Field("toggle", "Não mostrar", ""),
        }
        if(this.model.type === "imageselect") fields["src"] = new Field("text", "URL para imagem", "https://example.com/image.png")
        return fields

    }
}
import Repository from "@/core/repository/_repository";
import FormComponent from "../../forms/component";
import Componente from "../../_componente";
import Field from "@/core/entity/Field";
import Extra from "@/core/entity/Extra";
import FormNome from "../../forms/nome";

export default class FormNovaCamera {
    repository: Repository
    model: Componente
    name: string
    constructor(repository: Repository, model: any) {
        this.model = model
        this.name = "+Adicionar"
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra("Nova Câmera", "Criar", "end/createcamera/true", {}, true, "deletecamera/true"),
            "name": new Field("text", "Título", "nome"),
            "url": new Field("text", "URL", "nome"),
            "username": new Field("text", "Username", "nome"),
            "password": new Field("text", "Password", "nome"),
        }
        return fields
    }
}
import Repository from "@/core/repository/_repository";
import Componente from "../../_componente";
import Agregador from "../_agregador";

export default class CustomControl {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, fields: any): Promise<[Componente, boolean]> {
        const agregador = new Agregador();
        agregador.titulo = fields.titulo
        agregador.projId = root.projId
        agregador.projeto = root.projeto
        agregador.main = true
        agregador.options.code = "custom"
        agregador.options.type = "custom"

        const [result, err] = await this.repository.create("Componentes", agregador)
        if (err) console.error(result)
        else Object.assign(agregador, result)

        console.log(agregador)
        return [agregador, false]
    }
}

import Checksum from "@/core/AZ1/checksum";
import Repository from "@/core/repository/_repository";
import StaticModule from "@/service/module/_module";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";

export default class UpdateCamera {
    static action = {
        timeout: 10000,
        text: "Salvando Senha"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form);
        console.log(fields);

        const [result, err] = await this.repository.update("Cameras", form.model._id, {
            projId: form.model.projId,
            name: fields.name,
            url: fields.url,
            username: fields.username ? fields.username : "",
            password: fields.password ? fields.password : ""
        })
        console.log(result, err)
        if (err)
            snackbar.show(result, 3000, "error")
        else snackbar.show("Salvo", 3000, "success")
        return "back"
    }
}
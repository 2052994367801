import Componente from "../_componente";
interface OptionsAgregador {
    titulo: string;
    state: boolean;
    botoes: string[];
    root: string;
    code?: string;
    type?: string;
}
export default class Agregador extends Componente {
    tipo = "agregador"
    options: OptionsAgregador = {
        titulo: "Novo Agregador",
        state: false,
        botoes: [],
        root: ""
    };
    children: string[] = [];
}
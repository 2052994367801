import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormComponent from "@/Features/Componentes/forms/component";
import FormLocal from "@/Features/Componentes/status/forms/local";
import FormRotinasConexao from "@/Features/Programacao/forms/servidor/conexao";

export default class FormConexao {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        if (this.model.porta && typeof this.model.porta === "object")
            this.model.objname = `${this.model.nome}\n${this.model.porta.modulo.nome}`
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        /* Aqui tem que colocar o  tipo da tecla e acao das saidas o Local */
        console.log(this)
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "Atualizar", "end/updateconexao/last"),
        }
        if (this.model.tipo === "saida") {
            fields["min"] = new Field("text", "Mínimo", "0")
            fields["max"] = new Field("text", "Máximo", "100")
        } else if (this.model.tipo === "entradadigital") {
            if (typeof this.model.porta !== "object") {
                const [result, err] = await this.repository.findOne("PortasModulo", {
                    _id: this.model.porta,
                    projId: this.model.projId
                });
                if (!err) this.model.porta = result
            }
            fields["local"] = new Field("object", "Local", "", [new FormLocal(this.repository, this.model)])
            //colocar adicionar evento aqui para o servidor
            fields["servidor"] = new Field("object", "Evento Servidor", "", [new FormRotinasConexao(this.repository, { nome: "Gerenciar", model: this.model })])
        }
        const [result, err] = await this.repository.findMany("Componentes", {
            projId: this.model.projId,
            conexao: this.model._id
        })
        if (err) {
            console.error(result);
            return fields
        }
        const components: FormComponent[] = []
        result.forEach(c => {
            components.push(new FormComponent(this.repository, c))
        })
        fields["components"] = new Field("object", "Componentes", "", components)
        return fields
    }
}
import Checksum from "@/core/AZ1/checksum";
import Module from "@/core/module/_module";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";

export default class Cancel {
    module: Module
    constructor(module: Module) {
        this.module = module
    }
    async execute(component: any) {
        const [modId, e485, , err] = checkModulo(component)
        if (err) snackbar.show(modId, 3000, "error");
        else {
            let pkg = [255, 1, 6, 33, 0, e485, 0, 1, 40]
            if(component.options.action === "asw") pkg = [255, 1, 9, 12, 0, 1, 4, 2, 253, 230, 3, 2]
            await this.module.send(`${component.projId}/${modId}/CMD/CMD`, Checksum.calc(pkg).join(","), {tOut: 300});
        }
    }
}
import Repository from "@/core/repository/_repository";
export default class ConectaModulo {
    static action = {
        timeout: 3000,
        text: "Conectando Módulo"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        Object.assign(form.model, fields)
        const [result, err] = await this.repository.update("Modulos", form.model._id, form.model)
        if (err) console.error(result);
        else console.log(result);

        return "close"
    }
}
import Checksum from "@/core/AZ1/checksum";
import Module from "@/core/module/_module"
import ModulosList from "../_modulo";
export default class GetFeedback {
    app: any
    moduleId: string
    /**
     * 
     * @param app objeto do app Vue
     * @param moduleId juncao do projId e do modId 00000/00
     */
    constructor(app: any, moduleId: string) {
        this.app = app
        this.moduleId = moduleId
    }

    async get(modulo: ModulosList) {
        const module: Module = this.app.config.globalProperties.$module
        modulo.checked = true
        const pkg = [255, 1, 6, 3, 0, modulo.e485, 0, 1, 10]
        //const [, err] = await module.send(`${this.moduleId}/CMD/CMD`, Checksum.calc(pkg).join(","), { query: `CMD/${modulo.e485}/${modulo.tipo === "asw3" ? 13 : 7}` });
        const [, err] = await module.send(`${this.moduleId}/CMD/CMD`, Checksum.calc(pkg).join(","), { query: `any`, e485: modulo.e485.toString() });
        modulo.status = err ? "🔴" : "🟢"
        for (const i in modulo.components) {
            (modulo.components[i].conexao as any).status = modulo.status
        }
    }
}
<template>
  <div class="d-grid gap-mid o-scroll" style="max-height: 55vh">
    <div
      class="item d-grid gap-p p-10"
      v-for="item in field.list"
      v-bind:key="item._id"
      :value="item.tipo"
    >
      <p v-if="item.model.key === 'componente' || item.model.key === 'tipo'">
        {{ item.model.value }}
      </p>
      <div v-else class="d-flex gap-m w-100">
        <p class="d-grid ac-center opt-type">{{ item.model.key }}</p>
        <component
          v-bind:is="item.model.type"
          v-bind:field="item.model"
          v-bind:key="item.model._id"
          v-bind:id="item.model._id"
          v-model="item.model.value"
        ></component>
        <!-- <input :type="item.model.type" v-model="item.model.value" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import range from "./opcoes/range.vue";
import interruptor from "./opcoes/interruptor.vue";
import equa from "./opcoes/equa.vue";
import input from "./opcoes/in.vue";
import onoff from "./opcoes/switch.vue";
import vol from "./opcoes/vol.vue";
import agregador from "./opcoes/agregador.vue";
import text from "./Text.vue";
export default defineComponent({
  components: {
    range,
    interruptor,
    text,
    equa,
    input,
    onoff,
    vol,
    agregador,
  },
  name: "objectV",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
    };
  },
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
}

.opt-type {
  width: 50%;
  text-align: center;
  text-transform: capitalize;
}
</style>
import Repository from "@/core/repository/_repository";

export default class UpdateColumn {
    static action = {
        timeout: 10000,
        text: "Atualizando Coluna"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        const update: {[key: string]: any} = {
            projId: form.model.projId,
            name: fields.name,
            size: fields.size,
            nshow: fields.nshow,
            hshow: fields.hshow,
        }
        if(form.model.inoptions){
            update["options"] = {}
            for (const key in fields) {
                if (!Object.prototype.hasOwnProperty.call(update, key)&& !["key", "type"].find(k => k === key))
                    update["options"][key] = fields[key]
            }
        }
        await this.repository.update("TableColumns", form.model._id, update)
        return "back"
    }
}
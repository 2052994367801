<template>
    <div class="d-grid gap-s ac-center row-two column-fit-l" style="grid-template-columns: 25% 20% 53%;padding-left: 0.5em;">
        <p>Último acesso:</p>
        <strong>{{ arrumaData(components.list[i].options.date, "dd/MM hh:mm") }}</strong>
        <strong style="overflow: hidden;">{{ components.list[i].options.causer }}</strong>
    </div>
</template>

<script>
import { components } from "@/stores/components";
import arrumaData from "@/core/entity/ArrumaData";
export default {
    props: {
        component: Object,
    },
    data() {
        return {
            i: 0,
            components,
            arrumaData,
            date: "--/-- --:--",
            causer: "--"
        };
    },
    async created() {
        this.i = components.list.map((c) => c._id).indexOf(this.component._id);
    },
    methods: {
        screen() {
            console.log("#######");
            console.log(this.component);
            //modalcontrole.show(this.component.control, this.component);
        },
    },
};
</script>

<style>
</style>
import FormCreateModulo from "./CreateModulo"
import FormExtra from "./extra"
import FormGateway from "./gateway"

export default class Forms {
    static list: { [key: string]: any } = {
        "createmodulo": FormCreateModulo,
        "gateway": FormGateway,
        "extra": FormExtra
    }
    static check(key: string) {
        return Object.prototype.hasOwnProperty.call(this.list, key)
    }
}
import FormTituloComponente from "../nomeComponente"

export default class FormsCreate {
    static list: { [key: string]: typeof FormTituloComponente } = {
        "title": FormTituloComponente,
        "pasta": FormTituloComponente,
        "display": FormTituloComponente,
        "column": FormTituloComponente,
    }
    static titulos: { [key: string]: string } = {
        "title": "Título",
        "pasta": "Pasta",
        "display": "Painel",
        "column": "Coluna",
    }
    static check(key: string) {
        return Object.prototype.hasOwnProperty.call(this.list, key)
    }
}
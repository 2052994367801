import Checksum from "@/core/AZ1/checksum";
import Module from "@/core/module/_module";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";

export default class Som {
    module: Module
    constructor(module: Module) {
        this.module = module
    }
    async execute(component: any) {
        console.log(">>>  ASW >>>", component);

        const [modId, e485, , err] = checkModulo(component)
        if (err) snackbar.show(modId, 3000, "error");
        else {
            const pset: { [key: string]: number } = {
                pwr: 255,
                input: 255,
                vol: 255,
                loud: 255,
                bass: 255,
                treble: 255,
                mute: 255
            }
            let query = {query: `CMD/${e485}/13`, tOut: 1000}

            pset[component.options.type] = Number(component.options.state)
            const pkg = [255, 1, 15, 12, 0, 1, 4, 1, 254, pset.pwr, pset.input, pset.vol, pset.loud, pset.bass, pset.treble, pset.mute, 3, 94]

            if(component.options.type === "vol") query = {query: "none", tOut: 30}
            
            const [result, e] = await this.module.send(`${component.projId}/${modId}/CMD/CMD`, Checksum.calc(pkg).join(","), query);
            if(e) snackbar.show(result, 3000, "error")
        }
    }


}
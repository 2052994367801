import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormCreateDelimitadores {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        if(this.model.dia[model.tipo])
            Object.assign(this.model, this.model.dia[model.tipo])
        else this.model.hora = "00:00"
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        console.log(this.model);
        this.model.dia.delimitador = {tipo: this.model.tipo}
        const actions = [
            { name: "Nenhuma", value: "none" },
        ]
        if(this.model.dia[this.model.tipo]){
            this.model.hora = this.model.dia[this.model.tipo].hora
            this.model.action = this.model.dia[this.model.tipo].action
        }
        const fields = {
            "extras": new Extra(this.model.titulo, "Salvar", "end/createdelimitador", {}),
            "hora": new Field("time", "Hora do Início", ""),
            "action": new Field("select", "Programação", "Selecione uma opção", actions),
        }
        const [progs, err] = await this.repository.findMany("Programacoes", { projId: this.model.dia.agenda.dono })
        if (err) {
            console.error(progs);
            return fields
        }
        progs.forEach(p => actions.push({name: p.nome, value: p._id}))
        fields.action = new Field("select", "Programação", "Selecione uma opção", actions)
        return fields
    }
}
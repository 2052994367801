import Repository from "@/core/repository/_repository"

export default class AdicionaModulo {
    static action = {
        timeout: 10000,
        text: "Adicionando Módulo"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }

    async execute(programacao: any, item: any) {
        const programacaomodulo = {
            programacao: programacao._id,
            projId: programacao.projId,
            modulo: item.value,
            titulo: item.name,
            out0: "255",
            out1: "255",
            out2: "255",
            out3: "255",
        }
        return this.repository.create("ProgramacoesModulos", programacaomodulo)
    }
}
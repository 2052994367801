import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import FormStyle from "./_style"

export default class FormPosition extends FormStyle {
    constructor(repository: Repository, model: any) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = await super.getFields("position")
        fields["x"] = new Field("text", "X (de 1 a 18)", "nome")
        fields["y"] = new Field("text", "Y (de 1 a 18)", "nome")
        return fields
    }
}
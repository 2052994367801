import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { modal } from "@/stores/showModal";
import FormChoose from "./choose";
import FormUpdatePeriodo from "./updatePeriodo";
const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
export default class FormDia {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        console.log(this.model);
        const date = new Date(this.model.dia)
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(`${date.getDate()} de ${meses[date.getMonth()]}, ${date.getFullYear()}`, "none", "", {}),
            "button": new Field("button", "Adicionar Agendamento", "nome",[],{},(o)=>{
                console.log(o);
                setTimeout(() => {
                    modal.show(new FormChoose(this.repository, this.model))
                }, 100);
                return true
            }),
            "events": new Field("objectV", "Agendamentos", "", this.model.events.map((e: any) => {
                e.agendalista = {
                    agenda: this.model.agenda,
                    agendamentos: this.model.agendamentos
                }
                return new FormUpdatePeriodo(this.repository, e, this.model, true, this.model.update)
            }))
        }
        return fields

    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import Formstyle from "./_style"

export default class FormFont extends Formstyle {
    constructor(repository: Repository, model: any) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = await super.getFields("font")
        fields["size"] = new Field("text", "Tamanho", "12")
        fields["family"] = new Field("text", "Família", "Arial sans-serif")
        fields["color"] = new Field("text", "Cor", "white")
        fields["align"] = new Field("text", "Alinhamento", "center")
        return fields
    }
}
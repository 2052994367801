import Repository from "@/core/repository/_repository";
import router from "@/router";
import Socket from "@/service/socket";
import { login, snackbar } from "@/stores/login";

export default class TrocarSenha {
    static action = {
        timeout: 3000,
        text: "Criando Usuário"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(fields: any): Promise<any> {
        console.log(fields)
        Object.assign(fields, router.currentRoute.value.query)
        if(fields.newpassword && fields.newpassword.length){
            Socket.io.emit("changepassword", fields)
            const [result, err] = await Socket.io.wait("changepassword")
            if(err) 
                snackbar.show(result, 5000, "error")
            else {
                const msg = `Senha trocada com sucesso`
                snackbar.show(msg, 5000, "success")
                login.callback("login")
                router.replace("/")
            }
        }else snackbar.show("Insira uma senha válida", 3000)
        return "finish"
    }
}
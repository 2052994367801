import Field from "../entity/Field";

export default class AdapterConexaoConfiguracao {
    getFields(): Promise<{ [index: string]: Field }> {
        return new Promise(res => {
            res({
                "nome": new Field("disabled", "Nome", "Nome para Porta")
            })
        })
    }
}
import RepositoryStorage from '../core/repository/Storage';
import { App } from "vue";
import { Socket } from 'socket.io-client';
import RepositorySocket from '@/core/repository/Socket';
import RepositoryIndexedDB from '@/core/repository/IndexedDB';
import IndexedDB from '@/core/repository/IndexedDB/config';
const repositories: { [index: string]: any } = {
    "storage": RepositoryStorage,
    "socket": RepositorySocket,
    "indexedDB": RepositoryIndexedDB
}
export default {
    install: async (app: App, options: { tipo: string; socket: Socket }) => {
        /* aqui vou ter que fazer um controle se caso o indexedDB ainda nao tiver configurado entao tera que usar o socket
            e depois passar a usar o indexedDB */
        console.log(indexedDB)
        //const databases = await indexedDB.databases()
        /* if (databases.find(db => db.name === "Livtech")) {
            app.config.globalProperties.$repositorySocket = new repositories[options.tipo](options.socket)
            app.config.globalProperties.$repository = new repositories["indexedDB"](new IndexedDB("Livtech"))
        } else */
            app.config.globalProperties.$repository = new repositories[options.tipo](options.socket)
    }
}
<template>
  <modal
    :title="title"
    :exclude="exclude"
    @exclude="callExclude"
    @close="$emit('close')"
    :key="chave"
  >
    <div class="d-grid gap space-between">
      <form class="o-scroll d-grid gap-mid" @submit="retorna">
        <div
          class="entrada inteiro gap-low"
          v-for="(field, key) in fields"
          v-bind:key="field.id"
          :class="field.type == 'toggle' ? 'd-flex justify-between' : 'd-grid'"
        >
          <label v-if="field.type != 'snack' && field.type != 'error'&& field.type != 'button'&& field.type != 'hide'" :for="key">{{
            field.label
          }}</label>
          <label v-if="field.type == 'toggle'" class="switch">
            <component
              v-bind:is="field.type"
              v-bind:field="field"
              v-bind:formu="formu"
              v-bind:atribkey="key"
              v-bind:key="path"
              v-bind:id="key"
              v-model="atribs[key]"
              @object="mostraObject"
              @add="mostraAdd"
              @updateAtrib="updateAtrib"
            ></component>
            <span class="slider round"></span>
          </label>
          <component
            v-else
            v-bind:is="field.type"
            v-bind:field="field"
            v-bind:formu="formu"
            v-bind:atribkey="key"
            v-bind:key="path"
            v-bind:id="key"
            v-model="atribs[key]"
            @object="mostraObject"
            @back="back"
            @begin="begin"
            @controller="executeController"
            @updateAtrib="updateAtrib"
            @close="$emit('close')"
            @refresh="refresh"
          ></component>
        </div>
        <button type="submit" style="display: none;">nada</button>
      </form>
      <div class="footer">
        <button type="button" class="cinza" @click="Cancel">
          {{ close }}
        </button>
        <button
          v-if="mostraAction"
          type="submit"
          @click="retorna"
          @submit="retorna"
        >
          {{ action }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
//import { setStorage } from "@/scripts/Storage";
import { defineComponent } from "vue";
import FactoryController from "@/core/controller/_factory";
import Modal from "./Modal.vue";
import select from "@/components/Form/Select.vue";
import selectrange from "@/components/Form/SelectRange.vue";
import selectrepete from "@/components/Form/repete/SelectRepete.vue";
import duracao from "@/components/Form/repete/duracao.vue";
import text from "@/components/Form/Text.vue";
import textarea from "@/components/Form/Textarea.vue";
import object from "@/components/Form/Object.vue";
import objectm from "@/components/Form/ObjectM.vue";
import objectTable from "@/components/Form/ObjectTable.vue";
import objectV from "@/components/Form/ObjectV.vue";
import objectVIMG from "@/components/Form/ObjectVIMG.vue";
import objectVL from "@/components/Form/ObjectVL.vue";
import objectC from "@/components/Form/ObjectC.vue";
import opcoes from "@/components/Form/Opcoes.vue";
import disabled from "@/components/Form/Disabled.vue";
import toggle from "@/components/Form/Toggle.vue";
import add from "@/components/Form/Add.vue";
import action from "@/components/Form/Action.vue";
import show from "@/components/Form/Show.vue";
import button from "@/components/Form/Button.vue";
import number from "@/components/Form/Number.vue";
import snack from "@/components/Form/Snack.vue";
import password from "@/components/Form/Password.vue";
import error from "@/components/Form/Error.vue";
import date from "@/components/Form/Date.vue";
import time from "@/components/Form/Time.vue";
import times from "@/components/Form/Times.vue";
import copy from "@/components/Form/Copy.vue";
import copypre from "@/components/Form/Copypre.vue";
import checkbox from "@/components/Form/Checkbox.vue";
import selectfile from "@/components/Form/SelectFile.vue";
import file from "@/components/Form/File.vue";
import { alert, modal, wait } from "@/stores/showModal";
import { snackbar } from "@/stores/login";
export default defineComponent({
  name: "ModalConfiguracao",
  props: {
    form: Object,
  },
  components: {
    Modal,
    select,
    selectrange,
    selectrepete,
    duracao,
    text,
    textarea,
    object,
    objectm,
    objectTable,
    objectV,
    objectVIMG,
    objectVL,
    disabled,
    toggle,
    add,
    objectC,
    show,
    button,
    action,
    opcoes,
    number,
    snack,
    password,
    error,
    date,
    time,
    times,
    selectfile,
    copy,
    copypre,
    checkbox,
    file
  },
  data() {
    return {
      title: "",
      exclude: false,
      atribs: {},
      fields: {},
      forms: [],
      close: "Cancelar",
      path: 0,
      extras: {},
      action: "Salvar",
      mostraAction: true,
      formu: {},
      oldatribs: null,
      chave: 0
    };
  },
  created() {
    this.formu = modal.form;
    this.next(modal.form);
  },
  methods: {
    async refresh() {
      await this.setFields(this.formu);
      this.chave++
    },
    setExtras() {
      if (this.fields.extras) {
        this.extras = this.fields.extras;
        this.title = this.extras.title;
        this.exclude = this.extras.exclude;
        this.mostraAction = this.extras.action === "none" ? false : true;
        this.action = this.extras.action ? this.extras.action : this.action;
        delete this.fields.extras;
      }
    },
    async setOldFields(form) {
      this.formu = form
      this.fields = await form.getFields();
      this.setExtras();
      this.atribs = form.oldatribs;
    },
    async setFields(form) {
      this.formu = form
      this.fields = await form.getFields();
      this.setExtras();
      this.atribs = {};
      for (const key in this.fields) {
        if (Object.hasOwnProperty.call(form.model, key))
          this.atribs[key] = form.model[key];
        else if (key === "opt") this.atribs[key] = this.fields[key];
      }
    },
    getModel(last){
      if(!isNaN(Number(last))) return this.forms[this.forms.length - ( Number(last) + 1)]
      return this.forms[this.forms.length - 1]
    },
    async retorna(e) {
      e.preventDefault();
      //aqui tem que verificar a lista da forms;
      const length = this.forms.length - 1;
      if (length > 0) {
        const [cmd, controllerstr, last, event] = this.extras.controller.split("/");
        if (cmd === "end") {
          const [controller, err] = FactoryController.get(controllerstr);
          if (err) {
            console.error(controller);
            this.$emit("close");
          } else {
            console.log("######");
            console.log(controller.action);
            wait.show(controller.action.timeout, controller.action.text);
            const form = !last ? modal.form : this.getModel(last)
            const next = await new controller(this.$repository).execute(
              form,
              this.atribs
            );
            wait.show(0);
            if(next === "back" || event === "back") await this.back()
            else if(typeof event === "string" && event.includes("-")) {
              const events = event.split("-")
              if (events[0] === "back" && events.length)
                await this.back({backnumber: Number(events[1])})
            } else if(next === "begin") await this.begin()
            else if(next.split("/")[0] === "err") {
              console.error(next.split("/")[1]);
              snackbar.show(next.split("/")[1], 3000, "error")
            } else {
              this.forms = this.forms.splice(0, 1);
              if (next === "finish") this.$emit("close");
              else if (this.forms[0].oldatribs)
                await this.setOldFields(this.forms[0]);
              else await this.setFields(this.forms[0]);
            }
          }
        } else this.back();
      } else {
        const [controller, err] = FactoryController.get(this.extras.controller);
        if (err) {
          console.error(controller);
          this.$emit("close");
        } else {
          console.log("######");
          console.log(controller);
          wait.show(controller.action.timeout, controller.action.text);
          const result = await new controller(this.$repository).execute(
            modal.form,
            this.atribs
          );
          wait.show(0);
          if(result.startsWith("err")){
            snackbar.show(result.split("/")[1], 5000, "error")
          }else
            this.$emit(result, modal.form);
        }
      }
    },
    async mostraObject(obj) {
      if (obj.model) {
        console.log(this);
        this.close = "Voltar";
        this.forms[this.forms.length - 1].oldatribs = this.atribs;
        await this.next(obj);
      } else {
        this.forms = this.forms.splice(0, 1);
        await this.setFields(this.forms[0]);
      }
    },
    async executeController(controller) {
      console.log(controller);
      console.log(this.form);
      this.$emit("close", await controller.execute(this.form));
    },
    async Cancel() {
      const last = this.forms.length - 1;
      if (last > 0) {
        await this.back();
        if (last === 1) this.close = "Cancelar";
      } else this.$emit("close");
    },
    async next(form) {
      this.forms.push(form);
      await this.setFields(form);
      this.path++;
    },
    async back(element) {
      console.log(element)
      console.log("voltando");
      const backnumber = element && element.backnumber ? element.backnumber : 1
      console.log(this.forms)
      if(backnumber > 1) this.forms = this.forms.slice(0, (backnumber * -1));
      else this.forms.pop();
      console.log(this.forms)
      if (this.forms[this.forms.length - 1].oldatribs)
        await this.setOldFields(this.forms[this.forms.length - 1]);
      else await this.setFields(this.forms[this.forms.length - 1]);
      this.path -= backnumber;
    },
    async begin(){
      this.forms = [this.forms[0]]
      if (this.forms[0].oldatribs)
        await this.setOldFields(this.forms[0]);
      else await this.setFields(this.forms[0]);
      this.path = 0;
    },
    async callExclude() {
      const [controllerstr, last] = this.extras.controllerDelete.split("/");
      const [controller, err] = FactoryController.get(controllerstr);
      if (err) console.error(controller);
      else {
        console.log(controller);
        wait.show(controller.action.timeout, controller.action.text);
        const result = await new controller(this.$repository).execute(
          last ? this.formu : modal.form,
          this.atribs
        );
        wait.show(0);
        if(result === "back") await this.back()
        else this.$emit(result, modal.form);
      }
    },
    updateAtrib(key, value) {
      this.atribs[key] = value;
    },
  },

  unmounted() {
    ////this.sockets.unsubscribe("configuracao");
  },
});
</script>

<style>
.footer {
  display: flex;
  gap: 1em;
}
</style>
import Repository from "@/core/repository/_repository"
import { store } from "@/store"
import { programacoes } from "@/stores/programacao"
import UseCases from "../usecase/_list"

export default class CreateProgramacao {
    static action = {
        timeout: 10000,
        text: "Criando Programação"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        //Tem que acrescentar na lista os outros componentes como interrutor dimmer e passar o tipo no model para usar um usecase generico que só cria o componente
        const [newcomponent, err] = await new UseCases.list[fields.tipo](this.repository).execute(fields)
        if (err) console.error(newcomponent);
        else programacoes.push(newcomponent);

        return "finish"
    }
}
import CreateUsuario from "./createUsuario";
import Login from "./login";
import RecuperarSenha from "./recuperarSenha";
import TrocarSenha from "./trocarSenha";

export default class FactoryController {
    static controllers: { [index: string]: any } = {
        "createusuario": CreateUsuario,
        "login": Login,
        "recuperarsenha": RecuperarSenha,
        "trocarsenha": TrocarSenha,
    }

    static get(controller: string) {
        if (Object.prototype.hasOwnProperty.call(this.controllers, controller))
            return [this.controllers[controller], false]
        else
            return [`Controller ${controller} nao encontrado`, true]
    }
}
<template>
  <input
    v-model="value"
    type="text"
    :placeholder="field.placeholder"
    disabled
  />
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "disabled",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
    };
  },
  created() {
    this.value = this.$attrs.modelValue;
  },
});
</script>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import Modos from "../../entity/modos";

export default class SelectModos {
    repository: Repository
    model: any
    last?: any
    constructor(repository: Repository, model: any, last: any) {
        this.model = model
        if(model.modo)
            this.model.titulo = `${Modos.eventosNomes[model.modo]} - ${model.programacao ? model.programacao.nome : model.nome}`
        else this.model.titulo = model.programacao ? model.programacao.nome : model.nome
        this.repository = repository
        this.last = last
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        console.log(this.last)
        const list: { value: string; name: string }[] = Modos.getEventos(this.last.tipo)
        const fields = this.model.programacao ? {
            "extras": new Extra(this.model.programacao.nome, "Atualizar", "end/updateprogramacaoentrada/true", {}, true, "deleteprogramacaoentrada/true"),
            "modo": new Field("select", "Evento", "Selecione o Evento", list)
        } : {
            "extras": new Extra("Erro na Programação", "none", "", {}, true, "deleteprogramacaoentrada/true"),
            "modo": Field.make("show", "Programação é invalida apague esse evento")
        }
        return fields
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormComponent from "../../forms/component";
import FormServidor from "../../status/forms/servidor";
import Componente from "../../_componente";
export default class FormInterruptor extends FormComponent {
    constructor(repository: Repository, model: Componente) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        this.model.pulsar = this.model.options.pulsar
        this.model.tempo = this.model.options.tempo
        const fields = await super.getFields()
        console.log(fields);
        fields["extras"] = new Extra(this.model.titulo, "Atualizar", "updateinterruptor", {}, true, "deletecomponente")
        fields["servidor"] = new Field("object", "Servidor", "", [new FormServidor(this.repository, { nome: "Configurar Programações", model: this.model })]);
        fields["pulsar"] = new Field("toggle", "Pulsar", "")
        fields["tempo"] = new Field("number", "Tempo", "tempo entre 0,2s e 25,3s", [
            {name: "step",value: ".01"},
            {name: "min", value: "0.2"},
            {name: "max", value: "25.3"},
        ])
        return fields
    }
}
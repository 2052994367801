import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import AdicionarProgramacao from "./adicionarProgramacao";
import SelectModos from "./entradaprogramacao";

export default class FormServidor {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        console.log({
            projId: this.model.model.projId,
            _id: this.model.model.conexao._id,
        });

        const [result, err] = await this.repository.findOne("EntradaProgramacoes", {
            projId: this.model.model.projId,
            _id: this.model.model.conexao._id,
        })
        if (err) console.error(result);
        else console.log(result);


        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "none", ""),
        }
        const add = new AdicionarProgramacao(this.repository, { titulo: "+ Adicionar", model: this.model })
        const operadores: SelectModos[] = []
        if (result)
            result.programacoes.forEach((o: any) => {
                o.componentType = this.model.model.tipo
                operadores.push(new SelectModos(this.repository, o, this.model.model))
            });
        operadores.push(add)
        fields.operadores = new Field("objectV", "Programações", "", operadores, this.model, (o: any) => {
            console.log(o);
            this.model.model.programacaoentrada = o.model
            return true
        })

        return fields
    }
}
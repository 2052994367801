import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormNomeProgramacao from "@/Features/Programacao/forms/nomeProgramacao";
import Componente from "../_componente";
import ConectaProgramacao from "./programacao/conectaProgramacao";

export default class FormMacro {
    repository: Repository
    model: Componente
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        const add = new ConectaProgramacao(this.repository, { nome: "Add", model: this.model })
        const fields = {
            "extras": new Extra(this.model.titulo, "Atualizar", "updatecomponente", {}, true, "deletecomponente/true"),
            "titulo": new Field("text", "Titulo", "nome"),
            "action": new Field("object", "Configurar", "", [add]),
        }
        if (this.model.options.action) {
            add.model.nome = "Mudar"
            const [programacao, err] = await this.repository.findOne("Programacoes", { _id: this.model.options.action, projId: this.model.projId })
            if (err) console.error(programacao);
            else if (programacao)
                fields["action"] = new Field("object", "Configurar", "", [new FormNomeProgramacao(this.repository, programacao), add]);
        }

        return fields
    }
}
<template>
  <div class="d-grid gap" onselectstart="return false">
    <button @click="selectimage">Imagem de Fundo</button>
    <button @click="createComponente">Adicionar Componente</button>
    <div
      class="d-flex a-center gap-m"
      v-for="(component, i) in components"
      :key="component._id"
    >
      <div v-if="i > 0" @click="up(component)" class="pointer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-left-up"><polyline points="14 9 9 4 4 9"/><path d="M20 20h-7a4 4 0 0 1-4-4V4"/></svg></div>
      <div v-if="i === 0" style="width: 30px;"></div>
      <card-title-description :value="component" @mostraModulo="mostraModulo" />
    </div>
  </div>
</template>

<script>
import { modal } from "@/stores/showModal";
import ComponentForms from "@/Features/Componentes/forms/_list";
import AdapterCreateComponente from "@/core/adapter/CreateComponente";
import cardTitleDescription from "@/components/cards/_card.vue";
import { components, componentsMain } from "@/stores/components";
import FormSelectImage from "@/Features/Theme/form/selectimage"
export default {
  components: {
    cardTitleDescription,
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de modulos" },
      dropDown: false,
      configurar: false,
      configurando: false,
      adapter: {},
      configLayer: 0,
      root: {},
      components: [],
      create: AdapterCreateComponente,
      show: ComponentForms,
      collection: "RootComponentes",
      query: {
        projId: this.$route.params.projId,
      },
    };
  },
  async created() {
    components.clear();
    componentsMain.clear();
    this.components = componentsMain.listmain;
    this.header.subtitle = "Gerenciamento de Componentes";
    Object.assign(this.query, { tipo: "root" });
    const [root, err] = await this.$repository.findOne(
      this.collection,
      this.query
    );
    if (err) console.error("Erro ao pegar modulos");
    else if (root && Object.keys(root).length) {
      this.root = root;
      root.componentes.forEach((c) => {
        componentsMain.push(c);
        components.push(c);
      });
    }
  },
  methods: {
    async updateConfiguracao(/* adapter */) {
      this.configurando = false;
      const [result, err] = await this.$repository.findMany(this.collection, {
        projId: this.$route.params.projId,
      });
      if (err) console.error("Erro ao pegar modulos");
      else this.modulos = result;
    },
    mostraModulo(modulo) {
      modulo.sub = true
      modulo.root = this.root
      modal.show(new this.show.list[modulo.tipo](this.$repository, modulo));
    },
    createComponente() {
      modal.show(
        new this.show.list["selecttipo"](this.$repository, {titulo: "+ Adicionar", root: this.root, act: false })
      );
    },
    up(item) {
      console.log(this.root, item);
      const i = this.components.map((i) => i._id).indexOf(item._id);
      console.log(i);
      if (i === 0) return;
      const aux = this.components[i - 1];
      this.components[i - 1] = this.components[i];
      this.components[i] = aux;
      this.components[i - 1].index = i - 1;
      this.components[i].index = i;
      [this.components[i - 1], this.components[i]].forEach(async (i) => {
        const position = {
          index: i.index,
        };
        console.log(position);
        //tenho que fazer um updateQuery para poder atualizar pela query
        const query = {
          projId: this.root.projId,
          root: this.root._id,
          componente: i._id,
        };
        const result = await this.$repository.updateQuery(
          "ComponenteRoot",
          query,
          position
        );
        console.log(result);
      });
    },
    selectimage(){
      modal.show(new FormSelectImage(this.$repository, this.root))
    }
  },
};
</script>
import Checksum from "@/core/AZ1/checksum";
import Module from "@/core/module/_module";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";

export default class Ligar {
    module: Module
    /**
     * Tem que receber um tipo o repository mas modulo que ira escolher como vai chegar no modulo
     */
     constructor(module: Module) {
         this.module = module
     }
    async execute(component: any) {
        console.log(">>>  ligar >>>", component);
        console.log(component.conexao);
        const states: { [key: string]: number } = {
            "1": 255,
            "2": 255,
            "3": 255,
            "4": 255,
        }
        const [modId, e485, canal, err] = checkModulo(component)
        if (err) snackbar.show(modId, 3000, "error");
        else {
            //Aqui tem que adicionar um que quando for menor que 5 é 0 se form do tipo dimmer
            if (component.options.pulsar)
                states[canal] = Number(component.options.tempo.replace(',', '.')) * 10
            else
                states[canal] = Number(component.options.state)
            const pkg = [255, 1, 11, 12, 0, e485, 0, 0, states["4"], states["3"], states["2"], states["1"], 5, 22]
            const [result, err] = await this.module.send(`${component.projId}/${modId}/CMD/CMD`, Checksum.calc(pkg).join(","), {query: `CMD/${e485}/8`});
            if(err) {
                component.options.state = typeof component.options.state === "boolean" ? !component.options.state : 0
                snackbar.show(result, 3000, "error")
            }
            console.log(result, err)
        }
    }
}
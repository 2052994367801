import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import CreateElement from "../../usecase/elements/_element";
import AdicionaElement from "../../usecase/elements/adicionaElement";
import { wait } from "@/stores/showModal";

export default class SelectElementos {
    repository: Repository
    model: any
    constructor(repository: Repository, model: { titulo: string; root: any }) {
        this.model = model
        this.model.act = true
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list: {value: string; name: string}[] = [
            {name: "Tabela", value: "table"},
            {name: "Imagem", value: "image"},
            {name: "Retângulo", value: "square"},
            {name: "Vídeo", value: "video"},
            {name: "Texto", value: "text"},
            {name: "IFrame", value: "iframe"},
            {name: "Carrossel", value: "carrossel"},
        ]
        return {
            "extras": new Extra("Escolha", "none", ""),
            "novo": new Field("objectV", "Elementos", "", list, "", async (item: any) => {
                console.log(item)
                wait.show(5000, "Adicionando Elemento")
                const [c, e] = await new CreateElement(this.repository).execute(this.model.root, item)
                if(e) return false
                await new AdicionaElement(this.repository).execute(this.model.root, c)
                wait.show(0, "")
                //return "begin" 
                return "back/2" /* Aqui tem que mudar para voltar duas vezes */
            })
        }

    }
}
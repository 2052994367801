import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import FormElement from "./_element"

export default class FormImage extends FormElement{
    constructor(repository: Repository, model: any) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields = await super.getFields();
        fields["url"] = new Field("selectfile", "Origem da imagem", "www.exemplo.com.br/imagem.jpg")
        fields["objectfit"] = new Field("select", "Tipo de Redimensionamento", "Selecione", [
            {value: "contain", name: "contain"},
            {value: "cover", name: "cover"},
            {value: "fill", name: "fill"},
            {value: "none", name: "none"},
            {value: "scale-down", name: "scale-down"},
        ])
        return fields
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"

export default class FormSelectTheme {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Escolha um Tema", "none", ""),
            "themes": new Field("objectV", "Temas", "", [
                { value: "light", name: "Claro" },
                { value: "dark", name: "Escuro" }
            ], this.model, (i) => {
                localStorage.setItem("theme", i.value)
                document.documentElement.setAttribute("data-theme", i.value)
                return "close"
            })
        }
    }
}
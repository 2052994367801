import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"

export default class SelectModulos {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = {
            "extras": new Extra(this.model.nome, "Conectar", "end/conectamodulo"),
            "modulo": new Field("select", "Modulos", "Modulos nao encontrados", [])
        }
        const [modulos, err] = await this.repository.findMany("ModulosPortasConexoes", {
            projId: this.model.projId,
            modelo: "gateway"
        })
        if (err) {
            console.error(modulos);
            return fields;
        } else {
            const list: { value: string; name: string }[] = []
            console.log(modulos)
            modulos.forEach(m => {
                let model = false
                m.portas.forEach((p: any) => p.conexoes.forEach((c: any) => model = !model ? c.tipo === "bus485" : model))
                if(model) list.push({
                    value: m._id,
                    name: `${m.nome}`
                })
            })
            fields.modulo = new Field("select", "Modulos", "Selecione Modulo", list)
            return fields
        }

    }
}
import Repository from "./_repository";
export default class RepositoryWRTC implements Repository {
    type = "wrtc"
    channel: RTCDataChannel
    constructor(channel: RTCDataChannel) {
        this.channel = channel
    }
    
    msgId() {
        return new Array(25).join("0").replace(/[018]/g, (c: any) =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(24)
        );
    }

    async send(event: string, msg: any): Promise<[any, boolean]> {
        const err = await this.channel.waitConnection()
        console.log(this.channel)
        if (err && !this.channel.connected)
            return ["erro em esperar a conexao", true]
        this.channel.send(JSON.stringify({ event, msg }))
        return await this.channel.wait(event)
    }

    async create(collection: string, value: any): Promise<[any, boolean]> {
        const event = `db/create/${collection}/${this.msgId()}`
        return await this.send(event, value)
    }

    async read(collection: string, id: string): Promise<[any, boolean]> {
        const event = `db/read/${collection}/${this.msgId()}`
        return await this.send(event, id)
    }

    async update(collection: string, id: string, value: any): Promise<[any, boolean]> {
        const event = `db/update/${collection}/${this.msgId()}`
        return await this.send(event, { id, value })
    }
    async updateQuery(collection: string, query: any, value: any): Promise<[any, boolean]> {
        const event = `db/updatequery/${collection}/${this.msgId()}`
        return await this.send(event, { query, value })
    }
    async delete(collection: string, id: string): Promise<[any, boolean]> {
        const event = `db/delete/${collection}/${this.msgId()}`
        return await this.send(event, id)
    }

    async deleteMany(collection: string, query: any): Promise<[any, boolean]> {
        const event = `db/deletemany/${collection}/${this.msgId()}`
        return await this.send(event, query)
    }

    async deleteList(collection: string, id: string[]): Promise<[any, boolean]> {
        const event = `db/deletelist/${collection}/${this.msgId()}`
        return await this.send(event, id)
    }

    async deleteUsecase(collection: string, object: any): Promise<[any, boolean]> {
        const event = `db/deleteusecase/${collection}/${this.msgId()}`
        return await this.send(event, object)
    }

    async findMany(collection: string, query: { [index: string]: any }): Promise<[any, boolean]> {
        const event = `db/findMany/${collection}/${this.msgId()}`
        return await this.send(event, query)
    }

    async findManySortLimit(collection: string, query: { [index: string]: any; }, limit: number, sort: 1 | -1): Promise<[Array<any>, boolean]> {
        const event = `db/findManySortLimit/${collection}/${this.msgId()}`
        return await this.send(event, {
            query,
            limit,
            sort
        })
    }

    async findOne(collection: string, query: { [index: string]: any }): Promise<[any, boolean]> {
        const event = `db/findOne/${collection}/${this.msgId()}`
        return await this.send(event, query)
    }

    async findUseCase(collection: string, args: any): Promise<[any, boolean]> {
        const event = `db/findUseCase/${collection}/${this.msgId()}`
        return await this.send(event, args)
    }
}
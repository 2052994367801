<template>
    <div class="register-form d-grid gap">
        <div class="form-group d-grid gap-l">
            <div class="d-flex jc-between">
                <label> </label>
                <div class="clear1" onclick="buscarDadosTabela(true)">Buscar dados</div>
            </div>

            <div class="form-input d-grid gap-s">
                <label for="first_name" class="required">Responsável</label>
                <input type="text" name="first_name" id="first_name" autocomplete="off" />
            </div>

            <!-- <%- include('./atividades/inputProjeto.ejs'); %> -->
            <input-projeto :global="global" :projetos="['Projeto1', 'Projeto2']" @changeInput="changeInput"
                @setCallback="setCallback"></input-projeto>

            <!-- <%- include('./atividades/inputAmbiente.ejs'); %> -->
            <input-ambiente :global="global" :formulario="formulario" :key="formulario.projeto"></input-ambiente>

            <!-- <%- include('./atividades/inputSegmento.ejs'); %> -->
            <input-segmento :global="global" :key="formulario.projeto" @changeInput="changeInput"
                @setCallback="setCallback"></input-segmento>

            <!-- <%- include('./atividades/inputData.ejs'); %> -->
            <input-data></input-data>

            <!-- <%- include('./atividades/inputAtividade.ejs'); %> -->
            <input-atividade :global="global" :formulario="formulario" :key="formulario.segmento" @changeInput="changeInput"
                @setCallback="setCallback"></input-atividade>

            <!-- <%- include('./atividades/inputAcoes.ejs'); %> -->
            <input-acoes :global="global" :formulario="formulario" :key="formulario.acoes"></input-acoes>

            <!-- <%- include('./atividades/inputMaterial.ejs'); %> -->
            <input-material></input-material>

        </div>
        <div class="form-submit">
            <!-- <input type="submit" value="Reset" class="submit" id="reset" name="reset" /> -->
            <button type="button" class="submit" id="submit" name="submit">Enviar</button>
        </div>
    </div>
</template>
  
<script>
import InputAcoes from "../inputs/inputAcoes.vue"
import InputAmbiente from "../inputs/inputAmbiente.vue"
import InputAtividade from "../inputs/inputAtividade.vue"
import InputData from "../inputs/inputData.vue"
import InputMaterial from "../inputs/inputMaterial.vue"
import InputProjeto from "../inputs/inputProjeto.vue"
import InputSegmento from "../inputs/inputSegmento.vue"
export default {
    components: {
        InputAcoes,
        InputAmbiente,
        InputAtividade,
        InputData,
        InputMaterial,
        InputProjeto,
        InputSegmento,
    },
    data() {
        return {
            data: "",
            formulario: {},
            global: {
                regs: [],
                regs_ok: 0,
                //dados gerais dos projetos
                data: {},
                //callback relacionados aos dados gerais dos projetos
                dataRefresh_callbacks: [],
                //lista de peojetos
                projectsList: [],
                //callback refresh (projeto mudou)
                projChange_callbacks: [],
                //dados legenda
                objLegenda: {},
                //callback relacionados aos dados da legenda
                legendaRefresh_callbacks: [],
                //callback refresh (segmento mudou)
                segmentoChange_callbacks: [],
                //lista atual de acoes
                //acoesAtuais: {}
            },
        };
    },
    async created() {
        console.log("criou componente")
        //verifica se tem dados ja salvos no localStorage
        if (localStorage.data) {
            //se tiver, então carrega
            this.global.data = JSON.parse(localStorage.data);
            //EXE
            this.mainData_load()
        } else
            this.buscarDadosTabela(true)

        this.buscarDadosLegenda(true)
    },
    methods: {
        envia() {
            console.log("enviando formulario")
        },
        changeInput(key, value) {
            console.log("no formulario", key, value)
            this.formulario[key] = value
            //this.projeto = alteraca
        },
        setCallback(listName, callback) {
            this.global[listName].push(callback)
        },
        mainData_load() {
            //EXE
            //executa todas as funcoes associadas a atualizacao de dados
            if (this.global.dataRefresh_callbacks) {
                const max = this.global.dataRefresh_callbacks.length
                for (let i = 0; i < max; i++) {
                    const func = this.global.dataRefresh_callbacks[i]
                    if (typeof func == "function") {
                        func()
                    }
                }
            }
        },
        async proj_list_load() {
            //console.log("wait",0)
            let tryAgain = 0
            while (this.global.regs_ok == 0 && tryAgain < 50) {
                tryAgain++;
                await new Promise(resolve => setTimeout(resolve, 200));
                //console.log("wait",tryAgain)
            }
            if (this.global.regs_ok) {
                console.log("mat")
            }
        },
        async buscarDadosTabela(showLoading) {
            if (showLoading) {
                //document.getElementById("modal_loading_msg").innerHTML = "Aguardando dados projetos..."
                //document.getElementById("box_dark").style.display = "flex"
            }
            this.proj_list_load()
            const data = await this.httpRequest("https://script.google.com/macros/s/AKfycbxXbF36gtXYz7tOss0rj-v_-ogqlaLl20WMCdpFCdpIq48qx-OtG5R4RUdBNZn0MV6y/exec?cmd=get_regData");
            
            if (!data || !data.status) return

            this.global.regs = data.legData;
            if (typeof this.global.regs != "undefined") {
                this.global.regs_ok = 1;
                this.resumeProjetos(this.global.regs)
            } else {
                console.log("erro ao buscar regs")
            }
        },
        resumeProjetos(data) {
            //#out projCat,ambCat,recurso,proj,amb
            //data [proj,amb,rec]
            const COL_OFFSET = 28;
            const COL_PROJ_I = 0 + COL_OFFSET;
            const COL_AMB_I = 1 + COL_OFFSET;
            const COL_REC_I = 3 + COL_OFFSET;
            const COL_TAG_I = 17 + COL_OFFSET;

            const out = {};
            out[""] = {}
            //projeto.ambiente.recurso
            let projTag = ""
            let ambTag = ""
            let recursoTag = ""
            let curProj = ""
            let curAmb = ""
            let recurso = ""
            let tag = ""


            for (let i = 5; i < data.length; i++) {
                projTag = ""
                ambTag = ""
                recursoTag = ""
                recurso = ""
                tag = ""

                //novo projeto
                if (data[i][COL_PROJ_I] != "") {
                    projTag = data[i][COL_PROJ_I]
                    curProj = data[i][COL_PROJ_I]
                    curAmb = ""
                    out[curProj] = {}
                }

                //novo ambiente
                if (data[i][COL_AMB_I] != "") {
                    curAmb = data[i][COL_AMB_I]
                    ambTag = curAmb
                    out[curProj][curAmb] = {}
                }
                //novo recurso + tag
                if (data[i][COL_REC_I] != "") {
                    recurso = data[i][COL_REC_I]
                    recursoTag = recurso
                    tag = data[i][COL_TAG_I]
                    if (tag != "") recursoTag += " - " + tag
                    //out[curProj][curAmb][recursoTag] = {}
                }


            }

            this.global.data = out;

            if (!localStorage.data) {
                localStorage.data = JSON.stringify(this.global.data)
            }

            //EXE
            this.mainData_load()

            //console.log(JSON.stringify(global.data))
        },
        legendaData_load() {
            //EXE
            //executa todas as funcoes associadas a atualizacao de dados da legenda
            if (this.global.legendaRefresh_callbacks) {
                this.global.legendaRefresh_callbacks.forEach((func) => {
                    if (typeof func == "function")
                        func()
                })
            }
        },
        async buscarDadosLegenda(showLoading) {
            if (showLoading) {
                //document.getElementById("modal_loading_msg").innerHTML = "Aguardando dados legenda..."
                //document.getElementById("box_dark").style.display = "flex"            
            }
            //proj_list_load()
            const data = await this.httpRequest("https://script.google.com/macros/s/AKfycbxXbF36gtXYz7tOss0rj-v_-ogqlaLl20WMCdpFCdpIq48qx-OtG5R4RUdBNZn0MV6y/exec?cmd=get_legData");

            if (!data || !data.status) return

            const dadosLegenda = data.legData;
            if (typeof dadosLegenda != "undefined") {
                //console.log(JSON.stringify(dadosLegenda))
                this.resumeLegenda(dadosLegenda)
            } else {
                console.log("erro ao buscar regs")
            }

        },
        resumeLegenda(data) {
            //data ["",cat,ativ,acao]
            const COL_SEG_I = 1
            const COL_ATIV_I = 2
            const COL_ACAO_I = 3

            const out = {}
            out[""] = {}
            //segmento.atividade.acao
            let c_segmento = ""
            let c_atividade = ""
            let c_acao = ""
            let curSegmento = ""
            let curAtividade = ""

            for (let i = 2; i < data.length; i++) {
                c_segmento = ""
                c_atividade = ""
                c_acao = ""

                //nova segmento
                if (data[i][COL_SEG_I] != "") {
                    c_segmento = data[i][COL_SEG_I]
                    curSegmento = data[i][COL_SEG_I]
                    curAtividade = ""
                    out[curSegmento] = {}
                }

                //nova atividade
                if (data[i][COL_ATIV_I] != "") {
                    curAtividade = data[i][COL_ATIV_I]
                    c_atividade = curAtividade
                    out[curSegmento][curAtividade] = {}
                }
                //nova acao
                if (data[i][COL_ACAO_I] != "") {
                    c_acao = data[i][COL_ACAO_I]
                    out[curSegmento][curAtividade][c_acao] = {}
                }


            }

            this.global.objLegenda = out;
            console.log(out)
            if (!localStorage.leg) {
                localStorage.leg = JSON.stringify(out)
            }

            //EXE
            this.legendaData_load()

            //console.log(JSON.stringify(global_objLegenda))
        },
        httpRequest(url) {
            return new Promise(res => {
                const request = new XMLHttpRequest();
                request.onreadystatechange = function () {
                    if (this.readyState === 4) {
                        if (this.status === 200) {
                            try {
                                res(JSON.parse(this.responseText))
                            } catch (e) {
                                res(false)
                            }
                        }
                    }
                };
                request.open("GET", url, true);
                request.send(null);
            })
        }
    },
};
</script>

<style></style>
<template>
  <modal
    :title="title"
    :exclude="exclude"
    :class="{ wait: true }"
    @exclude="callExclude"
    @close="$emit('close')"
  >
  </modal>
</template>

<script>
//import { setStorage } from "@/scripts/Storage";
import { wait } from "@/stores/showModal";
import { defineComponent } from "vue";
import Modal from "./Modal.vue";

export default defineComponent({
  name: "ModalConfiguracao",
  props: {
    form: Object,
  },
  components: {
    Modal,
  },
  data() {
    return {
      title: "",
      msg: wait.msg,
      count: 0,
      animation: {},
    };
  },
  created() {
    wait.component = this
    wait.timeout = setTimeout(() => {
      wait.callback()
      wait.show(0)
    }, wait.timeout);
    this.start();
  },
  methods: {
    start() {
      this.title = `${this.msg}${".".repeat(this.count)}`;
      if (this.count < 3) this.count++;
      else this.count = 0;
      this.animation = setTimeout(() => {
        this.start();
      }, 700);
    },
    stop() {
      clearTimeout(this.animation);
      this.title = this.msg;
    },
  },
  unmounted() {
    this.stop();
  },
});
</script>

<style>
.wait {
  background-color: #000000d1 !important;
}
.wait .modal {
  min-height: inherit;
  bottom: inherit;
  border-radius: 0.7em;
}
</style>
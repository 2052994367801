<template>
  <div class="card d-grid gap-m ac-start row-13 column-full">
    <h1>{{ component.titulo }}</h1>
    <div class="o-auto">
      <table class="w-100">
        <thead>
          <tr>
            <th>Data</th>
            <th>Status</th>
            <th>Categoria</th>
            <th>Cliente</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="chamado in chamados" v-bind:key="chamado._id">
            <td>{{ getData(new Date(chamado.data)) }}</td>
            <td>{{ getStatus(chamado.status) }}</td>
            <td>{{ getCategoria(chamado.categoria) }}</td>
            <td>{{ chamado.cliente }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import DateToMask from "@/plugins/datetomask";
export default {
  props: {
    component: Object,
  },
  data() {
    return {
      chamados: [],
    };
  },
  async created() {
    const [chs] = await this.$repository.findMany("Chamados", {
      revenda: "6127ec188636ab5b087f5cfb",
    });
    console.error(chs);
    const [chamados, err] = await this.$repository.findMany("Chamados", {
      revenda: this.component.projeto,
    });
    if (err) console.error(chamados);
    else {
      this.chamados = chamados.reverse();
      console.log(chamados);
    }
    this.$socket.on("Chamados", (c) => console.log(c));
  },
  methods: {
    getStatus(status) {
      const statuslist = [
        { value: "0", name: "Pendente" },
        { value: "1", name: "Concluído" },
        { value: "2", name: "Analisando" },
        { value: "3", name: "Entrando em Contato" },
      ];
      return statuslist.find((s) => s.value === status).name;
    },
    getCategoria(categoria) {
      const categorias = [
        { value: "0", name: "Outros" },
        { value: "1", name: "Central Alarme" },
        { value: "2", name: "Câmera" },
      ];
      return categorias.find((c) => c.value === categoria).name;
    },
    getData(data) {
      return DateToMask.convert(data, "default");
    },
  },
};
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border-radius: 0.5em;
  background-color: var(--color-primary);
}
thead tr {
  background-color: var(--color-primary);
  border-radius: 0.5em;
}
th {
  background-color: var(--color-primary);
  color: white;
  padding: 10px;
  border-radius: 0.5em;
  font-size: 16px;
}
tbody {
  overflow-y: scroll;
  background-color: white;
}
tr:nth-child(even) {
  background-color: var(--color-primary-fade);
  color: var(--color-primary-contrast);
}
td {
  padding: 10px 0;
  font-size: 14px;
}
</style>
//import { Socket } from "socket.io-client";
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
//import CreateUsuario from "../usecase/CreateUsuario";

export default class AdapterChangePassword {
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Troca de Senha", "Trocar", "trocarsenha", { text: "Lembrou sua senha? ", extra: { type: "login", text: "Entre" } }),
            "newpassword": new Field("text", "Nova Senha", ""),
            "error": new Field("error", "Erro", "Senha"),
        }
    }
}
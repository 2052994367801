import Repository from "@/core/repository/_repository";
export default class CreateProjeto {
    static action = {
        timeout: 10000,
        text: "Criando Projeto"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        fields.revenda = form.model.revenda
        const [result, err] = await this.repository.create("CreateProjetos", fields)
        console.log(result, err);
        form.model.lista.push(result)
        return "close"
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import StaticModule from "@/service/module/_module";
import { wait } from "@/stores/showModal";
import Modulo from "../domain/entity/Modulo";

export default class FormStatusModulo {
    repository: Repository
    model: { modulo: Modulo, objname: string }
    constructor(repository: Repository, model: { modulo: Modulo }) {
        this.model = {
            modulo: model.modulo,
            objname: "Buscar"
        }
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        wait.show(5000, "Buscando dados do módulo")
        const [result, err] = await StaticModule.io.send(`${this.model.modulo.projId}/${this.model.modulo.modId}/CMD/PRJ_ST`, "{}", { query: "PRJ_ST/FB", tOut: 5000 });
        if(err) return {
            "extras": new Extra(this.model.modulo.nome, "none", ""),
            "err": Field.make("show", "Erro ao buscar dados"),
        }
        wait.show(0, "")
        const status = JSON.parse((result as string).replace('"hw"', '"hwm"').replace('"hw"', '"fw"'));
        const map: { [key: string]: { [key: string]: string } } = {
            "bus": { "0": "Inativo", "1": "Inativo" },
            "mqtt": { "0": "Inativo", "1": "Ativo" },
            "lan_md": { "0": "Wi-Fi", "1": "Cabo" },
            "bus_md": { "0": "Desativado", "1": "Ativado" },
            "snmp": { "-1": "Desativado", "0": "Ativado" },
            "cid_1": { "-1": "Desconhecido", "0": "Desativado", "1": "Ativado" },
            "fx": { "0": "Não", "1": "Sim" },
            "ntp": { "-1": "Desativado", "0": "Ativado" }
        }
        for (const key in map) {
            if (Object.prototype.hasOwnProperty.call(status, key)) {
                status[key] = map[key][status[key].toString()]
            }
        }
        Object.assign(this.model, status)
        return {
            "extras": new Extra(this.model.modulo.nome, "none", ""),
            "hwm": Field.make("show", "Modelo do Hardware"),
            "fw": Field.make("show", "Versão do Firmware"),
            "fx": Field.make("show", "IP Fixo"),
            "ip": Field.make("copy", "Endereço IP"),
            "sn": Field.make("copy", "Máscara de Rede"),
            "gw": Field.make("copy", "Endereço Gateway"),
            "dns": Field.make("copy", "Endereço Servidor DNS"),
            "bus": Field.make("show", "Status Comunicação RS485"),
            "mqtt": Field.make("show", "Status comunicação MQTT"),
            "lan_md": Field.make("show", "Modo de Funcionamento da Lan"),
            "bus_md": Field.make("show", "Modo de Funcionamento da Comunicação 485"),
            "snmp": Field.make("show", "SNMP"),
            "cid_1": Field.make("show", "Status conexão Contact ID"),
            "ntp": Field.make("show", "Status Comunicação NTP"),
        }
    }
}
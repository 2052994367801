import arrumaData from "@/core/entity/ArrumaData"
import { intervalo, repete, tipos } from "../lib"

export default class AdapterPeriodoTitleDescription {
    adapt(periodo: any){
        const adapted = {
            title: periodo.nome,
            description: periodo.tipo
        }
        if(periodo.tipo === "unico"){
            adapted.description = `${tipos[periodo.tipo]}: das ${arrumaData(periodo.dinicio.date)} às ${arrumaData(periodo.dfim.date)}`
            return adapted
        }

        if(periodo.repete === "semanal"){
            const week = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
            const days = periodo.dias.map((d: number) => week[d])
            adapted.description = `${tipos[periodo.tipo]}: ${repete[periodo.repete]} de ${days.join(", ")}`
            return adapted
        }

        if(periodo.repete === "intervalo"){
            const [key, value] = periodo.intervalo ? periodo.intervalo.split("/"): ["erro", "erro"]
            adapted.description = `${tipos[periodo.tipo]}: ${repete[periodo.repete]} de ${value} ${intervalo[key]}`
            return adapted
        }

        adapted.description = `${tipos[periodo.tipo]}: ${repete[periodo.repete]} às ${arrumaData(periodo.dinicio.date)}`
        return adapted
    }

}
import Camera from "./connection"

export default class CameraListeners {
    static list: { [key: string]: Array<(data: any) => void> } = {}
    static cameraid = ''
    static recever(ev: MessageEvent<any>) {
        if (typeof ev.data === "string")
            console.log(ev.data)
        else {
            if (!ev.data.byteLength) return
            const buffer = new Uint8Array(ev.data)
            let headercount = 0
            let protocount = 0
            while (protocount < 3 && headercount < ev.data.byteLength) {
                if (buffer[headercount] === 0) protocount++
                headercount++
            }
            const header = new TextDecoder().decode((ev.data as ArrayBuffer).slice(0, headercount - 3))
            if (Object.prototype.hasOwnProperty.call(this.list, header))
                this.list[this.cameraid].forEach(callback => {
                    callback(ev.data.slice(headercount))
                });
        }

    }
    static async push(projId: string, cameraId: string, callback: (data: any) => void) {
        this.cameraid = cameraId
        if (!Camera.channel || Camera.channel.readyState === "closed"){
            await Camera.start(`camera${projId}`, this.recever.bind(this))
        }
        if (Object.prototype.hasOwnProperty.call(this.list, cameraId))
            this.list[cameraId].push(callback)
        else {
            await Camera.waitConnection()
            Camera.channel.send(JSON.stringify({ event: "subscribeStream", msg: { camera: cameraId } }))
            this.list[cameraId] = [callback]
        }
    }
    
    static async remove(cameraId: string) {
        console.log("removendo")
        //delete this.list[cameraId]
        Camera.channel.send(JSON.stringify({ event: "unsubscribeStream", msg: { camera: cameraId } }))
    }
}
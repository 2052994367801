export default class Componente {
    [x: string]: any
    _id = ""
    projId = ""
    tipo = ""
    titulo = ""
    options: any = {}
    projeto = ""
    conexao: object | string = ""
    children?: string[]
    main = false
    /* static sort(a: Componente, b: Componente) {
        if (a.index > b.index) return 1;
        if (a.index < b.index) return -1;
        return 0;
    } */
}
import { App } from "vue"
import { Default } from "./command"
import { List, States } from "./list"

export default class State {
    static state: Default = new List.states["start"]()
    static tecnico?: { revenda: any, backto: string } = undefined
    static app: App
    static setState(state: keyof States) {
        if (this.state.name !== (state as string)) {
            this.state = new List.states[state]()
            this.state.setup(this.app)
        }
    }
}
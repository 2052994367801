<template>
	<app-layout :header="header" @dropDown="mostraDropDown" @page="setPage">
		<main class="d-grid gap" onselectstart="return false">
			<card-title-description v-for="servidor in servidores" :key="servidor._id" :value="servidor"
				@mostraModulo="mostraServidor" />
		</main>
	</app-layout>
</template>
  
<script>
import appLayout from "../layouts/appLayout.vue";
import cardTitleDescription from "@/components/cards/_card.vue";
import { getStorage, setStorage } from "@/scripts/Storage";
import RepositoryStorage from "@/core/repository/Storage";
import FormUpdateOfflineServidorLocal from "@/Features/SevidorLocal/form/offline";
import { modal } from "@/stores/showModal";
const storageRepository = new RepositoryStorage();
export default {
	name: "page-conta",
	components: {
		appLayout,
		cardTitleDescription
	},
	computed: {
		styleDropDown() {
			return "top: " + this.topDropDown + "px";
		},
	},
	data: () => {
		return {
			header: { title: "", subtitle: "Servidores Locais", labels: [] },
			servidores: [],
			dropDown: false,
			mudaConexao: false,
			topDropDown: 0,
			tentativas: 0,
			adapter: {},
			mostraform: false
		};
	},
	async created() {
		console.log("\n\n#### entrou no servidor local");
		this.header.labels = [
			{ name: "Hall", path: "hall", selected: false },
			{ name: "Conta", path: "conta", selected: false },
			{ name: "Servidor Local", path: "servidorlocal", selected: true },
		];
		document.title = "Servidores Locais";
		this.header.title = (await getStorage("nomeUsuario")).value;

		const [servidores, err] = await storageRepository.findMany("ServidoresLocais", {})
		if (!err)
			this.servidores = servidores
	},
	sockets: {
		pronto() {
			this.$store.commit("setSocketReady", true);
			console.log("POSSO MANDAR MENSAGEM HALL");
		},
	},
	methods: {
		async mostraServidor(servidor) {
			console.log(servidor)
			modal.show(new FormUpdateOfflineServidorLocal(storageRepository, servidor));
		},
	},
	unmounted() {
		console.log("saiu do monitoramento");
	},
};
</script>

<style>
.conta form.gap-mid {
	gap: 1em
}
</style>
import Repository from "@/core/repository/_repository"
import Socket from "@/service/socket"

export default class DeleteWallpaper {
    static action = {
        timeout: 10000,
        text: "Criando Wallpaper"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form, fields)
        const [result, err] = await this.repository.delete("Wallpapers", form.model._id)
        console.log(result, err)
        Socket.io.emit("deletefile", form.model)
        return "close"
    }
}
import Repository from "@/core/repository/_repository";
import FormComponent from "../../forms/component";
import Componente from "../../_componente";
import Field from "@/core/entity/Field";
import Extra from "@/core/entity/Extra";
import FormNome from "../../forms/nome";
import FormCameras from "./cameras";

export default class FormCamera {
    repository: Repository
    model: Componente
    constructor(repository: Repository, model: Componente) {
        this.model = model
        this.model.objname = `${this.model.titulo}\n${this.model.tipo}`
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.titulo, "Atualizar", "updatecomponente", {}, true, "deletecomponente/true"),
            "titulo": new Field("text", "Título", "nome"),
        }
        /* PARA MOSTRAR CAMERAS BASTA INSERIR O ID O SERVIDOR LOCAL NO PROJETO */
        /* TODO:
            [] - tem que fazer no menu dos projetos uma forma de adicionar camera
            [] - forma para quando ja esta no projeto com servidor local, conectar com o servidor da camera
        */
        const [result, err] = await this.repository.findUseCase("Cameras", { projId: this.model.projId })
        console.log(result, err)
        if(err) {
            fields["erro"] = Field.make("show", "Erro: servidor inoperante")
            return fields
        }
        const list: { value: string, name: string }[] = []
        result.forEach((cam: any) => {
            list.push({ value: cam._id, name: cam.name })
        });
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)

        fields["camera"] = Field.make("select", "Camera", "Selecinar", list)
        fields["cameras"] = Field.make("object", "Cameras", [new FormCameras(this.repository, { nome: "Gerenciar", projId: this.model.projId })])
        return fields
    }
}
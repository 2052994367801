<template>
 
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   width="32"
   height="32"
   viewBox="0 0 8.4666665 8.4666669"
   version="1.1"
   id="svg5029"
   inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
   sodipodi:docname="volDown.svg">
  <defs
     id="defs5023" />
  <sodipodi:namedview
     id="base"
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1.0"
     inkscape:pageopacity="0.0"
     inkscape:pageshadow="2"
     inkscape:zoom="15.999999"
     inkscape:cx="7.7221033"
     inkscape:cy="14.303059"
     inkscape:document-units="mm"
     inkscape:current-layer="layer1"
     showgrid="false"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     inkscape:window-x="-8"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     units="px"
     inkscape:showpageshadow="true"
     showguides="true"
     inkscape:guide-bbox="true">
    <sodipodi:guide
       position="0,0"
       orientation="0,1"
       id="guide830"
       inkscape:locked="false"
       inkscape:label=""
       inkscape:color="rgb(0,0,255)" />
    <sodipodi:guide
       position="0,0"
       orientation="1,0"
       id="guide832"
       inkscape:locked="false"
       inkscape:label=""
       inkscape:color="rgb(0,0,255)" />
  </sodipodi:namedview>
  <metadata
     id="metadata5026">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
     inkscape:label="Camada 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(0,-288.53332)">
    <rect
       style="fill:none;fill-opacity:1;stroke:none;stroke-width:0.47369584;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
       id="rect828"
       width="8.4666662"
       height="8.4666662"
       x="-1.0842022e-19"
       y="288.53333" />
    <g
       id="g1380"
       transform="matrix(0.14499945,0,0,0.14499945,-23.486013,59.769492)">
      <path
         id="path1483-2"
         d="m 195.79297,1581.1094 c -0.13456,0.013 -0.269,0.039 -0.40039,0.078 -0.45056,0.1351 -2.56955,1.885 -8.62696,7.123 l -8.02929,6.9434 h -3.50391 c -4.92763,0 -7.64755,0.054 -7.92383,0.1582 -0.76143,0.2871 -1.36637,1.0182 -1.54101,1.8594 -0.10658,0.5164 -0.14579,3.8463 -0.11719,10.0254 0.0475,10.2725 1.4e-4,9.8009 1.04883,10.5488 l 0.48828,0.3477 7.63086,0.043 3.62891,0.041 8.1914,7.084 c 6.12528,5.2982 8.32548,7.1212 8.73242,7.2246 v 0 c 1.09698,0.2803 2.22361,-0.2053 2.7168,-1.1719 0.24711,-0.4841 0.27494,-3.6701 0.28125,-18.4316 h -2.27734 c -3.3592,0 -5.03907,-2.0336 -5.03907,-6.0996 0,-4.0664 1.67987,-6.0997 5.03907,-6.0997 h 2.27734 c -0.009,-13.805 -0.0426,-17.9283 -0.23047,-18.3242 -0.42602,-0.8977 -1.40377,-1.4377 -2.3457,-1.3476 z"
         style="fill:#000000;stroke-width:0.1494478"
         inkscape:connector-curvature="0" />
      <g
         transform="matrix(1.3683356,0,0,1.3683356,-209.80507,-593.23458)"
         id="text1446-2"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:68.44878387px;line-height:1.25;font-family:Arial;-inkscape-font-specification:Arial;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:4.27804899"
         aria-label="-">
        <path
           sodipodi:nodetypes="sssssss"
           inkscape:connector-curvature="0"
           id="path1518"
           style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:ISOCPEUR;-inkscape-font-specification:'ISOCPEUR Bold';stroke-width:4.27804899"
           d="m 309.48739,1610.0833 h -11.80043 c -1.47058,0 -2.20587,-0.7353 -2.20587,-2.2058 0,-1.4706 0.73529,-2.2059 2.20587,-2.2059 h 11.80043 c 1.47058,0 2.20587,0.7353 2.20587,2.2059 0,1.4705 -0.73529,2.2058 -2.20587,2.2058 z" />
      </g>
    </g>
  </g>
</svg>



</template>

<script>
export default {
  name: "alert",
};
</script>
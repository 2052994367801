<template>
    <p :style="{'padding': '10px', 'grid-area': gridarea, 'font-size': fontsize, 'font-family': fontfamily, 'color': fontcolor, 'text-align': textalign }">{{text}}</p>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
    name: "appLayout",
    components: {
    },
    props: {
        element: Object,
    },
    data() {
        return {
            gridarea: "",
            text: "",
            fontsize: "",
            fontfamily: "",
            fontcolor: "",
            textalign: "",
        };
    },
    async created() {
        console.log(this.element)
        this.color = this.element.options.color
        const [style, err] = await this.$repository.findOne("ElementsStyle", {
            projId: this.element.projId,
            element: this.element._id
        })
        if (err) console.error(style);
        console.log(style)
        const p = style.position
        const s = style.size
        const f = style.font
        this.fontsize = f ? `${f.size}` : "inherit"
        this.fontfamily = f ? `${f.family}` : "inherit"
        this.fontcolor = f ? `${f.color}` : "inherit"
        this.textalign = f ? `${f.align}` : "inherit"
        this.gridarea = `${p.y}/${p.x}/span ${s.h}/span ${s.w}`
        this.text = this.element.options.text
    },
    methods: {
        fullscreen(e) {
            console.log(e)
            document.documentElement.requestFullscreen()
                .catch(err => console.error(err))
                .then(e => console.log(e))
        }
    },
});
</script>

<style>
.display img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
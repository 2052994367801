import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import Componente from "../_componente";
const adapter: { [key: string]: string } = {
    _id: "string",
    projId: "string",
    projeto: "string",
    tipo: "string",
    options: "object",
    titulo: "string",
    children: "object",
    index: "number",
    root: "string",
    componente: "string",
    main: "boolean",
    nshow: "boolean",
    control: "string",
    painel: "string",
    action: "string",
    func: "string"
}
export default class UpdateComponente {
    static action = {
        timeout: 3000,
        text: "Atualizando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        delete fields.conexao
        const conexao = form.model.conexao
        if (form.model.inoptions) {
            for (const key in fields) {
                if (Object.prototype.hasOwnProperty.call(new Componente, key))
                    form.model[key] = fields[key]
                else if (key === "icon")
                    form.model.options[key].icon = fields[key]
                else form.model.options[key] = fields[key]
            }
        } else
            Object.assign(form.model, fields)
        const update: { [key: string]: any } = {}
        for (const key in adapter) {
            if (Object.prototype.hasOwnProperty.call(form.model, key) && typeof form.model[key] === adapter[key])
                update[key] = form.model[key]
        }
        form.model.conexao = conexao
        if (form.model.painel && form.model.parent) {
            const i = (form.model.parent.painel as any[]).map(p => p._id).indexOf(form.model._id)
            if (i > -1) Object.assign(form.model.parent.painel[i], {titulo: form.model.titulo, options: form.model.options})
        }
        const [result, err] = await this.repository.update("Componentes", form.model._id, update)
        if (err) console.error(result);
        else console.log(result);
        return "close"
    }
}
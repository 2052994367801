<template>
  <modal :title="title" :exclude="exclude" :edit="edit" :voltar="true" @exclude="callExclude" @close="close1"
    :class="{ controle: true }"
    @edit="calledit">
    <component v-if="template" v-bind:is="templateType" :key="component" v-bind:component="component"></component>
    <section v-else class="widgets">
      <component v-for="component in components" v-bind:is="component.tipo" :key="component._id"
        v-bind:component="component"></component>
    </section>
  </modal>
</template>

<script>
//import { setStorage } from "@/scripts/Storage";
import { defineComponent } from "vue";
import Modal from "./Modal.vue";
import select from "@/components/Form/Select.vue";
import text from "@/components/Form/Text.vue";
import textarea from "@/components/Form/Textarea.vue";
import object from "@/components/Form/Object.vue";
import objectV from "@/components/Form/ObjectV.vue";
import objectC from "@/components/Form/ObjectC.vue";
import disabled from "@/components/Form/Disabled.vue";
import toggle from "@/components/Form/Toggle.vue";
import loud from "@/Features/Componentes/interruptor/ui/switch.vue";
import add from "@/components/Form/Add.vue";
import show from "@/components/Form/Show.vue";
import { modal, modalcontrole } from "@/stores/showModal";
import botao from "@/Features/Componentes/botao/ui/botao.vue";
import botaoiconeSQ from "@/Features/Componentes/botao/ui/iconeSQ.vue";
import volume from "@/Features/Componentes/agregador/ui/volume.vue";
import equa from "@/Features/Componentes/agregador/ui/equa.vue";
import entradas from "@/Features/Componentes/agregador/ui/entradas.vue";
import TV from "./Controle/TV.vue"
import AC from "./Controle/AC.vue"
import acesso from "@/Features/Componentes/acesso/ui/gerenciamento.vue"
import eventstable from "@/Features/Componentes/status/ui/eventsTable.vue"
import configdigitalin from "@/Features/Componentes/status/ui/configDigitalIn.vue"
import amostrastable from "@/Features/Componentes/grafico/ui/amostrasTable.vue"
import configsetpoint from "@/Features/Componentes/grafico/ui/configSetpoint.vue"
import EditButtons from "@/Features/Componentes/agregador/forms/edit.ts"
import statusvol from "@/Features/Componentes/agregador/ui/status.vue";

export default defineComponent({
  name: "ModalConfiguracao",
  props: {
    form: Object,
  },
  components: {
    Modal,
    select,
    text,
    textarea,
    object,
    objectV,
    disabled,
    toggle,
    add,
    objectC,
    show,
    botao,
    botaoiconeSQ,
    volume,
    equa,
    entradas,
    loud,
    "tv": TV,
    "ac": AC,
    acesso,
    statusvol,
    eventstable,
    configdigitalin,
    amostrastable,
    configsetpoint,
  },
  data() {
    return {
      title: "",
      template: false,
      edit: false,
      exclude: false,
      atribs: {},
      fields: {},
      forms: [],
      close: "Cancelar",
      path: 0,
      extras: {},
      components: [],
      modalcontrole,
      projId: "",
      templateType: "",
      templateTypes: ["ac", "tv", "acesso"]
    };
  },
  async created() {
    if(modalcontrole.parent.options.code === "custom") this.edit = true
    else if (modalcontrole.parent.options.code === "template") {
      this.edit = true
      if(this.templateTypes.find(t => t === modalcontrole.parent.options.type)){
        this.template = true
        this.templateType = modalcontrole.parent.options.type
      }
    }
    this.projId = this.$route.params.projId;
    console.log(modalcontrole.root);
    this.title = modalcontrole.parent.titulo
    //audio esta caindo aqui
    if(modalcontrole.parent.tipo === "audio" && !modalcontrole.parent.opened) {
      modalcontrole.root.splice(3, 0, ...modalcontrole.parent.painel.slice(1,3))
      modalcontrole.root.splice(4, 0, modalcontrole.parent.painel[3])
      modalcontrole.parent.opened = true
    }
    modalcontrole.root.forEach((c) => {
      console.log(c);
      c.parent = modalcontrole.parent;
      this.components.push(c);
    });

    //this.next(modal.form);
  },
  sockets: {
    configuracao(data) {
      console.log(data);
    },
  },
  methods: {
    async Cancel() {
      const last = this.forms.length - 1;
      if (last > 0) {
        await this.back();
        if (last === 1) this.close = "Cancelar";
      } else this.$emit("close");
    },

    async calledit(){
      console.log(modalcontrole)
      modal.show(new EditButtons(this.$repository, modalcontrole.parent))
    }
  },

  unmounted() {
    ////this.sockets.unsubscribe("configuracao");
  },
});
</script>

<style>
.footer {
  display: flex;
  gap: 1em;
}
</style>
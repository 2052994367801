<template>
  <card
    :class="[
      'd-flex',
      'gap-m',
      'justify-between',
      'a-center',
      'row-three',
      'column-fit-l',
    ]"
    @config="config"
  >
    <p v-if="components.list[i].conexao && components.list[i].conexao.status !== '🟢'">{{ components.list[i].conexao.status }}</p>
    <h2>{{ component.titulo }}</h2>
    <label v-if="!component.options.pulsar" class="switch">
      <input
        v-model="components.list[i].options.state"
        type="checkbox"
        name="switch"
        @change="execute"
      />
      <span class="slider round"></span>
    </label>
    <button v-else style="width: 35%" @click="execute" type="button">Pulsar</button>
  </card>
</template>

<script>
import { modal } from "@/stores/showModal";
import Factory from "../actions/_factory";
import ListForms from "../forms/_factory";
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
  },
  methods: {
    execute() {
      console.log(components.list[this.i].options.state);
      const action = Factory.actions[this.component.options.action];
      Object.assign(this.component.options, {
        state: components.list[this.i].options.state,
      });
      new action(this.$module).execute(this.component);
    },
    config() {
      console.log("config");
      if (ListForms.check(this.component.options.action))
        modal.show(
          new ListForms.forms[this.component.options.action](this.component)
        );
      else
        console.error(`Form "${this.component.options.action}" do not exist`);
    },
  },
};
</script>

<style>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
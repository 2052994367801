import FormWallpapers from "@/Features/Theme/form/wallpapers";
import Repository from "@/core/repository/_repository";
import { modal } from "@/stores/showModal";
import type { Socket } from "socket.io-client";

export default class Fomulario {
    socket: Socket
    repository: Repository
    list: {[key: string]: any} = {
        "wallpapers": FormWallpapers
    }
    constructor(socket: Socket, repository: Repository) {
        this.socket = socket
        this.repository = repository
    }
    execute(component: any) {
        console.log("vai abrir o link")
        console.log(component);
        if(Object.hasOwn(this.list, component.options.command)) {
            modal.show(new this.list[component.options.command](this.repository, component))
        }
    }
}
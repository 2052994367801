<template>
    <p>
        {{cell.value}}
    </p>
</template>

<script>
    export default {
        name: "text",
        props: {
            cell: Object
        }
    }
</script>
import Repository from "@/core/repository/_repository"

export default class UpdateWallpaper {
    static action = {
        timeout: 10000,
        text: "Criando Wallpaper"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form, fields)
        const update: { name: string, value?: string } = {
            name: fields.name,
            value: ""
        }
        if (fields.url) {
            const url = (fields.url as string).split("/")
            update.value = url[url.length - 1]
            console.log(update.value)
        } else delete update.value

        const [result, err] = await this.repository.update("Wallpapers", form.model._id, update)
        console.log(result, err)
        return "back"
    }
}
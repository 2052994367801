<template>
  <select v-model="value">
    <option value="1">Entrada 1</option>
    <option value="2">Entrada 2</option>
    <option value="3">Entrada 3</option>
  </select>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "select",
  props: {
    field: Object,
  },
  created() {
    this.value = this.field.value;
  },
  data() {
    return {
      value: "",
    };
  },
});
</script>
import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import { snackbar } from "@/stores/login";
interface Options {
    "titulo": string;
    "command": string;
    "action": string;
    "repetir": string;
    "code": string;
    "icon"?: object;
}
export default class CreateBotaoIR {
    static action = {
        timeout: 10000,
        text: "Criando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        const botao = {
            "projId": form.model.parent.projId,
            "tipo": form.tipo,
            "titulo": fields.titulo,
            "options": {
                "command": fields.command,
                "action": "ir",
                "repetir": fields.repetir,
                "code": fields.code
            } as Options,
            "main": false,
            "control": form.model.parent._id
        }
        if (form.tipo === "botaoiconeSQ")
            botao.options.icon = {
                "icon": fields.icon,
                "status": "normal"
            }
        console.log(botao)
        const [result, err] = await this.repository.create("Componentes", botao)
        if (err) {
            snackbar.show("Erro ao inserir botão", 3000, "error")
            return "finish"
        }
        console.log(result)
        result.parent = form.model.parent
        form.model.parent.control.push(result)
        snackbar.show("Botão inserido", 3000, "success")
        return "back"
    }
}
import Repository from "@/core/repository/_repository";

export default class AddTelaProgramacao {
    static action = {
        timeout: 10000,
        text: "Adicionando a Programação"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        const TelaProgramacao = {
            "projId": form.model.root.projId,
            "tela": fields.tela,
            "programacao": form.model.root._id,
            "index": form.model.root.programacoes ? form.model.root.programacoes.length - 1 : 0
        }
        const [result, err] = await this.repository.create("TelaProgramacao", TelaProgramacao)
        if(err) console.error(result);
        
        return "back"
    }
}
<template>
  <modal title="Troca WebSocket">
    <form>
      <div class="entrada inteiro grid-gap-low">
        <label for="nome">Server</label>
        <input id="nome" type="text" v-model="server" />
      </div>
    </form>
    <div class="footer">
      <button type="button" class="cinza" @click="$emit('close')">
        Cancelar
      </button>
      <button type="button" @click="retorna">Trocar</button>
    </div>
  </modal>
</template>

<script>
import { setStorage } from "@/scripts/Storage";
import { defineComponent } from "vue";
import Modal from "./Modal.vue";
export default defineComponent({
  name: "ModalMudaConexao",
  props: {
    title: { type: String, default: "Super Modal" },
  },
  data() {
    return {
      server: "",
    };
  },
  created() {
    this.server = this.$socket.io.uri;
  },
  methods: {
    retorna() {
      //this.$emit("confirm", this.nome, this.cidade);
      console.log(this.$socket);
      this.$socket.io.uri = this.server;
      this.$socket.disconnect();
      this.$socket.connect();
      setStorage("serverUri", this.server);
    },
  },
  components: { Modal },
});
</script>

<style>
.footer {
  display: flex;
  gap: 1em;
}
</style>
<template>
  <div class="d-grid gap-mid o-scroll" style="max-height: 55vh">
    <div
      class="item d-grid gap-p p-10"
      v-for="item in field.list"
      v-bind:key="item._id"
      :value="item.tipo"
      @click="$emit('controller', item)"
    >
      {{ item.model.nome }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  name: "objectC",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
    };
  },
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
}
</style>
<template>
    <div>
        <div class="">
            <div class="titulo" style="margin-bottom: 0px;">Materiais</div>
        </div>
        <div style="margin-bottom: 1em;" id="table23">
            <table id="produtos" style="width:100%; font-amily:arial; font-size:80%">
                <tr>
                    <th>Produto</th>
                    <th>Qtd</th>
                </tr>
                <tr id="row_prod1">
                    <td style="width: 80%"><input id="Prod1" class="prodName" list="products1"
                            onchange="prod_onChange(this.val,this.id)" /></td>
                    <td style="width: 20%"><input id="quant1" name="quant1" class="prodQtd" size="1" type="Number"
                            style="text-align:center;" min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod2" style="display:none;">
                    <td><input id="Prod2" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant2" name="quant2" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod3" style="display:none;">
                    <td><input id="Prod3" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant3" name="quant3" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod4" style="display:none;">
                    <td><input id="Prod4" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant4" name="quant4" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod5" style="display:none;">
                    <td><input id="Prod5" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant5" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod6" style="display:none;">
                    <td><input id="Prod6" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant6" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod7" style="display:none;">
                    <td><input id="Prod7" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant7" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod8" style="display:none;">
                    <td><input id="Prod8" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant8" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod9" style="display:none;">
                    <td><input id="Prod9" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant9" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod10" style="display:none;">
                    <td><input id="Prod10" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant10" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod11" style="display:none;">
                    <td><input id="Prod11" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant11" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod12" style="display:none;">
                    <td><input id="Prod12" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant12" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod13" style="display:none;">
                    <td><input id="Prod13" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant13" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod14" style="display:none;">
                    <td><input id="Prod14" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant14" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
                <tr id="row_prod15" style="display:none;">
                    <td><input id="Prod15" list="products1" onchange="prod_onChange(this.val,this.id)" />
                    </td>
                    <td><input id="quant15" name="quant5" class="text" size="1" type="Number" style="text-align:center;"
                            min="0" value="" maxlength="5" /></td>
                </tr>
            </table>
            <datalist id="products1">
            </datalist>

        </div><!--center-->
    </div><!--head-container-->
</template>

<script>
export default {
    props: {
        projetos: Object,
    },
    data() {
        return {
            data: "",
            elProject: document.getElementById("projName")
        };
    },
    async created() {
        console.log("criou componente")
    },
    methods: {
        envia() {
            console.log("enviando formulario")
        },
        refreshProjetos() {
            /* console.log("atualiza lista dos projetos")
            //console.log(document.getElementById("projetos").innerHTML)
            //atualiza lista de projetos
            let plist = ""
            for (const property in global_data) {
                if (property == "") continue
                if (property == "Legenda") continue
                if (property == "#fim#") continue

                plist += '<option value="'
                plist += property
                plist += '">'
            }

            document.getElementById("projetos").innerHTML = plist
            //console.log(">>>>>",global_projectsList)   */      
        },
        //monitora alteracao do projeto
        onProjectNameChange() {
            //EXE
            //executa todas as funcoes associadas a troca do projeto
            /* if (global_projChange_callbacks) {
                let max = global_projChange_callbacks.length
                for (let i = 0; i < max; i++) {
                    let func = global_projChange_callbacks[i]
                    if (typeof func == "function") {
                        func(elProject.value)
                    }
                }
            } */
        }
        /* elProject.onchange = onProjectNameChange */
    }
};
</script>

<style></style>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormProgramacaoLocal from "./local";
import SelectModulos from "./selectModulos";

export default class FormCenaLocal {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        const add = new SelectModulos(this.repository, { titulo: "+ Adicionar", macro: this.model })
        const fields = {
            "extras": new Extra(this.model.nome, "Atualizar", "updateprogramacao", {}, true, "deleteprogramacao"),
            "nome": new Field("text", "Titulo", "nome"),
            "id": new Field("number", "Id", "13 a 48", [{ name: "step", value: "1" }, { name: "min", value: "13" }, { name: "max", value: "48" },]),
            "modulos": new Field("objectV", "Modulos", "", [add])
        }
        const [programacao, err] = await this.repository.findOne("ProgramacaoModulos", {
            _id: this.model._id,
            projId: this.model.projId
        })
        if (err) return fields
        const cenas: FormProgramacaoLocal[] = []
        if (programacao)
            programacao.modulos.forEach((m: any) => {
                m.programacao = this.model
                cenas.push(new FormProgramacaoLocal(this.repository, m))
            });

        cenas.push(add)
        fields.modulos = new Field("objectV", "Modulos", "", cenas)
        return fields
    }
}
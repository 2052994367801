import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormProgramacaoLocal from "./local";
import SelectModulos from "./selectModulos";
import FormOperador from "./operador";
import SelectIluminacao from "./operadores/iluminacao";
import FormTituloComponente from "@/Features/Componentes/forms/nomeComponente";
import FormComponentMacro from "@/Features/Componentes/forms/macro";

export default class FormManageModulo {
    repository: Repository
    model: any
    operadores: any[] = []
    modulos: any[] = []
    
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        const add = new SelectModulos(this.repository, { titulo: "+ Adicionar", macro: this.model })
        const oadd = new SelectIluminacao(this.repository, { titulo: "+ Adicionar", macro: this.model })
        const fields = {
            "extras": new Extra(this.model.nome, "Enviar", "updateprogramacaogatewaycena", {}, true, "deleteprogramacao"),
            "nome": new Field("text", "Titulo", "nome"),
            "modulos": new Field("objectV", "Modulos", "", [add]),
            "operadores": new Field("object", "Ações", "", [oadd]),
        }
        const [programacao, err] = await this.repository.findOne("ProgramacaoModulos", {
            _id: this.model._id,
            projId: this.model.projId
        })
        if (err) return fields
        const cenas: FormProgramacaoLocal[] = []
        if (programacao){
            this.modulos = programacao.modulos
            programacao.modulos.forEach((m: any) => {
                m.programacao = this.model
                cenas.push(new FormProgramacaoLocal(this.repository, m))
            });
        }

        cenas.push(add)
        fields.modulos = new Field("objectV", "Modulos", "", cenas)
        const [result, merr] = await this.repository.findOne("Macros", { _id: this.model._id, projId: this.model.projId })
        if (merr) console.error(result);
        else {
            this.model.children = result
            const operadores: any[] = []
            const componentes: FormComponentMacro[] = []
            if (result){
                result.operadores.forEach((o: any) => {
                    this.operadores.push(o)
                    operadores.push(new FormOperador(this.repository, o))
                });
                result.componentes.forEach((o: any) => {
                    componentes.push(new FormComponentMacro(this.repository, o))
                });
            }
            operadores.push(oadd)
            fields.operadores = new Field("objectVL", "Ações", "", operadores)
        }
        return fields
    }
}
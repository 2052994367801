import Checksum from "@/core/AZ1/checksum";
import Repository from "@/core/repository/_repository";
import StaticModule from "@/service/module/_module";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";

export default class SalvarIdSenha {
    static action = {
        timeout: 10000,
        text: "Salvando Senha"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form);
        console.log(fields);
        if (isNaN(Number(fields.code)) || fields.code > 16777215) return "err/Senha precisa ser numérica e menor que 16777216"
        fields.code = Number(fields.code)
        const isnew = Object.keys(form.model).length === 1

        if (isnew) await this.repository.create("CodigosAcesso", {
            parent: form.parent._id,
            projId: form.parent.projId,
            name: fields.name,
            oldcode: fields.code,
            id: Number(fields.id)
        })
        else await this.repository.update("CodigosAcesso", form.model._id, {
            projId: form.model.projId,
            name: fields.name,
            oldcode: fields.code,
        })

        const [modId, e485, , err] = checkModulo(form.parent)
        if (err) return `err/${modId}`

        const pkg = [255, 1, 11, 12, 0, e485, 35, 0, Number(fields.id), ...this.decimalToBytes(fields.code, 24).reverse(), 1, 115]
        console.log(pkg)
        const [result, e] = await StaticModule.io.send(`${form.parent.projId}/${modId}/CMD/CMD`, Checksum.calc(pkg).join(","), { query: `CMD/${e485}/13/35` });
        if (e) snackbar.show(result, 3000, "error")
        return "back"
    }

    decimalToBytes(dec: number, bits: number, i?: number, B?: number): number[] {
        if (dec == 0) return new Array(Math.floor(bits / 8)).fill(0)
        B = typeof B === "undefined" ? 0 : B
        if (i === 256) B++;
        i = typeof i === "undefined" || i === 256 ? 1 : i
        const quo = dec % 2
        const result = this.decimalToBytes(Math.floor(dec / 2), bits, i * 2, B)
        result[B] = result[B] + quo * i
        return result
    }
}
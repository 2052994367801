import Repository from "@/core/repository/_repository";
import { store } from "@/store";
export default class CreateIRCommand {
    static action = {
        timeout: 10000,
        text: "Criando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        const list: {[key: string]: any} = {}
        for (const b of form.model.parent.control) {
            const code = b.options.code ? b.options.code : b._id.substr(-5)
            list[code] = {
                command: b.options.command,
                repetir: b.options.repetir
            }
        }
        const [result, err] = await this.repository.create("ComandosIR", {
            tipo: fields.tipo,
            marca: fields.marca,
            list
        })
        if(err) {
            console.log(result);
            return "finish"
        }
        return "back"
    }
}
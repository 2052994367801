import FormAgendaProgramacao from "./agenda"
import FormCena from "./cena"
import FormCenaLocal from "./cenalocal"
import FormCenasModulo from "./cenasmodulo"
import FormComponenteProgramacao from "./componente"
import FormCreateProgramacao from "./createProgramacao"
import FormMacro from "./macro"
import FormManageModulo from "./managemodulo"
import FormModuloProgramacao from "./modulo"

export default class Forms {
    static list: { [key: string]: any } = {
        "create": FormCreateProgramacao,
        "macro": FormMacro,
        "cena": FormCena,
        "cenalocal": FormCenaLocal,
        "managemodulo": FormManageModulo,
        "cenasmodulo": FormCenasModulo,
        "modulo": FormModuloProgramacao,
        "agenda": FormAgendaProgramacao,
        "componente": FormComponenteProgramacao,
    }
    static check(key: string) {
        return Object.prototype.hasOwnProperty.call(this.list, key)
    }
}
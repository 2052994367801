<!-- AQUI VOU TER QUE FAZER TIPO O SELECTRANGE
  POREM O tem que fazer subCAMPOS que SERAO O SELETOR DE DIAS DA SEMANADA E UM 
  SELECT COM TIPOS DE INTERVALOS, DIAS MESES ANOS E UM INPUT TIPO NUMBER.
  o diario nao precisa de subcampo
  TENHO QUE USAR O updateAtrib PARA MUDAR O CAMPO DO PERIODO
 -->
<template>
  <div class="d-grid gap-m">
    <select v-model="value" @change="check">
      <option v-for="item in field.list" v-bind:key="item.id" :value="item.value">
        {{ item.name }}
      </option>
    </select>
    <component :is="value" v-bind:formu="formu" @updateAtrib="setValue"></component>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import semanal from "./semanal.vue";
import intervalo from "./intervalo.vue"
export default defineComponent({
  name: "select",
  components: {
    semanal,
    intervalo
  },
  props: {
    field: Object,
    atribkey: String,
    formu: Object
  },
  created() {
    this.value = this.$attrs.modelValue;
    this.check();
  },
  data() {
    return {
      value: "",
      valuerange: "",
      range: false,
    };
  },
  methods: {
    check() {
      this.$emit("updateAtrib", this.atribkey, this.value);
    },
    setValue(key, value) {
      this.$emit("updateAtrib", key, value);
    },
  },
});
</script>
<template>
  <p v-if="value" class="error">{{ value }}</p>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "show",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
    };
  },
  created() {
    this.value = this.$attrs.modelValue;
  },
  updated() {
    this.value = this.$attrs.modelValue;
  },
});
</script>
<style>
.error {
  color: #3e0101;
  padding: 0.5em;
  background-color: #ff8c8c;
  border-radius: 5px;
  border: solid 2px #fd5959;
  text-align: center;
}
</style>
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"

export default class FormStyle {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.repository = repository
        this.model = model
    }
    async getFields(type: string): Promise<{ [index: string]: Field | Extra }> {
        const [style, err] = await this.repository.findOne("ElementsStyle", {
            projId: this.model.element.projId,
            element: this.model.element._id
        })
        if (err) return {
            "extras": new Extra(`Erro ao pegar: ${this.model.nome}`, "none", ""),
            "err": new Field("show", style, "")
        }
        if (Object.prototype.hasOwnProperty.call(style, type)) Object.assign(this.model, style[type])
        this.model.style = type
        this.model.styleid = style._id
        return {
            "extras": new Extra(this.model.nome, "Atualizar", "end/updatestyle/last"),
        }
    }
}
import Repository from "@/core/repository/_repository";
import Socket from "@/service/socket";

export default class DeleteElement {
    static action = {
        timeout: 10000,
        text: "Atualizando Coluna"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)

        await this.repository.deleteMany("ElementsStyle", {
            projId: form.model.projId,
            element: form.model._id
        })

        if (form.model.tipo === "table") {
            await this.repository.deleteMany("TableColumns", {
                projId: form.model.projId,
                table: form.model._id
            })
            const [rows, err] = await this.repository.findMany("TableRows", {
                projId: form.model.projId,
                table: form.model._id
            })
            if (err) return "back"

            for (const r of rows) {
                await this.repository.deleteMany("TableFields", {
                    projId: r.projId,
                    row: r._id
                })
            }

            await this.repository.deleteMany("TableRows", {
                projId: form.model.projId,
                table: form.model._id
            })
        }
        Socket.io.emit("deletefile", form.model)
        return "back"
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import Componente from "../_componente";
import FormComponent from "./component";

export default class FormRoot {
    repository: Repository
    model: Componente
    constructor(repository: Repository, model: Componente) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.tipo, "Atualizar", "updateporta"),
        }
        const children: FormComponent[] = []
        if(this.model.children)
        for (const c of this.model.children) {
            const [component, err] = await this.repository.findOne("Componentes", { _id: c, projId: this.model.projId })
            if (err) console.error(component);
            else children.push(new FormComponent(this.repository, component))
        }

        fields["children"] = new Field("object", "Componentes", "", children)
        return fields

    }
}
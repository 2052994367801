import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormAPI {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.model.objname = "Configurar"
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.titulo,"Atualizar", "end/updatecomponente/last"),
            "url": new Field("text", "URL da API com valores", "https://api.example.com/values"),
            "hkey": new Field("text", "Chave do cabeçalho", "access-token"),
            "hvalue": new Field("text", "Valor do cabeçalho", "token"),
        }
        return fields
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormAdicionaUsuario {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
        console.log(this.model);
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        console.log(this.model);
        
        const fields = {
            "extras": new Extra("Adicionar Usuário", "Adicionar", this.model.add ? "end/adicionausuario/true": "adicionausuario", {}),
            "username": new Field("text", "Login do Usuário", "username"),
            "nome": new Field("text", "Nome do Usuário", "nome"),
            "acesso": new Field("select", "Nível de Acesso", "Selecione", [
                {name: "Admin", value:"admin"},
                {name: "Usuário", value:"user"},
            ]),
        }
        return fields
    }
}
<template>
  <card
    :class="['d-flex', 'gap-m', 'a-center', 'row-three', 'column-fit-m']"
    @config="config"
  >
    <Icons :value="component.options.icon" />
    <p>{{ component.options.state }} °C</p>
  </card>
</template>
<script>
import Icons from "@/components/Icons/icons.vue";
import Factory from "../actions/_factory";
import ListForms from "../forms/_factory";
import { modal } from "@/stores/showModal";
import Card from "@/components/UI/Card.vue";
export default {
  components: {
    Card,
    Icons,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      action: "",
      titulo: "",
    };
  },
  async created() {
    this.action = this.component.options.action;
  },
  methods: {
    execute(e) {
      e.preventDefault();
      const action = Factory.actions[this.action];
      new action(this.$module).execute(this.component);
    },
    config() {
      console.log("config");
      if (ListForms.check(this.action))
        modal.show(new ListForms.forms[this.action](this.component));
      else console.error(`Form "${this.action}" do not exist`);
    },
  },
};
</script>

import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import { programacoes } from "@/stores/programacao";
import DeleteOperador from "./deleteOperador";
export default class DeleteProgramacao {
    static action = {
        timeout: 10000,
        text: "Apagando Programacao"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        if (form.model.children)
            for (const o of form.model.children.operadores) {
                await new DeleteOperador(this.repository).execute({ model: o }, fields)
            }

        const result = await this.repository.delete("Programacoes", form.model._id)
        console.log(result);
        programacoes.remove(form.model)

        /* const deleteList: { collection: string; idList: string[] } = {
            collection: "Componentes",
            idList: [form.model._id]
        }

        //é melhor usar uma um campo root para diser quem o componente pertence do que usar um array
        //pois esse processo nao seria necessario e tem o risco de excluir dois ou mais ao mesmo tempo e fazer o update do array com o antigo
        //e resolve o problema de pegar a lita de componentes na home ja pega com um findMany
        const [result, err] = await this.repository.deleteList(deleteList.collection, deleteList.idList)
        if (err) console.error(result)
        else console.log(result)

        components.remove(form.model)
        const [d1, e1] = await this.repository.deleteMany("ComponenteRoot", {
            projId: form.model.projId,
            componente: form.model._id
        })
        console.log(e1, d1)

        const [d2, e2] = await this.repository.deleteMany("Componentes", {
            projId: form.model.projId,
            painel: form.model._id
        })
        console.log(e2, d2)

        const [d3, e3] = await this.repository.deleteMany("Componentes", {
            projId: form.model.projId,
            control: form.model._id
        })
        console.log(e3, d3) */
        return "close"
    }
}
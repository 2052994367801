import { createRouter, createWebHistory } from '@ionic/vue-router';
import { isNavigationFailure, RouteRecordRaw } from 'vue-router';
import Login from '../pages/Login.vue'
import Hall from '../pages/Hall.vue'
import Token from '../pages/Token.vue'
import Chamado from '@/pages/Chamado.vue'
import { getStorage } from '@/scripts/Storage';
import { store } from '@/store';
import Project from "@/pages/project/_Project.vue"
import Display from "@/Features/Componentes/display/ui/page.vue"
import { modal, modalcontrole } from '@/stores/showModal';
import Conta from '@/pages/Conta.vue';
import ServidorLocal from '@/pages/ServidorLocal.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Login
  },{
    path: '/token',
    component: Token
  }, {
    path: '/hall',
    component: Hall,
    meta: {
      requestAuth: true
    },
  }, {
    path: '/conta',
    component: Conta,
    meta: {
      requestAuth: true
    },
  }, {
    path: '/servidorlocal',
    component: ServidorLocal,
    meta: {
      requestAuth: true
    },
  }, {
    path: '/p/:projId/:page',
    name: 'Projeto',
    component: Project,
    meta: {
      requestAuth: true
    }
  }, {
    path: '/p/:projId/display/:_id',
    name: 'Display',
    component: Display,
    meta: {
      requestAuth: true
    },
  }, {
    path: '/chamado/:projId',
    component: Chamado,
    meta: {
      requestAuth: true
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.afterEach((to, from, failure)=> {
  if(to.fullPath === "/hall" && from.fullPath === "/")
    router.options.history.state.favorite = true
  else router.options.history.state.favorite = false

  if (isNavigationFailure(failure)) {
    console.log('failed navigation', failure)
  }
})

router.beforeEach(async (to, from, next) => {
  // aqui se a tiver /p e for maior que 5 (significando que é uma subpasta) então posso criar uma rota que redireciona para a pagina project e trata la a abertura da subpasta
  const paths = to.path.split("/")
  
  if (modal.showing || modalcontrole.showing) {
    modal.showing = false
    modalcontrole.showing = false
    next()
  } else if (paths[paths.length - 1] === "modal" && !modal.showing && !modalcontrole.showing)
    next({ path: `${router.options.history.state.back}` })
  else if (to.matched.some(record => record.meta.requestAuth)) {
    store.commit("setProjId", to.params.projId)
    if ((await getStorage("accessToken")).value === null) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router

import Repository from "@/core/repository/_repository";

export default class CreateDelimitador {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }

    async execute(dia: string, periodo: any, obj: any) {
        const date = dia.split("T")
        const time = date[1].split(":")
        const delitime = periodo[obj.tipo].split(":")
        time[0] = delitime[0]
        time[1] = delitime[1]
        date[1] = time.join(":")
        console.log(date.join("T"));
        console.log(new Date(date.join("T")));
        const newdate = new Date(date.join("T"))
        newdate.setHours(newdate.getHours() - periodo.fuso)
        const delimitador = {
            "tipo": obj.tipo,
            "periodo": periodo._id,
            "date": newdate.getTime(),
            "checked": false,
            "action": obj.action,
            dono: periodo.dono
        }
        console.log(delimitador);

        return await this.repository.create("Delimitadores", delimitador)
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import RandomToken from "@/core/lib/randomtoken";
import Repository from "@/core/repository/_repository";
import FormNome from "@/Features/Componentes/forms/nome";
import router from "@/router";
import Socket from "@/service/socket";
import State from "@/service/states/state";
import FormTemporario from "./temporario";
import FormUsuarioMqtt from "./usuariomqtt";
import SelectServidorLocal from "./selectServidorLocal";
import { alert } from "@/stores/showModal";

export default class FormProjetoLocal {
    repository: Repository
    model: any
    lista: any[]
    constructor(repository: Repository, model: any, lista: any[]) {
        this.model = model
        this.repository = repository
        this.lista = lista
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "Atualizar", "updateprojeto", {}, true, "deleteprojeto"),
            "projId": new Field("copy", "Id do Projeto", "username"),
            "nome": new Field("text", "Nome", "nome"),
        }

        const addlocal = new SelectServidorLocal(this.repository, this.model, "add")
        let listlocal: any[] = [addlocal]
        if (this.model.servidorlocal) {
            console.log(this.model.servidorlocal)
            const [result, err] = await this.repository.findOne("ServidoresLocais", { _id: this.model.servidorlocal, revenda: this.model.revenda })
            if (err) console.error(result)
            else listlocal = [new FormNome(this.repository, { nome: result.nome }), Object.assign(addlocal, { type: "change" })]
        }

        fields["local"] = new Field("object", "Servidor local", "", listlocal)
        if(this.model.tipo === "turnlocal")
        fields["delete"] = Field.make("object", "Limpar dados da Nuvem", [Object.assign(new FormNome(this.repository, {}), {icon: true, type: "trash"})],async (): Promise<"none"> => {
            const confirm = await alert.show(`Deseja realmente APAGAR os dados do projeto "${this.model.nome}" armazendos na nuvem?`, true)
            if(!confirm) return "none"
            console.log(confirm);
            return "none"
        })
        if (this.model.acessorevenda)
            fields["manage"] = Field.make("object", "Gerenciar Projeto", [new FormNome(this.repository, { objname: "Entrar" })], async (): Promise<"none"> => {
                const event = `logintecnico/${RandomToken()}`
                Socket.io.emit(event, { _id: this.model._id, revenda: this.model.revenda })
                const [result, err] = await Socket.io.wait(event)
                console.log(result, err)
                if (err) return "none"
                //aqui vai ter que mudar o state para o do tecnico projeto
                Socket.io.emit("enterproject", { projId: this.model.projId });
                router.replace(`/p/${this.model.projId}/home`)
                State.tecnico = {
                    revenda: this.model.parent,
                    backto: "projetos"
                }
                return "none"
            })
        return fields
    }
}
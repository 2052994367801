<template>
    <div class="d-grid gap" onselectstart="return false">
        <button @click="createComponente">Criar Componente</button>
        <div class="d-flex a-center gap-m" v-for="(component) in components" :key="component._id">
            <card-title-description :value="component" @mostraModulo="mostraModulo" />
        </div>
    </div>
</template>

<script>
import { modal } from "@/stores/showModal";
import ComponentForms from "@/Features/Componentes/forms/_list";
import AdapterCreateComponente from "@/core/adapter/CreateComponente";
import cardTitleDescription from "@/components/cards/_card.vue";
import { components, componentsMain } from "@/stores/components";
import UseCases from "../usecase/_list";
export default {
    components: {
        cardTitleDescription,
    },
    computed: {
        styleDropDown() {
            return "top: " + this.topDropDown + "px";
        },
    },
    data() {
        return {
            header: { title: "", subtitle: "Gerenciamento de modulos" },
            dropDown: false,
            configurar: false,
            configurando: false,
            adapter: {},
            configLayer: 0,
            root: {},
            components: [],
            create: AdapterCreateComponente,
            show: ComponentForms,
            collection: "RootComponentes",
            query: {
                projId: this.$route.params.projId,
            },
        };
    },
    async created() {
        components.clear();
        componentsMain.clear();
        this.components = componentsMain.listmain;
        this.header.subtitle = "Gerenciamento de Componentes";
        Object.assign(this.query, { tipo: "root" });
        const listatipos = ["agregador"].concat(Object.keys(UseCases.list))
        const [root, err] = await this.$repository.findMany(
            "ComponentesConexao",
            {
                projId: this.$route.params.projId,
                tipo: { $in: listatipos }
            }
        );
        if (err) console.error("Erro ao pegar modulos");
        else if (root && Object.keys(root).length) {
            this.root = root;
            root.forEach((c) => {
                componentsMain.push(c);
                components.push(c);
            });
        }
    },
    methods: {
        mostraModulo(modulo) {
            modal.show(new this.show.list[modulo.tipo](this.$repository, modulo));
        },
        async createComponente() {
            console.log("passou aqui");
            const [root, err] = await this.$repository.findOne("RootChildrenNum",
                {
                    projId: this.$route.params.projId,
                    tipo: "root"
                });
            if (err) return console.error(root);
            if (root) modal.show(new this.show.list["createcomponente"](this.$repository, root));
            else {
                const [root, err] = await this.$repository.findOne(
                    "Componentes",
                    {
                        projId: this.$route.params.projId,
                        tipo: "root"
                    }
                );
                if (err) return console.error(root);
                console.log(root)
                root.children = 1
                modal.show(
                    new this.show.list["createcomponente"](this.$repository, root)
                );
            }
        },
    },
};
</script>
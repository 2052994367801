<template>
  <div class="d-grid justify-between a-center" style="grid-template-columns: 60% 35%;">
    <p v-if="waiting">{{ title }}</p>
    <p v-else style="line-break: anywhere;">{{ value }}</p>
    <label v-on:click="copy" class="objects">Copiar</label>
  </div>
</template>

<script>
import { snackbar } from "@/stores/login";
import { defineComponent } from "vue";
export default defineComponent({
  name: "show",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
      waiting: false,
      title: "",
      msg: "",
      count: 0,
      animation: {},
    };
  },
  async created() {
    this.value = this.$attrs.modelValue;
    if(this.field.getAsyncValue) {
      this.msg = this.field.waitValueMsg
      this.start()
      this.value = await this.field.getAsyncValue()
      this.stop()
    }
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.value)
      snackbar.show("Copiado", 2000, "success")
    },
    start() {
      this.waiting = true
      this.title = `${this.msg}${".".repeat(this.count)}`;
      if (this.count < 3) this.count++;
      else this.count = 0;
      this.animation = setTimeout(() => {
        this.start();
      }, 700);
    },
    stop() {
      this.waiting = false
      clearTimeout(this.animation);
      this.title = this.msg;
    },
  }
});
</script>
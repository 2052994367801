import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import CreateOperador from "../../controller/createOperador";
import SelectActions from "./actions";
import { NameValueList } from "@/core/entity/_lib";

export default class SelectIluminacao {
    repository: Repository
    model: { titulo: string; macro: any }
    constructor(repository: Repository, model: { titulo: string; macro: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const [componentes, err] = await this.repository.findMany("Componentes", {
            projId: this.model.macro.projId,
            main: true
        })
        if (err) return {}
        const list: any[] = []
        componentes.forEach(element => {
            if (element.tipo === "interruptor" || element.tipo === "dimmer")
                list.push({ name: element.titulo, value: element._id, element })
        });
        return {
            "extras": new Extra("Escolha", "Confirmar", "end/createoperadores/true"),
            "componentes": Field.make("checkbox", "Componentes", list),
        }

    }
}
<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="32"
    height="32"
    viewBox="0 0 8.4666665 8.4666669"
    version="1.1"
    id="svg5029"
    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    sodipodi:docname="cadeado-off-23x30.svg"
    class="icon-off"
  >
    <defs id="defs5023" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="7.9195959"
      inkscape:cx="-36.550223"
      inkscape:cy="10.45875"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1920"
      inkscape:window-height="1017"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      units="px"
      inkscape:showpageshadow="true"
    />
    <metadata id="metadata5026">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-288.53332)"
    >
      <g
        id="g3772-6-8-4-1-4-4"
        transform="matrix(0.02734733,0,0,0.02734733,-0.23172728,288.9215)"
        inkscape:export-xdpi="96"
        inkscape:export-ydpi="96"
        inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\cadeado-fechado-20x32.png"
      >
        <path
          d="M 160.44,89.902 H 74.524 V 52.345 C 74.524,34.51 89.034,20 106.87,20 c 17.836,0 32.42947,14.510195 32.346,32.345 l -0.1773,37.883254 c -0.0258,5.522939 7.49204,1.844361 13.01404,1.844361 5.522,0 8.17895,3.677127 8.04974,-1.844361 L 159.216,52.345 C 159.215,23.482 135.733,0 106.87,0 78.007,0 54.524,23.482 54.524,52.345 v 37.557 h -1.225 c -15.244,0 -27.646,12.402 -27.646,27.646 v 106.908 c 0,15.923 12.954,28.878 28.878,28.878 h 104.678 c 15.924,0 28.878,-12.955 28.878,-28.878 V 117.548 c 0,-15.244 -12.403,-27.646 -27.647,-27.646 z m 7.647,134.554 c 0,4.896 -3.982,8.878 -8.878,8.878 H 54.531 c -4.896,0 -8.878,-3.982 -8.878,-8.878 V 117.548 c 0,-4.216 3.431,-7.646 7.646,-7.646 H 160.44 c 4.216,0 7.646,3.43 7.646,7.646 v 106.908 z"
          id="path3762-0-0-4-1-6-9"
          inkscape:connector-curvature="0"
          sodipodi:nodetypes="scsssssscsscsssssssscssssssscc"
        />
        <path
          d="m 106.87,134.44 c -11.409,0 -20.691,9.282 -20.691,20.691 0,7.783 4.324,14.57 10.691,18.102 v 25.562 c 0,5.523 4.478,10 10,10 5.522,0 10,-4.477 10,-10 v -25.562 c 6.368,-3.532 10.691,-10.319 10.691,-18.102 0,-11.409 -9.282,-20.691 -20.691,-20.691 z"
          id="path3764-2-1-3-9-7-4"
          inkscape:connector-curvature="0"
        />
      </g>
      <g
        aria-label="OFF"
        id="text1678-8-6-0-3-1-6-5-0-1"
        transform="matrix(0.10600854,0,0,0.10600854,-17.936185,244.27544)"
      >
        <path
          d="m 209.99166,482.90159 q 0,4.73245 -2.71185,7.52406 -2.71185,2.77831 -7.49746,2.77831 -4.77233,0 -7.48418,-2.77831 -2.71185,-2.79161 -2.71185,-7.52406 0,-4.77232 2.71185,-7.53734 2.71185,-2.77832 7.48418,-2.77832 4.75903,0 7.48417,2.77832 2.72514,2.76502 2.72514,7.53734 z m -6.76633,5.01161 q 0.74443,-0.90395 1.10335,-2.12694 0.35892,-1.23629 0.35892,-2.89796 0,-1.78131 -0.41209,-3.03089 -0.4121,-1.24958 -1.07677,-2.0206 -0.67796,-0.7976 -1.56861,-1.15652 -0.87737,-0.35892 -1.83449,-0.35892 -0.97042,0 -1.83449,0.34563 -0.85077,0.34562 -1.56862,1.14323 -0.66467,0.74443 -1.09005,2.06047 -0.4121,1.30275 -0.4121,3.03089 0,1.76802 0.3988,3.0176 0.4121,1.23629 1.07677,2.0206 0.66466,0.78431 1.55532,1.15652 0.89066,0.37221 1.87437,0.37221 0.98371,0 1.87437,-0.37221 0.89065,-0.38551 1.55532,-1.18311 z"
          id="path1209-0-2-4"
          inkscape:connector-curvature="0"
        />
        <path
          d="m 228.05737,476.82652 h -9.10597 v 3.68226 h 8.4413 v 3.8285 h -8.4413 v 8.45459 h -5.07807 v -19.79385 h 14.18404 z"
          id="path1211-5-0-6"
          inkscape:connector-curvature="0"
        />
        <path
          d="m 245.76416,476.82652 h -9.10597 v 3.68226 h 8.4413 v 3.8285 h -8.4413 v 8.45459 h -5.07807 v -19.79385 h 14.18404 z"
          id="path1213-1-3-0"
          inkscape:connector-curvature="0"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "off",
};
</script>
import SocketSession from "@/service/session/session";
import type { Socket } from "socket.io-client";

export default class Link {
    socket: Socket
    constructor(socket: Socket) {
        this.socket = socket
    }
    execute(component: any) {
        console.log("vai abrir o link")
        console.log(component);
        SocketSession.app.config.globalProperties.$router.replace(component.options.command)
    }
}
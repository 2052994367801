<template>
    <div class="d-flex pcont">
        <p>{{ cell.value }}</p>
        <!-- <div class="points"></div> -->
    </div>
</template>

<script>
export default {
    name: "text",
    props: {
        cell: Object
    }
}
</script>

<style>
.pcont{
    grid-template-columns: auto auto;
}
.pcont p {
    width: 100%;
    position: relative;
}
.pcont p::after {
    content: "";
    width: -webkit-fill-available;
    width: -moz-available;
    position: absolute;
    border-bottom: solid 5px;
    border-bottom-style: dotted;
    top: 90%;
}
.points {
    border-bottom: solid;
    border-bottom-style: dotted;
    box-sizing: border-box;
    width: auto;
}
</style>
<template>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.87 22.71"> <title>volDown</title> <rect width="24" height="24"  fill="none" class="feather stroke feather-power"/><path d="M13.14.77A.51.51,0,0,0,13,.8,36,36,0,0,0,9.43,3.73L6.13, 6.58H.76q0, 4.74,0,9.46H6L9.38,19a34.57,34.57,0,0,0,3.59, 3h0a1,1,0,0,0,1.11-.48c.1-.2,0-.86,0-6.93h-.82c-1.38,0-2.07-1.48-2.07-3.15s.69-3.23, 2.07-3.23h.82c0-5.67.08-6.65,0-6.81-.17-.37-.77-.64-.94-.55Z" style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2"/>
<line x1="22.88" y1="12.11" x2="15.38" y2="12.11" 
style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2"/>
<line x1="19.13" y1="15.86" x2="19.13" y2="8.36" 
style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2"/>
</svg>


</template>

<script>
export default {
  name: "nomal",
};
</script>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import Modulo from "../domain/entity/Modulo";
import FormPorta from "./porta";
import FormStatusModulo from "./status";
import StaticModule from "@/service/module/_module";
import SafeJSONParser from "@/core/lib/safejsonparser";
//import CreateUsuario from "../usecase/CreateUsuario";

export default class FormGateway {
    repository: Repository
    model: Modulo
    constructor(repository: Repository, model: Modulo) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const [result, err] = await this.repository.findMany("Portas", { modulo: this.model._id, projId: store.state.projId })
        const fields = {
            "extras": new Extra(this.model.nome, "Atualizar", "updatemodulo", {}, true, "deletemodulo"),
            "nome": new Field("text", "Nome", "Nome para o Modulo"),
            "modId": new Field("text", "ModId", "id do Modulo"),
            "e485": new Field("number", "Endereço 485", "485 do Modulo"),
            "ip": new Field("copy", "IP", "000.000.000.000"), /* TODO: aqui tem que pegar o valor no módulo */
            "portas": new Field("text", "Portas", "Portas nao encontradas"),
            "state": Field.make("object", "Status", [new FormStatusModulo(this.repository, { modulo: this.model })])
        };

        fields.ip.waitValueMsg = "Pegando IP do Módulo";
        fields.ip.getAsyncValue = async () => {
            const [result, err] = await StaticModule.io.send(`${this.model.projId}/${this.model.modId}/CMD/PRJ_ST`, "{}", { query: "PRJ_ST/FB", tOut: 5000 });
            const checkIfIP = this.model.ip && this.model.ip !== "";
            if (err) return checkIfIP ? this.model.ip : result
            const [status, jsonerr] = SafeJSONParser(result)
            if (jsonerr) return checkIfIP ? this.model.ip : "Erro no pacote de status"
            if (status.ip && status.ip !== this.model.ip) {
                fields.ip.waitValueMsg = "Atualizando IP do Módulo"
                const [, err] = await this.repository.update("Modulos", this.model._id, {
                    projId: this.model.projId,
                    ip: status.ip
                })
                if(!err) this.model.ip = status.ip
            }
            return status.ip
        }
        if (err) {
            console.error(result);
            return fields;
        } else {
            const portas: FormPorta[] = []
            result.forEach((p: any) => {
                portas.push(new FormPorta(this.repository, p))
            })
            fields.portas = new Field("object", "Portas", "", portas)
            return fields
        }
    }
}
export default class Componente {
    _id = ""
    projId = ""
    mostrar = false
    raiz = ""
    tipo: string
    conexao = ""
    constructor(tipo: string) {
        this.tipo = tipo
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormSelectImage from "@/Features/Theme/form/selectimage";
import { components } from "@/stores/components";
import OrderComponent from "../../controllers/order";
import FormComponent from "../../forms/component";
import Forms from "../../forms/_list";
import Componente from "../../_componente";
import SelectTipoComponentes from "./componentes/tipos"
export default class FormPasta extends FormComponent {
    constructor(repository: Repository, model: Componente) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = await super.getFields()
        fields["img"] = new Field("object", "Imagem de Fundo", "", [new FormSelectImage(this.repository, this.model)])
        delete fields.conexao
        delete fields.local
        delete fields.action
        const add = new SelectTipoComponentes(this.repository, { titulo: "+ Adicionar", root: this.model, act: true })
        const list: any[] = [];
        const [root, err] = await this.repository.findOne("RootComponentes", {
            _id: this.model._id,
            projId: this.model.projId
        })
        if(err) return fields
        if (root)
        root.componentes.forEach((o: any) => {
            o.sub = true
            o.root = this.model
            components.push(o)
            list.push(new Forms.list[o.tipo](this.repository, o))
        });
        list.push(add)
        const order = new OrderComponent(this.repository, this.model)
        fields.componentes = Field.make("objectVL", "Componentes", list, {order: async i => {
            await order.setOrder(i)
            return false
        }})
        return fields
    }
}
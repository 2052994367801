<template>
    <div class="form-input d-grid gap-s">
        <div class="d-flex jc-between">
            <label for="Segmento" class="required">Segmento</label>
            <div class="clear1" @click="clear('Segmento')">Limpar</div>
        </div>
        <input list="segmentos" type="text" name="Segmento" id="Segmento" v-model="value" @change="changeInput" />
        <datalist id="segmentos">
            <option v-for="segmento in segmentos" v-bind:key="segmento" :value="segmento"></option>
        </datalist>
    </div>
</template>

<script>
export default {
    props: {
        projetos: Object,
        global: Object,
    },
    data() {
        return {
            data: "",
            segmentos: [],
            value: ""
        };
    },
    async created() {
        console.log("criou componente")
        this.refreshsegmentos()
        this.$emit("setCallback", "legendaRefresh_callbacks", this.refreshsegmentos)
    },
    methods: {
        envia() {
            console.log("enviando formulario")
        },
        clear(tipo) {
            console.log(this.value, tipo)
            this.value = ""
        },
        changeInput() {
            console.log("dentro do elemento", this.value)
            this.$emit("changeInput", "segmento", this.value)
            this.onSegmentoNameChange()
        },
        refreshsegmentos() {
            console.log("atualiza lista dos segmentos")
            //atualiza lista de segmentos
            for (const property in this.global.objLegenda) {
                if (property == "") continue
                this.segmentos.push(property)
            }
        },
        onSegmentoNameChange() {
            //EXE
            //executa todas as funcoes associadas a troca do segmento
            if (this.global.segmentoChange_callbacks) {
                this.global.segmentoChange_callbacks.forEach((func) => {
                    if (typeof func == "function") 
                        func(this.value)     
                });
            }
        }
    }
};
</script>

<style></style>
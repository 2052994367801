import CreateMacro from "./macro"

export default class UseCases {
    static list: { [key: string]: typeof CreateMacro } = {
        "macro": CreateMacro,
        "cena": CreateMacro,
        "cenalocal": CreateMacro,
        "managemodulo": CreateMacro,
    }
    static check(key: string) {
        return Object.prototype.hasOwnProperty.call(this.list, key)
    }
}
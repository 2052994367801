import Repository from "@/core/repository/_repository";
import Componente from "../../_componente";

export default class CreatePasta {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, fields: any): Promise<[Componente, boolean]> {
        const component = new Componente();
        component.tipo = "pasta"
        component.projId = root.projId
        component.projeto = root.projeto
        component.main = false
        component.titulo = fields.titulo
        component.func = "layout"
        const [result, err] = await this.repository.create("Componentes", component)
        if (err) console.error(result)
        else Object.assign(component, result)

        console.log(component)
        return [component, false]
    }
}
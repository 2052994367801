import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormNome {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any){
        this.repository = repository
        this.model = model
    }

    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {}
    }
}
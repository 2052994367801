<template>
  <div class="d-flex justify-between a-center">
    <pre>{{ value }}</pre>
    <label v-on:click="copy" class="objects">Copiar</label>
  </div>
</template>

<script>
import { snackbar } from "@/stores/login";
import { defineComponent } from "vue";
export default defineComponent({
  name: "show",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
    };
  },
  created() {
    this.value = this.$attrs.modelValue;
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.value)
      snackbar.show("Copiado", 2000, "success")
    }
  }
});
</script>
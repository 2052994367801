import UpdateBotao from "@/Features/Componentes/botao/controllers/updateBotao";
import ConectaComponente from "@/Features/Componentes/controllers/conectaComponente";
import CreateComponente from "@/Features/Componentes/controllers/createComponente";
import DeleteComponente from "@/Features/Componentes/controllers/deleteComponente";
import DeleteOperador from "@/Features/Programacao/controller/deleteOperador";
import UpdateComponente from "@/Features/Componentes/controllers/updateComponente";
import UpdateOptions from "@/Features/Componentes/controllers/updateOptions";
import SendLimit from "@/Features/Componentes/interruptor/controller/sendLimit";
import DeleteProgramacaoEntrada from "@/Features/Componentes/status/controller/deleteprogramacaoentrada";
import SendLocal from "@/Features/Componentes/status/controller/sendlocal";
import UpdateProgramacaoEntrada from "@/Features/Componentes/status/controller/updateProgramacaoEntrada";
import UpdateComponenteStatus from "@/Features/Componentes/status/controller/updatestatuslocal";
import ConectaModulo from "@/Features/Modulo/controller/conectaModulo";
import ControllerCreateModulo from "@/Features/Modulo/controller/CreateModulo";
import DeleteModulo from "@/Features/Modulo/controller/deleteModulo";
import UpdateConexao from "@/Features/Modulo/controller/updateConexao";
import UpdateModulo from "@/Features/Modulo/controller/updateModulo";
import CreateProgramacao from "@/Features/Programacao/controller/create";
import DeleteProgramacao from "@/Features/Programacao/controller/delete";
import UpdateProgramacao from "@/Features/Programacao/controller/update";
import AdicionaUsuario from "@/Features/Usuario/controller/adicionaUsuario";
import CreateUsuario from "@/Features/Usuario/controller/createUsuario";
import UpdateUsuarioProjeto from "@/Features/Usuario/controller/updateusuarioprojeto";
import DeleteUsuarioProjeto from "@/Features/Usuario/controller/deleteusuarioprojeto";
import CreateDelimitadores from "@/Features/Agenda/controllers/createDelimitadores";
import CreatePeriodo from "@/Features/Agenda/controllers/createPeriodo";
import UpdateDelimitadores from "@/Features/Agenda/controllers/updateDelimitadores";
import UpdatePeriodo from "@/Features/Agenda/controllers/updatePeriodo";
import SelectDay from "@/Features/Agenda/controllers/selectDay";
import DeletePeriodo from "@/Features/Agenda/controllers/deletePeriodo";
import UpdateInterruptor from "@/Features/Componentes/interruptor/controller/updateInterruptor";
import UpdateProgramacaoLocal from "@/Features/Programacao/controller/updateprogramacaolocal";
import AddColumn from "@/Features/Componentes/display/controller/addcolumn";
import AddValue from "@/Features/Componentes/display/controller/addvalue";
import UpdateValue from "@/Features/Componentes/display/controller/updatevalue";
import UpdateColumn from "@/Features/Componentes/display/controller/updatecolumn";
import DeleteColumn from "@/Features/Componentes/display/controller/deletecolumn";
import DeleteValue from "@/Features/Componentes/display/controller/deletevalue";
import UpdateStyle from "@/Features/Componentes/display/controller/updateStyle";
import CreateBotaoIR from "@/Features/Componentes/controllers/createBotaoIR";
import AddToPainel from "@/Features/Componentes/controllers/addtopainel";
import CreateIRCommand from "@/Features/Componentes/controllers/createIrCommand";
import CreateControl from "@/Features/Componentes/controllers/createControl";
import CreateProjeto from "@/Features/Projeto/controllers/createProjeto";
import UpdateUsuario from "@/Features/Usuario/controller/updateUsuario";
import AddTelaProgramacao from "@/Features/Componentes/display/controller/addTelaProgramacao";
import UpdatePrograma from "@/Features/Componentes/display/controller/updatePrograma";
import UpdateProjeto from "@/Features/Projeto/controllers/updateProjeto";
import DeleteProjeto from "@/Features/Projeto/controllers/deleteProjeto";
import SalvarIdSenha from "@/Features/Componentes/acesso/controllers/salvaridsenha";
import SetFavoriteProject from "@/Features/Usuario/controller/setFavorite";
import UpdateSetpoint from "@/Features/Componentes/grafico/controllers/updatesetpoint";
import CreateEndpoint from "@/Features/Usuario/controller/createendpoint";
import UpdateEndpoint from "@/Features/Usuario/controller/updateendpoint";
import DeleteEndpoint from "@/Features/Usuario/controller/deleteendpoint";
import UpdateInscricoes from "@/Features/Usuario/controller/updateinscricoes";
import CreateWallpaper from "@/Features/Theme/controllers/createwallpaper";
import UpdateWallpaper from "@/Features/Theme/controllers/updatewallpaper";
import DeleteWallpaper from "@/Features/Theme/controllers/deletewallpaper";
import CreateEventoProgramacao from "@/Features/Programacao/controller/createEventoProgramacao";
import UpdateAmostra from "@/Features/Componentes/grafico/controllers/updateamostra";
import CreateServidorLocal from "@/Features/SevidorLocal/controllers/create";
import ConfigServidorLocal from "@/Features/SevidorLocal/controllers/config";
import ConectaServidorLocal from "@/Features/Projeto/controllers/conectaServidorLocal";
import UpdateProjetoLocal from "@/Features/SevidorLocal/controllers/updateprojetolocal";
import DeleteTela from "@/Features/Componentes/display/controller/deletetela";
import DeletePrograma from "@/Features/Componentes/display/controller/deleteprograma";
import CreateCamera from "@/Features/Componentes/camera/controllers/createcamera";
import UpdateCamera from "@/Features/Componentes/camera/controllers/updatecamera";
import DeleteCamera from "@/Features/Componentes/camera/controllers/deletecamera";
import UpdateProgramacaoGatewayCena from "@/Features/Programacao/controller/updateprogramacaogatewaycena";
import CreateOperadores from "@/Features/Programacao/controller/createOperadores";
import UpdateUsuarioTemporario from "@/Features/Projeto/controllers/updateusuariotemporario";

export default class FactoryController {
    static controllers: { [index: string]: typeof UpdateBotao } = {
        "updatebotao": UpdateBotao,
        "updatemodulo": UpdateModulo,
        "deletemodulo": DeleteModulo,
        "createmodulo": ControllerCreateModulo,
        "conectamodulo": ConectaModulo,
        "createcomponente": CreateComponente,
        "deletecomponente": DeleteComponente,
        "updatecomponente": UpdateComponente,
        "updateinterruptor": UpdateInterruptor,
        "updatecomponentestatus": UpdateComponenteStatus,
        "conectacomponente": ConectaComponente,
        "updateoptions": UpdateOptions,
        "updateconexao": UpdateConexao,
        "deleteoperador": DeleteOperador,
        "sendlocal": SendLocal,
        "sendlimit": SendLimit,
        "createprogramacao": CreateProgramacao,
        "updateprogramacao": UpdateProgramacao,
        "deleteprogramacao": DeleteProgramacao,
        "updateprogramacaoentrada": UpdateProgramacaoEntrada,
        "deleteprogramacaoentrada": DeleteProgramacaoEntrada,
        "createeventoprogramacao": CreateEventoProgramacao,
        "createusuario": CreateUsuario,
        "updateusuario": UpdateUsuario,
        "adicionausuario": AdicionaUsuario,
        "updateusuarioprojeto": UpdateUsuarioProjeto,
        "deleteusuarioprojeto": DeleteUsuarioProjeto,
        "createdelimitador": CreateDelimitadores,
        "updatedelimitador": UpdateDelimitadores,
        "createperiodo": CreatePeriodo,
        "updateperiodo": UpdatePeriodo,
        "deleteperiodo": DeletePeriodo,
        "selectday": SelectDay,
        "updateprogramacaolocal": UpdateProgramacaoLocal,
        "addcolumn": AddColumn,
        "updatecolumn": UpdateColumn,
        "deletecolumn": DeleteColumn,
        "addvalue": AddValue,
        "updatevalue": UpdateValue,
        "deletevalue": DeleteValue,
        "updatestyle": UpdateStyle,
        "createbotaoir": CreateBotaoIR,
        "addtopainel": AddToPainel,
        "createircommand": CreateIRCommand,
        "createcontrol": CreateControl,
        "createprojeto": CreateProjeto,
        "updateprojeto": UpdateProjeto,
        "deleteprojeto": DeleteProjeto,
        "addtelaprogramacao": AddTelaProgramacao,
        "updateprograma": UpdatePrograma,
        "salvaridsenha": SalvarIdSenha,
        "setfavoriteproject": SetFavoriteProject,
        "updatesetpoint": UpdateSetpoint,
        "createendpoint": CreateEndpoint,
        "updateendpoint": UpdateEndpoint,
        "deleteendpoint": DeleteEndpoint,
        "updateamostra": UpdateAmostra,
        "updateinscricoes": UpdateInscricoes,
        "createwallpaper": CreateWallpaper,
        "updatewallpaper": UpdateWallpaper,
        "deletewallpaper": DeleteWallpaper,
        "createservidorlocal": CreateServidorLocal,
        "configservidorlocal": ConfigServidorLocal,
        "conectaservidorlocal": ConectaServidorLocal,
        "updateprojetolocal": UpdateProjetoLocal,
        "deletetela": DeleteTela,
        "deleteprograma": DeletePrograma,
        "createcamera": CreateCamera,
        "updatecamera": UpdateCamera,
        "deletecamera": DeleteCamera,
        "updateprogramacaogatewaycena" : UpdateProgramacaoGatewayCena,
        "createoperadores": CreateOperadores,
        "updateusuariotemporario": UpdateUsuarioTemporario,
    }

    static get(controller: string): [typeof UpdateBotao | string, boolean] {
        if (Object.prototype.hasOwnProperty.call(this.controllers, controller))
            return [this.controllers[controller], false]
        else
            return [`Controller ${controller} nao encontrado`, true]
    }
}
import Checksum from "@/core/AZ1/checksum";
import Repository from "@/core/repository/_repository";
import StaticModule from "@/service/module/_module";
import { store } from "@/store";
import { checkModulo } from "../../controllers/checkModulo";
export default class SendLimit {
    static action = {
        timeout: 3000,
        text: "Atualizando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        const [modId, e485, canal, err] = checkModulo(form.model)
        if (err) console.error(modId, form.model);
        else {
            StaticModule.io.send(
                `${form.model.projId}/${modId}/CMD/CMD`,
                Checksum.calc(`255,1,10,12,0,${e485},6,1,${canal - 1},${Number(fields.min)},${Number(fields.max)},1,123`.split(",").map(s => Number(s))).join(","),
            )
        }
        return "close"
    }
}
import Repository from "@/core/repository/_repository";
import Componente from "../../../_componente";

export default class CreateElement {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, fields: any): Promise<[Componente, boolean]> {
        const component = new Componente();
        component.tipo = fields.value
        component.projId = root.projId
        component.projeto = root.projeto
        component.main = false
        component.titulo = fields.name
        component.func = "element"
        const [result, err] = await this.repository.create("Componentes", component)
        if (err) {
            console.error(result)
            return [component, true]
        }
        Object.assign(component, result)

        console.log(component)
        await this.repository.create("ElementsStyle", {
            "element": component._id,
            "projId": component.projId,
            "position": { "x": "1", "y": "1" },
            "size": { "w": "1", "h": "1" }
        })
        return [component, false]
    }
}
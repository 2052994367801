<template>
  <main class="d-grid h-100" onselectstart="return false" style="grid-template-rows: min-content;
        background-color: var(--card-background);
    border-radius: 0.5em;">
    <nav style="" class="d-flex tabs">
      <div v-for="(tab, index) in tabs" :key="index" :class="{ selected: tab.selected }" @click="changeTab(tab)">
        <h3>{{ tab.name }}</h3>
      </div>
    </nav>
    <!-- Aqui vou ter que verificar se o projeto possui agenda
          se possuir entao mostra calendario se nao cria agenda e depois ja mostra calendario -->
    <div v-if="!agenda">
      <button @click="createAgenda">Criar Agenda</button>
    </div>
    <component v-else v-bind:is="show" :key="mes" v-bind:agenda="agenda" v-bind:agendamentos="agendamentos"
      v-bind:mes="mes" @change="changeMes" @updateCalendario="updateCalendario"></component>
  </main>
</template>

<script>
import calendario from "@/Features/Agenda/ui/calendario.vue"
import lista from "@/Features/Agenda/ui/lista.vue";
export default {
  name: "page-agenda",
  components: {
    calendario,
    lista
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de modulos" },
      dropDown: false,
      configurar: false,
      configurando: false,
      adapter: {},
      configLayer: 0,
      root: {},
      agenda: null,
      collection: "Agendas",
      query: {
        pertence: "projeto",
        dono: this.$route.params.projId,
      },
      mes: new Date().getMonth(),
      show: "calendario",
      tabs: [{
        name: "Calendário",
        value: "calendario",
        selected: true
      }, {
        name: "Lista",
        value: "lista",
        selected: false
      }],
      agendamentos: []
    };
  },
  async created() {
    this.header.subtitle = "Gerenciamento de Componentes";
    const [agenda, err] = await this.$repository.findOne(
      this.collection,
      this.query
    );
    if (err) console.error("Erro ao pegar agenda:", agenda);
    else this.agenda = agenda
    console.log(agenda);
    /* const [periodos, e] = await this.$repository.findMany(
      "PeriodoDelimitadores",
      {
        dono: this.$route.params.projId,
      }
    )
    if (e) console.error(periodos);
    console.log(periodos);
    this.agendamentos= periodos */
  },
  methods: {
    async updateConfiguracao(/* adapter */) {
      this.configurando = false;
      const [result, err] = await this.$repository.findMany(this.collection, {
        projId: this.$route.params.projId,
      });
      if (err) console.error("Erro ao pegar modulos");
      else this.modulos = result;
    },
    async createAgenda() {
      console.log("criando agenda");
      const [result, err] = await this.$repository.create("Agendas", {
        tipo: "programacao",
        pertence: "projeto",
        dono: this.$route.params.projId
      })
      if(err) console.error(result);
      else this.agenda = result
    },
    changeTab(tab) {
      this.show = tab.value
      this.tabs.forEach(t => t.selected = t.value === tab.value)
    },
    changeMes(action) {
      console.log(action);
      if (action === "next") this.mes++
      else this.mes--
    },
    updateCalendario() {
      this.mes++
      setTimeout(() => {
        this.mes--
      }, 100);
    }
  },
};
</script>

<style>
.tabs {
  padding: 0.5em;
}

.tabs div {
  cursor: pointer;
}

.tabs h3 {
  padding: 0.5em;
  color: #a7a7a7;
}

.tabs .selected h3 {
  font-weight: bold;
  color: var(--card-color);
}
</style>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import FormComponent from "../../forms/component";

export default class FormComponentStatus extends FormComponent{
    async getFields(): Promise<{ [index: string]: Field | Extra }>{
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields = await super.getFields()
        return {
            "extras": fields["extras"],
            "titulo": fields["titulo"],
            "conexao": fields["conexao"],
            "mask": Field.make("text", "Máscara", "#°C"),
            "action": fields["action"],
        }
    }
}
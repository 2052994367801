import Repository from "@/core/repository/_repository";

export default class AddColumn {
    static action = {
        timeout: 10000,
        text: "Atualizando Programação"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any){
        console.log(form)
        console.log(fields)
        const [c, e] = await this.repository.create("TableColumns", {
            projId: form.model.table.projId,
            table: form.model.table._id,
            key: fields.key,
            name: fields.name,
            type: fields.type,
            index: form.model.table.columns.length,
            hshow: true
        })
        if(e) console.error(c);
        return "back"
    }
}
import WRTCModule from "@/core/module/wrtc";
import RepositoryWRTC from "@/core/repository/Wrtc";
import Socket from "@/service/socket";
import Peer from "@/service/wrtc/peer";
import { store } from "@/store";
import { alert, wait } from "@/stores/showModal";
import { App } from "vue";
import { Default } from "../command"
import { snackbar } from "@/stores/login";
interface Eventos {
    offer: string;
    answer: string;
    start: string;
}

export default class P2P extends Default {
    constructor() {
        super("p2p")
    }
    tentativas = 0
    async setup(app: App): Promise<void> {
        const oldmodule = app.config.globalProperties.$module
        const oldrepository = app.config.globalProperties.$repository
        
        Peer.setChannel(Peer.connection.createDataChannel("localServer"))
        app.config.globalProperties.$module = new WRTCModule(Peer.channel)
        app.config.globalProperties.$repository = new RepositoryWRTC(Peer.channel)

        Socket.io.emit("startp2pstream", { projId: store.state.projId })
        const [eventos, serr]: [Eventos, boolean] = await Socket.io.wait("startp2pstream")
        if (serr && this.tentativas < 3) {
            this.tentativas++
            snackbar.show(`${this.tentativas}° tentativa falhou. Tentando novamente`, 4000)
            return await this.setup(app)
        } else if (serr) {
            snackbar.show(`Todas as tentativas falharam. ${eventos}`, 6000, "error")
            clearTimeout(wait.timeout)
            wait.show(0, "")
            return console.error(eventos);
        }

        Peer.channel.onopen = e => {
            console.log("open!!!");
            Peer.channel.connected = true
            clearTimeout(wait.timeout)
            wait.show(0, "")
        }

        Peer.connection.onicecandidate = e => {
            if (!Peer.connection.localDescription) return
            console.log(JSON.stringify(Peer.connection.localDescription))
            Socket.io.emit(eventos.offer, { offer: Peer.connection.localDescription })
        }
        Peer.connection.createOffer().then(o => Peer.connection.setLocalDescription(o))

        let bestanswer: any = {}
        let timeout = 0
        Socket.io.on(eventos.answer, (args) => {
            console.log(Peer.connection.currentRemoteDescription)
            bestanswer = args.answer
            if (timeout) clearTimeout(timeout)
            timeout = setTimeout(() => {
                console.log(JSON.stringify(args.answer))
                Peer.connection.setRemoteDescription(bestanswer)
                    .then(() => { console.log("Done !!!") })
                    .catch(e => { console.log("Aready Done !!!") })
            }, 1500);
        });

        /* TODO: nao esta mostrando os componentes da home depois de conectar */
        wait.callback = async () => {
            await alert.show("Erro ao se conectar com o Servidor Local")
            Peer.channel.error = true
            app.config.globalProperties.$module = oldmodule
            app.config.globalProperties.$repository = oldrepository
        }
        return
    }
}
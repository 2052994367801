import SafeJSONParser from "@/core/lib/safejsonparser";
import { components } from "@/stores/components";

declare global {
    interface RTCDataChannel {
        wait(event: string, time?: number): Promise<[any, boolean]>;
        waitConnection(): Promise<boolean>;
        emit(event: string, msg: any): void;
        on(event: string, callback: (data: any) => void): void;
        off(event: string, callback: (data: any) => void): void;
        listeners: { [key: string]: (data: any) => void };
        connected: boolean;
        error: boolean;
    }
}
export default class Peer {
    static connection = new RTCPeerConnection({
        iceServers: [
            {
                urls: "stun:stun.livtech.com.br:3478"
            }
        ],
    })
    static channel: RTCDataChannel
    static setChannel(channel: RTCDataChannel) {
        RTCDataChannel.prototype.wait = (event: string, time?: number): Promise<[any, boolean]> => {
            return new Promise(res => {
                let timeout = 0
                function response(result: any) {
                    res(result)
                    channel.off(event, response)
                    clearTimeout(timeout)
                }
                if (time)
                    timeout = setTimeout(() => {
                        res(["time out foi acionando", true])
                        channel.off(event, response)
                    }, time);
                channel.on(event, response)
            })
        }
        RTCDataChannel.prototype.waitConnection = (): Promise<boolean> => {
            return new Promise((res) => {
                if (channel.connected) res(false)
                else if (channel.error) res(true)
                else
                    setTimeout(async () => {
                        console.log("esperando connection...");
                        if (!channel.connected) res(await channel.waitConnection());
                        else res(false)
                    }, 50);
            })
        }

        RTCDataChannel.prototype.emit = (event: string, msg: any) => {
            channel.send(JSON.stringify({ event, msg }))
        }
        RTCDataChannel.prototype.listeners = {}
        RTCDataChannel.prototype.on = (event: string, callback: (data: any) => void) => {
            channel.listeners[event] = callback
        }
        RTCDataChannel.prototype.off = (event: string, callback: (data: any) => void) => {
            if (event in channel.listeners && channel.listeners[event] === callback)
                delete channel.listeners[event]
        }
        this.channel = channel
        Peer.channel.onmessage = e => {
            const [obj, err] = SafeJSONParser(e.data)
            if (err) return console.error(obj)
            const has = Object.prototype.hasOwnProperty
            if (!has.call(obj, "event") || !has.call(obj, "msg"))
                return console.error("Protocol error", obj)

            if (has.call(channel.listeners, obj.event))
                channel.listeners[obj.event](obj.msg)
            else if(obj.event === "component") components.update(obj.msg) /* Aqui tem que inserir os cases do socketio */
            else console.log(obj.event, obj.msg);

        }
        Peer.channel.onclose = (e) => console.error("P2P Closed!!!!!!", e);
    }
}
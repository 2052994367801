import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormSelectEndpoint {
    repository: Repository
    model: any
    icon = true
    type = "add"
    constructor(repository: Repository, model: any) {
        this.model = { user: model }
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list = [
            { name: "Push", value: "push" },
            { name: "Telegram", value: "telegram" },
        ]
        return {
            "extras": new Extra("Novo Endpoint", "Adicionar", "end/createendpoint/true"),
            "nome": Field.make("text", "Nome", "Celular 1, Telegram, Computador Empresa..."),
            "tipo": Field.make("select", "Tipos", "Selecionar", list),
        }
    }
}
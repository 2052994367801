export default class Checksum {
    static calc(pkg: number[]) {
        if (pkg[0] == 255 && pkg[1] == 1 && (pkg.length < pkg[2] + 3))
            return pkg;

        //calcula checksum
        //desconta 2 bytes do proprio checksum; soma os bytes do pkg
        const chkcalc = pkg.slice(0, -2).reduce((c, b) => c + b, 0);
        //altera dados do checksum
        if (chkcalc <= 65536) {
            pkg[pkg.length - 2] = chkcalc >> 8;
            pkg[pkg.length - 1] = chkcalc % 256;
        }

        return pkg;
    }
}
import Porta from "../Portas/_Porta"
import { Projeto } from "../Projeto"

export default class Modulo {
    _id = ""
    nome = ""
    tipo = ""
    modelo = ""
    e485 = ""
    projId = ""
    projeto = ""
    portas: Porta[] = []
    modId?: string
    constructor(tipo: string) {
        this.tipo = tipo
    }

    setProjeto(projeto: Projeto) {
        this.projeto = projeto._id
        this.projId = projeto.projId
    }

    isGateway() {
        return false
    }

    setModId(modId: string) {
        this.modId = modId
    }

    getObjectIds() {
        return ["projeto"]
    }

    getPortas(): Array<string> {
        return [""]
    }
}
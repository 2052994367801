import Repository from "@/core/repository/_repository";
import Conexao from "@/Model/Conexoes/_Conexao";
import Modulo from "@/Model/Modulo/_Modulo";
import Porta from "@/Model/Portas/_Porta";
import { Projeto } from "@/Model/Projeto";
//import Modulo from "../entity/Modulo";

export default class CreateModulo {
    repository: Repository;
    constructor(repository: Repository) {
        this.repository = repository
    }

    async execute(obj: { tipo: string; projeto: Projeto }): Promise<[any, boolean]> {
        const [modelo, err] = await this.repository.findOne("TemplatesModulo", { tipo: obj.tipo })
        if (err) return ["tipo invalido", true];
        const modulo = new Modulo(modelo.tipo)
        Object.assign(modulo, modelo)
        modulo.e485 = "2"
        if(modelo.modelo === "gateway"){
            modulo.setModId("01")
            modulo.e485 = "1"
        }
        modulo.nome = "Novo"
        modulo.projId = obj.projeto.projId
        modulo.projeto = obj.projeto._id
        const [result, e] = await this.repository.create("Modulos", modulo)
        if (e) return [result, true];
        Object.assign(modulo, result)
        for (const p of modulo.portas) {
            const porta = new Porta(p.nome, p.tipo, modulo._id)
            porta.projId = obj.projeto.projId
            porta.projeto = obj.projeto._id
            const [result, err] = await this.repository.create("Portas", porta)
            if (err) return [result, true]
            Object.assign(porta, result)
            for (const c of p.conexoes) {
                const conexao = new Conexao()
                Object.assign(conexao, c)
                conexao.porta = porta._id
                conexao.projId = obj.projeto.projId
                conexao.projeto = obj.projeto._id
                const [result, err] = await this.repository.create("Conexoes", conexao)
                if (err) return [result, true]
                else if ((obj.tipo === "asw3" && result.tipo === "audio") || (obj.tipo === "m5") || (obj.tipo === "m4")) {
                    c._id = result._id
                }
            }
        }
        return [modulo, false]
    }
}

/**
 * const [modulo, err] = new FactoryModulo().make(obj)
        if (err) return ["tipo invalido", true];
        modulo.nome = "Novo"
        modulo.setProjeto(obj.projeto)
        const [result, e] = await this.repository.create("Modulos", modulo)
        if (e) return [result, true];
        else {
            Object.assign(modulo, result)
            const portas = modulo.getPortas()
            for (const p of portas) {
                const [porta, err] = new FactoryPorta().make({ tipo: p, modulo: modulo._id })
                if (err) console.log(err, p);
                else {
                    porta.projId = obj.projeto.projId
                    porta.projeto = obj.projeto._id
                    const [result, err] = await this.repository.create("Portas", porta)
                    if (err) console.error(result)
                    Object.assign(porta, result)
                    for (const c of porta.getConexoes()) {
                        const [conexao, err] = new FactoryConexao().make({ tipo: c })
                        if (err) console.log(err);
                        else {
                            conexao.porta = porta._id
                            conexao.projId = obj.projeto.projId
                            conexao.projeto = obj.projeto._id
                            const [result, err] = await this.repository.create("Conexoes", conexao)
                            if (err) console.error(result)
                            porta.conexoes.push(conexao)
                        }
                    }
                    modulo.portas.push(porta)
                }
            }
            return [modulo, false]
        }
 */
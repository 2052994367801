import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class SelectServidorLocal {
    repository: Repository
    model: any
    icon = true
    type = "trash"
    constructor(repository: Repository, model: any, type: string) {
        this.model = model
        this.type = type
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [key: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "none", ""),
            "error": Field.make("show", "Erro ao pegar Servidores locais")
        }
        const [result, err] = await this.repository.findMany("ServidoresLocais", { revenda: this.model.revenda })
        console.log(result, err)
        if (err) return fields

        const list: { value: string; name: string }[] = result.map(s => ({ value: s._id, name: s.nome }))
        delete fields["error"]
        fields.extras = new Extra(this.model.nome, "Conectar", "end/conectaservidorlocal/true")
        fields.servidorlocal = new Field("select", "Servidores Locais", "Selecione", list)
        return fields
    }
}
export default class IndexedDB {
    indexedDB = window.indexedDB
    version = 0
    db: IDBDatabase = (null as any)
    request: IDBOpenDBRequest
    addingIndex = false
    /**
     * 
     * @param name Nome do Banco de dados
     * @param customonupgradeneeded Serve para indicar se o on upgrade sera gerado fora da classe
     * @param version 
     */
    constructor(name: string, customonupgradeneeded?: boolean, version?: number) {
        this.request = this.indexedDB.open(name);

        //talves eu nao precise guardar a versao
        this.version = Number(localStorage.getItem("IDBVersion"))
        this.request.onerror = function (event) {
            console.error("An error occuerred with IndexedDB");
            console.error(event);
        };
        if (!customonupgradeneeded)
            this.request.onupgradeneeded = this.onupgradeneeded.bind(this)
        this.request.onsuccess = this.onsuccess.bind(this)
    }

    onupgradeneeded() {
        console.log("👉 ONUPGRADENEEDED")
        const db = this.request.result
        db.createObjectStore("AgregadorSubs", { keyPath: "_id" });
        db.createObjectStore("ComponenteRoot", { keyPath: "_id" });
        db.createObjectStore("Componentes", { keyPath: "_id" });
        db.createObjectStore("ComponentesConexao", { keyPath: "_id" });
        db.createObjectStore("ComponentesRoot", { keyPath: "_id" });
        db.createObjectStore("Conexoes", { keyPath: "_id" });
        db.createObjectStore("ElementsStyle", { keyPath: "_id" });
        db.createObjectStore("GraficoAmostras", { keyPath: "_id" });
        db.createObjectStore("GraficoValores", { keyPath: "_id" });
        db.createObjectStore("Hall", { keyPath: "_id" });
        db.createObjectStore("Modulos", { keyPath: "_id" });
        db.createObjectStore("Portas", { keyPath: "_id" });
        db.createObjectStore("Projetos", { keyPath: "_id" });
        db.createObjectStore("ProjetosModulos", { keyPath: "_id" });
        db.createObjectStore("RootComponentes", { keyPath: "_id" });
        db.createObjectStore("TableColumns", { keyPath: "_id" });
        db.createObjectStore("TableFields", { keyPath: "_id" });
        db.createObjectStore("TableRows", { keyPath: "_id" });
        db.createObjectStore("TelaProgramacao", { keyPath: "_id" });
        db.createObjectStore("Usuarios", { keyPath: "_id" });
        db.createObjectStore("UsuariosProjeto", { keyPath: "_id" });

        /* store.createIndex("cars_colour", ["colour"], { unique: false });
        store.createIndex("colour_and_make", ["colour", "make"], { unique: false }); */
    }

    onsuccess() {
        this.db = this.request.result
        console.log("👉 Version", this.request.result.version)
    }

    addIndex(collection: string, keys: string[], index: string): Promise<[any, boolean]> {
        this.addingIndex = true
        this.db.close()
        return new Promise(res => {
            const request = indexedDB.open("Livtech", this.db.version + 1);
            console.log(request)
            request.onerror = (event) => {
                this.addingIndex = false
                res([`An error occuerred with IndexedDB: ${(event as any).error}`, true]);
            };

            request.onupgradeneeded = (event) => {
                const store = (event as any).target.transaction.objectStore(collection);
                const contains = store.indexNames.contains(index)
                if (!contains) {
                    console.log("nao contem", index)
                    store.createIndex(index, keys, { unique: false });
                }
            }

            request.onsuccess = () => {
                this.db = request.result;
                this.addingIndex = false
                res([this.db, false])
            }
        })
    }

    addStore(collection: string): Promise<[any, boolean]> {
        this.db.close()
        return new Promise(res => {
            const request = indexedDB.open("Livtech", this.db.version + 1);
            console.log(request)
            request.onerror = (event) => {
                console.log()
                res([`An error occuerred with IndexedDB: ${(event as any).error}`, true]);
            };

            request.onupgradeneeded = (event) => {
                const db = (event as any).target.result;
                db.createObjectStore(collection, { keyPath: "_id" });
            }

            request.onsuccess = () => {
                this.db = request.result;
                res([this.db, false])
            }
        })
    }

    deleteStore(collection: string): Promise<[any, boolean]> {
        this.db.close()
        return new Promise(res => {
            const request = indexedDB.open("Livtech", this.db.version + 1);
            console.log(request)
            request.onerror = (event) => {
                res([`An error occuerred with IndexedDB: ${(event as any).error}`, true]);
            };

            request.onupgradeneeded = (event) => {
                const db = (event as any).target.result;
                db.deleteObjectStore(collection);
            }

            request.onsuccess = () => {
                this.db = request.result;
                res([this.db, false])
            }
        })
    }
}
import Repository from "@/core/repository/_repository";

export default class AddValue {
    static action = {
        timeout: 10000,
        text: "Atualizando Programação"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any){
        console.log(form)
        console.log(fields)
        const value = {
            projId: form.model.table.projId,
            table: form.model.table._id,
            titulo: fields[Object.keys(fields)[0]],
            index: form.model.table.rows.length
        }
        
        console.log(value)
        const [c, e] = await this.repository.create("TableRows", value)
        if(e) return "back"
        //TODO: aqui vou ter que separa os campos em key, value 
        for (const key in form.model.columns) {
            if(!fields[key]) continue
            const field = {
                projId: form.model.table.projId,
                key,
                value: fields[key],
                row: c._id,
                column: form.model.columns[key]
            }
            console.log(field)
            const [f, e] = await this.repository.create("TableFields", field)
            if(e) console.log(f)
        }
        return "back"
    }
}
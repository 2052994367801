import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import FormElement from "./_element"

export default class FormSquare extends FormElement{
    constructor(repository: Repository, model: any) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields = await super.getFields();
        fields["color"] = new Field("text", "Cor", "valor no estilo aceito pelo CSS")
        return fields
    }
}
import Field from "../entity/Field";
import Conexao from "@/Model/Conexoes/_Conexao";
import AdapterConexaoConfiguracao from "./ConexaoConfiguracao";
import Repository from "../repository/_repository";
import Adapter from "./_adapter"
import { store } from "@/store";

export default class AdapterPortaConfiguracao implements Adapter {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    _id = ""
    conexoes: Conexao[] = []
    async getFields(): Promise<{ [index: string]: Field }> {
        const fields = {
            "nome": new Field("disabled", "Nome", "Nome para Porta"),
            "conexoes": new Field("disabled", "Conexoes", "Conexoes nao encontradas",)
        }
        const [result, err] = await this.repository.findMany("Conexoes", { porta: this._id, projId: store.state.projId })
        if (err) {
            console.error(result);
            return fields;
        } else {
            const conexoes: AdapterConexaoConfiguracao[] = []
            result.forEach(p => {
                conexoes.push(Object.assign(new AdapterConexaoConfiguracao(), p))
            })
            fields.conexoes = new Field("object", "Conexoes", "", conexoes)
            return fields
        }

    }
    getReturn() {
        return "update"
    }
}
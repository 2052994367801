import Componente from "../_component";

export default class Layout extends Componente {
    modelo = "many"
    componentes: string[] = []
    constructor(tipo: string) {
        super(tipo)
    }

    append(component: Componente) {
        this.componentes.push(component._id)
    }
}
<template>
  <card
    :class="[
      'd-flex',
      'gap-m',
      'justify-between',
      'a-center',
      'row-three',
      'column-fit-l',
    ]"
    @config="config"
  >
    <p v-if="components.list[i].conexao && components.list[i].conexao.status !== '🟢'">{{ components.list[i].conexao.status }}</p>
    <h2>{{ component.titulo }}</h2>
    <p>{{ components.list[i].options.mask ? components.list[i].options.mask.replace("#", components.list[i].options.state) : components.list[i].options.state }}</p>
  </card>
</template>

<script>
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
  },
  methods: {
    /* execute() {
      console.log(components.list[this.i].options.state);
      const action = Factory.actions[this.component.options.action];
      Object.assign(this.component.options, {
        state: components.list[this.i].options.state,
      });
      new action(this.$module).execute(this.component);
    },
    config() {
      console.log("config");
      if (ListForms.check(this.component.options.action))
        modal.show(
          new ListForms.forms[this.component.options.action](this.component)
        );
      else
        console.error(`Form "${this.component.options.action}" do not exist`);
    }, */
  },
};
</script>

<style>
</style>
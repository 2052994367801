<template>
  <section id="projeto" @click="abreProjeto">
    <h2>{{ value.nome }}</h2>
    <div v-if="value.tipo === 'local'">
      <p>Conectar via:</p>
      <div class="d-flex gap-m">
        <button @click="abrelocal">Conexão Local</button>
        <button v-if="online" @click="abrep2p">Direct Link</button>
      </div>
    </div>
  </section>
</template>

<script>

import { getStorage, setStorage } from "@/scripts/Storage";
//import cardBasicoAlarme from "./alarme/cardBasicAlarme.vue";
export default {
  name: "cardBasicProject",
  components: {
    //cardBasicoAlarme,
  },
  props: {
    value: Object,
  },
  data: () => {
    return {
      online: true
    }
  },
  created() {
    this.online = window.navigator.onLine
    console.log(this.value)
  },
  methods: {
    async abreProjeto() {
      console.log(this.value.projId);
      if (this.value.tipo !== "local")
        this.$router.replace(`/p/${this.value.projId}/home`);
    },
    abrep2p() {
      this.$router.replace(`/p/${this.value.projId}/home`);
    },
    async abrelocal(e) {
      console.log(e)
      e.preventDefault()
      const accessToken = (await getStorage("accessToken")).value;
      const refreshToken = (await getStorage("refreshToken")).value;
      const url = `http://${this.value.ip}:5002/token?projId=${this.value.projId}&refreshToken=${refreshToken}&accessToken=${accessToken}`;
      console.log(url)
      window.open(url)
      //window.open(`http://${this.value.ip}:5002/?refreshToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImdTYTZpIiwiaWF0IjoxNjg0MzUwMTM1fQ.WHZoEny2cz4U6UwcLnn3b92MBJgEgZ8b6jBbe-TFFJk&accessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImdTYTZpIiwiaWF0IjoxNjg0MzUwMTM1LCJleHAiOjE2ODQzNTAxNTB9.ZvAlN6Git4-APfqddJGWhaE3nvz71TXhpiLEfbzYoKA`)
    }
  },
};
</script>

<style>
section#projeto,
section#balanca {
  margin: 0 0 25px 0;
  padding: 10px;
  border-radius: 10px;
  display: grid;
  cursor: pointer;
  background-color: var(--ion-card-background);
}
</style>
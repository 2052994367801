import Componente from "../_componente";
interface Options {
    command: string;
    action: string;
    repetir?: string;
    icon?: { icon: string; status: string };
    state?: string;
    type?: string;
    code?: string;
}
export default class Botao extends Componente {
    tipo = "botao"
    painel?: string
    control?: string
    options: Options = {
        command: "",
        action: "",
    }
}
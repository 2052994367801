import Repository from "@/core/repository/_repository";
import { store } from "@/store";
import { modal } from "@/stores/showModal";
import { optionsDimmer } from "@/Features/Componentes/interruptor/options/dimmer";
import { optionsInterruptor } from "@/Features/Componentes/interruptor/options/interruptor";
import { optionsAgregador } from "./options/agregador";
import { optionsWait } from "./options/wait";
import { snackbar } from "@/stores/login";
interface Option {
    key: string;
    value: string;
    type: string;
    operador: string;
    projId: string;
}
export default class CreateOperador {
    static action = {
        timeout: 10000,
        text: "Criando Componente"
    }
    static optionsList: { [key: string]: Option[] } = {
        "interruptor": optionsInterruptor,
        "dimmer": optionsDimmer,
        "wait": optionsWait,
        "agregador": optionsAgregador,
        "acesso": optionsAgregador,
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(macro: any, item: any) {
        console.log(macro)
        console.log(item)
        console.log(store.state.projeto)
        const operador = {
            projId: macro.projId,
            projeto: "",
            macro: macro._id,
            tipo: "componente",
            index: macro.children ? macro.children.operadores.length : 0
        }
        if (item.value && item.value === "operador") operador.tipo = "operador"
        console.log(operador);
        const [result, err] = await this.repository.create("Operadores", operador)
        if (err) console.error(result);
        else {
            console.log(result);
            const options = [{
                key: "tipo",
                value: item.tipo,
                type: "main",
                operador: "",
                projId: ""
            }]
            if (operador.tipo === "componente") {
                options[0].key = "componente"
                options[0].value = item.model.element._id
            }
            const tipo = item.model ? item.model.element.tipo : item.tipo
            console.log(options)
            console.log(item.model)
            console.log(CreateOperador.optionsList[tipo])
            for (const o of options.concat(CreateOperador.optionsList[tipo])) {
                o.operador = result._id
                o.projId = result.projId
                const [re, err] = await this.repository.create("OpcoesOperadores", o)
                if (err) console.error(re);
            }
        }

        console.log(modal);
        snackbar.show("Operador criado!!!", 3000, "success")
        return operador
        //modal.show(new FormMacro(this.repository, macro))
        //Tem que chamar o model de wait e depois carregar o modal config passando a macro por model
    }
}
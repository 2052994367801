import Repository from "@/core/repository/_repository";
import { snackbar } from "@/stores/login";

export default class SetFavoriteProject {
    static action = {
        timeout: 3000,
        text: "Atualizando Relacionamento Usuário"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any): Promise<string> {
        //Object.assign(form.model, fields)
        console.log(form.model.user._id)
        console.log(fields);
        form.model.favorite = fields.favorite
        // Essa é um situracao que o usuario poderia coloar outro id e usar o username dele para fazer o update
        const [result, err] = await this.repository.update("Usuarios", form.model.user._id, {username: form.model.user.username, favorite: fields.favorite})
        if(err) console.error(result);
        else console.log(result)
        
        return "close"
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import { NameValueList } from "@/core/entity/_lib";
import Repository from "@/core/repository/_repository";

export default class FormSelectTela {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.repository = repository
        this.model = model
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const telas: NameValueList = []
        this.model.root.elements.forEach((e: any) => {
            console.log(e);
            
            if(e.model._id) telas.push({name: e.model.titulo, value: e.model._id})
        });
        return {
            "extras": new Extra(`Programacao do ${this.model.root.titulo}`, "Adicionar", "end/addtelaprogramacao/true"),
            "tela": Field.make("select", "Telas", "Selecionar", telas)
        }
    }
}
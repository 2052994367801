import AgregadorSubs from "./AgregadorSubs"
import ComponentesConexao from "./ComponentesConexao"
import ComponentesRoot from "./ComponentesRoot"
import RootComponentes from "./RootComponentes"
import Hall from "./Hall"
import ProjetosModulos from "./ProjetosModulos"
const Views: {[key: string]: any} = {
    ComponentesConexao,
    ComponentesRoot,
    RootComponentes,
    AgregadorSubs,
    Hall,
    ProjetosModulos,
}

export default Views
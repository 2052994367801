import Checksum from "@/core/AZ1/checksum";
import Repository from "@/core/repository/_repository"
import { checkModulo } from "@/Features/Componentes/controllers/checkModulo";
import StaticModule from "@/service/module/_module";

export default class UpdateProgramacaoLocal {
    static action = {
        timeout: 10000,
        text: "Atualizando Programação"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        fields.projId = form.model.projId
        await this.repository.update("ProgramacoesModulos", form.model._id, fields)
        const [modId, e485, , err] = checkModulo(form.model)
        if (err) console.error(modId, form.model);
        else {
            const pkg = [255, 1, 11, 12, 0, e485, 15, Number(form.model.programacao.id), Number(fields.out3), Number(fields.out2), Number(fields.out1), Number(fields.out0), 5, 59]
            console.log(pkg)
            const command = Checksum.calc(pkg).join(",")
            const params = {
                payload: command,
                topic: `${form.model.projId}/${modId}/CMD/CMD`,
            }
            console.log(params)
            StaticModule.io.send(`${form.model.projId}/${modId}/CMD/CMD`, Checksum.calc(pkg).join(","))
        }
        return "close"
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormBotaoIcon from "./botoes/icon";
import FormBotao from "./botoes/botao";
import SelectTypes from "./botoes/selectTypes";
import SelectToPainel from "./botoes/selectpainel";
import TemplateIrCommand from "./template/irCommand";
import TemplateControle from "./template/controle";
import FormStatusSub from "./statussub";

export default class FormEditButtons {
    repository: Repository
    model: any
    list: {[key: string]: typeof FormBotaoIcon} = {
        "botaoiconeSQ": FormBotaoIcon,
        "botao": FormBotao,
        "statussub" : FormStatusSub
    }
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const add = new SelectTypes(this.repository, { titulo: "+ Adicionar", parent: this.model})
        const botoes: any[] = [add]
        const painel: any[] = []
        this.model.control.forEach((b: any) => {
            if(Object.prototype.hasOwnProperty.call(this.list, b.tipo))
            botoes.push(new this.list[b.tipo](this.repository, b))
        });
        [1,2,3,4].forEach(n => painel.push(new SelectToPainel(this.repository, {nome:`Botão ${n}`, i: n, parent: this.model})))
        return {
            "extras": new Extra(this.model.titulo, "none", ""),
            "painel": new Field("object", "Botões do componente", "", painel),
            "gerar": new Field("object", "Gerar", "", [
                new TemplateIrCommand(this.repository, {parent: this.model}), 
                new TemplateControle(this.repository, {parent: this.model})
            ]),
            "operadores": new Field("objectV", "Todos os botões", "", botoes),
        }
    }
}
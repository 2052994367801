<template>
  <main class="d-grid gap" onselectstart="return false">
    <button @click="createComponente">Adicionar Projeto</button>
    <div class="d-flex a-center gap-m" v-for="projeto in projetos" :key="projeto._id">
      <card-title-description :value="projeto" @mostraModulo="mostraProjeto" />
    </div>
  </main>
</template>

<script>
import { modal } from "@/stores/showModal";
import ComponentForms from "@/Features/Componentes/forms/_list";
import cardTitleDescription from "@/components/cards/_cardprojId.vue";
import FormChoose from "@/Features/Usuario/forms/choose";
import FormProjeto from "@/Features/Projeto/form/projeto";
import FormCreateProjeto from "@/Features/Projeto/form/createProjeto";
import FormProjetoLocal from "@/Features/Projeto/form/local";
export default {
  name: "page-projetos",
  props: {
    project: Object
  },
  components: {
    cardTitleDescription,
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de modulos" },
      dropDown: false,
      configurar: false,
      configurando: false,
      adapter: {},
      configLayer: 0,
      root: {},
      projetos: [],
      create: FormChoose,
      show: ComponentForms,
      query: {
        projId: this.$route.params.projId,
      },
    };
  },
  async created() {
    this.header.subtitle = "Gerenciamento de Componentes";
    console.log(this.project)
    const [projetos, err] = await this.$repository.findOne(
      "RevendaProjetos",
      {
        projId: this.project.projId
      }
    );
    if (err) console.error("Erro ao pegar Usuarios:", projetos);
    else this.projetos = projetos.projetos
  },
  methods: {
    mostraProjeto(projeto) {
      console.log(projeto);
      projeto.parent = this.project
      if (projeto.tipo === "local" || projeto.tipo === "turnlocal")
        modal.show(new FormProjetoLocal(this.$repository, projeto, this.projetos));
      else
        modal.show(new FormProjeto(this.$repository, projeto, this.projetos));
    },
    createComponente() {
      modal.show(
        new FormCreateProjeto(this.$repository, { revenda: this.project._id, lista: this.projetos })
      );
    },
  },
};
</script>
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import FormPosition from "./styles/position"
import FormSize from "./styles/size"
export default class FormElement {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.repository = repository
        this.model = model
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const position = new FormPosition(this.repository, {objname: "Posição", element: this.model})
        const size = new FormSize(this.repository, {objname: "Tamanho", element: this.model})
        return {
            "extras": new Extra(this.model.titulo,"Atualizar", "end/updatecomponente/last/back", {}, true, "deletecomponente/true"),
            "titulo": new Field("text", "Título", "nome"),
            "style": new Field("object", "Estilo", "", [position, size]),
        }
    }
}
import Checksum from "@/core/AZ1/checksum";
import Repository from "@/core/repository/_repository";
import StaticModule from "@/service/module/_module";
import { store } from "@/store";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";

export default class UpdateSetpoint {
    static action = {
        timeout: 10000,
        text: "Atualizando Setpoint"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        fields.enable = fields.enable ? fields.enable : 0
        if(fields.offset < -10 || fields.offset > 10) return "error/Offset tem limíte de -10,0 a +10,0"
        Object.assign(form.model.options, fields)
        const [result, err] = await this.repository.update("Componentes", form.model._id, {
            projId: form.model.projId,
            options: form.model.options
        })
        console.log(result, err)
        const [modId, e485, , error] = checkModulo(form.model)
        if (error) return "close"
        const setpoint = (fields.setpoint * 10) + 10000
        const offset = Number(fields.offset) !== 255 ? ((Number(fields.offset) * 10) + 100) : 255
        const pkg = [255, 1, 16, 12, 0, 1, 29, 0, ((setpoint & 0xFF00) >> 8), (setpoint & 0x00FF), ((fields.t1 & 0xFF00) >> 8), (fields.t1 & 0x00FF), 0, 0, offset, Number(fields.histerese), Number(fields.enable), 4, 108]
        console.log(pkg)
        const [module, merr] = await StaticModule.io.send(`${form.model.projId}/${modId}/CMD/CMD`, Checksum.calc(pkg).join(","), { query: `CMD/${e485}/13/29` })
        if (merr) snackbar.show(module, 3000, "error")
        return "close"
    }
}
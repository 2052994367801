import Repository from "@/core/repository/_repository";
export default class UpdateConexao {
    static action = {
        timeout: 3000,
        text: "Atualizando Módulo"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        Object.assign(form.model, fields)
        const [result, err] = await this.repository.update("Conexoes", form.model._id, form.model)
        if (err) console.error(result);
        else console.log(result);
        //aqui tem que mandar para o modulo o configuração do min e max
        return "close"
    }
}
import Porta from "../Portas/_Porta"

export default class Conexao {
    _id = ""
    projId = ""
    projeto = ""
    porta = ""
    nome: string
    tipo: string
    componente = ""


    constructor()
    constructor(nome: string, tipo: string)
    constructor(nome?: string, tipo?: string) {
        this.nome = nome ? nome : ""
        this.tipo = tipo ? tipo : ""
    }

    setPorta(porta: Porta) {
        this.porta = porta._id
    }
}
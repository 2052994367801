<template>
    <card :class="[
        'd-grid',
        'gap-m',
        'ai-start',
        'a-center',
        'row-mc',
        'row-13',
        'column-fit-l',
    ]" @config="config">
        <div @click="screen" class="d-flex justify-between">
            <h2>{{ component.titulo }}</h2>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ccc" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize">
                <polyline points="9 18 15 12 9 6" />
            </svg>
        </div>
        <p v-if="component.options.enable" style="font-size: 14px;">
            Alerta se maior que <strong>{{ component.options.setpoint }}°C</strong> por mais de <strong>{{
                minTommhh(component.options.t1) }}</strong>.
        </p>
        <div class="chart" style="height: 100%;">
            <canvas :id="component._id" class="chart"></canvas>
        </div>
    </card>
</template>
  
<script>
import { Chart, registerables, Colors } from "chart.js";
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
import arrumaData from "@/core/entity/ArrumaData";
import { modal, modalcontrole } from "@/stores/showModal";
import FormSetpoint from "../form/setpoint";
Chart.register(...registerables);
Chart.register(Colors)
const theme = localStorage.getItem("theme")
Chart.defaults.color = theme === "dark" ? "#cdcdcd" : "#60605f"
export default {
    components: {
        Card,
    },
    props: {
        component: Object,
    },
    data() {
        return {
            value: false,
            components,
            i: 0,
            chart: {},
            element: {}
        };
    },
    async created() {
        const [amostras, err] = await this.$repository.findUseCase("GraficoAmostras", {
            projId: this.component.projId,
            grafico: this.component._id
        })
        if (err) console.error(amostras)
        console.log(amostras)
        amostras.reverse()
        let minValue, maxValue
        minValue = maxValue = amostras[0].max
        const labels = []
        const data = amostras.map(a => {
            labels.push(arrumaData(a.date))
            if (a.max < minValue) minValue = a.max
            if (a.max > maxValue) maxValue = a.max
            return a.max
        })
        const ctx = document.getElementById(this.component._id)
        this.element = ctx
        const chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels,
                datasets: [{
                    data,
                    borderColor: theme === "dark" ? "#cdcdcd" : "#60605f",
                    fill: false,
                    borderWidth: 2,
                    lineTension: 0.3,
                    pointRadius: 0.5,
                    maxValue,
                    minValue
                },]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        min: 14,
                        max: 30,
                    },
                    x: {
                        ticks: {
                            autoSkip: true,
                            autoSkipPadding: 10,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    colors: {
                        enabled: false
                    }
                },
            }
        })
        this.i = components.list.map((c) => c._id).indexOf(this.component._id);
        this.components.list[this.i].update = this.update.bind(this, chart);
        this.ajustaLimitesEixoY(chart)
        chart.update()
        this.resizeChart()
    },
    methods: {
        sleep(time) {
            return new Promise(res => setTimeout(() => res(), time))
        },
        async resizeChart() {
            this.element.parentElement.style.height = "99%"
            await this.sleep(1000)
            this.element.parentElement.style.height = "100%"
        },
        update(chart, amostra) {
            console.log(amostra)
            if (amostra)
                this.plotar(chart, amostra.date, amostra.max)
            this.resizeChart()
        },
        plotar(chart, date, temperatura) {
            if (chart.data.datasets[0].data.length > 300) {
                chart.data.datasets[0].data.shift();
                chart.data.labels.shift();
            }

            chart.data.labels.push(arrumaData(date));
            chart.data.datasets[0].data.push(temperatura);
            if (this.needAjust(chart, temperatura))
                this.ajustaLimitesEixoY(chart)
            chart.update();
        },
        needAjust(chart, temperatura) {
            if (temperatura > chart.data.datasets[0].maxValue) {
                chart.data.datasets[0].maxValue = temperatura
                return true
            }
            if (temperatura < chart.data.datasets[0].minValue) {
                chart.data.datasets[0].minValue = temperatura
                return true
            }
            return false
        },
        ajustaLimitesEixoY(chart) {
            let padding = 3
            if (chart.data.datasets[0].minValue < 0 && chart.data.datasets[0].maxValue < 0)
                chart.data.datasets[0].maxValue = 1;
            const amplitude = chart.data.datasets[0].maxValue - chart.data.datasets[0].minValue;
            if (amplitude > 4) padding = 1;

            chart.options.scales.y.min = Math.floor(chart.data.datasets[0].minValue - padding);
            chart.options.scales.y.max = Math.ceil(chart.data.datasets[0].maxValue + padding);
        },
        setpoint() {
            modal.show(new FormSetpoint(this.$repository, this.component))
        },
        minTommhh(min) {
            if (!isNaN(min)) {
                let h = Math.floor(min / 60);
                let m = Math.floor(min % 60);
                if (m.length == 1) m = "0" + m;
                h = h > 0 ? h + "h" : "";
                return h + m + "min";
            } else {
                return "--"
            }
        },
        screen() {
            console.log("#######");
            console.log(this.component);
            const component = {}
            Object.assign(component, this.component)
            component.control = [
                { tipo: "configsetpoint", parent: component },
                { tipo: "amostrastable", parent: component },
            ]
            modalcontrole.show(component.control, component);
        },
    },
};
</script>
  
<style>
div.chart {
    background-color: var(--chart-background);
    padding: 10px;
    border-radius: 0.5em;
}

.chart canvas {
    width: 100% !important;
}
</style>
import { getStorage, setStorage } from "@/scripts/Storage";
import { store } from "@/store";
import { components, conexao, display, modulos } from "@/stores/components";
import { Socket as io } from "socket.io-client";
import SocketSession from "./session/session";
import State from "./states/state";
import WSModule from "@/core/module/websocket";
import RepositorySocket from "@/core/repository/Socket";
import Peer from "./wrtc/peer";

export default class Socket {
    static io: io
    static auth: boolean
    static async setTokens(accessToken: string, refreshToken: string) {
        this.io.emit("settoken", { accessToken, refreshToken })
        const [result, err] = await this.io.wait("settoken");
        if (err) console.error("Token invalido");
        else if (result.new) {
            setStorage("accessToken", result.accessToken);
            setStorage("refreshToken", result.refreshToken);
        }
        this.auth = !err
    }
    static async setOns(path: string) {
        const accessToken = (await getStorage("accessToken")).value;
        const refreshToken = (await getStorage("refreshToken")).value;
        await this.setTokens(accessToken ? accessToken : "", refreshToken ? refreshToken : "")
        console.log(store.state.projId)
        if (store.state.projId) {
            //const state = { state: "project", params: { projId: store.state.projId } };
            if (State.state.name === "project") State.state.setup(State.app)
            else if (State.state.name !== "p2p") State.setState("project")
            components.key++
        }

        if (!this.io.listenersAny().length)
            this.io.onAny(async (event, args) => {
                const cmd = event.split("/")[0]
                switch (cmd) {
                    case "component":
                        components.update(args)
                        break;
                    case "redisplay":
                        if (display.elements.find((e: any) => e._id === args._id))
                            display.key++
                        break;
                    case "modulostatus":
                        modulos.update(args)
                        break;
                    case "entrada":
                        conexao.on(args)
                        break;
                    default:
                        //console.log("😃", event, args)
                        SocketSession.execute(event, args)
                        break;
                }
            })
        console.log(this.io.listenersAny())
    }

    static backToSocket() {
        Peer.channel.close()
        State.app.config.globalProperties.$module = new WSModule(this.io)
        State.app.config.globalProperties.$repository = new RepositorySocket(this.io)
    }
}
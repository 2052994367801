import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"

export default class FormCreateServidorLocal {
    repository: Repository
    model: any
    lista: any[]
    constructor(repository: Repository, model: any, lista: any[]) {
        this.model = model
        this.repository = repository
        this.lista = lista
    }

    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Novo Servidor Local", "Criar", "createservidorlocal"),
            "nome": Field.make("text", "Nome", "servidor-local")
        }
    }
}
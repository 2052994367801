import Repository from "@/core/repository/_repository";
import Botao from "../../botao/_botao";
import Componente from "../../_componente";
import Agregador from "../_agregador";

export default class TVControl {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, fields: any): Promise<[Componente, boolean]> {
        const agregador = new Agregador();
        agregador.titulo = fields.titulo
        agregador.projId = root.projId
        agregador.projeto = root.projeto
        agregador.main = true
        agregador.options.code = "template"
        agregador.options.type = "tv"
        const botoes = [
            { tipo: "botaoiconeSQ",icon: { icon: "controller-power", status: "normal" }, title: "", code: "POWER", action: "ir", repetir: "1" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-home", status: "normal" }, title: "", code: "HOME", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-menu", status: "normal" }, title: "", code: "MENU", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-input", status: "normal" }, title: "", code: "INPUT", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-plus", status: "normal" }, title: "", code: "VOLplus", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-minus", status: "normal" }, title: "", code: "VOLminus", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-mute", status: "normal" }, title: "", code: "MUTE", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-plus", status: "normal" }, title: "", code: "CHplus", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-minus", status: "normal" }, title: "", code: "CHminus", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-arrow-up", status: "normal" }, title: "", code: "UP", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-arrow-left", status: "normal" }, title: "", code: "LEFT", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-arrow-down", status: "normal" }, title: "", code: "DOWN", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-arrow-right", status: "normal" }, title: "", code: "RIGHT", action: "ir", repetir: "5" },
            { tipo: "botao", title: "Ok", code: "OK", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-back", status: "normal" }, title: "", code: "BACK", action: "ir", repetir: "5" },
            { tipo: "botaoiconeSQ",icon: { icon: "controller-settings", status: "normal" }, title: "", code: "CONF", action: "ir", repetir: "5" },
            { tipo: "botao", title: "1", code: "NUM1", action: "ir", repetir: "5" },
            { tipo: "botao", title: "2", code: "NUM2", action: "ir", repetir: "5" },
            { tipo: "botao", title: "3", code: "NUM3", action: "ir", repetir: "5" },
            { tipo: "botao", title: "4", code: "NUM4", action: "ir", repetir: "5" },
            { tipo: "botao", title: "5", code: "NUM5", action: "ir", repetir: "5" },
            { tipo: "botao", title: "6", code: "NUM6", action: "ir", repetir: "5" },
            { tipo: "botao", title: "7", code: "NUM7", action: "ir", repetir: "5" },
            { tipo: "botao", title: "8", code: "NUM8", action: "ir", repetir: "5" },
            { tipo: "botao", title: "9", code: "NUM9", action: "ir", repetir: "5" },
            { tipo: "botao", title: "0", code: "NUM0", action: "ir", repetir: "5" },
        ].map(b => {
            const botao = new Botao()
            botao.projId = root.projId
            botao.projeto = root.projeto
            botao.tipo = b.tipo
            botao.titulo = b.title
            botao.options.action = b.action
            botao.options.repetir = fields.comandos ? fields.comandos[b.code].repetir : ""
            botao.options.command = fields.comandos ? fields.comandos[b.code].command : ""
            botao.options.code = b.code
            if (b.icon) botao.options.icon = b.icon
            return botao
        });

        const [result, err] = await this.repository.create("Componentes", agregador)
        if (err) console.error(result)
        else Object.assign(agregador, result)

        //criando botoes que ficarao no componente
        let i = 1
        for (const botao of botoes.splice(0, 4)) {
            botao.painel = agregador._id
            botao.control = agregador._id
            botao.index = i++
            const [result, err] = await this.repository.create("Componentes", botao)
            if (err) console.error(result);
        }

        //inserindo botoes liga desliga na tela do controle
        for (const botao of botoes.splice(0)) {
            botao.control = agregador._id
            const [result, err] = await this.repository.create("Componentes", botao)
            if (err) console.error(result);
        }

        console.log(agregador)
        return [agregador, false]
    }
}

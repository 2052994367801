import Repository from "@/core/repository/_repository"

type Update = { nome: string; projId: string; id?: string}
export default class UpdateProgramacao {
    static action = {
        timeout: 10000,
        text: "Atualizando Programação"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        form.model.nome = fields.nome
        const update: Update = { nome: fields.nome, projId: form.model.projId }
        if(form.model.tipo === "cenalocal") update.id = fields.id
        await this.repository.update("Programacoes", form.model._id, update)
        //Tem que acrescentar na lista os outros componentes como interrutor dimmer e passar o tipo no model para usar um usecase generico que só cria o componente
        /* const [newcomponent, err] = await new UseCases.list[fields.tipo](this.repository).execute(fields)
        if (err) console.error(newcomponent);
        else console.log(newcomponent); */

        return "close"
    }
}
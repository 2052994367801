<template>
  <main class="d-grid gap" onselectstart="return false">
    <button @click="createModulo">Adicionar Módulo</button>
    <card-title-description
      v-for="modulo in modulos"
      :key="modulo._id"
      :value="modulo"
      @mostraModulo="mostraModulo"
    />
  </main>
</template>

<script>
import { modal } from "@/stores/showModal";
import ModuloForms from "@/Features/Modulo/forms/_list";
import AdapterCreateModulo from "@/Features/Modulo/forms/CreateModulo";
import cardTitleDescription from "@/components/cards/_card.vue";
import { modulos } from "@/Features/Modulo/store/list";
export default {
  name: "page-gerenciar",
  components: {
    cardTitleDescription,
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de modulos" },
      dropDown: false,
      configurar: false,
      configurando: false,
      adapter: {},
      configLayer: 0,
      modulos: modulos.list,
      create: AdapterCreateModulo,
      show: ModuloForms,
      collection: "Modulos",
      query: {
        projId: this.$route.params.projId,
      },
    };
  },
  async created() {
    modulos.clear();
    const [result, err] = await this.$repository.findMany(
      this.collection,
      this.query
    );
    if (err) console.error("Erro ao pegar modulos");
    else result.forEach((m) => modulos.push(m));
  },
  methods: {
    mostraDropDown(button) {
      if (this.dropDown) this.dropDown = false;
      else {
        this.dropDown = true;
        this.topDropDown = button.offsetTop + button.offsetHeight;
      }
    },

    mostraConfiguracao() {
      this.configurando = this.configurando ? false : true;
    },
    async updateConfiguracao(/* adapter */) {
      this.configurando = false;
      const [result, err] = await this.$repository.findMany(this.collection, {
        projId: this.$route.params.projId,
      });
      if (err) console.error("Erro ao pegar modulos");
      else this.modulos = result;
    },
    mostraModulo(modulo) {
      console.log("mostrando modulo");
      this.configLayer++;
      this.adapter = {};
      modal.show(new this.show.list[modulo.modelo](this.$repository, modulo));
    },
    mostraObject(obj) {
      console.log(obj);
      /* this.configLayer++;

      this.adapter = obj; */
    },
    createModulo() {
      modal.show(new this.show.list["createmodulo"](this.$repository, {}));
    },
  },
};
</script>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
const types: {[key: string]: string} = {
    "imageselect": "toggle",
    "textpoints": "text",
    "text": "text",
    "number": "number"
}
export default class AddValue {
    repository: Repository
    model: any
    constructor(repository: Repository, model: { titulo: string; table: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra("Adicionar Valor", "Adicionar", "end/addvalue/last"),
            "err": new Field("show", "ERRO AO PEGAR COLUNAS", "")
        }
        const [c, e] = await this.repository.findMany("TableColumns", {
            projId: this.model.table.projId,
            table: this.model.table._id
        })
        if(e) return fields
        delete fields.err
        this.model.columns = ({} as any)
        c.forEach(c => {
            fields[c.key] = new Field(types[c.type], c.name, "")
            this.model.columns[c.key] = c._id
        })
        return fields

    }
}
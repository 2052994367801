import SafeJSONParser from "@/core/lib/safejsonparser";
import Repository from "@/core/repository/_repository";
import { snackbar } from "@/stores/login";
import { wait } from "@/stores/showModal";
import AddValue from "../../controller/addvalue";

export default class UpdateFromAPI {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(table: any, option: string) {
        console.log(table)
        if(!table.options.url || table.options.url === "") {
            snackbar.show("Sem URL para pegar dados da API", 3000, "error")
            return "refresh"
        }
        wait.show(30000, "Pegando dados da API")
        const get = await new Promise<any[]>((res) => {
            const http = new XMLHttpRequest()
            http.onreadystatechange = () => {
                if (http.readyState === 4 && http.status === 200) {
                    const [result, err] = SafeJSONParser(http.responseText)
                    res(err ? [] : result)
                } else if (http.status > 300) res([])
            }
            http.open("GET", table.options.url, true);
            if (table.options.hkey)
                http.setRequestHeader(table.options.hkey, table.options.hvalue)
            http.send()
        })
        if (!get.length) return false
        const [element, err] = await this.repository.findOne("TableElements", {
            _id: table._id,
            projId: table.projId
        })
        if(err) console.error(element)
        const result: { [key: string]: any } = {}
        get.forEach(g => {
            if (Object.prototype.hasOwnProperty.call(g, table.options.primary))
                result[g[table.options.primary]] = g
        })
        for (const r of element.rows) {
            const p = r.fields.find((f: any) => f.key === table.options.primary)
            if (p) for (const f of r.fields) {
                f.value = result[p.value][f.key]
                delete f.column
                console.log(await this.repository.update("TableFields", f._id, f))
            }
            delete result[p.value]
        }
        if (option === "insert") {
            const add = new AddValue(this.repository)
            const columns: { [key: string]: any } = {}
            element.columns.forEach((c: any) => columns[c.key] = c._id)
            for (const key in result) {
                await add.execute({
                    model: {
                        table: element,
                        columns
                    }
                }, result[key])
            }
        }
        wait.show(0,"")
        return "refresh"
    }
}
import Repository from "@/core/repository/_repository";
export default class UpdateEndpoint {
    static action = {
        timeout: 30000,
        text: "Atualizando Endpoint"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, options: any): Promise<string> {
        console.log(form)
        console.log(options)
        const update = {
            username: form.user.username,
        }
        const [result, err] = await this.repository.update("Endpoints",form.model._id, Object.assign(update, options))
        if (err) return `err/${result}`
        return "back"
    }

    sleep(time: number) {
        return new Promise(res => setTimeout(() => res(0), time))
    }
}
<template>
  <main class="d-grid gap" onselectstart="return false">
    <button @click="createComponente">Adicionar Usuário</button>
    <div
      class="d-flex a-center gap-m"
      v-for="usuario in usuarios"
      :key="usuario._id"
    >
      <card-title-description :value="usuario" @mostraModulo="mostraUsuario" />
    </div>
  </main>
</template>

<script>
import { modal } from "@/stores/showModal";
import ComponentForms from "@/Features/Componentes/forms/_list";
import cardTitleDescription from "@/components/cards/_card.vue";
import FormChoose from "@/Features/Usuario/forms/choose";
import FormRelacionamentoUsuario from "@/Features/Usuario/forms/relacionamento";
import { push } from "ionicons/icons";
export default {
  name: "page-usuarios",
  components: {
    cardTitleDescription,
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de modulos" },
      dropDown: false,
      configurar: false,
      configurando: false,
      adapter: {},
      configLayer: 0,
      root: {},
      usuarios: [],
      create: FormChoose,
      show: ComponentForms,
      collection: "UsuariosProjeto",
      query: {
        projId: this.$route.params.projId,
      },
    };
  },
  async created() {
    this.header.subtitle = "Gerenciamento de Componentes";
    const [usuarios, err] = await this.$repository.findMany(
      this.collection,
      this.query
    );
    if (err) console.error("Erro ao pegar Usuarios:", usuarios);
    else usuarios.forEach(u => {
      if(!u.naomostrar) this.usuarios.push(u)
    })
  },
  methods: {
    async updateConfiguracao(/* adapter */) {
      this.configurando = false;
      const [result, err] = await this.$repository.findMany(this.collection, {
        projId: this.$route.params.projId,
      });
      if (err) console.error("Erro ao pegar modulos");
      else this.modulos = result;
    },
    mostraUsuario(usuario) {
      console.log(usuario);
      modal.show(new FormRelacionamentoUsuario(this.$repository, usuario, this.usuarios));
    },
    createComponente() {
      modal.show(
        new FormChoose(this.$repository, {list: this.usuarios})
      );
    },
  },
};
</script>
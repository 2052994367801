import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "../../../core/repository/_repository";
import FormCreatePeriodo from "./createPeriodo";

export default class FormChoose {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const forms = [
            new FormCreatePeriodo(this.repository, {titulo: "Evento Único", dia: this.model, tipo: "unico"}),
            new FormCreatePeriodo(this.repository, {titulo: "Evento Periódio", dia: this.model, tipo: "repete"})
        ]
        return {
            "extras": new Extra("Escolha", "none", ""),
            "tipo": new Field("objectV", "Tipos de Eventos", "", forms)
        }
    }
}
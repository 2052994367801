<template>
  <input v-model="value" type="range" max="63" />
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "text",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
    };
  },
  created() {
    this.value = this.$attrs.modelValue;
  },
});
</script>
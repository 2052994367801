import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"

export default class FormSelectProgramacao {
    repository: Repository
    model: any
    evento: any
    constructor(repository: Repository, model: any, evento: any) {
        this.repository = repository
        this.model = {titulo: evento.name, model}
        this.evento = evento
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        const [result, err] = await this.repository.findMany("Programacoes", { projId: this.model.model.projId })
        const list: {value: string; name: string}[] = []
        console.log(result, err)
        if(!err) result.forEach(r => list.push({value: r._id, name: r.nome}))
        return {
            "extras": new Extra("Selecionar Rotinas", "Adicionar", "end/createeventoprogramacao/true"),
            "rotina": Field.make("select", "Rotinas", "Selecionar", list)
        }
    }
}
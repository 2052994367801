<template>
  <div class="d-grid gap-mid o-scroll">
    <label v-for="item in list" v-bind:key="item.value" :for="item.value" class="d-grid gap-p p-10 w-100 jc-normal"
      :class="[item.icon ? 'icon center' : 'item']">
      <div class="d-flex gap-m">
        <input type="checkbox" :id="item.value" v-model="item.data" @change="retorna(item)">
        <p>{{ item.name }}</p>
      </div>
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  components: { },
  name: "checkbox",
  props: {
    field: Object,
    atribkey: String
  },
  created() {
    this.list = this.field.list;
  },
  data() {
    return {
      value: "",
      list: [],
    };
  },
  methods: {
    async retorna(item) {
      console.log(item, this.list)
      const datas = []
      this.list.forEach(i => {
        if(i.data) datas.push(i)
      })
      console.log(this.atribkey, datas)
      this.$emit("updateAtrib", this.atribkey, datas)
    },
  },
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
  cursor: pointer;
}
</style>
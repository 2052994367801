import Repository from "@/core/repository/_repository";
import Socket from "@/service/socket";
import { login, snackbar } from "@/stores/login";
import { modal, wait } from "@/stores/showModal";
import FormAdicionaUsuario from "../forms/addUser";

export default class CreateEndpoint {
    static action = {
        timeout: 30000,
        text: "Criando Endpoint"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, options: any): Promise<string> {
        console.log(form)
        console.log(options)
        const endpoint = {
            "nome": options.nome,
            "push": {},
            "endpoint": "",
            "ativo": true,
            "tipo": options.tipo,
            "username": form.model.user.username,
            "user": form.model.user._id
        }
        if (options.tipo === "telegram") {
            wait.msg = "Redirecionando para Telegram"
            await this.sleep(2000);
            Object.assign(document.createElement('a'), {
                target: '_blank',
                rel: 'noopener noreferrer',
                href: `https://t.me/livtechbot?start=${endpoint.username}`,
            }).click();
        } else if (options.tipo === "push") {
            console.log("ENTROU NO PUSH")
            const sw = await navigator.serviceWorker.ready;
            const push = await sw.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BE5RFmsqe-IQz8GxAlZg2S1NfXcNdC1SCCzqTx4aZgQm2ZMQiL470TLxz7UnAC3mJx5QEHn402kXBnQ5lVO6f0I'
            });
            endpoint.push = push
            endpoint.endpoint = push.endpoint
        }
        console.log(endpoint)
        const [result, err] = await this.repository.create("Endpoints", endpoint)
        if (err) return `err/${result}`
        return "back"
    }

    sleep(time: number) {
        return new Promise(res => setTimeout(() => res(0), time))
    }
}
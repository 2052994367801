import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import SelectModulos from "../../forms/conecta/modulos";
import FormConexao from "@/Features/Modulo/forms/conexao";
import FormServidor from "./servidor";
import FormComandoURL from "./comandoUrl";

export default class FormStatus {
    repository: Repository
    model: any
    fields: any = {}
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        const add = new SelectModulos(this.repository, { model: this.model }, "add")
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.titulo, "Atualizar", "updatecomponentestatus", {}, true, "deletecomponente/true"),
            "titulo": new Field("text", "Titulo", "nome"),
            "nshow": new Field("toggle", "Não Mostrar", "nome"),
            "conexao": new Field("object", "Conexão", "", [add]),
            "snackfild": new Field("snack", "", "", [], this.model, (conexao) => {
                console.log(conexao);
                return false
            }),
            "url":new Field("object", "Comando via URL para Alexa", "", [new FormComandoURL(this.repository, this.model)]),
        }
        console.log(this.model.conexao);
        if (!this.model.conexao || !Object.keys(this.model.conexao).length)
            return fields

        add.type = "change"
        const trash = new SelectModulos(this.repository, {model: this.model}, "trash")
        fields.conexao = Field.make("object", "Conexão", [new FormConexao(this.repository, this.model.conexao), add, trash], i => {
            console.log(i)
            if(i.type === "trash") {
                this.model.conexao = {}
                this.repository.update("Componentes", this.model._id, {
                    projId: this.model.projId,
                    conexao: ""
                })
                return "refresh"
            }
            return true
        })
        
        fields["servidor"] = new Field("object", "Servidor", "", [new FormServidor(this.repository, { nome: "Configurar Programações", model: this.model })]);
        return fields
    }
}
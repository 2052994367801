<template>
    <card :class="['d-grid', 'gap-m', 'row-three', 'column-fit-l', 'pointer', 'ac-start']" @config="config">
        <div @click="screen" class="d-flex justify-between">
            <h2>{{ component.titulo }}</h2>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ccc" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize">
                <polyline points="9 18 15 12 9 6" />
            </svg>
        </div>
    </card>
</template>

<script>
import { modal, modalslot } from "@/stores/showModal";
import ListForms from "../../forms/_list";
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
export default {
    components: {
        Card,
    },
    props: {
        component: Object,
    },
    data() {
        return {
            value: false,
            components,
            i: 0,
        };
    },
    async created() {
        console.log(this.component.conexao.status)
        this.i = components.list.map((c) => c._id).indexOf(this.component._id);
        this.value = this.component.options.state;
        const [component, err] = await this.$repository.findOne("AgregadorSubs", {
            _id: this.component._id,
            projId: this.$route.params.projId,
        });
        if (err) console.error(component);
        else {
            component.conexao = this.component.conexao;
            Object.assign(this.component, component);
            component.painel.forEach((c) => {
                c.parent = this.component;
                //this.components.push(c);
                components.push(c);
            });
            component.control.forEach((c) => components.push(c));
        }
    },
    methods: {
        config() {
            console.log("config");
            if (ListForms.check(this.component.options.action))
                modal.show(
                    new ListForms.forms[this.component.options.action](this.component)
                );
            else
                console.error(`Form "${this.component.options.action}" do not exist`);
        },
        screen() {
            console.log("#######");
            console.log(this.component);
            const component = {}
            Object.assign(component, this.component)
            modalslot.show(component.control, component);
        },
    },
};
</script>

<style>
.acesso .gap-m {
    grid-column-end: span 3 !important;
    padding: 0 !important;
}
.acesso div {
    grid-row-end: span 2;
}
</style>
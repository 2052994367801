<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="32"
    height="32"
    viewBox="0 0 8.4666665 8.4666669"
    version="1.1"
    id="svg5029"
    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    sodipodi:docname="mute.svg"
  >
    <g
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-288.53332)"
    >
      <g
        id="g1384"
        transform="matrix(0.14376255,0,0,0.14376255,-12.73544,61.4743)"
      >
        <path
          class="fill"
          sodipodi:nodetypes="cccccsccccccccccccccc"
          inkscape:connector-curvature="0"
          id="path1483-2-6"
          d="m 126.78124,1583.0781 c -0.13456,0.013 -0.26704,0.039 -0.39844,0.078 -0.45056,0.1352 -2.56955,1.8851 -8.62695,7.1231 l -5.37305,4.5781 16.98047,16.9861 c 3.3e-4,-1.8382 0.008,-0.7368 0.008,-2.9724 0,-21.7929 -0.0227,-23.9788 -0.24414,-24.4453 -0.42602,-0.8978 -1.40377,-1.4378 -2.3457,-1.3477 z m -26.11914,14.1836 c -1.39535,0.027 -2.22355,0.065 -2.36523,0.1192 -0.76143,0.2871 -1.36638,1.0162 -1.54102,1.8574 -0.10658,0.5164 -0.14579,3.8482 -0.11719,10.0273 0.0475,10.2725 1.4e-4,9.801 1.04883,10.5489 l 0.48828,0.3476 7.63086,0.043 3.63086,0.041 8.18945,7.084 c 6.12528,5.2982 8.32744,7.1192 8.73438,7.2226 1.09698,0.2803 2.22165,-0.2052 2.71484,-1.1718 0.17091,-0.3349 0.23364,-2.4121 0.26367,-7.4414 z"
        />
        <path
          class="fill"
          inkscape:connector-curvature="0"
          id="path1533"
          d="m 97.587891,1584.8535 a 2.3502349,2.3502349 0 0 0 -1.636719,4.0352 l 40.835938,40.8359 a 2.3502349,2.3502349 0 1 0 3.32227,-3.3242 l -40.833989,-40.834 a 2.3502349,2.3502349 0 0 0 -1.6875,-0.7129 z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "nomal",
};
</script>
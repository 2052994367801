import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormCreateUsuario {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Criar Conta", "Criar", this.model.add ? "end/createusuario/true" :"createusuario", { text: "Já possui uma conta? ", extra: { type: "login", text: "Entre" } }),
            "nome": new Field("text", "Nome", "Seu nome completo"),
            "email": new Field("text", "E-mail", "exemplo@dominio.com"),
            "username": new Field("text", "Nome de Usuário", "Nome de usuário único"),
            "password": new Field("password", "Senha", "Senha para acessa a conta"),
            "error": new Field("error", "Erro", ""),
        }
    }
}
import Checksum from "@/core/AZ1/checksum";
import Repository from "@/core/repository/_repository";
import StaticModule from "@/service/module/_module";
import { store } from "@/store";
import { checkModulo } from "../../controllers/checkModulo";
export default class SendLocal {
    static action = {
        timeout: 3000,
        text: "Atualizando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        const [modId, e485, canal, err] = checkModulo({conexao: form.model})
        if (err) console.error(modId, form.model);
        else {
            const pkg = [255, 1, 11, 12, 0, e485, 15, canal, isNaN(Number(fields.out3)) ? 255 : Number(fields.out3), Number(fields.out2), Number(fields.out1), Number(fields.out0), 5, 59]
            console.log(pkg)
            const command = Checksum.calc(pkg).join(",")
            const params = {
                payload: command,
                topic: `${form.model.projId}/${modId}/CMD/CMD`,
            }
            console.log(params)
            StaticModule.io.send(`${form.model.projId}/${modId}/CMD/CMD`, Checksum.calc(pkg).join(","))
            //if (fields.tecla)
            StaticModule.io.send(
                `${form.model.projId}/${modId}/CMD/CMD`,
                //255,1,9,12,0,2,16,6,13,255,2,57;
                Checksum.calc(`255,1,9,12,0,${e485},16,${canal},${Number(fields.tecla)},255,2,57;`.split(",").map(s => Number(s))).join(","),
            )
        }
            
        return "close"
    }
}
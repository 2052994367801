<template>
  <app-layout :header="header" @dropDown="mostraDropDown">
    <main class="d-grid gap" onselectstart="return false">
      <card-title-description
        v-for="modulo in modulos"
        :key="modulo._id"
        :value="modulo"
        @mostraModulo="mostraModulo"
      />
    </main>
    <modal-drop-down
      :style="styleDropDown"
      v-if="dropDown"
      @selected="acaoDropDown"
    >
      <li value="2">Criar</li>
      <li value="1">Sair</li>
    </modal-drop-down>
    <modal-configuracao
      v-if="configurando"
      @close="updateConfiguracao"
      @update="updateConfiguracao"
      :adapter="adapter"
      :key="configLayer"
      @object="mostraObject"
    ></modal-configuracao>
  </app-layout>
</template>

<script>
import appLayout from "../layouts/appLayout.vue";
import ModalDropDown from "@/components/Modal/modalDropDown.vue";
import ModalConfiguracao from "@/components/Modal/modalConfiguracao.vue";
import cardTitleDescription from "@/components/cards/_card.vue";
import AdapterCreateChamado from "@/Features/Chamado/form/CreateChamado";
import UpdateChamado from "@/Features/Chamado/form/UpdateChamado";

export default {
  name: "page-chamado",
  components: {
    appLayout,
    ModalDropDown,
    ModalConfiguracao,
    cardTitleDescription,
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Chamados" },
      dropDown: false,
      configurar: false,
      configurando: false,
      adapter: {},
      configLayer: 0,
      modulos: [],
      create: AdapterCreateChamado,
      show: UpdateChamado,
      collection: "Chamados",
      categorias: [],
      status: [],
    };
  },
  async created() {
    console.log(this.$store.state.projeto);
    const [projeto, e] = await this.$repository.findMany("Projetos", {
      projId: this.$route.params.projId,
    });
    console.log(projeto);
    if (e) console.error("erro ao pegar projeto");
    else
      this.$store.commit("setProjeto", {
        projeto: projeto[0],
        socket: this.$socket,
      });
    const [categorias, ec] = await this.$repository.findMany(
      "ChamadosCategorias",
      {}
    );
    if (ec) console.error(categorias);
    else this.categorias = categorias;
    const [status, es] = await this.$repository.findMany("ChamadosStatus", {});
    if (es) console.error(status);
    else this.status = status;
    this.updateConfiguracao();
    /* const evandrouzeda = await this.$repository.findMany("usuarios", {
      username: await new GetUsername(this.$repository).execute(),
    });
    console.log(evandrouzeda); */
  },
  methods: {
    mostraDropDown(button) {
      if (this.dropDown) this.dropDown = false;
      else {
        this.dropDown = true;
        this.topDropDown = button.offsetTop + button.offsetHeight;
      }
    },
    acaoDropDown(selected) {
      switch (selected.value) {
        case 0:
          console.log("abrindo conta");
          this.mudaConexao = true;
          break;
        case 1:
          console.log("saindo fora");
          break;
        case 2:
          console.log("criando elemento");
          this.adapter = new this.create(this.$repository);
          console.log(this.adapter);
          this.configurando = true;
          break;
      }
    },
    mostraConfiguracao() {
      this.configurando = this.configurando ? false : true;
    },
    async updateConfiguracao(/* adapter */) {
      this.configurando = false;
      const projeto = this.$store.state.projeto;
      console.log(projeto);
      const [result, err] = await this.$repository.findMany(this.collection, {
        projeto: projeto._id,
      });
      if (err) console.error(`Erro ao pegar Chamados: ${result}`);
      else {
        result.map((e) => {
          e.nome = this.categorias.length
            ? this.categorias.find((c) => c.value === e.categoria).name
            : e.categoria;
          e.tipo = this.status.length
            ? this.status.find((s) => s.value === e.status).name
            : e.status;
        });
        this.modulos = result;
      }
      /* console.log(adapter);
      delete adapter.repository;
      this.$repository.update(this.collection, adapter._id, adapter); */
    },
    mostraModulo(modulo) {
      console.log("mostrando modulo");
      this.configLayer++;
      this.adapter = {};
      this.adapter = Object.assign(new this.show(this.$repository), modulo);
      this.configurando = true;
      //this.adapter.repository = this.$repository;
      console.log(this.adapter);
    },
    mostraObject(obj) {
      console.log(obj);
    },
  },
};
</script>
<template>
  <div class="d-flex gap o-scroll pb-10">
    <div class="center" v-for="item in field.list" v-bind:key="item._id" :value="item.tipo"
      @click="retorna(item)"
      :class="[item.icon? 'icon': 'objects', item.type]">
      <component v-if="item.icon" v-bind:is="item.type"></component>
      <p v-else>{{ item.model.objname ? item.model.objname : item.model.nome ? item.model.nome : item.model.titulo }}
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import trash from "./icons/trash.vue";
import add from "./icons/add.vue";
import change from "./icons/change.vue";
export default defineComponent({
  components: {
    trash,
    add,
    change
  },
  name: "object",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    async retorna(item) {
      if (!this.field.action) return this.$emit("object", item);
      const result = await this.field.action(item)
      console.log(result)
      if (typeof result === "string") return this.$emit(result, item);

      if (result) return this.$emit("object", item);

      this.$emit("back", item);
    },
  },
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
  white-space: pre;
  cursor: pointer;
}
</style>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import AdicionaModulo from "../controller/adicionaModulo";

export default class SelectModulos {
    repository: Repository
    model: { titulo: string; macro: any }
    constructor(repository: Repository, model: { titulo: string; macro: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const [modulos, err] = await this.repository.findMany("Modulos", {
            projId: this.model.macro.projId
        })
        if (err) return {}
        const list: {value: string; name: string}[] = []
        modulos.forEach(m => {
                list.push({ value: m._id, name: `${m.nome} - ${m.tipo}` })
        });
        return {
            "extras": new Extra("Escolha", "none", "conectacomponente"),
            "modulo": new Field("objectV", "Componentes", "", list, "", async (item: any) => {
                console.log(item)
                //TODO: vou ter que criar ProgramacoesModulos e depois fazer a views
                await new AdicionaModulo(this.repository).execute(this.model.macro, item)
                /* if (item.model.element.tipo === "audio") return true
                await new CreateOperador(this.repository).execute(this.model.macro, item) */
                return false
            })
        }

    }
}
<template>
  <div v-if="conexao.nome" class="d-grid gap-m">
    <label>Sugestão de conexão (última tecla pressionada)</label>
    <div class="d-flex a-center gap-m objects">
      <p class="w-100">{{ conexao.nome }}</p>
      <button @click="retorna">Conectar</button>
    </div>
  </div>
</template>

<script>
import SelectConexoes from "@/Features/Componentes/forms/conecta/conexoes";
import { defineComponent } from "vue";
import { conexao } from "@/stores/components";
export default defineComponent({
  components: {},
  name: "snack",
  props: {
    field: Object,
  },
  created() {
    this.list = this.field.list;
    conexao.on = (c) => {
      console.log(c);
      Object.assign(this.conexao, c);
    };
  },
  data() {
    return {
      value: "",
      conexao: {},
      list: [],
    };
  },
  methods: {
    retorna() {
      console.log(this.field.model);
      const model = {
        modulo: {
          portas: [{ conexoes: [this.conexao] }],
          nome: this.conexao.nome,
        },
        componente: this.field.model,
        conexao: this.conexao._id,
      };
      this.field.action(this.conexao);
      this.$emit("object", new SelectConexoes(this.$repository, model));
    },
  },
  unmounted() {
    console.log("saiu do snackEntrada");
    //this.$socket.off(`entrada/${this.field.model.projId}`)
  }
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
}
.item {
  width: 100%;
}
</style>
import Repository from "../_repository";
import Aggregation from "./aggregation";
import IndexedDB from "./config";
import Method from "./methods";
import Create from "./methods/create";
import Find from "./methods/find";

export default class RepositoryIndexedDB extends Create(Find(Method)) implements Repository{
    type = "indexeddb"
    read(collection: string, id: string): Promise<[any, boolean]> {
        throw new Error("Method not implemented.");
    }
    update(collection: string, id: string, value: any): Promise<[any, boolean]> {
        throw new Error("Method not implemented.");
    }
    updateQuery(collection: string, query: any, value: any): Promise<[any, boolean]> {
        throw new Error("Method not implemented.");
    }

    /* 
        TODO: tenho que organizar esse repository em mixing para organizar melhor
     */

    findManySortLimit(collection: string, query: { [index: string]: any; }, limit: number, sort: 1 | -1): Promise<[any[], boolean]> {
        throw new Error("Method not implemented.");
    }


    findUseCase(collection: string, args: any): Promise<[any, boolean]> {
        throw new Error(`Method not implemented. ${collection}`);
    }

    delete(collection: string, id: string): Promise<[any, boolean]> {
        throw new Error("Method not implemented.");
    }
    deleteUsecase(collection: string, query: any): Promise<[any, boolean]> {
        throw new Error("Method not implemented.");
    }
    deleteMany(collection: string, query: any): Promise<[any, boolean]> {
        throw new Error("Method not implemented.");
    }
    deleteList(collection: string, id: string[]): Promise<[any, boolean]> {
        throw new Error("Method not implemented.");
    }

}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormRelacionamentoUsuario {
    repository: Repository
    model: any
    list: []
    constructor(repository: Repository, model: any, list: []) {
        this.model = model
        this.repository = repository
        this.list = list
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        const fields = {
            "extras": new Extra(this.model.nome, "Atualizar", "updateusuarioprojeto", {}, true, "deleteusuarioprojeto"),
            "username": new Field("show", "Login do Usuário", "username"),
            "nome": new Field("text", "Nome do Usuário", "nome"),
            "acesso": new Field("select", "Nível de Acesso", "Selecione", [
                {name: "Admin", value:"admin"},
                {name: "Usuário", value:"user"},
            ]),
        }
        return fields
    }
}
<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="32"
    height="32"
    viewBox="0 0 8.4666665 8.4666669"
    version="1.1"
    id="svg5029"
    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    sodipodi:docname="temperatura_alerta_17x32.svg"
    class="icon-alert"
  >
    <defs id="defs5023" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="5.6"
      inkscape:cx="-12.408429"
      inkscape:cy="19.551696"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1366"
      inkscape:window-height="705"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      units="px"
      inkscape:showpageshadow="true"
    />
    <metadata id="metadata5026">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-288.53332)"
    >
      <g
        id="g1147"
        transform="matrix(0.26458333,0,0,0.26458333,-138.71917,442.68178)"
      >
        <g
          transform="matrix(0.51512678,0,0,0.51512678,21.80995,-355.0227)"
          id="text1106-8"
          style="
            font-style: normal;
            font-weight: normal;
            font-size: 40px;
            line-height: 1.25;
            font-family: sans-serif;
            letter-spacing: 0px;
            word-spacing: 0px;
            stroke: none;
          "
          aria-label="!"
        >
          <path
            sodipodi:nodetypes="cccccccccc"
            inkscape:connector-curvature="0"
            id="path1108-2"
            d="m 990.65977,-439.31421 -0.70542,15.71738 h -3.32032 l -0.67645,-15.65671 z m -0.39289,23.72519 h -3.98438 v -4.12109 h 3.98438 z"
          />
        </g>
        <path
          inkscape:connector-curvature="0"
          id="path1143-9"
          d="m 530.1203,-574.99724 c -0.078,-0.85937 -0.1717,-2.60318 -0.2074,-3.87513 l -0.065,-2.31262 1.1004,1.2e-4 1.1005,1.3e-4 -0.163,2.66389 c -0.09,1.46514 -0.1629,3.20889 -0.1629,3.875 0,1.17168 -0.024,1.21111 -0.7301,1.21111 -0.7138,0 -0.7333,-0.035 -0.8725,-1.5625 z"
        />
        <path
          inkscape:connector-curvature="0"
          id="path1145-5"
          d="m 529.8709,-570.16549 v -1.0625 h 1 1 v 1.0625 1.0625 h -1 -1 z"
        />
        <path
          inkscape:export-ydpi="96"
          inkscape:export-xdpi="96"
          inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\temperatura_alerta_17x32.png"
          sodipodi:nodetypes="cccccccccccccccccccccsccscccccccccccsccccccccccccccc"
          inkscape:connector-curvature="0"
          id="path4168-7-9-7-6"
          d="m 538.0987,-550.94703 c -2.5556,-0.75649 -4.6784,-2.78188 -5.5867,-5.33025 -0.381,-1.06867 -0.5126,-3.1626 -0.2761,-4.39335 0.2852,-1.48553 1.1714,-3.13579 2.3017,-4.28649 l 0.9851,-1.00268 0.046,-6.40325 0.045,-6.40327 0.4127,-0.89341 c 0.4791,-1.03755 1.4864,-2.05511 2.4938,-2.51948 1.038,-0.47838 2.9701,-0.44681 4.0649,0.0664 0.9954,0.46669 1.8525,1.30304 2.3583,2.30143 0.2978,0.5875 0.3989,1.03127 0.4535,1.99052 l 0.07,1.23445 1.5219,0.0903 c 1.3989,0.0831 1.548,0.12005 1.8442,0.45853 0.3944,0.45048 0.4144,1.06666 0.049,1.51864 -0.2492,0.30821 -0.4116,0.34469 -1.827,0.40997 l -1.5538,0.0718 -0.042,1.23297 -0.042,1.23294 0.8862,0.0927 c 0.4873,0.051 1.1943,0.0927 1.5712,0.0927 0.5694,0 0.7353,0.0637 0.9821,0.37756 0.4019,0.51107 0.3804,1.12842 -0.054,1.56354 -0.329,0.329 -0.4497,0.35175 -1.863,0.35175 h -1.5113 v 1.56444 1.56443 l 0.9016,0.86575 c 3.438,3.30107 3.5238,8.65772 0.1924,11.97393 -2.268,2.2577 -5.4102,3.06991 -8.4241,2.1777 z m -1.5945,-7.6546 c 0.07,-0.17736 0.2376,-0.63623 0.3735,-1.01973 0.346,-0.97738 1.4874,-2.51098 2.9581,-3.40977 0.6632,-0.40523 1.242,-0.86964 1.3332,-1.06985 0.112,-0.24399 0.1904,-2.28462 0.1689,-6.43209 v -6.01202 h 0.4948 1.2761 l -0.066,-0.89565 c -0.037,-0.49262 -0.094,-1.03579 -0.1295,-1.20705 -0.161,-0.79739 -1.476,-1.75632 -2.418,-1.76337 -0.7266,-0.006 -1.6509,0.54976 -2.1037,1.26364 l -0.4137,0.65227 v 6.70358 6.70351 l -0.4656,0.41732 c -1.8995,1.70198 -2.1448,1.97753 -2.6229,2.947 -0.5679,1.15164 -0.822,2.76814 -0.4969,3.15994 0.2772,0.334 1.9641,0.34075 2.1126,-0.0377 z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "alert",
};
</script>
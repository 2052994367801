import Repository from "@/core/repository/_repository"

export default class CreateWallpaper {
    static action = {
        timeout: 10000,
        text: "Criando Wallpaper"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        const [result, err] = await this.repository.create("Wallpapers", {
            name: fields.name,
            projId: form.model.projId
        })
        console.log(result, err)
        return "back"
    }
}
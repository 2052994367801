import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "../../../core/repository/_repository";

export default class FormNomeProgramacao {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model);
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra("Criar Programação", "Criar", "end/createprogramacao"),
            "titulo": new Field("text", "Nome", "Nome do Componente"),
            "tipo": new Field("show", "Programação", "Nome do Componente"),
        }
        for (const key in this.model) {
            if (key !== "tipo" && key !== "titulo" && key !== "nome")
                fields[key] = new Field("show", key, "")
        }
        return fields
    }
}
import Repository from "@/core/repository/_repository";
export default class CreateDelimitadores {
    static action = {
        timeout: 3000,
        text: "Conectando Compenente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        form.model[form.model.delimitador.tipo] = {
            tipo: form.model.delimitador.tipo,
            hora: fields.hora,
            action: fields.action ? fields.action : "none"
        }
        console.log(form.model[form.model.delimitador.tipo])
        return "back"
    }
}
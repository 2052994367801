import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormTituloComponente from "../nomeComponente";
import FormSelectMarcas from "./_selectMarca";

export default class FormSelectControle {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const forms: any[] = []
        const [controls, err] = await this.repository.findMany("ControlesIR", {})
        if (err) {
            console.log(controls);
            return {
                "extras": new Extra("Selecione Tipo", "none", "none"),
                "tipo": new Field("objectV", "Tipos", "", forms)
            }
        }
        controls.forEach(c => {
            c.code = "template"
            forms.push(new FormSelectMarcas(this.repository, c))
        });
        forms.push(new FormTituloComponente(this.repository, {
            tipo: "custom",
            comandos: "custom",
            nome: "Customizado",
            titulo: `Customizado`.toUpperCase()
        }))
        return {
            "extras": new Extra("Selecione Tipo", "none", "none"),
            "tipo": new Field("objectV", "Tipos", "", forms)
        }
    }
}
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.css';
import './theme/color.css';

//import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client';
import { store } from './store';
//import { getStorage } from './scripts/Storage';
import repository from './plugins/repository';
import modulo from './plugins/modulo'
import state from './plugins/state'
import socketio from './plugins/socket';
import Socket from './service/socket';
import SocketSession from './service/session/session';
import StaticModule from './service/module/_module';
import PreventPop from './router/pop';
import { server } from './stores/login';
import teste from './core/repository/IndexedDB/teste';
//await storage.create();
console.log("👉 Server Url:", server.url)
const socket = SocketIO(server.url, {
  auth: {
    accessToken: "",
    refreshToken: ""
  }
})
Socket.io = socket
//Socket.setOns()
const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  //.use(repository, { tipo: "indexedDB", socket: IDB })
  .use(repository, { tipo: "socket", socket })
  .use(modulo, { tipo: "socket", socket })
  .use(state)
  .use(socketio, { socket });

SocketSession.app = app
StaticModule.io = app.config.globalProperties.$module
/* async function startSocket() {
}
startSocket() */
router.isReady().then(() => {
  app.mount('#app');
});
window.onpopstate = PreventPop

const theme = localStorage.getItem("theme")
if(theme && theme === "dark") document.documentElement.setAttribute("data-theme", "dark")

//teste(app);
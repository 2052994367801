import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormCreateDelimitador {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.model.hora = "00:00"
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const actions = [
            { name: "Nenhuma", value: "none" },
        ]
        const fields = {
            "extras": new Extra(this.model.titulo, "Salvar", "end/createdelimitador/true", {}),
            "hora": new Field("time", "Hora do Início", ""),
            "action": new Field("select", "Programação", "Selecione uma opção", actions),
        }
        const [progs, err] = await this.repository.findMany("Programacoes", { projId: this.model.periodo.dono })
        if (err) {
            console.error(progs);
            return fields
        }
        progs.forEach(p => actions.push({name: p.nome, value: p._id}))
        fields.action = new Field("select", "Programação", "Selecione uma opção", actions)
        return fields
    }
}
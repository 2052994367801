import { Socket } from "socket.io-client";
import Module, { PkgQuery } from "./_module";

export default class WSModule extends Module {
    socket: Socket
    constructor(socket: Socket){
        super()
        this.socket = socket
    }
    
    send(topic: string, payload: string, query?: PkgQuery, component?: string): Promise<[any, boolean]> {
        const id = this.msgId()
        this.socket.emit(`module/${id}`, {topic, payload, component, tOut: query?.tOut, query: query?.query, e485: query?.e485})
        return this.socket.wait(`module/${id}`)
    }

}
import Adapter from "@/core/adapter/_adapter"
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import OrderComponent from "@/Features/Componentes/controllers/order"
import FormNome from "@/Features/Componentes/forms/nome"
import Forms from "@/Features/Componentes/forms/_list"
import { wait } from "@/stores/showModal"
import AdicionaElement from "../../../usecase/elements/adicionaElement"
import CreateElement from "../../../usecase/elements/_element"
import FormElement from "../_element"
import FormPrograma from "./programa"
import FormSelectTela from "./selectTela"

export default class FormCarrossel extends FormElement {
    constructor(repository: Repository, model: any) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields = await super.getFields();
        const telas: any[] = [];
        let add = new FormNome(this.repository, {titulo: "+ Adicionar", value: "add", act: true})
        const [root, err] = await this.repository.findOne("RootComponentes", {
            _id: this.model._id,
            projId: this.model.projId
        })
        if(err) return fields
        if (root)
        root.componentes.forEach((o: any) => {
            telas.push(new Forms.list[o.tipo](this.repository, o))
        });
        telas.push(add)
        this.model.elements = telas
        fields["telas"] = Field.make("objectV", "Telas", telas, async (o) => {
            if (o.model.value === "add") {
                wait.show(5000, "Adicionando Elemento")
                const [c, e] = await new CreateElement(this.repository).execute(this.model, {
                    value: "tela",
                    name: "Tela"
                })
                if(e) return "none"
                await new AdicionaElement(this.repository).execute(this.model, c)
                wait.show(0, "")
                return "refresh"
            }
            return "object"
        })
        const order = new OrderComponent(this.repository, this.model)
        const programacoes: Adapter[] = []
        add = new FormSelectTela(this.repository, {titulo: "+ Adicionar", root: this.model, act: true})
        fields["programacao"] = Field.make("objectVL", "Programacao", [add], {order: async i => {
            await order.setOrder(i)
            return false
        }})
        const [programacao, e] = await this.repository.findOne("ProgramacaoTelas", {
            _id: this.model._id,
            projId: this.model.projId
        })
        if(e || !programacao) return fields
        programacao.programacao.forEach((t: any) => programacoes.push(new FormPrograma(this.repository, t)))
        programacoes.push(add)
        this.model.programacoes = programacoes
        fields["programacao"] = Field.make("objectVL", "Programacao", programacoes, {order: async i => {
            await order.setOrder(i)
            return false
        }})
        return fields
    }
}
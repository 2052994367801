import Repository from "./_repository";
import { Storage } from '@capacitor/storage';

function Token() {
    return new Array(25).join("0").replace(/[018]/g, (c: any) =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(24)
    );
}

export default class RepositoryStorage implements Repository {
    type = "storage"
    findManySortLimit(collection: string, query: { [index: string]: any; }, limit: number, sort: 1 | -1): Promise<[any[], boolean]> {
        throw new Error("Method not implemented.");
    }
    findUseCase(collection: string, args: any): Promise<[any, boolean]> {
        console.log(collection, args);
        throw new Error("Method not implemented.");
    }
    deleteUsecase(collection: string, object: any): Promise<[any, boolean]> {
        console.log(collection, object);
        throw new Error("Method not implemented.");
    }
    updateQuery(collection: string, query: any, value: any): Promise<[any, boolean]> {
        console.log(collection, query, value);
        throw new Error("Method not implemented.");
    }
    deleteMany(collection: string, query: any): Promise<[any, boolean]> {
        console.log(collection, query);
        throw new Error("Method not implemented.");
    }
    deleteList(collection: string, id: string[]): Promise<[any, boolean]> {
        console.log(collection, id);
        throw new Error("Method not implemented.");
    }
    async create(collection: string, value: any): Promise<[any, boolean]> {
        if (!value._id)
            value._id = Token()
        const [result, empty] = await this.verifyValue(collection)

        if (empty)
            return [await Storage.set({ key: collection, value: JSON.stringify([value]) }), false]
        else {
            console.log(result);
            if (typeof result === "object" && result.constructor.name === "Array")
                result.push(value)
            return [await Storage.set({ key: collection, value: JSON.stringify(result) }), false]
        }
    }

    async read(collection: string, id: string): Promise<[any, boolean]> {
        const [result, empty] = await this.verifyValue(collection)
        if (empty) return ["id nao encontrado", true]
        else return [result.find((e: any) => e._id === id), false]
    }

    async update(collection: string, id: string, value: any): Promise<[any, boolean]> {
        if (value.repository) delete value.repository
        const [result, empty] = await this.verifyValue(collection)
        if (empty) return ["id nao encontrado", true]
        else {
            const maped = result.map((e: any) => {
                if (e._id.toString() === id.toString())
                    Object.assign(e, value)
                return e
            })
            return [await Storage.set({ key: collection, value: JSON.stringify(maped) }), false]
        }
    }

    async delete(collection: string, id: string): Promise<[any, boolean]> {
        const [result, empty] = await this.verifyValue(collection)
        if (empty) return ["id nao encontrado", true]
        else {
            result.filter((e: any) => e.id != id)
            return [await Storage.set({ key: collection, value: JSON.stringify(result) }), false]
        }
    }

    async verifyValue(key: string): Promise<[any, boolean]> {
        const { value } = await Storage.get({ key })
        if (value) {
            try {
                return [JSON.parse(value), false]
            } catch (error) {
                return [value, true]
            }
        } else return [value, true]
    }

    async findMany(collection: string, query: { [index: string]: any }): Promise<[any, boolean]> {
        const [result, empty] = await this.verifyValue(collection)
        if (empty) return [`Collection ${collection} nao encontrada`, true]
        else {
            const r: any[] = []
            result.find((e: any) => {
                let test = true
                for (const key in query) {
                    if (!Object.prototype.hasOwnProperty.call(e, key) || e[key] !== query[key]) test = false
                }
                if (test) r.push(e)
            })
            return [r, false]
        }
    }

    async findOne(collection: string, query: { [index: string]: any }): Promise<[any, boolean]> {
        const [result, empty] = await this.verifyValue(collection)
        if (empty) return [`Collection ${collection} nao encontrada`, true]
        else {
            const r: any[] = []
            result.find((e: any) => {
                let test = true
                for (const key in query) {
                    if (!Object.prototype.hasOwnProperty.call(e, key) || e[key] !== query[key]) test = false
                }
                if (test) r.push(e)
            })
            return [r, false]
        }
    }
}
import CreateModulo from "../domain/usecase/CreateModulo";
import Forms from "../forms/_list";
import { store } from "@/store";
import Repository from "@/core/repository/_repository";
import { modulos } from "../store/list";
import { modal } from "@/stores/showModal";
import CreateComponente from "@/Features/Componentes/controllers/createComponente";
export default class ControllerCreateModulo {
    static action = {
        timeout: 30000,
        text: "Criando Módulo"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form);
        console.log(fields)
        console.log(store.state.projeto)
        const [modulo, err] = await new CreateModulo(this.repository).execute({
            tipo: fields.tipo,
            projeto: store.state.projeto,
        });
        if (err) console.error(modulo);
        else {
            modulos.push(modulo)
            setTimeout(() => {
                modal.show(new Forms.list[modulo.modelo](this.repository, modulo))
            }, 100);
            if (fields.tipo === "asw3")
                new CreateComponente(this.repository).execute({ model: modulo, fromothersrc: true }, { tipo: "audio", titulo: "Novo Áudio", conexao: modulo.portas[0].conexoes[0]._id })
            else if (fields.tipo === "m5")
                [1, 2, 3].forEach(i => new CreateComponente(this.repository).execute({ model: modulo, fromothersrc: true }, {
                    tipo: "interruptor",
                    titulo: modulo.portas[1].conexoes[i - 1].nome,
                    conexao: modulo.portas[1].conexoes[i - 1]._id
                }))
        }

        return "close"
    }
}
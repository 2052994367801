import Module, { PkgQuery } from "./_module";

export default class WRTCModule extends Module {
    channel: RTCDataChannel
    constructor(channel: RTCDataChannel) {
        super()
        this.channel = channel
    }
    async send(topic: string, payload: string, query?: PkgQuery, component?: string): Promise<[any, boolean]> {
        const id = this.msgId()
        this.channel.send(JSON.stringify({
            event: `module/${id}`,
            msg: { topic, payload, component, tOut: query?.tOut, query: query?.query, e485: query?.e485 }
        }))
        return this.channel.wait(`module/${id}`)
    }

}
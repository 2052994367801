<template>
  <card :class="['d-grid', 'gap-m', 'ac-center', 'row-three', 'column-fit-s']" @config="config">
    <button class="botao" @mousedown="pressstart" @mouseup="pressend" @touchstart="pressstart" @touchend="pressend"
      @mouseleave="pressend">
      {{ component.titulo }}
    </button>
  </card>
</template>
<script>
import Factory from "../actions/_factory";
import ListForms from "../forms/_factory";
import { modal } from "@/stores/showModal";
import Card from "@/components/UI/Card.vue";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      action: "",
      titulo: "",
      pressed: false,
      oldrepetir: "",
      componente: this.component
    };
  },
  async created() {
    this.action = this.component.options.action;
  },
  methods: {
    pressstart(e) {
      e.target.classList.add("active")
      e.preventDefault();
      this.pressed = true
      this.holded = false
      setTimeout(() => this.executeloop(e), 1500)
      this.oldrepetir = this.componente.options.repetir
    },
    pressend(e) {
      e.target.classList.remove("active")
      e.preventDefault();
      if (!this.pressed) return
      this.pressed = false
      if (!this.holded) return
      this.componente.options.repetir = this.oldrepetir
      const action = Factory.actions["cancel"];
      new action(this.$module).execute(this.componente);
    },
    executeloop(e) {
      this.execute(e)
      if(!this.pressed) return
      setTimeout(() => {
        if (this.pressed) {
          this.holded = true
          this.componente.options.repetir = "15"
          this.executeloop(e)
        }
      }, 2000);
    },
    async execute(e) {
      e.preventDefault();
      const action = Factory.actions[this.action];
      if (this.componente.parent && this.componente.parent.options.type === "ac") {
        if(this.componente.options.titulo === "OFF") 
          Object.assign(this.componente.parent.painel[2].options, { state: "OFF", statestr: "OFF" })
        else if(this.componente.options.titulo === "ON") {
          const temp = this.componente.parent.control.find(b => b._id === this.componente.parent.painel[2].options.temp)
          Object.assign(this.componente.parent.painel[2].options, { state: temp.titulo.substring(0, 2), statestr: `${temp.titulo.substring(0, 2)}°C` })
        }
        const [result, err] = await this.$repository.update("Componentes", this.componente.parent.painel[2]._id, {
          projId: this.componente.parent.painel[2].projId,
          options: this.componente.parent.painel[2].options
        })
        if(err) console.error(result)
      }
      new action(this.$module).execute(this.componente);
    },
    config() {
      console.log("config");
      if (ListForms.check(this.action))
        modal.show(new ListForms.forms[this.action](this.component));
      else console.error(`Form "${this.action}" do not exist`);
    },
  },
};
</script>

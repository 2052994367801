import Repository from "@/core/repository/_repository";
export default class UpdateProjeto {
    static action = {
        timeout: 10000,
        text: "Criando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        form.model.nome = fields.nome
        form.model.status = fields.status
        fields._id = form.model._id
        fields.revenda = form.model.revenda
        delete fields.temporario
        delete fields.servidorlocal
        const [result, err] = await this.repository.update("UpdateProjetos", form.model._id, fields)
        console.log(result, err);
        return "close"
    }
}
import { reactive } from "vue";
export const login = reactive({
    callback: (extra: string) => {
        console.log("callback", extra);
    },
    show(extra: string) {
        this.callback(extra)
    }
})

export const snackbar = reactive({
    showing: false,
    text: "",
    timeout: 0,
    state: "",
    show(text?: string, time?: number, state?: "error" | "success") {
        this.showing = this.showing ? false : true
        this.text = text ? text : ""
        this.state = state ? state : ""
        clearTimeout(this.timeout)
        if(!this.showing && text) setTimeout(() => {this.show(text, time, state)}, 200)
        else if (this.showing) this.timeout = setTimeout(() => {
            this.showing = false
        }, time ? time : 5000);
    }
})

export const server = reactive({
    url: process.env.NODE_ENV === "development" ? "http://localhost:5000" : location.origin,
})
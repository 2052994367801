<template>
  <app-layout :header="header" @dropDown="mostraDropDown" @page="setPage" :homeimg="homeimg">
    <main class="d-grid gap" onselectstart="return false">
      <div id="fundoMenu" class="fundo_z6" style="display: none" onclick="mostraMenu(0)">
        .
      </div>
      <h3 class="header mb10">Seus Projetos</h3>
      <section id="projetos" class="d-grid gap">
        <card-basic-project v-for="item in projetos" v-bind:value="item" v-bind:key="item.id"></card-basic-project>
        <h3 v-if="!projetos">Você não possui acesso a um Projeto</h3>
      </section>
    </main>
    <modal-muda-conexao v-if="mudaConexao" @close="mostraMudaConexao"></modal-muda-conexao>
  </app-layout>
</template>

<script>
import appLayout from "../layouts/appLayout.vue";
import cardBasicProject from "../components/cards/cardBasicProject.vue";
import ModalMudaConexao from "@/components/Modal/modalMudaConexao.vue";
import Socket from "@/service/socket";
import RepositoryStorage from "@/core/repository/Storage";
import GetUsername from "@/Features/Usuario/usecases/getUsername"
import { getStorage, setStorage } from "@/scripts/Storage";

const storageRepository = new RepositoryStorage();
export default {
  name: "page-hall",
  components: {
    appLayout,
    cardBasicProject,
    ModalMudaConexao,
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data: () => {
    return {
      header: { title: "", subtitle: "Hall", labels: [] },
      projetos: [],
      dropDown: false,
      mudaConexao: false,
      topDropDown: 0,
      tentativas: 0,
      favoriteProject: null,
      homeimg: 'repeating-radial-gradient( circle at 0 0, transparent 0, var(--screen-background-shade) 40px ), repeating-linear-gradient( #2f2f2f55, var(--screen-background-tint) )'
    };
  },
  async created() {
    if (this.$state.state.name === "p2p") Socket.backToSocket()
    console.log("\n\n#### entrou no hall");
    document.title = "Hall";
    console.log("👉 esta online:", window.navigator.onLine)
    if (window.navigator.onLine) {
      await this.$socket.waitSocket();
      this.pegaPage();
    } else {
      this.header.title = (await getStorage("nomeUsuario")).value;
      const [result, err] = await storageRepository.findMany("ProjetosLocais", {})
      if (err) console.error(result)
      else
        result.forEach(p => this.projetos.push(p));
    }
    this.header.labels = [
      { name: "Hall", path: "hall", selected: true },
      { name: "Conta", path: "conta", selected: false },
      { name: "Servidor Local", path: "servidorlocal", selected: false },
    ];
    this.$state.setState("start");
  },
  sockets: {
    pronto() {
      this.$store.commit("setSocketReady", true);
      console.log("POSSO MANDAR MENSAGEM HALL");
    },
  },
  methods: {
    hall(object) {
      this.header.title = object.nome;
      setStorage("nomeUsuario", object.nome);
      if (object.projetos.length === 1 && object.projetos[0].projeto.tipo !== "local") this.favoriteProject = object.projetos[0].projeto.projId
      const servidoresLocais = []
      object.projetos.forEach(async (projeto) => {
        if (object.favorite === projeto.projeto._id) this.favoriteProject = projeto.projeto.projId
        const project = {
          _id: projeto.projeto._id,
          projId: projeto.projeto.projId,
          nome: projeto.projeto.nome,
          tipo: projeto.projeto.tipo,
          ip: projeto.projeto.ip,
          servidorlocal: projeto.projeto.servidorlocal
        };
        this.projetos.push(project);
        console.log(project)
        if (project.tipo === "local") {
          if (!servidoresLocais.find(s => project.servidorlocal === s)) {
            console.log(" => servidor local")
            servidoresLocais.push(project.servidorlocal)
          }
          const [result, err] = await storageRepository.findOne("ProjetosLocais", { _id: projeto.projeto._id })
          if (err || !result) {
            const [result, err] = await storageRepository.create("ProjetosLocais", project)
            console.log("criou", result, err)
          } else {
            const [result, err] = await storageRepository.update("ProjetosLocais", project._id, project)
            console.log("atualizou", result, err)
          }
        }
      });
      servidoresLocais.forEach(async s => {
        const [servidorlocal, err] = await this.$repository.findUseCase("ServidoresLocaisUsuario", { _id: s })
        if(err) return console.error(servidorlocal)
        console.log(servidorlocal, err)
        const [check, cerr] = await storageRepository.findOne("ServidoresLocais", { _id: servidorlocal._id })
          if (cerr || !check) {
            const [result, err] = await storageRepository.create("ServidoresLocais", servidorlocal)
            console.log("criou Servidor Local", result, err)
          } else {
            const [result, err] = await storageRepository.update("ServidoresLocais", servidorlocal._id, servidorlocal)
            console.log("atualizou Servidor Local", result, err)
          }
      })
      console.log(this.header)
      console.log(this.projetos)
    },
    async pegaPage() {
      const [result, err] = await this.$repository.findOne("Hall", {
        username: await new GetUsername(this.$repository).execute(),
      });
      console.log(result, err)
      if (err) console.error(result);
      else this.hall(result);

      if (this.$router.options.history.state.favorite && this.favoriteProject)
        this.$router.replace(`/p/${this.favoriteProject}/home`);
    },
    setPage(page) {
      console.log(page);
    },
    mostraDropDown(button) {
      if (this.dropDown) this.dropDown = false;
      else {
        this.dropDown = true;
        this.topDropDown = button.offsetTop + button.offsetHeight;
      }
    },
    acaoDropDown(selected) {
      switch (selected.value) {
        case 0:
          console.log("abrindo conta");
          this.mudaConexao = true;
          break;
        case 1:
          console.log("saindo fora");
          break;
      }
    },
    mostraMudaConexao() {
      this.mudaConexao = this.mudaConexao ? false : true;
    },
  },
  unmounted() {
    //this.sockets.unsubscribe("pronto");
    //this.sockets.unsubscribe("hall");
    console.log("saiu do monitoramento");
  },
};
</script>

<style>
#projetos {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
</style>
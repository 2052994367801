import Repository from "@/core/repository/_repository";
export default class AddToPainel {
    static action = {
        timeout: 10000,
        text: "Adicionando Botão"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form.model.parent.painel)
        const update = {
            projId: form.model.parent.projId,
            painel: "",
            index: -1
        }
        const painel: {[key: number]: any} = {
            1: null,
            2: null,
            3: null,
            4: null
        }
        form.model.parent.painel.forEach((b: any) => painel[b.index] = b)
        if (form.model.select !== "") {
            const [, e] = await this.repository.update("Componentes", form.model.select, update)
            if (e) return "finish"
            painel[form.model.i] = null
        }
        if(fields.select !== "") {
            update.painel = form.model.parent._id
            update.index = form.model.i
            const [, e] = await this.repository.update("Componentes", fields.select, update)
            if (e) return "finish"
            const b = form.model.parent.control.find((b: any) => b._id === fields.select)
            Object.assign(b, update)
            painel[form.model.i] = b
        }
        form.model.parent.painel = [1,2,3,4].map(n => painel[n]).filter(n => n)
        return "back"
    }
}
<template>
  <section class="widgets homeimg" :class="project.tipo === 'gerenciamento' ? 'gerenciamento' : ''">
    <component
      v-for="component in components"
      v-bind:is="component.tipo.replace('-', '')"
      :key="component._id"
      v-bind:component="component"
      v-bind:project="project"
      @page="setpage"
    ></component>
  </section>
</template>

<script>
import tabelachamados from "@/components/UI/tabelaChamados.vue";
import botao from "@/Features/Componentes/botao/ui/botao.vue";
import botaoprog from "@/Features/Componentes/macro/ui/botao.vue";
import botaoicone from "@/Features/Componentes/botao/ui/icone.vue";
import interruptor from "@/Features/Componentes/interruptor/ui/switch.vue";
import dimmer from "@/Features/Componentes/interruptor/ui/dimmer.vue";
import agregador from "@/Features/Componentes/agregador/ui/agregador.vue";
import audio from "@/Features/Componentes/agregador/ui/agregador.vue";
import digitalin from "@/Features/Componentes/status/ui/digitalin.vue";
import eventdigitalin from "@/Features/Componentes/status/ui/eventdigitalin.vue";
import title from "@/Features/Componentes/title/ui/title.vue";
import pasta from "@/Features/Componentes/pasta/ui/pasta.vue";
import display from "@/Features/Componentes/display/ui/display.vue";
import grafico from "@/Features/Componentes/grafico/ui/grafico.vue";
import acesso from "@/Features/Componentes/acesso/ui/acesso.vue";
import statusProjetos from "@/Features/Componentes/status/ui/statusProjetos.vue";
import maplist from "@/Features/Componentes/subprojetos/ui/maplist.vue";
import atividade from "@/Features/Componentes/atividades/ui/atividades.vue";
import camera from "@/Features/Componentes/camera/ui/camera.vue";
import column from "@/Features/Componentes/column/ui/column.vue";
import { components, componentsMain } from "@/stores/components";
export default {
  name: "page-home",
  components: {
    tabelachamados,
    botao,
    botaoicone,
    interruptor,
    agregador,
    audio,
    dimmer,
    botaoprog,
    digitalin,
    "status": digitalin,
    "statusprojetos": statusProjetos,
    maplist,
    eventdigitalin,
    title,
    pasta,
    display,
    grafico,
    acesso,
    atividade,
    camera,
    column,
  },
  props: {
    project: Object,
  },
  data() {
    return {
      components: [],
    };
  },
  async created() {
    components.clear();
    componentsMain.clear();
    this.components.splice(0, this.components.length);

    const [root, err] = await this.$repository.findOne("RootComponentes", {
      projId: this.project.projId,
      tipo: "root",
    });
    if (err) console.error(root);
    else if (typeof root === "object" && Object.keys(root).length) {
      root.componentes.forEach((c) => {
        if (typeof c.conexao !== "object" || !Object.keys(c.conexao).length) c.conexao = { status: "⚫" }
        if (
          typeof c.nshow === "undefined" ||
          (typeof c.nshow === "boolean" && !c.nshow)
        )
          this.components.push(c);
        components.push(c);
      });
      this.$emit("bgimg", root.options)
    }
  },
  methods: {
    setpage(label) {
      this.$emit("page", label)
    }
  }
};
</script>
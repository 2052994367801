import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { repeteList } from "../lib";
import FormCreateDelimitadores from "./createDelimitadores";

export default class FormCreatePeriodo {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
        console.log(this.model);
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        console.log(this.model);
        this.model.dia.periodo = "unico"
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.titulo, "Adicionar", "end/createperiodo/true", {}),
            "nome": new Field("text", "Nome do Evento", "nome"),
            "delimitadores": new Field("duracao", "Duração", "", [
                new FormCreateDelimitadores(this.repository, {titulo: "Início", dia: this.model.dia, tipo: "inicio"}),
                new FormCreateDelimitadores(this.repository, {titulo: "Fim", dia: this.model.dia, tipo: "fim"})
            ]),
            
        }
        if(this.model.tipo === "repete")
            fields["repete"] = new Field("selectrepete", "Repete", "Selecione um tipo", repeteList)
        return fields
    }
}
<template>
  <div class="d-grid gap-mid o-scroll">
    <div
      v-for="item in list"
      v-bind:key="item._id"
      class="item d-grid gap-p p-10 w-100"
      :class="!item.add ? 'img' : ''"
      @click="retorna(item)"
      :style="{'background-image': !item.add ? `url(${server.url}/_img/${item.value})` : ''}"
    >
      {{ item.name ? item.name : item.model.name }}
    </div>
  </div>
</template>

<script>
import { server } from "@/stores/login";
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  name: "objectVIMG",
  props: {
    field: Object,
  },
  created() {
    this.list = this.field.list;
  },
  data() {
    return {
      value: "",
      list: [],
      server
    };
  },
  methods: {
    async retorna(item) {
      if (!this.field.action) return this.$emit("object", item);
      const result = await this.field.action(item)
      if(typeof result === "string") return this.$emit(result, item);

      if(result) return this.$emit("object", item);

      this.$emit("back", item);
    },
  },
});
</script>

<style>
.item.img {
    height: 75px;
    background-size: cover;
    background-position: center;
    color: white;
}
</style>
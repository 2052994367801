export default class Factory {
    classesMap: { [index: string]: any } = {}
    make(object: { tipo: string }): [object, boolean] {
        try {
            const [tipo] = object.tipo.split("-")
            if (Object.prototype.hasOwnProperty.call(this.classesMap, tipo)) {
                const obj = new this.classesMap[tipo]()
                const otipo = obj.tipo
                Object.assign(obj, object)
                obj.tipo = otipo
                return [obj, false]
            }
            else return [{}, true]
        } catch (error) {
            console.log(error);
            console.log(object);
            return [{}, true]
        }
    }
}
<template>
  <card
    :class="[
      'd-flex',
      'gap-m',
      'justify-between',
      'row-two',
      'column-full',
      'bg-none',
      'bf-none',
      'ai-end',
      'pb-0'
    ]"
  >
    <h2>{{ component.titulo }}</h2>
  </card>
</template>

<script>
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
export default {
  components: {
    Card,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
  },
};
</script>
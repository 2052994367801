import Repository from "@/core/repository/_repository";
import { componentsMain } from "@/stores/components";
import Componente from "../../_componente";
export default class CopiarComponente {
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(root: any, componente: any): Promise<[Componente, boolean]> {
        console.log(componente)
        let [r] = root.children ? [root] : await this.repository.findOne("RootChildrenNum", {
            projId: root.projId,
            tipo: "root"
        });
        if (typeof r === "string") r = { children: componentsMain.listmain.length }
        const [rela, err] = await this.repository.create("ComponenteRoot", {
            projId: root.projId,
            projeto: root.projeto,
            root: root._id,
            index: r.children,
            componente: componente._id
        })
        console.log(rela, err)
        return [componente, false]
    }
}
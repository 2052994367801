import FormCreateDelimitador from "@/Features/Agenda/forms/createDelimitador"
import FormUpdateDelimitadores from "@/Features/Agenda/forms/updateDelimitadores"
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"

export default class FormAgendaProgramacao {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }>  {
        console.log(this.model)
        return {
            "extras": new Extra(this.model.model.nome, "none", ""),
            "delimitadores": new Field("duracao", "Duração", "", [
                this.model.model.dinicio ?
                    new FormUpdateDelimitadores(this.repository, this.model.model.dinicio) :
                    new FormCreateDelimitador(this.repository, { titulo: "Início", periodo: this.model.model, tipo: "inicio" })
                ,
                this.model.model.dfim ?
                    new FormUpdateDelimitadores(this.repository, this.model.model.dfim) :
                    new FormCreateDelimitador(this.repository, { titulo: "Fim", periodo: this.model.model, tipo: "fim" })
            ]),
        }
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import FormElement from "./_element"
import FormFont from "./styles/font"

export default class FormText extends FormElement{
    constructor(repository: Repository, model: any) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        this.model.inoptions = true
        Object.assign(this.model, this.model.options)
        const fields = await super.getFields();
        (fields["style"] as any).list.push(new FormFont(this.repository, {objname: "Fonte", element: this.model})); 
        fields["text"] = new Field("text", "Texto", "Texto que será mostrado")
        return fields
    }
}
import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import FormBotao from "./botao"

export default class FormBotaoIcon extends FormBotao{
    tipo = "botaoiconeSQ"
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = await super.getFields()
        const icons: { value: string; name: string }[] = [
            { value: "controller-power", name: "CPower" },
            { value: "controller-vol-up", name: "CVolUp" },
            { value: "controller-vol-down", name: "CVolDown" },
            { value: "controller-menu", name: "Menu" },
            { value: "controller-back", name: "Voltar" },
            { value: "controller-home", name: "Home" },
            { value: "controller-input", name: "Input" },
            { value: "controller-arrow-up", name: "ArrowUp" },
            { value: "controller-arrow-right", name: "ArrowRight" },
            { value: "controller-arrow-down", name: "ArrowDown" },
            { value: "controller-arrow-left", name: "ArrowLeft" },
            { value: "controller-minus", name: "Minus" },
            { value: "controller-mute", name: "CMute" },
            { value: "controller-plus", name: "Plus" },
            { value: "controller-settings", name: "Settings" }
        ];
        if(!this.model.create) this.model.icon = this.model.icon.icon
        fields["icon"] = new Field("select", "Icone", "", icons)
        return fields
    }
}
import { App } from "vue"

export interface CommandParams {
    event: string;
    args: any;
}

export type CommandReturn = Promise<[any, boolean]>
export type Command = (app: App) => void

export abstract class Default {
    name: string
    constructor(name: string){
        this.name = name
    }
    abstract setup(app: App): void
}
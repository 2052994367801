import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import Socket from "@/service/socket";
import router from "@/router";
import State from "@/service/states/state";
import FormNome from "../../forms/nome";
import RandomToken from "@/core/lib/randomtoken";

export default class FormStatusModulos {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "none", ""),
        }
        if (this.model.acessorevenda)
            fields["manage"] = Field.make("object", "Gerenciar Projeto", [new FormNome(this.repository, { objname: "Entrar" })], async (): Promise<"none"> => {
                const event = `logintecnico/${RandomToken()}`
                Socket.io.emit(event, { _id: this.model._id, revenda: this.model.revenda })
                const [result, err] = await Socket.io.wait(event)
                console.log(result, err)
                if (err) return "none"
                //aqui vai ter que mudar o state para o do tecnico projeto
                Socket.io.emit("enterproject", { projId: this.model.projId });
                router.replace(`/p/${this.model.projId}/home`)
                State.tecnico = {
                    revenda: this.model.parent,
                    backto: "home"
                }
                return "none"
            })

        const list:any[] = []
        this.model.modulos.forEach((m: any) => {
            list.push(Object.assign(new FormNome(this.repository, {}), {name: `${m.conexao ? m.conexao.status : "⚫"} - ${m.nome}`}))
        });
        fields["modulos"] = Field.make("objectV", "Módulos", list, (m) => {
            console.log(m)
            return "none"
        })

        return fields
    }
}
<template>
    <div class="table" :style="{ 'grid-area': gridarea, 'font-size': fontsize, 'font-family': fontfamily, 'color': fontcolor }">
        <div class="thead" :style="{ 'grid-template-columns': gridtemplatecolumns }">
            <div v-for="item in columns" :key="item._id"><label v-if="item.hshow">{{ item.name }}</label></div>
        </div>
        <div :class="body">
            <div class="row" v-for="item in rows" :key="item._id"
                :style="{ 'grid-template-columns': gridtemplatecolumns }">
                <component v-for="cell in item.fields"
                :key="cell._id"
                :cell="cell"
                :is="cell.column.type"
                v-bind:style="{'grid-column-start': cell.column.index, 'grid-row-start': 1}"></component>
            </div>
        </div>
    </div>
</template>

<script>
    import text from "./cells/text.vue";
    import textpoints from "./cells/textpoints.vue";
    import imageselect from "./cells/imageselect.vue";
import { defineComponent } from "vue";
export default defineComponent({
    name: "appLayout",
    components: {
        text,
        textpoints,
        imageselect
    },
    props: {
        element: Object,
    },
    data() {
        return {
            elements: [],
            gridarea: "",
            gridtemplatecolumns: "",
            fontsize: "",
            fontfamily: "",
            fontcolor: "",
            body: "normal",
            columns: [],
            rows: []
        };
    },
    async created() {
        console.log(this.element)
        const [table, err] = await this.$repository.findOne("TableElements", {
            projId: this.element.projId,
            _id: this.element._id
        })
        if (err) console.error(table);
        console.log(table)
        this.body = table.style.body ? table.style.body.struct : "normal"
        const p = table.style.position
        const s = table.style.size
        const f = table.style.font
        this.gridarea = `${p.y}/${p.x}/span ${s.h}/span ${s.w}`
        this.fontsize = f ? `${f.size}` : "inherit"
        this.fontfamily = f ? `${f.family}` : "inherit"
        this.fontcolor = f ? `${f.color}` : "inherit"
        table.columns.forEach(c => {
            if (!c.nshow) {
                this.columns.push(c)
                this.gridtemplatecolumns += ` ${c.size}%`
            }
        })

        table.rows.forEach(r => {
            const row = { _id: r._id, fields: [] }
            r.fields.forEach(f => { if (!f.column.nshow) row.fields.push(f) })
            this.rows.push(row)
        })
    },
    methods: {
        fullscreen(e) {
            console.log(e)
            document.documentElement.requestFullscreen()
                .catch(err => console.error(err))
                .then(e => console.log(e))
        }
    },
});
</script>

<style>
.table {
    display: grid;
    grid-template-rows: min-content;
    padding: 10px;
}

.thead {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.table .row {
    display: grid;
    width: 100%;
    align-items: center;
}

.thead div {
    padding: 10px;
    font-weight: bold;
}


.table .fill {
    display: grid;
    align-items: start;
    align-content: space-between;
}
.table .normal {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.table .normal .row {
    padding: 5px;
}
.table .normal .row:nth-child(odd) {
    background-color: #e0e0e07c;
}
</style>
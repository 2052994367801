import Socket from "@/service/socket"
interface Eventos {
    offer: string;
    answer: string;
    start: string;
}
export default class Camera {
    static connection = new RTCPeerConnection({
        iceServers: [
            {
                urls: "stun:stun.livtech.com.br:3478"
            }
        ],

    })
    static channel: RTCDataChannel
    static connected = false
    static async start(projId: string, callback: (ev: MessageEvent<any>) => any) {
        console.log("starting", projId)
        Socket.io.emit("startp2pstream", { projId })
        const [eventos, serr]: [Eventos, boolean] = await Socket.io.wait("startp2pstream")
        if(serr) return
        this.channel = this.connection.createDataChannel(projId);

        this.channel.onmessage = callback
        this.channel.onopen = e => {
            this.connected = true
            console.log("open!!!!");
        } /* tenho que fazer um wait para open */
        this.channel.onclose = e => {
            this.connected = false
            console.log("closed!!!!!!");
        }
        this.channel.onerror = e => console.log("onerror", e);

        this.connection.onicecandidate = e => {
            if (!this.connection.localDescription) return
            console.log(JSON.stringify(this.connection.localDescription))
            Socket.io.emit(eventos.offer, { offer: this.connection.localDescription })
        }
        this.connection.createOffer().then(o => this.connection.setLocalDescription(o));

        let bestanswer: any = {}
        let timeout = 0
        Socket.io.on(eventos.answer, (args) => {
            console.log(this.connection.currentRemoteDescription)
            bestanswer = args.answer
            if (timeout) clearTimeout(timeout)
            timeout = setTimeout(() => {
                console.log(JSON.stringify(args.answer))
                this.connection.setRemoteDescription(bestanswer)
                    .then(() => { console.log("Done !!!") })
                    .catch(e => { console.log("Aready Done !!!") })
            }, 1500);
        });
        await new Promise(res => setTimeout(() => res(''), 2000))
    }

    static async waitConnection() {
        return new Promise((res) => {
            if (this.connected) res(true)
            else
                setTimeout(async () => {
                    console.log("esperando socket...");
                    if (!this.connected) res(await this.waitConnection());
                    else res(true)
                }, 50);
        })
    }
}
import FactoryComponente from "@/Model/Factory/Componente";
import Layout from "../entity/componentes/layout/_layout";
import Repository from "../repository/_repository";

export default class CreateComponente {
    repository: Repository;
    constructor(repository: Repository) {
        this.repository = repository
    }

    async execute(obj: { tipo: string; raiz: Layout }) {
        const [component, err] = new FactoryComponente().make(obj)
        if (err) return console.log("tipo invalido");
        component.raiz = obj.raiz._id
        await this.repository.create("Componentes", component)
        obj.raiz.append(component)
        await this.repository.update("Componentes", obj.raiz._id, obj.raiz)
        //aqui tem que atualizar o raiz
        return component
    }
}
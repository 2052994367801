import Checksum from "@/core/AZ1/checksum";
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import StaticModule from "@/service/module/_module";
import Socket from "@/service/socket";
import { snackbar } from "@/stores/login";
import { alert, wait } from "@/stores/showModal";
import { checkModulo } from "../../controllers/checkModulo";

export default class FormLocal {
    repository: Repository
    model: any
    fields: any = {}
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        const teclas = [
            { value: "11", name: "Pulsador NA" },
            { value: "12", name: "Pulsador NF" },
            { value: "13", name: "Interruptor NA TOGGLE" },
            { value: "14", name: "Interruptor NF TOGGLE" },
        ]
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "Atualizar", "end/sendlocal/true", {}),
            "tecla": new Field("select", "Tipo da Tecla", "Selecione um tipo", teclas),
        }
        //this.model.tecla = "11"
        const saidas = [
            { value: "255", name: "Não Mudar" },
            { value: "254", name: "Trocar" },
            { value: "1", name: "Ligar" },
            { value: "0", name: "Desligar" },
        ]
        this.model.out0 = "255"
        this.model.out1 = "255"
        this.model.out2 = "255"
        fields["out0"] = new Field("select", "Saída 1", "Selecione um estado", saidas);
        fields["out1"] = new Field("select", "Saída 2", "Selecione um estado", saidas);
        fields["out2"] = new Field("select", "Saída 3", "Selecione um estado", saidas);
        if (this.model.porta.modulo.tipo === "mtog4") {
            this.model.out3 = "255"
            const dimmer = saidas.concat([{ value: "2", name: "Dimmer" }]);
            fields["out0"] = new Field("selectrange", "Saída 1", "Selecione um estado", dimmer);
            fields["out1"] = new Field("selectrange", "Saída 2", "Selecione um estado", dimmer);
            fields["out2"] = new Field("select", "Saída 3", "Selecione um estado", saidas);
            fields["out3"] = new Field("select", "Saída 4", "Selecione um estado", saidas);
        }
        console.log(this.model);
        const [modId, e485, canal, err] = checkModulo({ conexao: this.model })
        if (err) snackbar.show(modId, 3000, "error");
        else {
            StaticModule.io.send(
                `${this.model.projId}/${modId}/CMD/CMD`,
                Checksum.calc(`255,1,11,12,0,${e485},19,${canal},0,0,0,0,1,45`.split(",").map(s => Number(s))).join(","),
            )
            wait.show(10000, "Pegando parâmetros do modulo")
            const [retorno, e] = await Socket.io.wait(`${this.model.projId}/${modId}/${e485}/${canal}`, 10000)
            wait.show(0, "");
            if (e) await alert.show("Erro ao pegar parametros do Modulo");
            else Object.assign(this.model, retorno)
        }

        /* (async () => {
            const [modId, e485, canal, err] = checkModulo({conexao: this.model})
            if (err) snackbar.show(modId, 3000, "error");
            else {
                StaticModule.io.send(
                    `${this.model.projId}/${modId}/CMD/CMD`,
                    Checksum.calc(`255,1,11,12,0,${e485},19,${canal},0,0,0,0,0,0`.split(",").map(s => Number(s))).join(","),
                )
                const [retorno, e] = await Socket.io.wait(`${this.model.projId}/${modId}/${e485}/${canal}`, 10000)
                if (e) await alert.show("Erro ao pegar parametros do Modulo");
                else this.fields.tecla.value = retorno.tecla
            }
        })() */
        return fields
    }
}
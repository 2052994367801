import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "../../../core/repository/_repository";
import Modulo from "../domain/entity/Modulo";

export default class FormCreateModulo {
    repository: Repository
    model: Modulo
    constructor(repository: Repository, model: Modulo) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const modulos: {value: string; name: string}[] = []
        const [templates, err] = await this.repository.findMany("TemplatesModulo", {})
        if(err) console.error(templates)
        templates.forEach(t => modulos.push({value: t.tipo, name: t.tipo.toUpperCase()}))
        return {
            "extras": new Extra("Criar Modulos", "Criar", "createmodulo"),
            "tipo": new Field("select", "Módulos", "Selecione o Modulo", modulos)
        }
    }
}
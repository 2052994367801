<template>
    <div class="d-grid gap-m">
        <div @click="voltar" class="d-flex a-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather stroke feather-arrow-left">
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
        </div>
        <section class="widgets homeimg">
            <component v-for="component in components" v-bind:is="component.tipo.replace('-', '')" :key="component._id"
                v-bind:component="component" @page="setpage"></component>
        </section>
    </div>
</template>

<script>
import tabelachamados from "@/components/UI/tabelaChamados.vue";
import botao from "@/Features/Componentes/botao/ui/botao.vue";
import botaoprog from "@/Features/Componentes/macro/ui/botao.vue";
import botaoicone from "@/Features/Componentes/botao/ui/icone.vue";
import interruptor from "@/Features/Componentes/interruptor/ui/switch.vue";
import dimmer from "@/Features/Componentes/interruptor/ui/dimmer.vue";
import agregador from "@/Features/Componentes/agregador/ui/agregador.vue";
import audio from "@/Features/Componentes/agregador/ui/agregador.vue";
import digitalin from "@/Features/Componentes/status/ui/digitalin.vue";
import title from "@/Features/Componentes/title/ui/title.vue";
import pasta from "@/Features/Componentes/pasta/ui/pasta.vue";
import display from "@/Features/Componentes/display/ui/display.vue";
import grafico from "@/Features/Componentes/grafico/ui/grafico.vue";
import { components, componentsMain, mostrando } from "@/stores/components";
export default {
    components: {
        tabelachamados,
        botao,
        botaoicone,
        interruptor,
        agregador,
        audio,
        dimmer,
        botaoprog,
        digitalin,
        "status": digitalin,
        title,
        pasta,
        display,
        grafico
    },
    props: {
        project: Object,
    },
    data() {
        return {
            components: [],
        };
    },
    async created() {
        //TODO: aqui tem que chamar o modelo do componente que então chamará o componente final
        //if (components.list.length > 1) return;
        const projId = this.$router.currentRoute.value.fullPath.split("/")[2]
        console.log(this.$route.params)
        console.log("\n\n###### entrou na page");
        console.log(this.$store.state.projeto)
        components.clear();
        componentsMain.clear();
        this.components.splice(0, this.components.length);

        const [root, err] = await this.$repository.findOne("RootComponentes", {
            _id: this.$route.params.page,
            projId,
        });
        if (err) console.error(root);
        else if (typeof root === "object" && Object.keys(root).length) {
            this.$emit("bgimg", root.options)
            console.log(root);
            root.componentes.forEach((c) => {
                if (
                    typeof c.nshow === "undefined" ||
                    (typeof c.nshow === "boolean" && !c.nshow)
                )
                    this.components.push(c);
                components.push(c);
            });
        }
        console.log(root)
    },
    methods: {
        setpage(label) {
            this.$emit("page", label)
        },
        voltar() {
            this.$router.back()
        }
    }
};
</script>
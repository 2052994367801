import Repository from "@/core/repository/_repository";
import { store } from "@/store";
export default class DeleteProgramacaoEntrada {
    static action = {
        timeout: 3000,
        text: "Atualizando Modo"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        console.log(form.model.programacaoentrada)
        const programacaoentrada = form.model.programacaoentrada ? form.model.programacaoentrada : form.last.programacaoentrada
        const [deleted, derr] = await this.repository.deleteMany("ProgramacoesEntradas", {
            projId: programacaoentrada.projId,
            _id: programacaoentrada._id
        })
        if (derr) console.error(deleted)
        else console.log(deleted)

        return "back"
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";
export default class DimmerLimit {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = {
            conexao: model,
            objname: `Configurar ${model.nome}`
        }
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log("\n\n###########\n\n");
        this.model.min = "0"
        this.model.max = "100"
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "Atualizar", "end/sendlimit", {}),
            "min": new Field("number", "Mínimo", "Selecione um tipo"),
            "max": new Field("number", "Máximo", "Selecione um tipo"),
        }
        console.log(this.model);
        const [modId, , , err] = checkModulo(this.model)
        if (err) snackbar.show(modId, 3000, "error");
        else {
            /* StaticModule.io.send(
                `${this.model.projId}/${modId}/CMD/CMD`,
                Checksum.calc(`255,1,11,12,0,${e485},19,${canal},0,0,0,0,1,45`.split(",").map(s => Number(s))).join(","),
            )
            const [retorno, e] = await Socket.io.wait(`${this.model.projId}/${modId}/${e485}/${canal}`)
            if (e) console.error(retorno);
            else Object.assign(this.model, retorno) */
        }

        return fields

    }
}
import FormAr from "./ar";
import FormASW from "./asw";
import CommandMqtt from "./commandMqtt";

export default class ListForms {
    static forms: { [key: string]: any } = {
        "commandmqtt": CommandMqtt,
        "ar": FormAr,
        "asw": FormASW
    }
    static check(key: string) {
        return Object.prototype.hasOwnProperty.call(this.forms, key)
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import CreateOperador from "../../controller/createOperador";

export default class SelectOperadores {
    repository: Repository
    model: { titulo: string; macro: any }
    constructor(repository: Repository, model: { titulo: string; macro: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list = [
            { value: "operador", name: "Wait", tipo: "wait" }
        ]
        return {
            "extras": new Extra("Escolha", "none", ""),
            "modulo": new Field("objectV", "Operadores", "", list, "", (item: any) => {
                new CreateOperador(this.repository).execute(this.model.macro, item)
                return false
            })
        }

    }
}
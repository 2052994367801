import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormConexao from "@/Features/Modulo/forms/conexao";
import DimmerLimit from "../interruptor/forms/limit";
import Componente from "../_componente";
import SelectModulos from "./conecta/modulos";

export default class FormComponent {
    repository: Repository
    model: Componente
    constructor(repository: Repository, model: Componente) {
        this.model = model
        this.model.objname = `${this.model.titulo}\n${this.model.tipo}`
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        const add = new SelectModulos(this.repository, { model: this.model }, "add")
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.titulo, "Atualizar", "updatecomponente", {}, true, "deletecomponente/true"),
            "titulo": new Field("text", "Título", "nome"),
            "conexao": new Field("object", "Conexões", "", [add]),
            "local": new Field("object", "Limites", "", [add]),
            "action": new Field("action", "Teste", "", [], this.model),
        }
        if (this.model.tipo === "dimmer")
            fields.local = new Field("object", "Limites", "", [new DimmerLimit(this.repository, this.model.conexao)])
        else delete fields.local

        if (!this.model.conexao || !Object.keys(this.model.conexao).length) {
            fields.conexao = new Field("object", "Conexões", "", [add])
            return fields
        }
        add.type = "change"
        const trash = new SelectModulos(this.repository, {model: this.model}, "trash")
        fields.conexao = Field.make("object", "Conexões", [new FormConexao(this.repository, this.model.conexao), add, trash], i => {
            console.log(i)
            if(i.type === "trash") {
                this.model.conexao = {}
                this.repository.update("Componentes", this.model._id, {
                    projId: this.model.projId,
                    conexao: ""
                })
                return "refresh"
            }
            return true
        })
        return fields
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import AdicionaComponente from "../../usecase/adicionaComponente";
import CopiarComponente from "../../usecase/copiarComponente";

export default class FormCopyMove {
    repository: Repository
    model: { titulo: string; root: any }
    constructor(repository: Repository, model: { titulo: string; root: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra(this.model.titulo, "none", "createcomponente"),
            "tipo": new Field("objectV", "Deseja", "", [
                { value: "move", name: "Mover" },
                { value: "copy", name: "Copiar" },
            ], "", async (item: any) => {
                console.log(item);
                
                if (item.value === "move")
                    await new AdicionaComponente(this.repository).execute(this.model.root, this.model)
                else
                    await new CopiarComponente(this.repository).execute(this.model.root, this.model)
                if(this.model.root.tipo === "root")
                return "close"
                return "begin"
            })
        }
    }
}
import Adapter from "../adapter/_adapter"
export type List = Array<{ value: string; name: string } | Adapter>
export type NameValueList = Array<{ value: string; name: string }>
//Action se retornar true faz acao normal, se false retorna um. Só esta funcionando no ObjectV
export type returns = "object" | "none" | "back" | "refresh" | "begin" | "close"
export type Action = (o?: any) => boolean | Promise<boolean | returns> | returns
export type Action2 = (o?: any) => Promise<returns> | returns

export interface FieldsTypes {
    "text": [label: string, placeholder: string];
    "number": [label: string, placeholder: string, extra?: {min: number; max: number}];
    "password": [label: string, placeholder: string];
    "show": [label: string];
    "copy": [label: string];
    "copypre": [label: string];
    "checkbox": [label: string, list: NameValueList];
    "select": [label: string, placeholder: string, list: NameValueList];
    "selectrepete": [label: string, placeholder: string, list: NameValueList];
    "selectfile": [label: string, placeholder: string];
    "file": [label: string, placeholder: string];
    "selectrange": [label: string];
    "object": [label: string, list: Adapter[], action?: Action];
    "objectC": [label: string];
    "objectV": [label: string, list: Adapter[], action?: Action];
    "objectVL": [label: string, list: Adapter[], extra: {order?: Action, action?: Action2}];
    "objectTable": [label: string];
    "objectm": [label: string];
    "objectVIMG": [label: string];
    "disabled": [label: string, placeholder: string];
    "toggle": [label: string];
    "add": [label: string, placeholder: string];
    "skip": [label: string, placeholder: string];
    "time": [label: string, placeholder: string];
    "times": [label: string, placeholder: string];
    "duracao": [label: string, placeholder: string];
    "button": [label: string, placeholder: string];
    "date": [label: string, placeholder: string];
    "textarea": [label: string, placeholder: string];
    "opcoes": [label: string];
    "snack": [label: string];
    "error": [label: string];
}
export const FieldsTypesMap: {[key: string]: string[]} = {
    "text": ["label", "placeholder"],
    "number": ["label", "placeholder", "extra"],
    "password": ["label", "placeholder"],
    "show": ["label"],
    "copy": ["label"],
    "copypre": ["label"],
    "checkbox": ["label", "list"],
    "select": ["label", "placeholder", "list"],
    "selectrepete": ["label", "placeholder", "list"],
    "selectfile": ["label", "placeholder"],
    "file": ["label", "placeholder"],
    "selectrange": ["label"],
    "object": ["label", "list", "action"],
    "objectC": ["label"],
    "objectV": ["label", "list", "action"],
    "objectVL": ["label", "list", "extra"],
    "objectTable": ["label"],
    "objectm": ["label"],
    "objectVIMG": ["label"],
    "disabled": ["label", "placeholder"],
    "toggle": ["label"],
    "add": ["label", "placeholder"],
    "skip": ["label", "placeholder"],
    "time": ["label", "placeholder"],
    "times": ["label", "placeholder"],
    "duracao": ["label", "placeholder"],
    "button": ["label", "placeholder"],
    "date": ["label", "placeholder"],
    "textarea": ["label", "placeholder"],
    "opcoes": ["label"],
    "snack": ["label"],
    "error": ["label"],
}
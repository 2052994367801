import Checksum from "@/core/AZ1/checksum";
import Module from "@/core/module/_module";
import { snackbar } from "@/stores/login";
import { checkModulo } from "../../controllers/checkModulo";

export default class CommandMqtt {
    module: Module
    constructor(module: Module) {
        this.module = module
    }
    async execute(component: any) {
        const [modId, e485, , err] = checkModulo(component)
        if (err) return snackbar.show(modId, 3000, "error");
        const pkg = (component.options.command as string).split(",").map(s => Number(s))
        if (!pkg.length) return console.error("Comando invalido:", component.options.command);

        pkg[5] = e485
        const [result, e] = await this.module.send(`${component.projId}/${modId}/CMD/CMD`, Checksum.calc(pkg).join(","), {query: `CMD/${e485}/7`}, component.parent._id);
        if (e) snackbar.show(result, 3000, "error")
    }
}
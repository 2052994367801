import Repository from "@/core/repository/_repository";
export default class UpdateInscricoes {
    static action = {
        timeout: 30000,
        text: "Atualizando Endpoint"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, options: any): Promise<string> {
        console.log(form)
        console.log(options)
        for (const key in options) {
            if (Object.prototype.hasOwnProperty.call(form.model.inscricoes, key)) {
                if (form.model.inscricoes[key].criar) {
                    console.log("criando", key)
                    const [result, err] = await this.repository.create("EndpointInscricoes", {
                        projId: key,
                        notificar: options[key],
                        tipo: form.model.tipo,
                        endpoint: form.model._id
                    })
                    console.log(result, err)
                } else {
                    console.log("Atualizando", form.model.inscricoes[key]._id)
                    const [result, err] = await this.repository.update("EndpointInscricoes", form.model.inscricoes[key]._id, {
                        projId: key,
                        notificar: options[key]
                    })
                    console.log(result, err)
                }
            }
        }
        return "back"
    }

    sleep(time: number) {
        return new Promise(res => setTimeout(() => res(0), time))
    }
}
<template>
  <section class="card d-grid gap-p p-10 w-100 pointer" @click="retorna">
    <h2>{{ adapted.title}}</h2>
    <p>{{ adapted.description }}</p>
  </section>
</template>

<script>
export default {
  name: "cardTitleDescription",
  props: {
    object: Object,
    adapter: Object
  },
  data(){
    return {
        adapted: {
          title: "Erro no adapter",
          description: "Erro no adapter"
        }
    }
  },
  created(){
    this.adapted = new this.adapter().adapt(this.object)
  },
  methods: {
    retorna() {
      this.$emit("action", this.object);
    },
  },
};
</script>
<template>
  <button type="button" @click="create">{{ field.label }}</button>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  name: "button",
  props: {
    field: Object,
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    async create() {
      //aqui chama a action e fecha se for true
      const result = this.field.action ? await this.field.action(this.field) : null
      if(typeof result === "boolean" && result)
        this.$emit("close");
      else if(typeof result === "string") this.$emit(result)
    },
  },
});
</script>

<style>
.objects {
  background-color: var(--ion-color-light-tint);
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
}
</style>
import FormComponent from "../../forms/component";
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import FormStatusSub from "./statussub";
export default class FormAgregador extends FormComponent {
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = await super.getFields()
        if (this.model.options.type === "ac") {
            const [result, err] = await this.repository.findOne("Componentes", {
                projId: this.model.projId,
                control: this.model._id,
                tipo: "statussub", 
                titulo: "Sensor temp"
            })
            if(err || !result) return fields
            fields["sensortemp"] = Field.make("object", "Sensor Temperatura", [new FormStatusSub(this.repository, result)])
        }
        return fields
    }
}
<template>
    <div class="form-input d-grid gap-s">
        <div class="d-flex jc-between">
            <label for="projAmbientes">Ambiente</label>
            <div class="clear1" @click="clear('projAmbientes')">Limpar</div>
        </div>
        <input list="ambientes" type="text" name="Ambientes" v-model="value" @change="changeInput" />
        <datalist id="ambientes">
            <option v-for="ambiente in ambientes" v-bind:key="ambiente" :value="ambiente"></option>
        </datalist>
    </div>
</template>

<script>
export default {
    props: {
        formulario: Object,
        global: Object
    },
    data() {
        return {
            data: "",
            elProject: document.getElementById("projName"),
            value: "",
            ambientes: [],
            map: { Projeto1: ["sala", "cozinha"], Projeto2: ["quarto", "banheiro"] }
        };
    },
    async created() {
        console.log("criou componente")
        this.refreshAmbientes(this.formulario.projeto)
    },
    methods: {
        envia() {
            console.log("enviando formulario")
        },
        clear(tipo) {
            console.log(this.value, tipo)
            this.value = ""
        },
        changeInput() {
            console.log("dentro do elemento", this.value)
            this.$emit("changeInput", "ambiente", this.value)
        },
        refreshAmbientes(projName) {
            //atualiza lista de ambientes
            console.log("Atualizando ambiente")
            if (this.global.data) {
                const projData = this.global.data[projName]
                console.log(projData, this.global.data)
                if (projData) {
                    for (const property in projData) {
                        if (property == "") continue
                        if (property == "Geral") continue
                        this.ambientes.push(property)
                    }              
                }
            }

        }
    }
};
</script>

<style></style>
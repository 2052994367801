import Repository from "@/core/repository/_repository";

export default class UpdateStyle {
    static action = {
        timeout: 10000,
        text: "Atualizando Estilo"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        const update: {[key: string]: any} = {}
        update["projId"]= form.model.element.projId,
        update[form.model.style] = fields
        await this.repository.update("ElementsStyle", form.model.styleid, update)
        return "back"
    }
}
<template>
  <select v-model="value">
    <option v-for="botao in botoes" :key="botao._id" :value="botao._id">
      {{ botao.titulo }}
    </option>
  </select>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "select",
  props: {
    field: Object,
  },
  async created() {
    const [agregador, err] = await this.$repository.findOne("AgregadorSubs", {
      projId: this.field.projId,
      _id: this.field.componente,
    });
    if (err) console.error(agregador);
    else if (agregador) {
      agregador.painel.forEach((b) => this.botoes.push(b));
      agregador.control.forEach((b) => this.botoes.push(b));
    }
    this.value = this.field.value;
  },
  data() {
    return {
      value: "",
      botoes: [],
    };
  },
});
</script>
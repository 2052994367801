import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class FormCreateProjeto {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Criar Projeto", "Criar", "createprojeto"),
            "nome": new Field("text", "Nome", "nome"),
            "tipo": Field.make("select", "Tipo", "Selecione", [
                {value: "alarme", name: "Alarme"},
                {value: "local", name: "Local"},
            ])
        }
    }
}
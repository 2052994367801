import Repository from "@/core/repository/_repository";
export default class CreateServidorLocal {
    static action = {
        timeout: 10000,
        text: "Criando Servidor Local"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)

        const [result, err] = await this.repository.create("ServidoresLocais", {
            nome: fields.nome,
            uuid: crypto.randomUUID(),
            revenda: form.model.revenda
        })
        console.log(result, err);
        form.lista.push(result)
        return "close"
    }
}
<template>
    <div :class="[
        'noselect',
        'd-grid',
        'gap-m',
        'row-mc',
        'row-13',
        'column-full',
    ]" @config="config">
        <div class="d-flex justify-between">
            <h2>{{ component.titulo }}</h2>
            <change class="cursor-pointer" @click="refresh"></change>
        </div>
        <div class="d-grid gap-m ac-start p-05em br-05em bg-screen projetosmodulos auto-fit">
            <div class="d-grid gap-s br-05em p-05em bg-card cursor-pointer" v-for="projeto in projetos" :key="projeto._id"
                @click="form(projeto)">
                <h2>{{ projeto.nome }}</h2>
                <div class="d-flex gap-m o-scroll">
                    <p v-for="modulo in projeto.modulos" :key="modulo._id">{{ modulo.conexao ? modulo.conexao.status : "⚫" }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import FormStatusModulos from "../forms/statusmodulos";
import { modal } from "@/stores/showModal";
import change from "@/components/Form/icons/change.vue";
export default {
    components: {
        change
    },
    props: {
        component: Object,
        project: Object,
    },
    data() {
        return {
            value: false,
            projetos: [],
            store: { root: {} },
            i: 0,
        };
    },
    async created() {
        this.getProjects()
    },
    methods: {
        async getProjects() {
            this.projetos = []
            this.store = { root: {} }
            const [result, err] = await this.$repository.findMany("ProjetosModulos", {
                revenda: this.project._id,
                status: "ativo"
            })
            if (err) return console.error(result)
            result.forEach(p => {
                p.rank = p.modulos.length ? 0 : -1
                p.modulos.forEach(m => {
                    if (!m || !m.conexao) return
                    if (m.conexao.status === "🔴") p.rank++
                    if (m.conexao.status === "🟡") p.rank += 0.5
                })

                if (!Object.keys(this.store.root).length) return Object.assign(this.store.root, p)

                this.insere(this.store.root, p)
            })
            console.log(this.store.root)
            this.pushProjeto(this.store.root)
        },
        insere(raiz, p) {
            if (p.rank <= raiz.rank) {
                if (raiz.proximo) this.insere(raiz.proximo, p)
                else {
                    raiz.proximo = p
                    p.anterior = raiz
                }
            } else {
                if (!raiz.anterior)
                    this.store.root = p
                else {
                    raiz.anterior.proximo = p
                    p.anterior = raiz.anterior
                }
                p.proximo = raiz
                raiz.anterior = p
            }
        },
        pushProjeto(raiz) {
            this.projetos.push(raiz)
            if (raiz.proximo) this.pushProjeto(raiz.proximo)
        },
        form(projeto) {
            projeto.parent = this.project
            modal.show(new FormStatusModulos(this.$repository, projeto))
        },
        refresh() {
            this.getProjects()
        }
    },
};
</script>
  
<style></style>
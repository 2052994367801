<template>
  <form class="o-auto d-grid gap-mid" @submit.prevent="retorna">
    <div
      class="entrada inteiro gap-low"
      v-for="(field, key) in fields"
      v-bind:key="field.id"
      :class="field.type == 'toggle' ? 'd-flex justify-between' : 'd-grid'"
    >
      <label :for="key" v-if="field.type != 'error'">{{ field.label }}</label>
      <label v-if="field.type == 'toggle'" class="switch">
        <component
          v-bind:is="field.type"
          v-bind:field="field"
          v-bind:key="path"
          v-bind:id="key"
          v-model="atribs[key]"
          @object="mostraObject"
          @add="mostraAdd"
        ></component>
        <span class="slider round"></span>
      </label>
      <component
        v-else
        v-bind:is="field.type"
        v-bind:field="field"
        v-bind:key="path"
        v-bind:id="key"
        v-model="atribs[key]"
        @object="mostraObject"
        @controller="executeController"
      ></component>
      <p
        v-if="field.list && field.list[0].value == 'add'"
        class="forgot-password"
        @click="$emit('forgot', 'forgot')"
      >
        {{ field.list[0].name }}
      </p>
    </div>
    <!-- Talvez possa so fazer um lista de botoes no adapter para o proximo passo -->
    <div class="inteiro d-flex gap">
      <button type="submit">{{ extras.action }}</button>
    </div>
  </form>
</template>

<script>
import checkbox from "@/components/Form/Checkbox.vue";
import select from "@/components/Form/Select.vue";
import text from "@/components/Form/Text.vue";
import textarea from "@/components/Form/Textarea.vue";
import object from "@/components/Form/Object.vue";
import objectV from "@/components/Form/ObjectV.vue";
import objectC from "@/components/Form/ObjectC.vue";
import disabled from "@/components/Form/Disabled.vue";
import toggle from "@/components/Form/Toggle.vue";
import add from "@/components/Form/Add.vue";
import show from "@/components/Form/Show.vue";
import password from "@/components/Form/Password.vue";
import error from "@/components/Form/Error.vue";
import loginopt from "@/components/Form/LoginOpt.vue";
import FactoryController from "@/core/controller/_factory";
export default {
  props: {
    adapter: Object,
  },
  components: {
    select,
    text,
    textarea,
    object,
    objectV,
    disabled,
    toggle,
    add,
    objectC,
    show,
    password,
    loginopt,
    error,
    checkbox
  },
  data() {
    return {
      atribs: {},
      fields: [],
      extras: {},
    };
  },
  async created() {
    console.log(this.adapter);
    if(!this.adapter) return
    console.log(this.adapter);
    const model = this.adapter.model
    this.fields = await this.adapter.getFields();
    this.extras = this.fields.extras;
    delete this.fields.extras;
    for (const key in this.fields) {
      if (Object.hasOwnProperty.call(model, key))
        this.atribs[key] = model[key];
    }
  },
  methods: {
    retorna() {
      console.log(this.atribs);
      const [result, err] = FactoryController.get(this.extras.controller);
      if (err) console.error(result);
      else {
        const controller = new result(this.$repository);
        return controller.execute(this.adapter, this.atribs);
      }
    },
  },
};
</script>
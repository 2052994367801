<template>
  <card
    :class="['d-grid', 'gap-m', 'row-five', 'column-fit-l', 'pointer', component.options.type]"
    @config="config"
  >
  <div @click="screen" class="d-flex justify-between">
    <p v-if="components.list[i].conexao && components.list[i].conexao.status !== '🟢'">{{ components.list[i].conexao.status }}</p>
    <h2>{{ component.titulo }}</h2>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ccc" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize"><polyline points="9 18 15 12 9 6"/></svg>
  </div>
    <section class="widgets">
      <component
        v-for="botao in component.painel"
        v-bind:is="botao.tipo"
        :key="botao._id"
        v-bind:component="botao"
      ></component>
    </section>
  </card>
</template>

<script>
import { modal, modalcontrole } from "@/stores/showModal";
import botao from "@/Features/Componentes/botao/ui/botaomenor.vue";
import botaoicone from "@/Features/Componentes/botao/ui/icone.vue";
import botaoiconeSQ from "@/Features/Componentes/botao/ui/iconeSQ.vue";
import statusvol from "./status.vue";
import statussub from "./statussub.vue";
import Factory from "../actions/_factory";
import ListForms from "../forms/_factory";
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
export default {
  components: {
    Card,
    botao,
    botaoicone,
    botaoiconeSQ,
    statusvol,
    statussub,
  },
  props: {
    component: Object,
  },
  data() {
    return {
      value: false,
      components,
      i: 0,
    };
  },
  async created() {
    this.i = components.list.map((c) => c._id).indexOf(this.component._id);
    this.value = this.component.options.state;
    const [component, err] = await this.$repository.findOne("AgregadorSubs", {
      _id: this.component._id,
      projId: this.$route.params.projId,
    });
    if (err) console.error(component);
    else {
      component.conexao = this.component.conexao;
      Object.assign(this.component, component);
      component.painel.forEach((c) => {
        c.parent = this.component;
        this.components.push(c);
        components.push(c);
      });
      component.control.forEach((c) => components.push(c));
    }
  },
  methods: {
    execute() {
      const action = Factory.actions[this.component.options.action];
      Object.assign(this.component.options, { state: this.value });
      new action(this.$module).execute(this.component);
    },
    config() {
      console.log("config");
      if (ListForms.check(this.component.options.action))
        modal.show(
          new ListForms.forms[this.component.options.action](this.component)
        );
      else
        console.error(`Form "${this.component.options.action}" do not exist`);
    },
    screen() {
      console.log("#######");
      console.log(this.component);
      modalcontrole.show(this.component.control, this.component);
    },
  },
};
</script>

<style>
</style>
<template>
  <main class="d-grid gap" onselectstart="return false">
    <button @click="createComponente">Adicionar Programação</button>
    <div
      class="d-flex a-center gap-m"
      v-for="programacao in programacoes"
      :key="programacao._id"
    >
      <card-title-description
        :value="programacao"
        @mostraModulo="mostraModulo"
      />
    </div>
  </main>
</template>

<script>
import { modal } from "@/stores/showModal";
import Forms from "@/Features/Programacao/forms/_list";
import cardTitleDescription from "@/components/cards/_card.vue";
import { programacoes } from "@/stores/programacao";
export default {
  name: "page-programacao",
  components: {
    cardTitleDescription,
  },
  computed: {
    styleDropDown() {
      return "top: " + this.topDropDown + "px";
    },
  },
  data() {
    return {
      header: { title: "", subtitle: "Gerenciamento de modulos" },
      dropDown: false,
      configurar: false,
      configurando: false,
      adapter: {},
      configLayer: 0,
      programacoes: [],
      show: Forms,
    };
  },
  async created() {
    this.header.subtitle = "Gerenciamento de Programações";
    programacoes.clear();
    this.programacoes = programacoes.list;
    const [prog, err] = await this.$repository.findMany("Programacoes", {
      projId: this.$route.params.projId,
    });
    if (err) console.error("Erro ao pegar Programações");
    else if (prog) {
      prog.forEach((p) => programacoes.push(p));
    }
    console.log(programacoes);
  },
  methods: {
    async updateConfiguracao() {
      this.configurando = false;
      const [result, err] = await this.$repository.findMany(this.collection, {
        projId: this.$route.params.projId,
      });
      if (err) console.error("Erro ao pegar modulos");
      else this.modulos = result;
    },
    mostraModulo(modulo) {
      modal.show(new this.show.list[modulo.tipo](this.$repository, modulo));
    },
    createComponente() {
      modal.show(new this.show.list["create"](this.$repository, this.root));
    },
  },
};
</script>
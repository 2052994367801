import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import RepositoryIndexedDB from "@/core/repository/IndexedDB"
import IndexedDB from "@/core/repository/IndexedDB/config"
import { setallprojeto } from "@/core/repository/IndexedDB/teste"
import Repository from "@/core/repository/_repository"
import { wait } from "@/stores/showModal"
import { Socket } from "socket.io-client"

export default class FormSetOffline {
    socket: Socket
    model: any
    constructor(socket: Socket, model: any) {
        this.model = model
        this.socket = socket
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        return {
            "extras": new Extra("Ativar Banco de Dados Offline", "none", ""),
            "themes": new Field("objectV", "Temas", "", [
                { value: "active", name: "Ativar" },
                { value: "deactive", name: "Desativar" }
            ], this.model, async (i) => {
                if(i.value === "active"){
                    console.log("Ativando banco de dados local")
                    /* const IDB = new IndexedDB("Livtech")
                    const repositoryIDB = new RepositoryIndexedDB(IDB); */
                    wait.show(30000, "Pegando dados com o servidor")
                    await setallprojeto(this.socket, /* repositoryIDB */)
                    wait.show(0, "")
                    return "none"
                }
                console.log("Desativando banco de dados local")
                /* para desativar de fato tem que desconectar */
                
                indexedDB.deleteDatabase("Livtech")
                return "none"
            })
        }
    }
}
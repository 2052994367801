import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import SelectExist from "./_exist"
import SelectElementos from "./_select";
export default class ChooseSelect {
    repository: Repository
    model: any
    constructor(repository: Repository, model: { titulo: string; root: any }) {
        this.model = model
        this.model.act = true
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list: any[] = [
            new SelectElementos(this.repository, { titulo: "Elemento Novo", root: this.model.root }),
            new SelectExist(this.repository, { titulo: "Elemento Existente", root: this.model.root })
        ]
        const exist: {name: string; value: string}[] = []
        const [result, err] = await this.repository.findMany("Componentes", {
            projId: this.model.root.projId,
            func: "element"
        })
        if(err) console.error(err)
        else result.forEach(c => exist.push({name: c.titulo, value: c._id}))
        return {
            "extras": new Extra("Escolha", "none", ""),
            "novo": new Field("objectV", "Criar de", "", list)
        }

    }
}
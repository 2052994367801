import Extra from "@/core/entity/Extra"
import Field from "@/core/entity/Field"
import Repository from "@/core/repository/_repository"
import AdicionarProgramacaoEventoModulo from "./servidor/adicionarProgramacao"
import SelectModos from "./servidor/entradaprogramacao"
import FormSelectEventos from "./servidor/selectEventos"
import FormRotinasConexao from "./servidor/conexao"

export default class FormModuloProgramacao {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        const entradas = this.pegaConexaoPorTipo(this.model.model, "entradadigital")
        const saidas = this.pegaConexaoPorTipo(this.model.model, "saida")

        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra(this.model.nome, "none", ""),
            "entradas": new Field("object", "Entradas", "", entradas.map(e => new FormRotinasConexao(this.repository, { nome: e.nome, model: e }))),
            "saidas": new Field("object", "Saídas", "", saidas.map(s => new FormRotinasConexao(this.repository, { nome: s.nome, model: s }))),
        }

        const [result, err] = await this.repository.findOne("EntradaProgramacoes", {
            projId: this.model.model.projId,
            _id: this.model.model._id,
        })
        if (err || !result) console.error(result);
        else console.log(result);

        const add = new FormSelectEventos(this.repository, { titulo: "+ Adicionar", model: this.model.model })
        const operadores: SelectModos[] = []
        if (result) {
            this.model.have = result.programacoes.length
            result.programacoes.forEach((o: any) => {
                o.componentType = this.model.model.tipo
                operadores.push(new SelectModos(this.repository, o, this.model.model))
            });
        } else this.model.have = 0
        operadores.push(add)
        fields.operadores = new Field("objectV", "Eventos", "", operadores, this.model, (o: any) => {
            console.log(o);
            this.model.model.programacaoentrada = o.model
            return true
        })
        return fields
    }

    pegaConexaoPorTipo(modulo: any, tipo: string) {
        const list: any[] = []
        console.log(modulo)
        modulo.portas.forEach((p: any) => {
            p.conexoes.forEach((c: any) => {
                if (c.tipo === tipo) list.push(c)
            })
        })
        return list
    }
}
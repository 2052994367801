import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import SelectModos from "./entradaprogramacao";

export default class AdicionarProgramacao {
    repository: Repository
    model: { titulo: string; model: any }
    constructor(repository: Repository, model: { titulo: string; model: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const list: any[] = []
        const fields = {
            "extras": new Extra("Escolha", "none", ""),
            "modulo": new Field("show", "Programações", "", list)
        }

        console.log(this.model)
        const [result, err] = await this.repository.findMany("Programacoes", { projId: this.model.model.model.projId })
        if (err) {
            (this.model as any).modulo = "Sem programações, vá para Programação e crie uma"
            console.error(result);
        } else {
            result.forEach(p => list.push(new SelectModos(this.repository, p, this.model.model.model)))
            fields.modulo = new Field("objectV", "Programações", "", list, this.model.model.model, async (o: any) => {
                console.log(o);
                console.log();

                const [entrada, err] = await this.repository.create("ProgramacoesEntradas", {
                    programacao: o.model._id,
                    entrada: this.model.model.model.conexao._id,
                    projId: this.model.model.model.projId,
                    tipo: this.model.model.model.tipo,
                    modo: "0"
                })
                if (err) {
                    console.error(entrada);
                    return false
                }
                console.log(entrada)
                this.model.model.model.programacaoentrada = entrada
                return true
            })
            console.log(result);
        }

        return fields
    }
}
<template>
  <div class="card noselect">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "component-card",
  data() {
    return {
      timeout: undefined,
    };
  },
  methods: {
    time(status) {
      if (status === "start" && !this.timeout) {
        this.timeout = setTimeout(() => {
          this.$emit("config");
          this.timeout = undefined;
        }, 1000);
      } else if (status === "end" && this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }
    },
  },
};
</script>

<style>
</style>

<!-- <div
    class="card"
    @mousedown="time('start')"
    @mouseup="time('end')"
    @touchstart="time('start')"
    @touchend="time('end')"
  > -->
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class AddColumn {
    repository: Repository
    model: { nome: string; table: any }
    constructor(repository: Repository, model: { nome: string; table: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const types: {value: string; name: string}[]= [
            {value: "text", name: "Texto"},
            {value: "number", name: "Número"},
            {value: "textpoints", name: "Texto com Pontos"},
            {value: "imageselect", name: "Imagem Booleana"},
        ] 
        return {
            "extras": new Extra("Adicionar Coluna", "Adicionar", "end/addcolumn/last"),
            "key": new Field("text", "Chave", "Identificador da coluna"),
            "name": new Field("text", "Título", "Título da coluna"),
            "type": new Field("select", "Tipo", "", types),
        }

    }
}
export default class Compatibilidade {
    static list: { [key: string]: string[] } = {
        audio: ["audio"],
        entradadigital: ["digital-in", "eventdigitalin"],
        saida: ["interruptor", "dimmer"],
        saidair: ["agregador"],
        temperatura: ["status", "grafico", "statussub"],
        sensorporta: ["acesso"]
    }

    static check(componente: string, conexao: string): boolean {
        if (!Object.prototype.hasOwnProperty.call(this.list, conexao))
            return false
        const result = this.list[conexao].find(t => t === componente)
        return result ? true : false
    }
}
<template>
    <div>
        <img v-if="cell.value" :src="cell.column.options.src" style="object-fit: contain;width: 90%;">
    </div>
</template>

<script>
export default {
    name: "imageselect",
    props: {
        cell: Object
    }
}
</script>
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import FormComponent from "../../forms/component";
import Componente from "../../_componente";
export default class FormAtividade extends FormComponent {
    constructor(repository: Repository, model: Componente) {
        super(repository, model)
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = await super.getFields()
        delete fields.conexao
        delete fields.local
        delete fields.action
        return fields
    }
}
<template>
    <card :class="['d-grid', 'gap-m', 'row-13', 'column-half-full', 'pointer', 'ac-start']" style="
    grid-row-end: span 16;
" @config="config">
        <video class="camera" controls autoplay :id="component._id"></video>
    </card>
</template>

<script>
import Card from "@/components/UI/Card.vue";
import { components } from "@/stores/components";
import CameraListeners from "../wrtc/listeners";
export default {
    components: {
        Card,
    },
    props: {
        component: Object,
    },
    data() {
        return {
            value: false,
            components,
            i: 0,
        };
    },
    async created() {
        console.log(this.component)
            const chunks = [];
            await new Promise(res => setTimeout(() => {
                res({})
            }, 2000))
            const video = document.getElementById(this.component._id);
            console.log(video)
            const mse = new MediaSource();
            console.log(mse)
            let sourceBuffer;
            video.src = URL.createObjectURL(mse);
            console.log(video.src)
            mse.addEventListener('sourceopen', onMediaSourceOpen);
            function onMediaSourceOpen() {
                if (mse.readyState !== "open") return
                sourceBuffer = mse.addSourceBuffer('video/mp4; codecs="avc1.4d401f"');
                sourceBuffer.addEventListener('updateend', addMoreBuffer);
                video.play();
            }

            function addMoreBuffer() {
                if (!sourceBuffer || sourceBuffer.updating || !chunks.length) {
                    return;
                }
                /* TODO: aqui tem lotando o buffer, tem que desinscrever quando sai da pagina*/
                try {
                    sourceBuffer.appendBuffer(chunks.shift());
                } catch (error) {
                    //sourceBuffer.remove(start, end)
                }
            }
            /* 
            [x] servidor para typescript
            [x] adicionar cameras no banco
            [x] fazer o servidor pegar do banco
            [x] pegar pelo servidor local que conecta via P2P/WRTC
            [x] servidor confirmando token
            [] implementar adicionar camera no banco de dados
             */
            /* TODO: adicionar um eventlistener on play para quando iniciar ja ir para o final */
            function gotoend() {
                video.playbackRate = 2
                video.addEventListener("waiting", () => {
                    console.log("end")
                    video.playbackRate = 1
                })
                console.log(video.getVideoPlaybackQuality().totalVideoFrames)
                //video.currentTime = (video.getVideoPlaybackQuality().totalVideoFrames / 30) + 1
            }

            CameraListeners.push(this.component.projId, this.component.options.camera, (data) => {
                const frame = new Uint8Array(data)
                chunks.push(frame);
                addMoreBuffer();
            })
    },

    unmounted() {
        CameraListeners.remove(this.component.options.camera)
    },

    methods: {
    }
};
</script>

<style>
video.camera {
    width: calc(100% - 1em);
    height: calc(100% - 1em);
    position: absolute;
    left: 0.5em;
    top: 0.5em;
}
</style>
import Repository from "@/core/repository/_repository";
import { store } from "@/store";
export default class UpdateProgramacaoEntrada {
    static action = {
        timeout: 3000,
        text: "Atualizando Modo"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        const programacaoentrada = form.model.programacaoentrada ? form.model.programacaoentrada : form.last.programacaoentrada
        Object.assign(programacaoentrada, fields)
        console.log(programacaoentrada)
        if (typeof programacaoentrada.programacao === "object")
            programacaoentrada.programacao = programacaoentrada.programacao._id
        const [result, err] = await this.repository.update("ProgramacoesEntradas", programacaoentrada._id, programacaoentrada)
        if (err) console.error(result);
        else console.log(result);

        return "close"
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import Compatibilidade from "./compatibilidade";

export default class SelectConexoes {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.model = model
        this.model.nome = model.modulo.nome
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        const fields = {
            "extras": new Extra(this.model.nome, "Conectar", "end/conectacomponente/true"),
            "conexao": new Field("select", "Conexões", "Conexoes nao encontradas", [])
        }

        const list: { value: string; name: string }[] = []
        this.model.modulo.portas.forEach((p: any) => {
            p.conexoes.forEach((c: any) => {
                if (Compatibilidade.check(this.model.componente.tipo, c.tipo))
                    list.push({
                        value: c._id,
                        name: `${c.nome}`
                    })
            })
        })
        fields.conexao = new Field("select", "Conexões", "Selecione uma Conexão", list)
        return fields
    }
}
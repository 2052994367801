<template>
  <div class="d-grid gap o-scroll tableconfig">
    <div class="d-grid gap-mid" :style="{ 'grid-template-columns': gridtemplatecolumns }">
      <div></div>
      <div class="margin center" v-for="(item, i) in columns" v-bind:key="item._id" :value="item.tipo">
        <svg @click="order(item, 'columns')" v-if="i > 0" style="width: 20px;" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" class="feather feather-corner-up-left">
          <polyline points="9 14 4 9 9 4" />
          <path d="M20 20v-7a4 4 0 0 0-4-4H4" />
        </svg>
      </div>
      <div class="add objects" @click="add('column')">+ Coluna</div>
    </div>
    <div class="d-grid gap-mid header" :style="{ 'grid-template-columns': gridtemplatecolumns }">
      <div></div>
      <div class="objects" v-for="item in columns" v-bind:key="item._id" :value="item.tipo"
        @click="opencell(item, 'head')">
        {{ item.name }}
      </div>
    </div>
    <div class="d-grid gap body">
      <div class="d-grid gap-mid row" v-for="(item, i) in rows" v-bind:key="item._id"
        :style="{ 'grid-template-columns': gridtemplatecolumns }">
        <div class="margin center">
          <svg @click="order(item, 'rows')" v-if="i > 0" style="width: 20px;" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" class="feather feather-corner-left-up">
            <polyline points="14 9 9 4 4 9" />
            <path d="M20 20h-7a4 4 0 0 1-4-4V4" />
          </svg>
        </div>
        <div class="objects" v-for="c in columns" :key="c._id"
          @click="opencell(item.fields[c.key] ? item.fields[c.key] : { column: c, row: item, new: true }, 'body')">
          {{ item.fields[c.key] ? item.fields[c.key].value : "+" }}
        </div>
        <div class="center pointer" @click="removerow(item)"><svg style="width: 20px;"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" class="feather stroke feather-trash-2">
            <polyline points="3 6 5 6 21 6" />
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
            <line x1="10" y1="11" x2="10" y2="17" />
            <line x1="14" y1="11" x2="14" y2="17" />
          </svg></div>
      </div>
      <div class="add objects" @click="add('row')">+ Linha</div>
    </div>
  </div>
</template>

<script>
import DeleteValue from "@/Features/Componentes/display/controller/deletevalue";
import AddColumn from "@/Features/Componentes/display/form/elementos/addColumn";
import AddValue from "@/Features/Componentes/display/form/elementos/addValue";
import FormColumn from "@/Features/Componentes/display/form/elementos/column";
import FormValue from "@/Features/Componentes/display/form/elementos/value";
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  name: "object",
  props: {
    field: Object,
    formu: Object,
  },
  async created() {
    console.log(this.formu)
    this.element = this.formu.model.table
    const [table, err] = await this.$repository.findOne("TableElements", {
      projId: this.element.projId,
      _id: this.element._id
    })
    if (err) console.error(table);
    console.log(table);
    this.element = table
    this.columns = table.columns
    this.rows = table.rows
    for (const i in table.rows) {
      const result = {}
      table.rows[i].fields.forEach(g => result[g.key] = g)
      table.rows[i].fields = result
    }
    table.columns.forEach(c => {
      this.gridtemplatecolumns += ` ${c.size ? this.roundwidth(Number(c.size), 50) : 10}%`
    })
    this.gridtemplatecolumns += " 6em"
    //TODO: Quando adiciona uma coluna tem que adicioanr os Field nas linhas passando o column
  },
  data() {
    return {
      value: "",
      gridtemplatecolumns: "6em",
      addColumn: {},
      addValue: {},
      element: {},
      columns: [],
      rows: [],
      celltypes: {
        "head": FormColumn,
        "body": FormValue,
      },
      addtypes: {
        "column": AddColumn,
        "row": AddValue
      }
    };
  },
  methods: {
    roundwidth(x, y) {
      if (x > y) return y
      return this.roundwidth(x, y - 5)
    },
    opencell(cell, type) {
      this.$emit("object", new this.celltypes[type](this.$repository, cell))
    },
    add(type) {
      this.$emit("object", new this.addtypes[type](this.$repository, { table: this.element }))
    },
    async removerow(row) {
      await new DeleteValue(this.$repository).execute({ model: row }, {})
      const i = this.rows.map(m => m._id).indexOf(row._id)
      this.rows.splice(i, 1);
    },
    async order(item, type) {
      console.log(item, type);
      console.log(item);
      const i = this[type].map((i) => i._id).indexOf(item._id);
      console.log(i);
      if (i === 0) return;
      const aux = this[type][i - 1];
      this[type][i - 1] = this[type][i];
      this[type][i] = aux;
      this[type][i - 1].index = i - 1;
      this[type][i].index = i;
      if (type === "columns") {
        this.gridtemplatecolumns = "6em"
        this.columns.forEach(c => {
          this.gridtemplatecolumns += ` ${c.size ? this.roundwidth(Number(c.size), 50) : 10}%`
        })
        this.gridtemplatecolumns += " 6em"
      }
      [this[type][i - 1], this[type][i]].forEach(async (i) => {
        console.log(i)
        const result = await this.$repository.update(type === "columns"? "TableColumns": "TableRows", i._id, i);
        console.log(result);
      });
      console.log(this[type]);
    }
  }
});
</script>

<style>
.objects {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 0.7em;
  text-align: center;
  /* min-width: 20vw; */
  max-width: inherit;
  white-space: pre;
  cursor: pointer;
}

.header .objects {
  background-color: rgb(98 98 98 / 37%);
}

.body .row:nth-child(even) .objects {
  background-color: #c7c7c77c;
}

.add.objects {
  background-color: var(--color-primary) !important;
  color: var(--color-primary-contrast);
  width: 6em;
}

.margin {
  background-color: var(--color-primary-fade);
  border-radius: 0.5em;
}
</style>
import Repository from "@/core/repository/_repository";
import Forms from "../forms/_list";
import { store } from "@/store";
import { components, componentsMain } from "@/stores/components";
import { modal } from "@/stores/showModal";
import UseCases from "../usecase/_list";
export default class CreateComponente {
    static action = {
        timeout: 10000,
        text: "Criando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        //Tem que acrescentar na lista os outros componentes como interrutor dimmer e passar o tipo no model para usar um usecase generico que só cria o componente
        const [newcomponent, err] = await new UseCases.list[fields.tipo](this.repository).execute(form.model, fields)
        if (err) console.error(newcomponent);
        else {
            let [root] = form.model.children && !form.fromothersrc ? [form.model] : await this.repository.findOne("RootChildrenNum", {
                projId: form.model.projId,
                tipo: "root"
            });
            if (!root) {
                const [result, err] = await this.repository.findOne("Componentes", {
                    projId: form.model.projId,
                    tipo: "root"
                })
                if (err || !result) {
                    console.error("erro ao inserir componente no root");
                    return "finish"
                }
                result.children = 0
                root = result
            }
            if (typeof root === "string") root = { children: componentsMain.listmain.length }
            this.repository.create("ComponenteRoot", {
                projId: form.model.projId,
                projeto: form.model.projeto,
                root: form.fromothersrc ? root._id : form.model._id,
                index: root.children,
                componente: newcomponent._id
            })
            componentsMain.push(newcomponent)
            components.push(newcomponent)
        }
        if (!form.fromothersrc)
            setTimeout(() => {
                modal.show(new Forms.list[newcomponent.tipo](this.repository, newcomponent))
            }, 100);

        if (form.back) return "back"
        return "finish"
    }
}
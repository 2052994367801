<template>
    <div class="form-input d-grid gap-s">
        <div class="d-flex jc-between">
            <label for="Atividade">Atividade</label>
            <div class="clear1" onclick="clear25('Atividade')">Limpar</div>
        </div>
        <input list="atividades" type="text" name="Atividade" id="Atividade" v-model="value" @change="changeInput" />
        <datalist id="atividades">
            <option v-for="atividade in atividades" v-bind:key="atividade" :value="atividade"></option>
        </datalist>
    </div>
</template>

<script>
export default {
    props: {
        global: Object,
        formulario: Object,
    },
    data() {
        return {
            data: "",
            elProject: document.getElementById("projName"),
            value: "",
            atividades: [],
        };
    },
    async created() {
        console.log("criou componente")
        this.refreshAtividade(this.formulario.segmento)
        this.$emit("Set")
    },
    methods: {
        envia() {
            console.log("enviando formulario")
        },
        changeInput() {
            console.log("dentro do elemento", this.value)
            this.$emit("changeInput", "atividade", this.value)
            this.onAtiviadeNomeChange()
        },
        refreshAtividade(segmento) {
            console.log("refreshAtividade")

            //atualiza lista de atividades
            if (this.global.objLegenda && segmento) {
                const segData = this.global.objLegenda[segmento]
                if (segData) {
                    for (const property in segData) {
                        if (property == "") continue
                        this.atividades.push(property)
                    }
                    //console.log("++++++",list)                  
                }
            }

            //limpa lista de acoes
            //refreshAcoes()
        },
        //monitora alteracao do projeto
        onAtiviadeNomeChange() {
            console.log("onAtiviadeNomeChange")

            const segmento = this.formulario.segmento
            const atividade = this.value
            let acoes = this.global.objLegenda[segmento]
            if (acoes) acoes = acoes[atividade]
            //atualiza lista de acoes
            //refreshAcoes(acoes)
            this.$emit("changeInput", "acoes", acoes)
        }
        /* elProject.onchange = onProjectNameChange */
    }
};
</script>

<style></style>
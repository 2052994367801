import Repository from "@/core/repository/_repository";
import { store } from "@/store";
export default class DeletePeriodo {
    static action = {
        timeout: 10000,
        text: "Apagando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        const [result, err] = await this.repository.delete("Periodos", form.model._id)
        if (err) return `erro/${result}`

        const [d1, e1] = await this.repository.deleteMany("Delimitadores", {
            dono: form.model.dono,
            periodo: form.model._id,
        })
        console.log(e1, d1)
        const i = form.model.agendalista.agendamentos.map((m: any) => m._id).indexOf(form.model._id)
        form.model.agendalista.agendamentos.splice(i, 1);
        if (form.update)
            form.update()
        return "close"
    }
}
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";

export default class TemplateIrCommand {
    repository: Repository
    model: any
    constructor(repository: Repository, model: any) {
        this.repository = repository
        this.model = model
        this.model.nome = "Template Comandos IR"
    }

    async getFields(): Promise<{[key: string]: Field | Extra}> {
        this.model.tipo = this.model.parent.options.type
        return {
            "extras": new Extra("Template Comandos IR", "Gerar", "end/createircommand/true"),
            "tipo": new Field("show", "Tipo", ""),
            "marca": new Field("text", "Nome da Coleção", "marca-123")
        }
    }
}
import Repository from "@/core/repository/_repository";
import { store } from "@/store";

export default class UpdateUsuarioProjeto {
    static action = {
        timeout: 3000,
        text: "Atualizando Relacionamento Usuário"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, options: any): Promise<string> {
        const usuariosProjeto = {
            nome: options.nome,
            acesso: options.acesso,
            projId: store.state.projeto.projId,
        }
        console.log(usuariosProjeto);
        
        const [result, err] = await this.repository.update("UsuariosProjeto", form.model._id, usuariosProjeto)
        if(err) console.error(result);
        console.log(result);
        Object.assign(form.model, usuariosProjeto)
        return "close"
    }
}
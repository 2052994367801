import Repository from "@/core/repository/_repository";
import { store } from "@/store";
export default class UpdateInterruptor {
    static action = {
        timeout: 3000,
        text: "Atualizando Componente"
    }
    repository: Repository
    constructor(repository: Repository) {
        this.repository = repository
    }
    async execute(form: any, fields: any) {
        console.log(form)
        console.log(fields)
        console.log(store.state.projeto)
        Object.assign(form.model, fields)
        const aux = form.model.conexao
        delete form.model.conexao
        form.model.options.pulsar = fields.pulsar
        form.model.options.tempo = fields.tempo
        const [result, err] = await this.repository.update("Componentes", form.model._id, form.model)
        if (err) console.error(result);
        else console.log(result);
        form.model.conexao = aux
        return "close"
    }
}
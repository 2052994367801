<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="32"
    height="32"
    viewBox="0 0 8.4666665 8.4666669"
    version="1.1"
    id="svg5029"
    inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
    sodipodi:docname="temperaturaO.svg"
    class="icon-normal"
  >
    <defs id="defs5023" />
    <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="11.2"
      inkscape:cx="-17.295891"
      inkscape:cy="19.298669"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="false"
      inkscape:window-width="1920"
      inkscape:window-height="1057"
      inkscape:window-x="-8"
      inkscape:window-y="-8"
      inkscape:window-maximized="1"
      units="px"
      inkscape:showpageshadow="true"
    />
    <metadata id="metadata5026">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          <dc:title></dc:title>
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g
      inkscape:label="Camada 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(0,-288.53332)"
    >
      <path
        d="m 3.5094406,296.92554 c -0.676169,-0.20016 -1.237827,-0.73605 -1.478148,-1.41028 -0.100806,-0.28276 -0.135625,-0.83678 -0.07305,-1.16242 0.07546,-0.39304 0.309933,-0.82969 0.608991,-1.13414 l 0.260641,-0.2653 0.01217,-1.69418 0.01191,-1.69421 0.109194,-0.23637 c 0.126762,-0.27451 0.393276,-0.54375 0.659818,-0.66662 0.274637,-0.12655 0.785839,-0.11822 1.075504,0.0175 0.263367,0.12348 0.490141,0.34478 0.623967,0.60894 0.07879,0.15544 0.105543,0.27284 0.119989,0.52665 l 0.01852,0.3266 0.402669,0.0238 c 0.370126,0.022 0.409575,0.0318 0.487945,0.12134 0.104351,0.11917 0.109643,0.2822 0.01297,0.4018 -0.06593,0.0815 -0.108903,0.0912 -0.483394,0.10848 l -0.41111,0.019 -0.01111,0.3262 -0.01111,0.32623 0.234474,0.0246 c 0.128932,0.0135 0.315992,0.0246 0.415714,0.0246 0.150653,0 0.194548,0.0169 0.259847,0.0999 0.106336,0.13523 0.100647,0.29858 -0.01429,0.4137 -0.08705,0.087 -0.118983,0.0931 -0.492919,0.0931 h -0.399866 v 0.41394 0.41392 l 0.238549,0.22908 c 0.909637,0.87338 0.932338,2.29068 0.05091,3.16809 -0.600075,0.59735 -1.431448,0.81224 -2.228876,0.57618 z m -0.421878,-2.02528 c 0.01852,-0.0469 0.06287,-0.16833 0.09882,-0.2698 0.09154,-0.2586 0.393541,-0.66437 0.782664,-0.90217 0.175471,-0.10721 0.328612,-0.23009 0.352742,-0.28305 0.02963,-0.0646 0.05038,-0.6045 0.04469,-1.70183 v -1.59068 h 0.130916 0.337635 l -0.01746,-0.23698 c -0.0098,-0.13034 -0.02487,-0.27406 -0.03426,-0.31936 -0.0426,-0.21097 -0.390525,-0.46471 -0.639763,-0.46656 -0.192246,-0.003 -0.4368,0.14544 -0.556604,0.33433 l -0.109458,0.17258 v 1.77366 1.77364 l -0.12319,0.11041 c -0.502576,0.45032 -0.567478,0.52321 -0.693976,0.77973 -0.150256,0.30469 -0.217487,0.73239 -0.131471,0.83605 0.07334,0.0884 0.519668,0.0902 0.558959,-0.01 z"
        id="path4168-7-9-7"
        inkscape:connector-curvature="0"
        sodipodi:nodetypes="cccccccccccccccccccccsccscccccccccccsccccccccccccccc"
        inkscape:export-filename="C:\Users\felip\Desktop\geatel-master\_img\temperatura_17x32.png"
        inkscape:export-xdpi="96"
        inkscape:export-ydpi="96"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "nomal",
};
</script>
import AdapterGenerico from "@/core/adapter/_generico";
import Extra from "@/core/entity/Extra";
import Field from "@/core/entity/Field";
import Repository from "@/core/repository/_repository";
import UpdateFromAPI from "../../usecase/elements/updatefromapi";

export default class FormData {
    repository: Repository
    model: any
    constructor(repository: Repository, model: { objname: string; table: any }) {
        this.model = model
        this.repository = repository
    }
    async getFields(): Promise<{ [index: string]: Field | Extra }> {
        console.log(this.model)
        const fields: { [index: string]: Field | Extra } = {
            "extras": new Extra("Gerenciando Dados", "none", ""),
            "api": new Field("object", "Pegar dados da API","", [
                new AdapterGenerico({objname: "Somente Atualizar", value: "update"}),
                new AdapterGenerico({objname: "Atualizar e inserir", value: "insert"})
            ], {}, async o =>{
                return await new UpdateFromAPI(this.repository).execute(this.model.table, o.model.value)
            }),
            "data": new Field("objectTable", "Tabela", ""),
        }
        return fields
    }
}